import React, {useEffect, useState} from 'react';
import './AiPanel.css';
import {formatPrice, arraySum} from "./QBApp";
import CpiAddOnsPanel from "./cpi/CpiAddOnsPanel";

export default function AiPanel(props) {

    const TOTALS_COMPONENT_KEY = 'ai';
    const [checkedItems, setCheckedItems] = useState({});

    const handleChange = (event) => {
        console.log('AiPanel::handleChange() props.pricing ', props.pricing);
        console.log('AiPanel::handleChange() event.target.name: ' + event.target.name);
        console.log('AiPanel::handleChange() event.target.checked: ', event.target.checked);
        // updating an object instead of a Map
        let newVal = {...checkedItems, [event.target.name] : event.target.checked };
        console.log("AiPanel::handleChange() checkedItems: ", newVal);
        setCheckedItems(newVal);

        let aiSelected = [];
        let prices = Object.entries(props.pricing.ai_automation.options).reduce( (arr, [key, item]) => {
            if (key in newVal && newVal[key] === true) {
                arr.push(item.price);
                aiSelected = {...aiSelected, [key]: item}
            }
            props.setAiSelected(aiSelected);
            return arr;
        }, [])


        let totals = {...props.totals};
        totals[TOTALS_COMPONENT_KEY] = arraySum(prices);

        //console.log('AiPanel::handleChange() - props.totals originally was ', props.totals)
        //console.log('AiPanel::handleChange() - updating props.setTotals() to ', totals)
        props.setTotals(totals);

    }



    const items =  (
               Object.entries(props.pricing).length > 0
            && Object.entries(props.pricing.ai_automation).length > 0
            && Object.entries(props.pricing.ai_automation.options).length > 0
        )
        ?
            Object.entries(props.pricing.ai_automation.options).map( ([key, item]) => (
                <label htmlFor={key} key={key + '_label'} className="price-table">

                    <input type="checkbox" key={key + '_checkbox'} name={key} id={key} value={key} price={item.price} className="price-table"
                           checked={key in checkedItems ? checkedItems[key] : false}
                           onChange={handleChange}
                    />
                    {item.title}
                    <span
                        id={key}
                        key={key}
                        value={key + '_span'}
                        price={item.price}
                        className={"price-table" + (checkedItems[key] ? ' active' : '')}
                    >
                        {formatPrice(item.price, props.currencySymbol,'AiPanel.js')}
                    </span>

                    {(item.attachment && item.attachment.length > 0) ?
                        <div className="sowImg price-table">
                            <a target="_blank"
                               href={item.attachment}
                            >
                               <img src="/images/quote-builder/sow_scope_icon.png" border="0"/>
                            </a>
                        </div>
                        : ''
                    }
                </label>
            ))
        : ''
    ;


    useEffect(() => {
        console.log('AiPanel::useEffect() props:', props);
        setCheckedItems({});
        props.setAiSelected({});
        console.log('AiPanel::useEffect() reset setCheckedItems');
    },[props.apiData]);


    return (

        <>
            <div className="panel panel-default hidden_when_implementation_only clearable_panel" id="panel_ai">
                <div className="panel-heading panel-orange">AI & Automation<span
                    className="collapse-option"></span>
                </div>
                <div className="panel-body">
                    <a className="panel-description"
                       href="/images/quote-builder/Solution_Details.pdf" target="_blank">View
                        detailed descriptions</a>

                    {props.flowType.isUpsell &&
                        <div className="quick-tip hr-top-gray-solid">
                            <strong>Quick Tip:</strong> Don't forget to re-add recurring packages this customer had
                            previously
                        </div>
                    }

                    <h3 className="panel-header mb-0">Monthly Conversation Pool</h3>

                    <label htmlFor="bot_dar" name="bot_dar" className="price-table">
                        <input checked={true} type="checkbox"
                               name="bot_dar"
                               className="price-table active"
                               disabled="disabled"
                        />
                        <span id="users">{props.namedUserNumber}</span> platform users * 1,000 billable bot conversations
                        <span price="Included" id="bot_dar" name="bot_dar" className="price-table active">
                            {formatPrice(props.namedUserNumber * 1000, '', 'AiPanel.js')}
                        </span>
                    </label>
                    <h3 className="panel-header mb-0">Build Your Own</h3>
                    <label htmlFor="bot_dar" name="bot_dar" className="price-table">
                        <input checked={true} type="checkbox"
                               name="conversation_builder"
                               className="price-table active"
                               disabled="disabled"
                        />
                        <span>Conversation Builder</span>
                        <span price="Included" id="conversation_builder" name="conversation_builder"
                              className="price-table active">
                                Included
                        </span>
                    </label>

                    <h3 className="panel-header">We Build For You <small>Includes bot development, implementation
                        and
                        ongoing optimization (on select packages)</small>
                    </h3>
                    <p className="panel-monthly-cost pull-up">Monthly Cost</p>

                    { items }

                </div>
                <div className="panel-footer">
                    <p className="subtotal">AI &amp; Automation total <span className="ai_auto_total">
                        {formatPrice(props.totals[TOTALS_COMPONENT_KEY], props.currencySymbol,'AiPanel.js')}
                    </span></p>
                </div>
            </div>
        </>

    )
}




