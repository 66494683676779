import React, {useEffect, useState} from 'react';
import './MyQuoteSummaryPanel.css';
import {
    formatPrice,
    formatInputPrice,
    arraySum,
    QUOTE_FLOW_FLAG_IS_ORIGINAL,
    QUOTE_FLOW_FLAG_IS_RENEWAL,
    PRICING_MODEL_NAMED_USER,
    PRICING_MODEL_VELOCITY,
    PRICING_MODEL_ALWAYS_ON_ADVISOR,
    getTotalKeysByPricingModel, PRICING_MODEL_CPI, info_modal, truncateFloat
} from "./QBApp";

import CpiMyQuote, {getFilteredItems} from "./cpi/CpiMyQuote";
import CpiInteractionCostPanel, {
    getDivisionFactorForBillingPeriod,
    getFrequencyNumberFromLabel
} from "./cpi/CpiInteractionCostPanel";
import {
    TOTALS_COMPONENT_KEY_CPI_EXPERT_ONETIME_CONFIGURATION, TOTALS_COMPONENT_KEY_CPI_EXPERT_ONETIME_DEPLOYMENT,
    TOTALS_COMPONENT_KEY_CPI_EXPERT_ONETIME_TRAINING
} from "./cpi/CpiServicesPanel";


export default function MyQuoteSummaryPanel(props) {

    /**
     * We keep both "percentage discounts" and "amounts after discounts"
     * managed locally to allow back and forth dynamic conversion in the UI
     * and only send up the prop updates for props.setDiscounts since we only
     * really need the percentage discounts (and not the subtotal dollar amounts)
     * to generate Quotes
     */
    const [percentageDiscounts, setPercentageDiscounts] = useState({}); // discount e.g. 20 for 20% discount
    const [carets, setCarets] = useState({users: false, ai : false, expert_services: false , onetime: false });

    const [recurrentTotal, setRecurrentTotal] = useState(0);
    const [annualTotal, setAnnualTotal] = useState(0);


    const handleExportData = () => {

/*
        if(props.cpiSelected === ''){
            info_modal('Data Export Issue', 'Please select a Conversational Cloud Automation package')
            return false;
        } else
*/
        if(!props.cpiCreditsNumber>0){
            info_modal('Data Export Issue', 'Please select number of interaction credits')
            return false;
        }


        // '?discounts='+ encodeURIComponent(JSON.stringify(props.discounts))

        // props.discounts:
        //
        // "cpi_billing_period_total":1,
        // "cpi_overage_cost":2,
        // "cpi_automation":4,
        // "cpi_addons":5,
        // "cpi_expert_services":6,
        // "cpi_onetime_deployment":7,
        // "cpi_onetime_training":8
        // "cpi_onetime_configuration":9

        // CPI data:
        /*
           cpiCreditsNumber={cpiCreditsNumber}
            setCpiCreditsNumber={setCpiCreditsNumber}
            cpiProductSelectedQuantity={cpiProductSelectedQuantity}
            setCpiProductSelectedQuantity={setCpiProductSelectedQuantity}
            cpiAddOnsSelected={cpiAddOnsSelected}
            setCpiAddOnsSelected={setCpiAddOnsSelected}
            cpiSelected={cpiSelected}
            setCpiSelected={setCpiSelected}
            cpiOnetimeSelected={cpiOnetimeSelected}
            setCpiOnetimeSelected={setCpiOnetimeSelected}
            billingDetails={billingDetails}
            cpiAutomationLevelTotal={cpiAutomationLevelTotal}
            setCpiAutomationLevelTotal={setCpiAutomationLevelTotal}
            cpiAddOnsTotal={cpiAddOnsTotal}
            setCpiAddOnsTotal={setCpiAddOnsTotal}
            cpiExpertServicesRecurrentTotal={cpiExpertServicesRecurrentTotal}
            setCpiExpertServicesRecurrentTotal={setCpiExpertServicesRecurrentTotal}
            cpiExpertServicesOnetimeTotal={cpiExpertServicesOnetimeTotal}
            setCpiExpertServicesOnetimeTotal={setCpiExpertServicesOnetimeTotal}
            cpiExpertServicesOnetimeTrainingTotal={cpiExpertServicesOnetimeTrainingTotal}
            setCpiExpertServicesOnetimeTrainingTotal={setCpiExpertServicesOnetimeTrainingTotal}
            cpiExpertServicesOnetimeConfigurationTotal={cpiExpertServicesOnetimeConfigurationTotal}
            setCpiExpertServicesOnetimeConfigurationTotal={setCpiExpertServicesOnetimeConfigurationTotal}
            cpiExpertServicesOnetimeDeploymentTotal={cpiExpertServicesOnetimeDeploymentTotal}
            setCpiExpertServicesOnetimeDeploymentTotal={setCpiExpertServicesOnetimeDeploymentTotal}
            getBillingPeriodPrice={getBillingPeriodPrice}
         */



        let payload =  {
            Account: props.selectedAccount,
            CurrencySymbol: props.currencySymbol,
            BillingPeriod : props.billingDetails?.billing_period?.value,
            PoolingFrequency: props.contactInfo.cpi_pooling_frequency,
            PoolingFrequencyMultiplier : 12
                / getFrequencyNumberFromLabel(props.contactInfo.cpi_pooling_frequency)
                / getDivisionFactorForBillingPeriod(props.billingDetails?.billing_period?.value),

            Interaction: {
                PeriodCredits: props.cpiCreditsNumber || 0,
                UnitListPrice: props.cpiCreditsCost.price || 0,
                PeriodPrice: props.cpiPeriodMinimumFee || 0,
                Discount: props.discounts.cpi_billing_period_total || 0,
                PeriodMinimumTotal:props.totals.cpi_billing_period_total || 0,
                DiscountedUnitPrice: props.totals.cpi_overage_cost || 0,
                OverageMarkup: props.discounts.cpi_overage_cost || 0,
                OverageCost: props.totals.cpi_overage_marked_up || 0
            },

            Automation: {
                Selection: props.cpiSelected[Object.keys(props.cpiSelected)[0]]
                    ? props.cpiSelected[Object.keys(props.cpiSelected)[0]].title // e.g. 'Conversation Builder',
                    : 'None',
                Price: props.cpiSelected[Object.keys(props.cpiSelected)[0]]
                    ? props.cpiSelected[Object.keys(props.cpiSelected)[0]].price / getDivisionFactorForBillingPeriod(props.billingDetails.billing_period.label)
                    : 0,
                Discount: props.discounts.cpi_automation || 0,
                Subtotal: props.cpiAutomationLevelTotal || 0,
            },

            AddOns: {
                Selections: [
                    // from props.cpiAddOnsSelected, populated below
                    // {Selection: 'Social Connect', price: 1},
                    // {Selection: 'Social Connect', price: 1},
                ],
                'Discount' : props.discounts.cpi_addons || 0,
                'Subtotal': props.discounts.cpi_addons
                        ? (props.cpiAddOnsTotal - (props.cpiAddOnsTotal * props.discounts.cpi_addons / 100))
                        : props.cpiAddOnsTotal
            },

            Recurrent: {
                Selections: [
                    // from props.servicesMonthlySelected, populated below
                    // {Selection: 'ESP - Tier Bronze', price: 1},
                    // {Selection: 'COE - Tier Gold', price: 1},
                ],
                'Discount': props.discounts.cpi_expert_services || 0,
                'Subtotal': props.discounts.cpi_expert_services
                    ? (props.cpiExpertServicesRecurrentTotal - (props.cpiExpertServicesRecurrentTotal * props.discounts.cpi_expert_services / 100))
                    : props.cpiExpertServicesRecurrentTotal
            },
            OneTimeDeploy: {
                Selections: [
                    // from props.servicesMonthlySelected, populated below
                    // {Selection: 'Deployment 1', price: 1},
                    // {Selection: 'Deployment 2', price: 1},
                ],
                'Discount': props.discounts.cpi_onetime_deployment || 0,
                'Subtotal': props.discounts.cpi_onetime_deployment
                    ? (props.cpiExpertServicesOnetimeDeploymentTotal - (props.cpiExpertServicesOnetimeDeploymentTotal * props.discounts.cpi_onetime_deployment / 100))
                    : props.cpiExpertServicesOnetimeDeploymentTotal
            },
            OneTimeTraining: {
                Selections: [
                    // from props.servicesMonthlySelected, populated below
                    // {Selection: 'Training 1', price: 1, qty: 50},
                    // {Selection: 'Training 2', price: 1, qty: 10},
                ],
                'Discount': props.discounts.cpi_onetime_training || 0,
                'Subtotal': props.discounts.cpi_onetime_training
                    ? (props.cpiExpertServicesOnetimeTrainingTotal - (props.cpiExpertServicesOnetimeTrainingTotal * props.discounts.cpi_onetime_training / 100))
                    : props.cpiExpertServicesOnetimeTrainingTotal
            },
            OneTimeConfiguration: {
                Selections: [
                    // from props.servicesMonthlySelected, populated below
                    // {Selection: 'SKU Name', price: 1, qty: 50},
                ],
                'Discount': props.discounts.cpi_onetime_configuration || 0,
                'Subtotal': props.discounts.cpi_onetime_configuration
                    ? (props.cpiExpertServicesOnetimeConfigurationTotal - (props.cpiExpertServicesOnetimeConfigurationTotal * props.discounts.cpi_onetime_configuration / 100))
                    : props.cpiExpertServicesOnetimeConfigurationTotal
            },

        } // payload


        Object.entries(props.cpiAddOnsSelected).map(([productCode, product]) => (
            payload.AddOns.Selections.push({
                Selection: product.title,
                price: product.price / getDivisionFactorForBillingPeriod(props.billingDetails.billing_period.label)
            })
        ));

        Object.entries(props.servicesMonthlySelected).map(([productCode, product]) => (
            payload.Recurrent.Selections.push({
                Selection: product.title,
                price: product.price / getDivisionFactorForBillingPeriod(props.billingDetails.billing_period.label)
            })
        ));

        Object.entries(props.onetimeSelected).map(([productCode, product]) => (
            (product.type === 'cpi_deployment' &&
                payload.OneTimeDeploy.Selections.push({
                    Selection: product.title,
                    price: product.price * props.cpiProductSelectedQuantity[productCode], // default Qty (non-editable) ,
                    default_qty: props.cpiProductSelectedQuantity[productCode]
                })
            )
            ||
            ((product.type === 'cpi_training' || product.type === 'cpi_configuration') &&
            payload.OneTimeTraining.Selections.push({
                Selection: product.title,
                price: product.price,
                qty: props.cpiProductSelectedQuantity[productCode]
            })
            )

        ));

        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'X-CSRF-TOKEN': window.csrf_token
            },
            body: JSON.stringify(payload)
        };

        window.qb.data_export_screen.display();

        fetch('/export_data', requestOptions)

        .then(async response => {
           const data = await response.json();
           console.log('got: ', data);
           window.qb.data_export_screen.done(data.url);
        })
        // .then(data => setPostId(data.id))
        .catch(error => {
            console.log('ERROR:', error)
        })
    }

    const onetimeTotal = (isInput, currencySymbol=null) => {

        // NU + CPI one-time fees keys for highest order component props.totals and props.discounts
        let keys = [
            TOTALS_COMPONENT_KEY_CPI_EXPERT_ONETIME_TRAINING,
            TOTALS_COMPONENT_KEY_CPI_EXPERT_ONETIME_CONFIGURATION,
            TOTALS_COMPONENT_KEY_CPI_EXPERT_ONETIME_DEPLOYMENT
        ]
        let total = 0
        keys.forEach(function(item, index) {
            total = total + (props.discounts[item]
                ? (props.totals[item] - (props.totals[item] * props.discounts[item] / 100))
                : props.totals[item]);
        });
        return isInput ? formatInputPrice(total) : formatPrice(total, currencySymbol)
    }

    const toggleCaret = (e, key, oldVal) => {
        e.preventDefault();
        let newCarets = []
        newCarets[key] = oldVal === false ? true : false
        // console.log(' carets[   '+ key + '] : ' +  newCarets[key])
        setCarets({...carets, ...newCarets})

    }

    const caretController = (key) => (
        <a onClick={(e) => toggleCaret(e, key, carets[key])} href="#">
            <span style={{marginLeft:6,color:"#cccccc"}}>
                <i className={'fas fa-caret-' + (carets[key] ? 'down': 'right') + ' fa-lg'}></i>
            </span>
        </a>
    )
    const handleHideDiscounts = (value) => {
        props.setHideDiscounts(value ? true : false);
        // console.log('MyQuoteSummaryPanel.js:handleHideDiscounts props.setHideDiscounts() set to ' + value)
    }

    const handleDollarDiscount = (key, value) => {

        console.log('MyQuoteSummaryPanel.js:handleDollarDiscount() - key: '+key+' value: ' + value);

        // convert $ to % and update relevant fields
        let amount = (key === 'users') ? props.namedUserTotal : props.totals[key]; // dollar amount entered (after discount)
        let percentageValue = (100 - (100 * parseFloat(value) / amount)).toFixed(8)

        console.log('MyQuoteSummaryPanel.js:handleDollarDiscount() - converted dollar amount: '+value+' to percentage: ' + percentageValue);
        // shallow copy it with spread operator to force the parent component to re-render
        let discounts = {...props.discounts}    // % discounts e.g. {users: "10"} for 10%
        discounts[key] = parseFloat(percentageValue);
        props.setDiscounts(discounts);

        // force total dollar discount updates when post-discount dollar amounts get manually updated
        recalculateTotals(key, value);
    }

    const handlePercentageDiscount = (key, value) => {
        // e.g. users => 5  (meaning 5% discount on platform users)
        // console.log('MyQuoteSummaryPanel.js:handlePercentageDiscount() for: ' + key + ' = ' + value);
        // shallow copy it with spread operator to force the parent component to re-render
        let discounts = {...props.discounts}    // % discounts e.g. {users: "10"} for 10%

        discounts[key] = parseFloat(value);
        props.setDiscounts(discounts);

        // console.log('MyQuoteSummaryPanel.js:handlePercentageDiscount() props.discounts set to: ', discounts);

        // percentage discount provided
        let tmp = {...percentageDiscounts};
        // console.log('MyQuoteSummaryPanel.js:handlePercentageDiscount() percentageDiscounts (percentage) {...percentageDiscounts}: ', tmp);
        tmp[key] = parseFloat(value); // e.g. {users : 10} for "10%"
        setPercentageDiscounts(tmp) // local state for field display

        let amount = (key === 'users') ? props.namedUserTotal : props.totals[key]; // dollar amount entered (after discount)
        // force total dollar discount updates when %ages get manually updated
        recalculateTotals(key, (amount - (amount * value/100)));
    }

    function recalculateTotals(forceUpdateDollarKey= null, forceUpdateDollarValue=null) {


        // console.log('MyQuoteSummaryPanel.js::recalculateTotals() props.totals:', props.totals)
        // populate the amount after discount with default pre-discounted values if the key is not there yet
        // e.g. we have logic for amountsAfterDiscounts.ai || props.totals.ai below... that shouldn't be needed
        /*
        for(let key in props.totals){
            if(!key in amountsAfterDiscounts){
                let val = props.totals[key];
                console.log('MyQuoteSummaryPanel.js::recalculateTotals() setting '+key+' in amountsAfterDiscounts to '+val)
                amountsAfterDiscounts[key] = val;
            }
        }*/
        let amountsAfterDiscounts = [];

        if(props.pricingModel === PRICING_MODEL_NAMED_USER) {
            amountsAfterDiscounts['users'] = parseFloat(
                props.discounts['users']
                    ? (props.namedUserTotal - (props.namedUserTotal * props.discounts['users'] / 100))
                    : props.namedUserTotal
            );
        }

        for(let key in props.totals){

            // a mapping of all the pricing keys
            // only tally up the keys specific to the current pricing model
            if(getTotalKeysByPricingModel(props.pricingModel).includes(key)
            ) {
                let discountAmount = parseFloat(
                    props.discounts[key]
                        ? (props.totals[key] - (props.totals[key] * props.discounts[key] / 100))
                        : props.totals[key]
                );
                discountAmount = isNaN(discountAmount) ? 0 : discountAmount
                amountsAfterDiscounts[key] = discountAmount

            }
            /*

            // only sum up model-specific keys
            if(props.pricingModel === PRICING_MODEL_VELOCITY && key !== TOTALS_COMPONENT_KEY_VELOCITY_PACKAGES) {
                // do not sum up velocity for non-velocity
                continue;
            }else if(props.pricingModel === PRICING_MODEL_NAMED_USER && key === TOTALS_COMPONENT_KEY_VELOCITY_PACKAGES) {
                // do not sum up named user for velocity
                continue;
            }
            */

        }

        console.log('MyQuoteSummaryPanel::amountsAfterDiscounts : ', amountsAfterDiscounts); // includes onetime


        if(forceUpdateDollarKey !== null && forceUpdateDollarValue !== null){
            amountsAfterDiscounts[forceUpdateDollarKey] = truncateFloat(forceUpdateDollarValue);
        }

        let recurrent = arraySum(Object.values(amountsAfterDiscounts))
        if('onetime' in amountsAfterDiscounts){
            recurrent = recurrent - amountsAfterDiscounts['onetime'];
        }
        if('cpi_onetime_deployment' in amountsAfterDiscounts){
            recurrent = recurrent - amountsAfterDiscounts['cpi_onetime_deployment'];
        }
        if('cpi_onetime_configuration' in amountsAfterDiscounts){
            recurrent = recurrent - amountsAfterDiscounts['cpi_onetime_configuration'];
        }
        if('cpi_onetime_training' in amountsAfterDiscounts){
            recurrent = recurrent - amountsAfterDiscounts['cpi_onetime_training'];
        }

        console.log('-----------recurrent:', recurrent);
        console.log('-----------arraySum(Object.values(amountsAfterDiscounts)):', arraySum(Object.values(amountsAfterDiscounts)));

        let newRecurrent = (recurrent > 0 && !isNaN(recurrent)) ? recurrent : 0

        props.setRecurrentTotal(newRecurrent ?? 0)
        // props.setOnetimeTotal(CPI_ONETIME_TOTALS['cpi_onetime'] ?? 0 )

        //props.setCpiExpertServicesOnetimeTrainingTotal(getItemsTotal(getFilteredItems(props.cpiOnetimeSelected,'cpi_training')))
        //props.setCpiExpertServicesOnetimeDeploymentTotal(getItemsTotal(getFilteredItems(props.cpiOnetimeSelected,'cpi_deployment')))


        setRecurrentTotal(newRecurrent);

        console.log('amountsAfterDiscounts: ', amountsAfterDiscounts)
        setAnnualTotal(
            newRecurrent * 12
        );
    }

    const smsLineItems =
        Object.entries(props.smsSelectedQuantity).length > 0
            ? Object.entries(props.smsSelectedQuantity).map(([key, quantity]) => (
                (quantity > 0 && 'guided_sms' in props.onetimeSelected) // strictly SMS, excludes WhatsApp
                && props.pricing[key].price > 0 // exclude free products e.g. exclude sms_options_long, which is free
                &&  <div key={key} style={{width:230}}>
                        <div style={{minWidth:150,width:150,height:22,overflow:"hidden",float:"left"}}>
                            {quantity} {'SMS '+ (props.pricing[key].short_title) }
                        </div>
                        <div style={{minWidth:70,width:70,height:22,float:"right",textAlign:"right",paddingRight:10}}>
                            {formatPrice(quantity * props.pricing[key].price, props.currencySymbol)}
                        </div>
                    </div>

            ))
            : ''

    const getSinglePackageSummary = (totalValue, packageKey, packageLabel, totalLabel, totalHeaderLabel='Total Fee') => {

        console.log("MyQuoteSummaryPanel.js: getSinglePackageSummary() props.totals: w/ packageKey "+packageKey ,props.totals )
        console.log("MyQuoteSummaryPanel.js: getSinglePackageSummary() props.totals[packageKey]: ", props.totals[packageKey])

        return <>

            <div className="row">
                <div className="col-xl-12 col-lg-4 paddingLRNone marginTop8">
                    Products Purchased <span className="floatRight">{totalHeaderLabel}</span>
                    <hr className="hr-solid" style={{margin: "0 0 5px 0"}} />
                </div>


                <div className="col-xl-12 col-lg-4 paddingLRNone">
                    <p className="cost-totals">
                        {packageLabel}
                        <span name={"quote_monthly_total__"+packageKey}>
                            {formatPrice(props.totals[packageKey], props.currencySymbol)}
                        </span>
                    </p>
                </div>

                <div style={{marginTop: 10}}>
                    <input type="number"
                        id={packageKey+"__discount"}
                        className="numericInput"
                        style={{marginRight: 0, textAlign:'center'}}
                        onChange={(event) => handlePercentageDiscount(packageKey, event.target.value)}
                        value={props.discounts[packageKey] || ''}
                    /> % Discount
                </div>

                <div style={{marginTop: 9, marginLeft: 20}}>
                    <span className="subtotalHeaderInline">Subtotal &nbsp;</span>
                    <span className="floatRight"> {props.currencySymbol}
                        <input type="number"
                               className="numericInput dollarInput"
                               id={packageKey+"__subtotal"}
                               value={formatInputPrice(
                                   props.discounts[packageKey]
                                       ? (props.totals[packageKey] - (props.totals[packageKey] * props.discounts[packageKey] / 100))
                                       : props.totals[packageKey]
                               )}
                               style={{marginRight: 0}}
                               onChange={(event) => handleDollarDiscount(packageKey, event.target.value)}
                        />
                    </span>
                </div>


                <div className="col-xl-12 col-lg-4 paddingLRNone" style={{marginTop: 5}}>

                    <hr className="hr-dotted" style={{margin:"0 0 5px 0"}}/>
                    <p className="cost-totals">
                        {totalLabel}
                        <span name={"quote_monthly_total__"+packageKey}>
                            {formatPrice(totalValue, props.currencySymbol)}
                        </span>
                    </p>
                </div>

            </div>
        </>
    }

    const hasEtsImplementationPackage = () => {
        return (   Object.keys(props.onetimeSelected).includes('LPPS-00001')
            || Object.keys(props.onetimeSelected).includes('LPPS-00002')
            || Object.keys(props.onetimeSelected).includes('LPPS-00003')
            || Object.keys(props.onetimeSelected).includes('LPPS-00008')
        )
    }

    useEffect(() => {
        // console.log('MyQuoteSummaryPanel::useEffect() props.totals WAS UPDATED:', props.totals)
        // console.log('MyQuoteSummaryPanel::useEffect() props.discounts:', props.discounts)

        for(let index in props.discounts){
            handlePercentageDiscount(index, props.discounts[index])
            // console.log('handlePercentageDiscount() re-set for '+ index + '  to ' + props.discounts[index] + ' to reflect updated pricing totals')
        }

        // console.log('MyQuoteSummaryPanel::useEffect() props.aiSelected: ', props.aiSelected)
        recalculateTotals();


        // TODO rm dependency -- used by window.qb.loading_screen.done() in QBApp.js via quote_builder.js
        // it's a legacy hook to is_discount_justification_required() function in quote_builder.js
        window.hasOneTimeServices =
                // either we got a mix of implementation & non-implementation SKUs (only one implementation SKU can be selected)
            (hasEtsImplementationPackage() && Object.keys(props.onetimeSelected).length > 1)
                // as long as it's NOT just a single implementation SKU selected
            || (!hasEtsImplementationPackage() && Object.keys(props.onetimeSelected).length >= 1)
        ;


        console.log(recurrentTotal)

    },[props.totals,
        props.onetimeSelected,  // covers default pre-selects
        props.showSmallScrollableQuote,
        props.smsSelectedQuantity, // SMS quantity updates
        props.namedUserTotal,
        props.flowType,
        props.useNewOpportunity,
        props.pricingModel,
/*
        props.cpiProductSelectedQuantity,
        props.cpiExpertServicesRecurrentTotal,
        props.cpiExpertServicesOnetimeTotal,
        props.discounts

 */
    ]);


    const getTotalAmountByKey = (key) => {
        let amount = 0
        switch (key) {
            /*
            not used:
             */
            case 'cpi_automation':
                amount = props.cpiAutomationLevelTotal
                break;
            case 'cpi_addons':
                amount = props.cpiAddOnsTotal
                break;



            case 'cpi_expert_services':
                amount = props.cpiExpertServicesRecurrentTotal
                break;
            case 'cpi_onetime':
                amount = props.cpiExpertServicesOnetimeTotal
                break;
            case 'cpi_onetime_training':
                amount = props.cpiExpertServicesOnetimeTrainingTotal
                break;
            case 'cpi_onetime_configuration':
                amount = props.cpiExpertServicesOnetimeConfigurationTotal
                break;
            case 'cpi_onetime_deployment':
                amount = props.cpiExpertServicesOnetimeDeploymentTotal
                break;
            case 'cpi_billing_period_total':
                amount = props.totals.cpi_billing_period_total
                break;
        }
        return amount
    }

    /**
     *
     * @param key e.g. training|deployment
     */
    const onetimeBlock = (key, title) => {
        return <div style={{marginBottom:5,marginTop:5}}><i><span style={{textTransform: 'capitalize'}}>{title}</span> (onetime)</i>

            {caretController('cpi_onetime_'+key)}
            {!carets['cpi_onetime_' + key]
            &&
            <>
                <span className="total-packages-onetime2 floatRight">
                    {formatPrice(getTotalAmountByKey('cpi_onetime_' + key), props.currencySymbol)}
                </span>
            </>

            ||
            <>

                <div>
                    {lineItems(
                        getFilteredItems(props.onetimeSelected, 'cpi_' + key)
                        , props.currencySymbol
                        , false
                        , props
                    )
                    }
                </div>
                <div style={{marginTop: 10}}>
                    <input type="number"
                           id={"onetime__discount_"+key}
                           className="numericInput"
                           style={{marginRight: 0, textAlign:'center'}}
                           onChange={(event) => handlePercentageDiscount('cpi_onetime_'+key, event.target.value)}
                           value={props.discounts['cpi_onetime_' + key] || ''}
                    /> % Discount
                </div>
                {subTotalBlock(props, 'cpi_onetime_'+key, {marginTop: 5})}
            </>
            }

        </div>
    }

    const subTotalBlock = (props, key, style, totalOverrideValue=null) => {

        return <div style={style}>
            <span className="subtotalHeader">Subtotal</span>
            <span className="floatRight">
                {props.currencySymbol}
                <input type="number"
                       className="numericInput dollarInput"
                       id={key+"__subtotal"}
                       value={formatInputPrice(

                           totalOverrideValue===null
                               ? discountedTotal(key)
                               : totalOverrideValue
                       )}
                       style={{marginRight: 0}}
                       onChange={(event) => handleDollarDiscount(key, event.target.value)}
                />
            </span>
        </div>
    }

    const discountedTotal = (key) => {

        let total = props.discounts[key]
            ? (getTotalAmountByKey(key) - (getTotalAmountByKey(key) * props.discounts[key] / 100))
            : getTotalAmountByKey(key)

        console.log('MyQuoteSummaryPanel::discountedTotal getting total for key: ', key, ' getTotalAmountByKey() = ', getTotalAmountByKey(key));

        return total;
    }


    return (
        <>
         <div className="row" id="myQuoteContainer">


            <div id="my_quote_sidebar_commercial"
                 className={
                     'col-lg-2 col-md-3 sidebar clearable_panel '
                     + (props.floatWhenScrolling ? ' fix-sidebar' : '')
                 }
                 style={
                     props.showSmallScrollableQuote
                     ? {minWidth:CONTAINER_MIN_WIDTH_WITH_SCROLLBAR,
                         direction:"rtl",
                         overflowY:"auto",
                         height: (window.innerHeight-20)
                       }
                     : {minWidth:CONTAINER_MIN_WIDTH_WITHOUT_SCROLLBAR}
                 }
            >


                <div className="sidebar-header2">
                    <div className="myQuote">
                        <div className="floatLeft">
                            <img src="/images/quote-builder/Quote-Icon.png" />
                        </div>
                        <div className="floatLef myQuoteText">
                            <div>My Quote</div>
                            <div className="subtitle">
                                {props.quoteType}
                                {
                                    (
                                        !props.flowType[QUOTE_FLOW_FLAG_IS_ORIGINAL]
                                        && !props.flowType[QUOTE_FLOW_FLAG_IS_RENEWAL]
                                    )
                                        && (props.useNewOpportunity ? " (NEW)" : " (CHANGE)")
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <div className="sidebar-body" style={{fontSize:"13px",clear: "both"}}>

                    {
                     (props.pricingModel === PRICING_MODEL_ALWAYS_ON_ADVISOR) && (
                         getSinglePackageSummary(recurrentTotal*12, 'managed_services', 'Always On Advisor', 'Annual total', 'Monthly Fee')
                     )
                    ||
                     (props.pricingModel === PRICING_MODEL_VELOCITY) && (
                         getSinglePackageSummary(recurrentTotal, 'velocity', 'Velocity Package', 'Total Cost')
                     )

                    || (props.pricingModel === PRICING_MODEL_CPI) && (
                        <CpiMyQuote
                            totals={props.totals}
                            onetimeTotal={onetimeTotal}
                            discounts={props.discounts}
                            currencySymbol={props.currencySymbol}

                            servicesMonthlySelected={props.servicesMonthlySelected} // re-used across models
                            cpiCreditsNumber={props.cpiCreditsNumber}
                            setCpiCreditsNumber={props.setCpiCreditsNumber}
                            cpiProductSelectedQuantity={props.cpiProductSelectedQuantity}
                            setCpiProductSelectedQuantity={props.setCpiProductSelectedQuantity}
                            cpiAddOnsSelected={props.cpiAddOnsSelected}
                            setCpiAddOnsSelected={props.setCpiAddOnsSelected}
                            cpiSelected={props.cpiSelected}
                            setCpiSelected={props.setCpiSelected}
                            cpiOnetimeSelected={props.cpiOnetimeSelected}
                            setCpiOnetimeSelected={props.setCpiOnetimeSelected}
                            setDiscounts={props.setDiscounts}
                            setRecurrentTotal={props.setRecurrentTotal}
                            setOnetimeTotal={props.setOnetimeTotal}
                            onetimeSelected={props.onetimeSelected}

                            billingDetails={props.billingDetails}
                            cpiAutomationLevelTotal={props.cpiAutomationLevelTotal}
                            setCpiAutomationLevelTotal={props.setCpiAutomationLevelTotal}
                            cpiAddOnsTotal={props.cpiAddOnsTotal}
                            setCpiAddOnsTotal={props.setCpiAddOnsTotal}
                            cpiExpertServicesRecurrentTotal={props.cpiExpertServicesRecurrentTotal}
                            setCpiExpertServicesRecurrentTotal={props.setCpiExpertServicesRecurrentTotal}
                            cpiExpertServicesOnetimeTotal={props.cpiExpertServicesOnetimeTotal}
                            setCpiExpertServicesOnetimeTotal={props.setCpiExpertServicesOnetimeTotal}
                            cpiExpertServicesOnetimeTrainingTotal={props.cpiExpertServicesOnetimeTrainingTotal}
                            setCpiExpertServicesOnetimeTrainingTotal={props.setCpiExpertServicesOnetimeTrainingTotal}
                            cpiExpertServicesOnetimeConfigurationTotal={props.cpiExpertServicesOnetimeConfigurationTotal}
                            setCpiExpertServicesOnetimeConfigurationTotal={props.setCpiExpertServicesOnetimeConfigurationTotal}
                            cpiExpertServicesOnetimeDeploymentTotal={props.cpiExpertServicesOnetimeDeploymentTotal}
                            setCpiExpertServicesOnetimeDeploymentTotal={props.setCpiExpertServicesOnetimeDeploymentTotal}
                            cpiPeriodMinimumFee={props.cpiPeriodMinimumFee}

                            getBillingPeriodPrice={props.getBillingPeriodPrice}
                            pricing={props.pricing}
                        />
                    )
                    || (props.pricingModel === PRICING_MODEL_NAMED_USER) && (
                     <div className="row">
                        <div className="col-xl-12 paddingLRNone marginTop8">
                            Products Purchased <span className="floatRight">Monthly Fee</span>
                            <hr className="hr-solid" style={{margin: "0 0 5px 0"}} />

                            <div style={{marginBottom:5}}><i>Platform Users</i>
                                {caretController('users')}
                                {!carets.users
                                    &&
                                        <>
                                        <span className="total-packages-users2 floatRight">
                                            {formatPrice(props.namedUserTotal, props.currencySymbol)}
                                        </span>
                                        </>

                                    ||
                                        <>
                                        <p className="subtotal">
                                            {props.namedUserNumber} {props.namedUserPlatformLevel.level ?? ''} Users

                                            <span className="total-packages-users2">
                                                {formatPrice(props.namedUserTotal, props.currencySymbol)}
                                            </span>
                                        </p>
                                        <input type="number"
                                               className="numericInput"
                                               id="platform_users__discount"
                                               value={props.discounts.users || ''}
                                               style={{marginRight:0, textAlign:'center'}}
                                               onChange={( event ) => handlePercentageDiscount('users', event.target.value)}
                                               placeholder="0"
                                        /> % Discount

                                        <div style={{marginTop: 5}}>
                                            <span className="subtotalHeader">Subtotal</span>
                                            <span className="floatRight">
                                                 {props.currencySymbol} <input type="number"
                                                       className="numericInput dollarInput"
                                                       id="platform_users__subtotal"
                                                       value={formatInputPrice(
                                                           props.discounts.users
                                                                ? (props.namedUserTotal-(props.namedUserTotal * props.discounts.users/100))
                                                                : props.namedUserTotal
                                                       )}
                                                       style={{marginRight:0, marginBottom: 4}}
                                                       onChange={( event ) => handleDollarDiscount('users', event.target.value)}
                                                       // onBlur={() => props.actions.updateInput(this.state.inputValue)}
                                                       prefix={props.currencySymbol}

                                                />

                                            </span>

                                        </div>
                                        {hr}
                                        </>
                                }
                            </div>


                            <div style={{marginBottom:5}}><i>AI &amp; Automation</i>
                                {caretController('ai')}
                                {!carets.ai
                                &&
                                <>
                                    <span className="total-packages-ai2 floatRight">
                                        {formatPrice(props.totals.ai, props.currencySymbol)}
                                    </span>
                                </>

                                ||
                                <>
                                    <div>
                                        {lineItems(props.aiSelected, props.currencySymbol, true, props)}
                                    </div>

                                    <div style={{marginTop: 10}}>
                                        <input type="number"
                                            id="ai_automation__discount2"
                                            className="numericInput"
                                            style={{marginRight: 0, textAlign:'center'}}
                                            onChange={(event) => handlePercentageDiscount('ai', event.target.value)}
                                            value={props.discounts.ai || ''}

                                        /> % Discount
                                    </div>

                                    <div style={{marginTop: 5}}>
                                        <span className="subtotalHeader">Subtotal</span>
                                        <span className="floatRight">
                                            {props.currencySymbol}
                                            <input type="number"
                                              className="numericInput dollarInput"
                                              id="ai__subtotal"
                                              value={formatInputPrice(
                                                  props.discounts.ai
                                                      ? (props.totals.ai - (props.totals.ai * props.discounts.ai / 100))
                                                      : props.totals.ai
                                              )}
                                              style={{marginRight: 0}}
                                              onChange={(event) => handleDollarDiscount('ai', event.target.value)}
                                            />
                                        </span>

                                    </div>


                                    <p className="subtotal sms-package sms-package-short"></p>
                                    <p className="subtotal sms-package sms-package-vanity"></p>

                                    {hr}
                                </>
                                }
                            </div>



                            <div>
                                {smsLineItems}
                            </div>




                            <div style={{marginBottom:5}}><i>Expert Services (recurring)</i>
                                {caretController('expert_services')}
                                {!carets.expert_services
                                &&
                                <>
                                    <span className="total-packages-exp2 floatRight">
                                        {formatPrice(props.totals.expert_services, props.currencySymbol)}
                                    </span>
                                </>

                                ||
                                <>
                                    {lineItems(props.servicesMonthlySelected, props.currencySymbol, true, props)}

                                    <div style={{marginTop:10}}>
                                        <input type="number"
                                            id="expert_services__discount2"
                                            className="numericInput"
                                            style={{marginRight:0, textAlign:'center'}}
                                            onChange={( event ) => handlePercentageDiscount('expert_services', event.target.value)}
                                            value={props.discounts.expert_services || ''}
                                        /> % Discount
                                    </div>
                                    <div style={{marginTop: 5}}>
                                        <span className="subtotalHeader">Subtotal</span>
                                        <span className="floatRight">
                                          {props.currencySymbol}
                                          <input type="number"
                                              className="numericInput dollarInput"
                                              id="expert_services__subtotal"
                                             value={formatInputPrice(
                                                 props.discounts.expert_services
                                                     ? (props.totals.expert_services-(props.totals.expert_services * props.discounts.expert_services/100))
                                                     : props.totals.expert_services
                                             )}
                                              style={{marginRight:0}}
                                              onChange={( event ) => handleDollarDiscount('expert_services', event.target.value)}
                                        />
                                        </span>
                                    </div>
                                </>
                                }
                            </div>



                        </div>

                        <div className="col-xl-12 col-lg-4 paddingLRNone">

                            <hr className="hr-dotted" style={{margin:"0 0 5px 0"}}/>
                            <p className="cost-totals">
                                Monthly total
                                <span name="quote_monthly_total_commercial2">
                                    {formatPrice(recurrentTotal, props.currencySymbol)}
                                </span>
                            </p>
                            <p className="cost-totals">Annual total
                                <span name="quote_annual_total_commercial2">
                                    {formatPrice(annualTotal, props.currencySymbol)}
                                </span>
                            </p>
                        </div>
                        <div className="col-xl-12 col-lg-4 paddingLRNone">
                            {hr}
                            {onetimeBlock('configuration', 'Custom Services')}
                            {hr}
                            {onetimeBlock('deployment', 'Implementation')}
                            {hr}
                            {onetimeBlock('training', 'Training')}
                            {hr}

                            <p className="cost-totals">Onetime Services total
                                <span name="quote_onetime_total_v2">{onetimeTotal(false, props.currencySymbol)}</span>
                            </p>

                        </div>
                     </div>
                     )}


                    <button type="submit"
                            className="btn btn-lg btn-default mt-3 submit-quote"
                            id="submit-quote"
                            onClick={props.postQuoteData}
                            disabled={props.isWaitingForData}
                    >Build Quote</button>

                    {/*props.pricingModel !== PRICING_MODEL_CPI
                    && <label htmlFor="hide_discounts">
                        <input id="hide_discounts"
                               value="hide_discounts"
                               type="checkbox"
                               className="info-field"
                               name="hide_discounts"
                               style={{marginRight:5,marginTop:4}}
                               onChange={( event ) => handleHideDiscounts(event.target.checked)}
                               value={props.hideDiscounts || false}
                        />
                        Hide discount % from quote

                    </label>
                    */}

                    {props.pricingModel === PRICING_MODEL_CPI &&
                        <>
                        {hr}
                        <center>

                            <a
                            href={
                                   '#'
                            }
                                    onClick={() => handleExportData()}>

                                Export Data
                            </a>
                        </center>
                        </>
                    }

                </div>
            </div>
         </div>

        </>

    )

}

export const hr = <hr className="hr-solid" style={{margin: "5px 0 0 0"}} />

const lineItems = (selectedProp, currencySymbol, isRecurrent=true, props) => {
    return Object.entries(selectedProp).length > 0
        ? Object.entries(selectedProp).map(([key, item]) => (
            <div key={key} style={{width:240}}>
                <div style={{minWidth:160,width:160,height:22,overflow:"hidden",float:"left"}}>
                    {
                        (item.type === 'cpi_training' || item.type === 'cpi_deployment' || item.type === 'ps_subscription')
                            ? (props.productSelectedQuantity[item.productCode] || item.default_quantity || 1)
                            : (
                                item.productCode in props.productSelectedQuantity // e.g. for Custom One Time Expert Services
                                    ? props.productSelectedQuantity[item.productCode]
                                    : 1
                              )
                    }
                    &nbsp;{item.title.replace("Deployment:", "").replace("Training:", "").trim()}
                </div>
                <div style={{minWidth:70,width:70,height:22,float:"right",textAlign:"right",paddingRight:10}}>
                    {formatPrice(
                        isRecurrent
                            ? props.getBillingPeriodPrice(
                            (item.type === 'ps_subscription')
                                ? item.price * ((props.productSelectedQuantity[item.productCode] || item.default_quantity || 1)/12)
                                : item.price
                            , false)
                            // one-time fees do not need to reflect the billing period
                            : item.price * (props.productSelectedQuantity[item.productCode] || item.default_quantity || 1)
                        , currencySymbol
                    )}
                </div>
            </div>
        ))
        : '';
}

export const CONTAINER_MIN_WIDTH_WITH_SCROLLBAR = 262
export const CONTAINER_MIN_WIDTH_WITHOUT_SCROLLBAR = 250

