// !minifyOnSave

// import React, { Component } from 'react';
// import ReactDOM from 'react-dom';
// import Account from "./account";

// this FILE is used for BOTH QB and CC Payment Flow

console.log('QuoteBuilder JS Loaded.');

if (typeof Sentry != "undefined") {
    Sentry.init({ dsn: 'https://45805746024a47108532a998750236c1@sentry.io/1550382' });
    console.log('Sentry JS Loaded.');
} else {
    console.log('Sentry JS NOT Loaded.');
}

if (typeof gtag === "undefined") {
    try {
        // google analytics block
        const script = document.createElement('script');
        script.src = "https://www.googletagmanager.com/gtag/js?id=UA-277946-52";
        script.async = true;
        document.body.appendChild(script);
        window.dataLayer = window.dataLayer || [];

        function gtag() {
            dataLayer.push(arguments);
        }

        gtag('js', new Date());
        gtag('config', 'UA-277946-52');
        console.log('Google Analytics loaded from quote_builder.js');
    } catch (e){
        console.log('Ran into an issue creating a google tag', e)
    }
}



/*// Feature detects Navigation Timing API support.
if (window.performance) {
    // Gets the number of milliseconds since page load
    // (and rounds the result since the value must be an integer).
    var timeSincePageLoad = Math.round(performance.now());

    // Sends the timing hit to Google Analytics.
    ga('send', 'timing', 'JS Dependencies', 'load', timeSincePageLoad);
}*/

/*
    "Effective Date" should be locked for CC Orders + Renewals
*/


window.orderType = 'direct';
window.isFirstLoad = true;
window.uncheckedImplementations = false;
const dynamically_updated_discount_fields = {
    managed: ['managed_services_discount'],
    velocity: ['velocity_packages_discount'],
    commercial: ['platform_users_discount', 'ai_automation_discount', 'expert_services_ongoing_discount']
};

function isVowel(x) {  return /[aeiouAEIOU]/.test(x.charAt(0)); }

window.qb = {

    //api_token: 'svAdVX01XqaDZgowuX0rWXACrnNjfc8yzceqSQ7OAfrGBw6k9nMnOdxMEQGs',
    fields: {},
    spinnerTimeout: {},
    selections: {},
    onetime_total: {},
    monthly_total: {},
    fixed_total: {},
    setPricebook: null,
    setOpportunity: null,
    is_new: false,
    is_change: false,
    is_upsell: false,
    is_new_upsell: false,
    is_renewal: false,
    is_new_renewal: false,
    is_flat_renewal: false,
    is_upsell_co_term: false,
    is_upsell_reset: false,
    is_implementation_only: false,
    is_client: false,
    is_rapid_deploy: false,
    popup_upsell: null,
    popup_missing_error: null,
    data_opportunities: [],
    data_account: null,
    data_pricing: null,
    order_quote_type: '',

    accounts: [], // pre-loaded account data

    has_valid_contract: false,
    avatar: ' <img style="border-radius: 50%; height: 64px" src="/images/quote-builder/vader.png"><br><br>',
    quoteImg: '<img src="/images/quote-builder/Quote-Icon.png" srcset="/images/quote-builder/Quote-Icon@2x.png 3x" />',
    error_retry_count: 0,
    data_contacts: {},
    data_contracts: {},
    data_contract_selected_id: null,
    data_contract_selected: null,
    force_reset_term: false,
    ajax_popup: null,
    order_id: null,
    quote_type_label: '',
    pricing_model: 'commercial',
    account: null,
    account_owner_role: null,
    load_chat_bubble_3: true,

    /**
     * I'm trying to do [qb flow] for account
     * [SFDC account]/[ACCOUNT NAME], but QB
     * detetected that my contract sub is having
     * issues [insert issue]
     */
    get_contact_field_ops_text: function(quote_issue='', return_string='text'){
        let email_address = 'fieldops-lp@liveperson.com';
        let quote_type = encodeURIComponent(window.qb.quote_type_label);
        let account_name = encodeURIComponent(window.qb.data_account ? window.qb.data_account.Name : '(not selected)');
        let account_url = window.qb.data_account.web_link;

        let opp_url = window.qb.setOpportunity ? encodeURIComponent(window.qb.setOpportunity.web_link) : '';
        quote_issue = encodeURIComponent(quote_issue);
        let br = '%0D%0A%0D%0A';
        let email_body = 'Hi,'
            +br+'I am trying to build '
            + (isVowel(quote_type) ? 'an ' : 'a ')
            +(quote_type ? quote_type+' ' : '')
            +'Quote for account ' + account_name + ' but QB detected the following issue: '
            +br+ quote_issue
            +br+ 'SFDC Account link: '+account_url
            +((opp_url && opp_url!='undefined') ? ('%0D%0ASFDC Opportunity link: '+opp_url) : '')
            +br+'Cheers!';
        let mailto = 'mailto:'+email_address+'?subject=QB Quote Request for ' + account_name + ' Account&body=' + email_body;


        // <button type="button" class="btn btn-primary">Email FieldOps</button>
        let text = ' Please reach out to the awesome field ops team '
            + '<br>to update this information before building a Quote.';
                    //<br><br><a class="btn btn-primary" id="contact_field_ops_link" target="_blank" href="'+mailto+'">Email FieldOps</a>')
            ;

        if(return_string == 'text'){
            return text;
        } else if(return_string == 'mailto'){
            return mailto;
        }


    },

    get_contact_field_ops_popup_button: function(quote_issue){
        let mailto = window.qb.get_contact_field_ops_text(quote_issue, 'mailto');

        return  {
            ok: {
                text: "Email FieldOps",
                btnClass: 'btn-primary',
                keys: ['enter'],
                action: function(){
                    //$('#contact_field_ops_link').trigger('click');
                    window.open(mailto);
                    location.reload();
                }
            }
        };
    },

    init: function() {

        $('.pricing-feedback').show();

        $('label.oal').append('<span class="outsourced-labor-price price-table">&mdash;</span>');
        $('label.ela').append('<span class="expert-live-advisor-price price-table">&mdash;</span>');
        $('label.op').append('<span class="op-packages-price price-table">&mdash;</span>');
        $('label.ps').append('<span class="ps-packages-price price-table">&mdash;</span>');
        // $('label[name=product_package]').append('<span class="platform-level-price price-table">$79</span>');
        $('label[name=monthly_discount]').append('<span class="discount-price">&mdash;</span>');
        $('label[name=onetime_discount]').append('<span class="discount-price-onetime">&mdash;</span>');

        $('label[name=velocity_packages_discount]').append('<span class="velocity-packages-discount-price">&mdash;</span>');
        $('label[name=managed_services_discount]').append('<span class="managed-services-discount-price">&mdash;</span>');

        $('label[name=platform_users_discount]').append('<span class="platform-users-discount-price">&mdash;</span>');
        $('label[name=ai_automation_discount]').append('<span class="ai-automation-discount-price">&mdash;</span>');
        $('label[name=expert_services_ongoing_discount]').append('<span class="expert-services-ongoing-discount-price">&mdash;</span>');

        // preset to a dash
        this.update_expert_services_total();

        //$('input.spinner').spinner();
        $.ajaxSetup({
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            }
        });

        // register an event for fields that have corresponding dynamic values e.g. discounts auto-updates on the screen

        $.each(dynamically_updated_discount_fields, function(pricing_model, fields) {
            $.each(fields, function(k, field) {
                $('input[name="' + field + '"]').keyup(function () {
                    //console.log( "assigned "+field+" handler for .keyup() in init: function(){}" );
                    window.qb.update_monthly_total();
                });
            });
        });

        // restrict fields 'two-decimals' class to two digit decimals
        $( function() {
            $('.two-decimals').keyup(function(){
                if($(this).val().indexOf('.')!=-1){
                    if($(this).val().split(".")[1].length > 2){
                        if( isNaN( parseFloat( this.value ) ) ) return;
                        this.value = parseFloat(this.value).toFixed(2);
                    }
                }
                return this; //for chaining
            });
        });

        $('#reset_term').change(
            /**
             *   It should be gray when ‘reset term’ is not selected
             *   On both upsell one and upsell 2
             *   Reset term - means the rep can select a new term, 12,18.etc
             *   If reset is not ticked, then the contract is ‘coterminous’ therefor it’s term is ‘as per prior order for
             */
            function(){
                if (this.checked) {

                    console.log('enabling subscription_term');

                    $('label[for*="subscription_term"] select').prop('disabled', false).select2();
                    $('label[for*="subscription_term"] span.select2').removeClass('disabled');
                } else {
                    console.log('disabling subscription_term');

                    $('label[for*="subscription_term"] select').prop('disabled', true).select2();
                    $('label[for*="subscription_term"] span.select2').addClass('disabled');
                }
            });

    },
    /**
     *
     * @param method
     * @param url
     * @param async        - IGNORED FOR NOW
     * @param useCache
     * @param data
     * @param callback
     */
    request: function(method, url, async, useCache, data, callback) {

        data = data || {}
        data.api_token = this.api_token ? this.api_token : window.apiToken;

        // skip the network request and re-use account-level preloaded currency data
/**
        if(url) {
            console.log('url: ' + url);
            console.log(window.qb.setCurrency);
            if (url.includes('/fields') && window.qb.data_pricing && window.qb.setCurrency) {
                callback(window.qb.data_pricing[window.qb.setCurrency]);
                return;
            } else {
                console.log('window.qb.data_pricing', window.qb.data_pricing);
                console.log('window.qb.setCurrency', window.qb.setCurrency);
                console.log('url', url);
//                return;
            }
            // cb(window.qb.data_pricing[window.qb.setCurrency]);
        }
 **/
        //console.log('$.ajax({}) method: '+method+' data: ', data);
        $.ajax({
            method: method,
            url: url,
            retries : 3,
            cache: useCache ? true : false,
            data: data,
            async: true, // IGNORED FOR NOW async ? false :true,
            headers: {'x-csrf-token': window.csrf_token},
        }).done(function(data) {
            callback(data);

            // console.log('executed callback data:', data);
            // console.log('url: ' + url);
            // console.log('executed callback:', callback);

        });
    },

    all_fields: function(filter, callback, field, norender) {

        //$('body').addClass('loading');
        $('div[name="implementations"]').addClass('loading');
        $('#expert_services_panel_monthly_services').addClass('loading');
        $('#submit-quote').addClass('loading'); // build quote button
        $('label[for="industries"]').addClass('loading');
        field = field || null;
        var currency = window.qb.setCurrency || 'USD';
        var pricebook = window.qb.setPricebook;
        callback = callback || function(){};
        norender = norender || [];
        var _this = this;
        var cb = function(data) {
            _this.fields = data;
            if (field) {
                if (norender.indexOf(field) < 0) window.qb.render_field(field);
            } else {
                $('field').each(function() {
                    var id = $(this).attr('id');
                    if (norender.indexOf(id) < 0) {
                        window.qb.render_field(id);

                    }
                });
            }
            if (field === null) window.qb.init();
            callback(data);

            /*
            if(window.qb.flow_change_managed_services_popup != null) {
                window.qb.flow_change_managed_services_popup.close(); //prevent double popupsfor industry
            }
            $('select[name="industries"]').trigger('change');
            */
            $('select[name="industries"]').trigger('change');

            //$('body').removeClass('loading');
            $('div[name="implementations"]').removeClass('loading');
            $('#expert_services_panel_monthly_services').removeClass('loading');
            $('#submit-quote').removeClass('loading'); // build quote button
            $('label[for="industries"]').removeClass('loading');

            if($('.implementations_total').text() == "——" && window.uncheckedImplementations == false){
                // nothing's checked? possibly something's gone wrong... load default selections

                if(window.qb.error_retry_count < 5) {
                    $('select[name="product_package"]').trigger('change');
                } else {
                    console.log('window.qb.error_retry_count > 5')
                }
                 //console.log('checked default one-time services (self-corrected)')
            } else {
                 //console.log('one-time services already checked (nothing to do)')
            }

        };

        this.request('GET', window.fieldsUrl,  true,true, {filter: filter, currency: currency, pricebook: pricebook}, cb);
        //this.request('GET', 'https://mapi.net/api/pricing/fields', {filter: filter}, cb);

        // console.log('all_fields(,,'+field+',), calling window.qb.disable_billing_details_for_velocity to undo the callback field reset');
        // window.qb.update_ui_for_velocity();
        //window.qb.update_ui_for_managed_services();

        /**
        // pre-requisite: we must have this data preloaded prior to calling this.
        if(window.qb.data_pricing){
            if(window.qb.setOpportunity && window.qb.data_opportunities.length > 0) {
                let CurrencyIsoCode = window.qb.data_opportunities.find(opp => opp.Id === window.qb.setOpportunity.Id).CurrencyIsoCode;
                cb(window.qb.data_pricing[CurrencyIsoCode]);
                console.log("all_fields(): using selected opp's currency : "+CurrencyIsoCode);
            } else {
                console.log('all_fields(): defaulting to USD currency due to blank Opportunity');
                cb(window.qb.data_pricing["USD"]);
            }
        } else {
            console.log('MISSING PRICING DATA.... in all_fields()');
        }
        **/

        window.qb.update_ui_for_velocity(); // ensures we apply this after the fields are updated (otherwise could be overridden)
        window.qb.after_render.outsourced_labor($('select[name="outsourced_labor"]'));


        if (window.qb.has_valid_contract) {

            // remove CC option for upsells
            console.log('removing CC option for non-Original flow');
            $("select[name='payment_method'] option[value='card']").remove().trigger('change');

        } else {

            //re-add CC option if not already present for non-upsells
            if ($("select[name='payment_method'] option[value='card']").length == 0) {
                console.log('re-adding CC option for Original flow');
                $('select[name="payment_method"]').append('<option value="card">Credit Card</option>').trigger('change');
            }
        }

        console.log('all_fields() done');



    },


    waitForPricingData: function (data, callback){
        if(!data) {
            (async () => {
                while (!window.qb.data_pricing) {
                    console.log("debug: waiting for Pricing data to be available in waitForPricingData() ...");
                    await new Promise(resolve => setTimeout(resolve, 200));
                }
                console.log("debug: got Pricing data in waitForPricingData(), proceeding...");
                callback(window.qb.data_pricing);
            })();
        } else {
            callback(data);
        }
    },

    render_field: function(field, key, return_value) {

        // console.log('render_field(' + field + ', ' + key + ', '+ return_value + ');');

        window.qb.monthly_total[field] = 0;
        window.qb.onetime_total[field] = 0;
        $('*[field-link="'+field+'"]').remove();
        var field_attr = key ? this.fields[field].options[key] : this.fields[field];
        var field_type = '', select_type, _this = this;
        if (field_attr.type == 'select') {
            field_type = field_attr.multiple ? this.field_templates.select.multiple.control : this.field_templates.select.one.control;
            select_type = field_attr.multiple ? 'multiple' : 'one';
        } else if (field_attr.type == 'radio') {
            field_type = this.field_templates[field_attr.type].control;
        } else {
            field_type = this.field_templates[field_attr.type];
        }

        if (field_attr.type == 'spinner') {
            window.qb.spinnerTimeout[field] = null;
            field_attr.class = field_attr.class || '';
            field_attr.type == 'text';
            field_attr.class = field_attr.class += ' spinner';
        }


        field_type = field_type.replace('$title$', field_attr.title);
        field_type = field_type.replace('$name$', field);
        field_type = field_type.replace('$price$', window.qb.numberWithCommas(field_attr.price));

        let control = $(field_type).attr('name', field)
            .attr('class', field_attr.class || '');
        //.attr('id', field);


        if (field_attr.type == 'select') {
            if (control.children().length) {
                control.children().attr('name', field).attr('class', field_attr.class || '');
            }
            Object.keys(field_attr.options).forEach(function(k, i) {
                var value = field_attr.options[k];
                var option = null;
                if ((typeof value) === 'string') {
                    var option = $(_this.field_templates.select[select_type].option).attr('value', k).text(value);
                }
                if ((typeof value) === 'object') {
                    var template = _this.field_templates.select[select_type].option
                        .replace('$title$', value.title)
                        .replace('$price$', window.qb.numberWithCommas(value.price))
                        .replace('$name$', k);
                    var option = $(template);
                    if (select_type == 'one') {
                        option.attr('price', value.price).attr('value', k);
                    } else {
                        option.children().attr('id', k)
                            .attr('value', k)
                            .attr('price', value.price)
                            .attr('event', field_attr.event || 'change')
                            .addClass(value.class || '')
                            //.parent().addClass((value.class || '') + '-label')
                            .addClass(field_attr.class || '');
                        if ((field_attr.default || []).indexOf(k) >= 0) {
                            option.children('input').prop('checked', true);
                        }
                    }
                    option[field_attr.event || 'change'](_this.callbacks[key || field]);
                }

                // append a data element for attached one-time / SOW files, to be used to build the download icon later
                if(value.attachment) {
                    option.attr('data-attachment', value.attachment);
                    //console.log(value.attachment);
                }

                if (control.children().length && select_type == 'one') {
                    control.children().append(option);
                } else {
                    control.append(option);
                }
                if (window.qb.after_render[k]) window.qb.after_render[k](option);
            });
            if (control.children().length && select_type == 'one') {
                control.children().val(field_attr.default || control.children().children('option').attr('value'));
            } else {
                control.val(field_attr.default || control.find('option').attr('value'));
            }
        } else if (field_attr.type == 'radio') {

            // control.children().attr('class', field_attr.class || 'no-class-found?');


            Object.keys(field_attr.options).forEach(function(k, i) {
                var value = field_attr.options[k];
                var template = _this.field_templates.radio.option
                    .replace('$title$', value.title)
                    .replace('$price$', window.qb.numberWithCommas(value.price) || '0')
                    .replace(/\$name\$/g, field);
                var option = $(template);
                if ((field_attr.default || []).indexOf(k) >= 0) {
                    option.find('input').prop('checked', true);
                }

                // append a data element for attached one-time / SOW files, to be used to build the download icon later
                if(value.attachment) {
                    option.attr('data-attachment', value.attachment);
                    //console.log(value.attachment);
                }

                option.children()
                    .attr('event', field_attr.event || 'mouseover')
                    .attr('value', k)
                    .attr('price', value.price)
                    .attr('id', k)
                option.attr('for', k)
                    .attr('price', value.price)
                control.append(option);
                if (window.qb.after_render[k]) window.qb.after_render[k](option);
            });
        } else if (field_attr.type == 'group') {
            Object.keys(field_attr.options).forEach(function(k, i) {
                var value = field_attr.options[k];
                var ctl = _this.render_field(field, k, true);
                if (window.qb.after_render[k]) window.qb.after_render[k](ctl);
                control.append(ctl);
            });
        } else {
            if (control.children().length) {
                control.children().attr('price', field_attr.price)
                    .attr('id', field)
                    .attr('name', field)
                    .attr('max', field_attr.max)
                    .attr('min', field_attr.min)
                    .attr('class', field_attr.class || '');

            } else {
                control.attr('price', field_attr.price)
                    .attr('id', field)
                    .attr('name', field)
                    .attr('max', field_attr.max)
                    .attr('min', field_attr.min)
                    .attr('class', field_attr.class || '');

            }
        }
        if (field_attr.type == 'boolean') {
            if (field_attr.default === true) {
                if (control.children().length) {
                    control.children().prop({'checked': true, 'disabled':'disable'});
                } else {
                    control.prop('checked', true);
                }
            }
        }

        if (control.children().length && field_attr.type != 'boolean') {
            control.children()[field_attr.event || 'change'](_this.callbacks[key || field]);
            control.children().val(field_attr.default || '0');
            control.children().attr('event', field_attr.event || 'change');
            //control.children().trigger(field_attr.event || 'change');
        } else if (control.children().length && field_attr.type == 'boolean') {
            control.children().attr('event', field_attr.event || 'change');
            control[field_attr.event || 'change'](_this.callbacks[key || field]);
            control.val(field_attr.default || '0');
        } else {
            control[field_attr.event || 'change'](_this.callbacks[key || field]);
            control.val(field_attr.default || '0');
            control.attr('event', field_attr.event || 'change');
            //control.trigger(field_attr.event || 'change');
        }
        control.attr('field-link', field);
        if (return_value) {
            return control;
        }
        $('field#'+field).before(control).hide();
        if (field_attr.type == 'select') {
            control.find('select').select2();
        }
        if (field_attr.type == 'spinner') {
            control.find('input').on('click', function(){
                $(this).trigger('keyup');
            }).spinner();
        }

        if (window.qb.after_render[field]) window.qb.after_render[field](control);

    },
    implementations_uncheck_checkboxes: function() {
        console.log('implementations_uncheck_checkboxes()');
        $('div[name="implementations"] input[type="checkbox"]').prop("checked", false);
        window.qb.process_defaults('implementations');
        window.qb.after_render.outsourced_labor();
    },
    /**
     *
     * Returns 0, 7, 5.50, 5.55 type formatted numbers or their string equivalents
     *
     * @param x
     * @param asString
     * @returns {string | decimal | int}
     */
    numberRound: function (x, asString) {

        if(!x){
            return asString ? '\u2014' : 0;
        }
        let decimalPlaces = 2;
        let num = asString
            ? Number(x).toFixed(2)  // two place decimals as a string e.g. "0.02"
            : Number(Math.round(parseFloat(x + 'e' + decimalPlaces)) + 'e-' + decimalPlaces); // e.g. 0.02
        if(asString) {
            num = num.replace('.00', '');
        }

        // console.log('numberRound : ' + x + ' => ' +  num);

        return num;
    },

    numberWithCommas: function (x, noCurrency) {
        if(!x){
            return '';
        }
        // strip out any commas (formatting might be present)
        x = x.toString().replace(',', '');

        if(isNaN(parseFloat(x))){
            return x;
        }

        x = Number(x).toFixed(2);
        // add commas and replace trailing .00's for whole dollar amounts when completely round
        var num = x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",").replace('.00', '');
        return !isNaN(parseFloat(num)) && !noCurrency ? window.qb.fields.currency_symbol + num : num;
    },

    field_templates: {
        select: {
            one: { // used in the "billing details" section
                control: '<label for="$name$">$title$ <select></select></label>',
                option: '<option>$title$</option>'
            },
            multiple: {
                control: '<div><h2>$title$</h2></div>',
                option: '<label for="$name$"><input type="checkbox"> $title$ <span>$price$</span></label>'
            },
        },
        boolean: '<label for="$name$"><input type="checkbox"> $title$ <span>$price$</span></label>',
        text: '<label for="$name$">$title$ <input type="text"></label>',
        spinner: '<label for="$name$">$title$ <input type="text"></label>', // up/down num selector
        number: '<label for="$name$">$title$ <input type="number"></label>', // not used
        group: '<div><h2>$title$</h2></div>',
        radio: {    // used for velocity packages
            control: '<div><h2>$title$</h2></div>',// '<div>$title$</div>',
            option: '<label for="$name$"><input type="radio" name="$name$"> $title$ <span class="price-table">$price$</span></label>'
            // option: '<label><input type="radio" name="$name$"> <span class="radio-title">$title$</span> <span> + $price$ monthly</span></label>'
        }
    },

    /**
     * populate "contact us for scope" email links for items that have no skus/pricing in the pricing file
     */
    populateNoSkuSowContact: function (){

        //console.log('populateNoSkuSow()');

        // populate the no-sku SOW email contacts
        $('.info_only').each(function(){
            //console.log($(this).closest('label').text());
            if($(this).is('span')){
                //console.log($(this));

                if(window.qb.data_account) { // have a selected account

                    //console.log('populateNoSkuSow() '+ window.qb.data_account.Legal_Entity__c);

                    let new_product_sow_icon_name = '';
                    let new_product_sow_email = '';

                    if(window.qb.data_account.Legal_Entity__c == 'LP Inc') {
                        new_product_sow_icon_name = 'ken';
                        new_product_sow_email = 'na-commercial-im-lp@liveperson.com';
                    } else { // BV Netherlands and others
                        new_product_sow_icon_name  = 'scott';
                        new_product_sow_email = 'commercialisd-lp@liveperson.com';
                    }

                    // console.log("window.qb.data_account.Legal_Entity__c: " + window.qb.data_account.Legal_Entity__c + "  new_product_sow_icon_name: " + new_product_sow_icon_name);

                    let account_name = encodeURIComponent(window.qb.data_account ? window.qb.data_account.Name : '(not selected)');
                    let product_name = encodeURIComponent($(this).closest('label').text().trim());
                    let email_body = 'Hi,%0D%0A%0D%0ACan you please help me with the Package SOW for product ' + product_name + ' related to account ' + account_name + '?%0D%0A%0D%0ACheers!';
                    let mailto = 'mailto:'+new_product_sow_email+'?subject=SOW Request for ' + product_name + ' product on ' + account_name + ' Account&body=' + email_body;
                    let info_only_div = '<div style="float:right; padding-right: 10px;margin: -15px 0 -10px 0;"><a target="_blank" href="' + mailto + '"><img src="/images/quote-builder/'+new_product_sow_icon_name+'.png" border="0"></a>';
                    //class="info_only disabled price-table"
                    $(this).replaceWith(info_only_div + '<div style="float:right; width: 77px;text-align:center; padding-right: 0px;margin: 5px 0 -10px 30px;"><a target="_blank" href="' + mailto + '">contact us for scope</a></div>');
                }
            }

        });
    },

    after_render: {
        /*conversation_builder: function(control) {
            var c = $('<span>').attr('id', 'bot_dar').text('0 bot DAR');
            control.find('span').append(c);
        },*/



        implementations: function(control) {

            // console.log('implementations callback', control);

            // Add an SOW PDF download link
            control.find('label').each(function () {
                let attachment = $(this).attr('data-attachment');
                if (attachment) {
                    $('<div style="float:right; padding-right: 20px;margin: -15px 0 -10px 0;"><a target="_blank" href="' + attachment + '"><img src="/images/quote-builder/sow_scope_icon.png" border="0"></a></div>').appendTo($(this));
                }
            });

            window.qb.populateNoSkuSowContact();


            // for Upsell "Change" (existing Opp) flow, disable the One-time services by default
            if (window.qb.is_upsell && !window.qb.is_new_upsell) {
                window.uncheckedImplementations = true;
                window.qb.implementations_uncheck_checkboxes();
            } else {
                window.uncheckedImplementations = false;
            }

        },
        ai_automation: function(control) {
            // Add an SOW PDF download link
            control.find('label').each(function () {
                let attachment = $(this).attr('data-attachment');
                if (attachment) {
                    $('<div style="float:right; padding-right: 20px;margin: -15px 0 -10px 0;"><a target="_blank" href="'+attachment+'"><img src="/images/quote-builder/sow_scope_icon.png" border="0"></a></div>').appendTo($(this));
                }
            });
        },

        velocity_packages: function(control) {
            // Add a pitch deck PPT download link
            control.find('label').each(function () {
                let attachment = $(this).attr('data-attachment');
                if (attachment) {
                    $('<div style="float:right; padding-right: 20px;margin: -15px 0 -10px 0;"><a target="_blank" href="'+attachment+'"><img style="opacity:.85;padding-top:8px;width:45px;" src="/images/quote-builder/guru_logo.png" border="0"></a></div>').appendTo($(this));
                }
            });
            // console.log('in velocity_packages: function(control)');
        },

        managed_services: function(control) {
            // Add a pitch deck PPT download link
            control.find('label').each(function () {
                let attachment = $(this).attr('data-attachment');
                if (attachment) {
                    $('<div style="float:right; padding-right: 20px;margin: -15px 0 -10px 0;"><a target="_blank" href="'+attachment+'"><img style="opacity:.85;padding-top:8px;width:45px;" src="/images/quote-builder/guru_logo.png" border="0"></a></div>').appendTo($(this));
                }
            });
            console.log('in managed_services: function(control)');

        },

        guided_sms: function(control) {
            var radioAccordian = $('<a>').addClass('accordian').click(function(e){
                e.preventDefault();
                $(this).parent().find('.sms-options').slideToggle();
                $(this).toggleClass('active');
            });
            var smsOptions = $('<div>').addClass('sms-options options-container');
            //var smsopt1 = window.qb.render_field('sms_options', null, true);
            var smsopt1 = window.qb.render_field('sms_options_long', null, true);
            var smsopt2 = window.qb.render_field('sms_options_short', null, true);
            var smsopt3 = window.qb.render_field('sms_options_vanity', null, true);
            smsOptions.append(smsopt1).append(smsopt2).append(smsopt3);
            smsopt1.find('input').on('click', function(){
                $(this).trigger('keyup');
            }).spinner();
            smsopt2.find('input').on('click', function(){
                $(this).trigger('keyup');
            }).spinner();
            smsopt3.find('input').on('click', function(){
                $(this).trigger('keyup');
            }).spinner();
            control.append(radioAccordian).append(smsOptions).addClass('options');
        },
        whatsapp: function(control) {
            var radioAccordian = $('<a>').addClass('accordian').click(function(e){
                e.preventDefault();
                $(this).parent().find('.whatsapp-options').slideToggle();
                $(this).toggleClass('active');
            });
            var whatsappOptions = $('<div>').addClass('whatsapp-options options-container');
            var waopt1 = window.qb.render_field('whatsapp_options_number', null, true);
            waopt1.find('input').on('click', function(){
                $(this).trigger('keyup');
            }).spinner();
            whatsappOptions.append(waopt1);
            control.append(radioAccordian).append(whatsappOptions).addClass('options');
        },
        partner_success: function(control) {
            if (window.orderType == 'partner') control.show();
        },
        product_package: function(control) {
            control.find('option').each(function() { // partner discount e.g. 0.7 (30% off base pricing on selected packages)
                var price = window.qb.numberRound($(this).attr('price') * (window.orderType == 'direct' ? 1 : .7));
                $(this).attr('price', price);
                var text = $(this).text().replace(/[0-9]+/, price);
                $(this).text(text);
            });
            control.find('select').select2();
        },
        industries: function(control){
/*
            $(control).select2().on("select2:selecting", function(e) {
                console.log("assigned previous value of " + $(control).select2("data")[0].id + " to industries prev-data attr");  // 0 or 1 on change
                control.attr('prev-data', obj[0].id);
            });
*/
            // make required
            $('label[for*="industries"] select').prop('required', null).addClass('required');
            // ad a required asterisks
            $('label[for*="industries"]').contents().eq(0).replaceWith($('label[for*="industries"]').contents().eq(0).text() + ' <span class="required">*</span>')

        },
        /**
         * For Automotive Industry selection, we swap outsourced_labor for on_demand_advisor
         * and vice-versa for other industries
         * @param control
         */
        outsourced_labor: function(control){
            if($('select[name="industries"]').val() == 'Automotive' && !$('select[name="on_demand_advisor"]').is(':visible')){

                // console.log('replacing outsourced_labor with on_demand_advisor');
                delete window.qb.selections.outsourced_labor; // reset any set data
                delete window.qb.selections.on_demand_advisor; // reset any set data
                delete window.qb.selections.customer_success; // reset any set data

                $('select[name="outsourced_labor"]').select2().val('default').trigger('change');
                $('label[for*="outsourced_labor"]').hide();

                $('select[name="on_demand_advisor"]').select2().val('default').trigger('change');
                $('label[for*="on_demand_advisor"]').show();


            } else if($('select[name="industries"]').val() != 'Automotive' && !$('select[name="outsourced_labor"]').is(':visible')){
                // console.log('replacing on_demand_advisor with outsourced_labor');
                delete window.qb.selections.outsourced_labor; // reset any set data
                delete window.qb.selections.on_demand_advisor; // reset any set data
                delete window.qb.selections.customer_success; // reset any set data

                $('select[name="outsourced_labor"]').select2().val('default').trigger('change');
                $('label[for*="outsourced_labor"]').show();

                $('select[name="on_demand_advisor"]').select2().val('default').trigger('change');
                $('label[for*="on_demand_advisor"]').hide();

            }

        }
    },

    callbacks: {
        sms_options: function() {
            var total = 0;
            total = window.qb.numberRound($(this).attr('price')) || 0;
            window.qb.monthly_total.sms_options = total;
            window.qb.update_monthly_total();
            window.qb.update_total();

            var radioValue = $(this).attr('price');
            radioValue = window.qb.numberWithCommas(radioValue)
            var radioName = $(this).find('.radio-title').text();

            window.qb.selections.sms_options = {};

            if($('div[name="sms_options"] input:checked').val() != 'long_code'){
                $('.sms-package').show().html('SMS ' + radioName + '<span class="sms-package-cost"></span>').find('.sms-package-cost').text(radioValue);
                window.qb.selections.sms_options.value = $('div[name="sms_options"] input:checked').val();
            }
            else{
                $('.sms-package').hide();
            }

        },
        whatsapp_options_number: function() {
            clearTimeout(window.qb.spinnerTimeout.whatsapp_options_number);
            var _this = this;
            window.qb.spinnerTimeout.whatsapp_options_number = setTimeout(function() {
                window.qb.selections.whatsapp_options_number = {};
                window.qb.selections.whatsapp_options_number.value = $(_this).val();
                window.qb.callbacks.implementations();
                var price = window.qb.fields.implementations.options.whatsapp.price;
                $('span#whatsapp').text(window.qb.numberWithCommas(price * $(_this).val()));
            }, 500);
        },
        sms_options_short: function() {
            clearTimeout(window.qb.spinnerTimeout.sms_options_short);
            var _this = this;
            window.qb.spinnerTimeout.sms_options_short = setTimeout(function() {
                window.qb.selections.sms_options_short = {};
                window.qb.callbacks.implementations();
                var price = (window.qb.numberRound($(_this).attr('price')) || 0);
                var val = window.qb.numberWithCommas(price * $(_this).val());
                if ($(_this).val() > 0) {
                    $('.sms-package-short').show().html('SMS Short Code <span class="sms-package-cost"></span>').find('.sms-package-cost').text(val);
                } else {
                    $('.sms-package-short').hide();
                }
                window.qb.fixed_total.sms_options_short = price * $(_this).val();
                window.qb.selections.sms_options_short.value = $(_this).val();
                window.qb.update_monthly_total();
                window.qb.update_total();
            }, 500);
            //$('span#guided_sms').text('$' + window.qb.numberWithCommas(price * $(this).val()));
        },
        sms_options_long: function() {
            clearTimeout(window.qb.spinnerTimeout.sms_options_long);
            var _this = this;
            window.qb.spinnerTimeout.sms_options_long = setTimeout(function() {
                window.qb.selections.sms_options_long = {};
                window.qb.callbacks.implementations();
                var price = (window.qb.numberRound($(_this).attr('price')) || 0);
                var val = window.qb.numberWithCommas(price * $(_this).val());
                window.qb.fixed_total.sms_options_long = price * $(_this).val();
                window.qb.selections.sms_options_long.value = $(_this).val();
                window.qb.update_monthly_total();
                window.qb.update_total();
            }, 500);
            //$('span#guided_sms').text('$' + window.qb.numberWithCommas(price * $(this).val()));
        },
        sms_options_vanity: function() {
            clearTimeout(window.qb.spinnerTimeout.sms_options_vanity);
            var _this = this;
            window.qb.spinnerTimeout.sms_options_vanity = setTimeout(function() {
                window.qb.selections.sms_options_vanity = {};
                window.qb.callbacks.implementations();
                var price = (window.qb.numberRound($(_this).attr('price')) || 0);
                var val = window.qb.numberWithCommas(price * $(_this).val());
                if ($(_this).val() > 0) {
                    $('.sms-package-vanity').show().html('SMS Vanity Code <span class="sms-package-cost"></span>').find('.sms-package-cost').text(val);

                } else {
                    $('.sms-package-vanity').hide();
                }
                window.qb.fixed_total.sms_options_vanity = price * $(_this).val();
                window.qb.selections.sms_options_vanity.value = $(_this).val();
                window.qb.update_monthly_total();
                window.qb.update_total();
            }, 500);
            //$('span#guided_sms').text('$' + window.qb.numberWithCommas(price * $(this).val()));
        },
        /*
        subscription_term: function() {
            window.qb.update_total();
        },*/
        velocity_packages: function() { // callback

            console.log('in velocity_packages() callback');
            var total = 0;
            window.qb.selections.velocity_packages = {value: ""};

            $('div[name="velocity_packages"] input').each(function() {
                $(this).next('.price-table').removeClass('active');
            });

            $('div[name="velocity_packages"] input:checked').each(function() {
                $(this).next('.price-table').addClass('active'); // activate the price rectangle to the right of the checkbox
                total += window.qb.numberRound($(this).attr('price')) || 0;
                window.qb.selections.velocity_packages.value = $(this).attr('id');
            });
            window.qb.monthly_total.velocity_packages = total;
            window.qb.update_velocity_packages_total();
            window.qb.update_monthly_total();
            window.qb.update_total();
        },
        managed_services: function() { // callback

            console.log('in managed_services() callback');
            var total = 0;
            window.qb.selections.managed_services = {value: ""};

            $('div[name="managed_services"] input').each(function() {
                $(this).next('.price-table').removeClass('active');
            });

            $('div[name="managed_services"] input:checked').each(function() {
                $(this).next('.price-table').addClass('active'); // activate the price rectangle to the right of the checkbox
                total += window.qb.numberRound($(this).attr('price')) || 0;
                window.qb.selections.managed_services.value = $(this).attr('id');
            });
            window.qb.monthly_total.managed_services = total;
            window.qb.update_managed_services_total();
            window.qb.update_monthly_total();
            window.qb.update_total();
        },
        ai_automation: function() {
            var total = 0;
            $('div[name="ai_automation"] span.price-table').removeClass('active');
            window.qb.selections.ai_automation = {};
            window.qb.selections.ai_automation.value = [];
            $('div[name="ai_automation"] input:checked').each(function() {
                $(this).next('.price-table').addClass('active');
                total += window.qb.numberRound($(this).attr('price')) || 0;
                window.qb.selections.ai_automation.value.push($(this).attr('id'));
            });
            window.qb.monthly_total.ai_automation = total;
            window.qb.update_ai_automation_total();
            window.qb.update_monthly_total();
            window.qb.update_total();
        },
        implementations: function() {
            var total = 0;
            window.qb.selections.implementations = {};
            window.qb.selections.implementations.value = [];
            $('div[name="implementations"] span.price-table').removeClass('active');
            $('div[name="implementations"] input[type="checkbox"]:checked').each(function() {
                var price = window.qb.numberRound($(this).attr('price')) || 0;
                if ($(this).attr('id') == 'whatsapp') {
                    price = price * $('input#whatsapp_options_number').val();
                }
                $(this).next('.price-table').addClass('active');
                total += price;
                window.qb.selections.implementations.value.push($(this).attr('id'));
            });
            window.qb.onetime_total.implementations = total;

            var impValue = '';
            if(total == 0){
                impValue = '\u2014'
            }
            else{
                impValue = window.qb.numberWithCommas(total)
            }
            $('span.implementations_total').text(impValue);
            if($('#guided_sms').prop('checked')){
                $('label[for=guided_sms] a.accordian').show();
            }
            else{
                $('input[name="sms_options_long"]').val('0');
                $('input[name="sms_options_short"]').val('0');
                $('input[name="sms_options_vanity"]').val('0');
                $('label[for=guided_sms] a.accordian, .sms-options').hide().removeClass('active');
                $('.sms-package').html('');
                window.qb.selections.sms_options_long = {};
                window.qb.selections.sms_options_short = {};
                window.qb.selections.sms_options_vanity = {};
                window.qb.selections.sms_options_long.value = 0;
                window.qb.selections.sms_options_short.value = 0;
                window.qb.selections.sms_options_vanity.value = 0;
            }
            if($('#whatsapp').prop('checked')){
                $('label[for=whatsapp] a.accordian').show();
            }
            else{
                $('label[for=whatsapp] a.accordian, .whatsapp-options').hide().removeClass('active');
                window.qb.selections.whatsapp_options_number = {};
                window.qb.selections.whatsapp_options_number.value = 0;
            }
            window.qb.update_onetime_total();
            window.qb.update_total();

        },
        conversation_builder: function() {
            var total = 0;
            $('#conversation_builder + span.price-table').removeClass('active');
            $(this).children('input:checked').each(function() {
                $(this).next('.price-table').addClass('active');
                total += window.qb.numberRound($(this).attr('price')) || 0;
            });
            window.qb.monthly_total.conversation_builder = total;
            window.qb.update_ai_automation_total();
            window.qb.update_monthly_total();
            window.qb.update_total();
        },
        number_users: function() {
            clearTimeout(window.qb.spinnerTimeout.number_users);
            window.qb.spinnerTimeout.number_users = setTimeout(function() {

                if(window.isFirstLoad) {
                    window.isFirstLoad = false;

                    $('select[name="product_package"]').trigger('change'); // this calls an HTTP API

                    // console.log('using window.isFirstLoad');
                    // console.log('triggered select[name="product_package"] change event triggered');

                } else {

                    // console.log('NOT using window.isFirstLoad');

                    /**
                     * ADDED FROM callbacks.product_package: function(){}
                     */

                    window.qb.selections.number_users = {};
                    window.qb.selections.product_package = {};

                    window.qb.selections.product_package.value = $('select[name="product_package"]').val(); // e.g. "premier"
                    window.qb.selections.number_users.value = $("#number_users").val();                     // e.g. "5"
                    let price = $('select[name="product_package"]').find(':selected').attr('price') || 0;             // e.g. "249"
                    let total = ($("#number_users").val() ||0) * price;    // e.g. "1245"    ("5" * )

                    $('.total-packages-users').text(window.qb.numberWithCommas(total));
                    $('.platform-level-price').text(window.qb.numberWithCommas(price));

                    window.qb.monthly_total.product_package = total;
                    //window.qb.update_presets(['industries', 'product_package'], 'implementations');
                    window.qb.update_monthly_total();
                    window.qb.update_total();
                }

                // this block updates the orange AI & Automation number of users
                // e.g. "9" platform users * 1,000 billable bot conversations = "9,000"
                var dar = $('input[name="number_users"]').val() * 1000;
                var usr = window.qb.numberWithCommas($('input[name="number_users"]').val(),1);
                $('span#bot_dar').text(window.qb.numberWithCommas(dar, 1));
                $('span#users').text(usr);



            }, 500);
        },
        currency: function() {
            window.qb.setCurrency = $(this).val();
            $('select[name="industries"]').trigger('change');
        },
        monthly_discount: function() {
            clearTimeout(window.qb.spinnerTimeout.monthly_discount);
            window.qb.spinnerTimeout.monthly_discount = setTimeout(function() {
                window.qb.selections.monthly_discount = {};
                window.qb.selections.monthly_discount.value = $('input[name="monthly_discount"]').val();
                window.qb.update_monthly_total();
                window.qb.update_total();
            }, 500);
        },
        velocity_package_discount: function() {
            clearTimeout(window.qb.spinnerTimeout.velocity_package_discount);
            window.qb.spinnerTimeout.velocity_package_discount = setTimeout(function() {
                window.qb.selections.velocity_package_discount = {};
                window.qb.selections.velocity_package_discount.value = $('input[name="velocity_package_discount"]').val();
                window.qb.update_velocity_packages_total();
                window.qb.update_total();
            }, 500);
        },
        managed_services_discount: function() {
            clearTimeout(window.qb.spinnerTimeout.managed_services_discount);
            window.qb.spinnerTimeout.managed_services_discount = setTimeout(function() {
                window.qb.selections.managed_services_discount = {};
                window.qb.selections.managed_services_discount.value = $('input[name="managed_services_discount"]').val();
                window.qb.update_managed_services_total();
                window.qb.update_total();
            }, 500);
        },
        onetime_discount: function() {
            clearTimeout(window.qb.spinnerTimeout.onetime_discount);
            window.qb.spinnerTimeout.onetime_discount = setTimeout(function() {
                window.qb.selections.onetime_discount = {};
                window.qb.selections.onetime_discount.value = $('input[name="onetime_discount"]').val();
                window.qb.update_onetime_total();
                window.qb.update_total();
            }, 500);
        },
        customer_success: function() {
            var total = 0;
            window.qb.selections.customer_success = {};
            $(this).children('option:selected').each(function() {
                total = window.qb.numberRound($(this).attr('price')) || 0;
                if($(this).attr('value') == 'default') {
                    delete window.qb.selections.customer_success;
                } else {
                    window.qb.selections.customer_success.value = $(this).attr('value');
                }
            });
            $('.op-packages-price').text(total > 0 ? window.qb.numberWithCommas(total) : '—');
            window.qb.monthly_total.customer_success = total;
            window.qb.update_expert_services_total();
            window.qb.update_monthly_total();
            window.qb.update_total();
        },
        partner_success: function() {
            var total = 0;
            window.qb.selections.partner_success = {};
            $(this).children('option:selected').each(function() {
                total = window.qb.numberRound($(this).attr('price')) || 0;
                if($(this).attr('value') == 'default') {
                    delete window.qb.selections.partner_success;
                } else {
                    window.qb.selections.partner_success.value = $(this).attr('value');
                }
            });
            $('.ps-packages-price').text(total > 0 ? window.qb.numberWithCommas(total) : '—');
            window.qb.monthly_total.partner_success = total;
            window.qb.update_expert_services_total();
            window.qb.update_monthly_total();
            window.qb.update_total();
        },
        outsourced_labor: function() {
            var total = 0;
            window.qb.selections.outsourced_labor = {};
            $(this).children('option:selected').each(function() {
                total = window.qb.numberRound($(this).attr('price')) || 0;
                if($(this).attr('value') == 'default') {
                    delete window.qb.selections.outsourced_labor;
                    return;
                } else {
                    window.qb.selections.outsourced_labor.value = $(this).attr('value');
                }
            });
            $('.outsourced-labor-price').text(total > 0 ? window.qb.numberWithCommas(total) : '—');
            window.qb.monthly_total.outsourced_labor = total;
            window.qb.update_expert_services_total();
            window.qb.update_monthly_total();
            window.qb.update_total();
        },
        on_demand_advisor: function() {
            var total = 0;
            window.qb.selections.on_demand_advisor = {};
            $(this).children('option:selected').each(function() {
                total = window.qb.numberRound($(this).attr('price')) || 0;
                if($(this).attr('value') == 'default') {
                    delete window.qb.selections.on_demand_advisor;
                    return;
                } else {
                    window.qb.selections.on_demand_advisor.value = $(this).attr('value');
                }
            });
            $('.expert-live-advisor-price').text(total > 0 ? window.qb.numberWithCommas(total) : '—');
            window.qb.monthly_total.on_demand_advisor = total;
            window.qb.update_expert_services_total();
            window.qb.update_monthly_total();
            window.qb.update_total();
        },
        industries: function() { /*
            window.qb.update_presets(['industries']);
            $('select[name="product_package"]').trigger('change');
            window.qb.update_selection($(this).attr('name'), $(this).val());
            */
            window.qb.update_selection($(this).attr('name'), $(this).val());
        },
        bundles: function() {
            window.qb.update_presets(['industries']);
            window.qb.update_selection($(this).attr('name'), $(this).val());
        },
        product_package: function() {
            var total = 0;
            window.qb.selections.number_users = {};
            window.qb.selections.product_package = {};
            $(this).children('option:selected').each(function() {
                var price = $(this).attr('price');

                window.qb.selections.number_users.value = $('input[name="number_users"]').val();
                window.qb.selections.product_package.value = $(this).attr('value');
                total = (window.qb.numberRound(price) || 0) * (window.qb.numberRound($('input[name="number_users"]').val()) || 0);

                $('.total-packages-users').text(window.qb.numberWithCommas(total));
                $('.platform-level-price').text(window.qb.numberWithCommas(price));
            });
            window.qb.monthly_total.product_package = total;
            window.qb.update_presets(['industries', 'product_package'], 'implementations');
            window.qb.update_monthly_total();
            window.qb.update_total();

        },
        payment_terms: function() {
            window.qb.update_selection($(this).attr('name'), $(this).val());
        },
        subscription_term: function() {
            window.qb.update_selection($(this).attr('name'), $(this).val());
        },
        billing_period: function() {
            window.qb.update_selection($(this).attr('name'), $(this).val());
        },
        payment_method: function() {
            if ($(this).val() == 'card') {
                // disable payment terms, force "Due up receipt"
                $('select[name="payment_terms"]').val('Due on receipt').prop('disabled', true).trigger('change');
                $('label[for*="payment_terms"] span.select2').addClass('disabled');

                // upon_signature and effective date should be disabled for all CC orders
                $('#upon_signature').prop('checked', true);
                $('#upon_signature').addClass('disabled').prop('disabled', true);
                $('#effective_date').addClass('disabled').prop('disabled', true);

            } else {
                // enable payment terms
                $('select[name="payment_terms"]').prop('disabled', false).trigger('change');
                $('label[for*="payment_terms"] span.select2').removeClass('disabled');
                if (!$('#upon_signature').is(':checked')) {
                    $('#effective_date').removeClass('disabled').prop('disabled', null);
                    // console.log('in callbacks.payment_method: function() -- effective_date Enabled');
                }
                $('#upon_signature').removeClass('disabled').prop('disabled', null);
            }

            if(window.qb.is_renewal) {
                $('#effective_date').addClass('disabled').prop('disabled', true);
                $('#upon_signature').addClass('disabled').prop('disabled', true);
            }

            window.qb.update_selection($(this).attr('name'), $(this).val());
        },
        auto_renew: function() {
            window.qb.update_selection($(this).attr('name'), $(this).val());
        }

    },

    upon_signature: function(control) {
        if ($(control).is(':checked')) {
            $('#effective_date').val(window.qb.formatDate(new Date())); // set to current date
            $('#effective_date').addClass('disabled').prop('disabled', true);
        } else {
            $('#effective_date').removeClass('disabled').prop('disabled', null);
            // console.log('in upon_signature() -- effective_date Enabled');

        }
    },

    update_selection: function(field, value) {

        if(window.qb.validate_new_selection(field, window.qb.selections[field] ? window.qb.selections[field].value : null, value)) {
            window.qb.selections[field] = {};
            window.qb.selections[field].value = value;
        }
    },

    flow_change_managed_services: function(old_val, new_val, is_enterprise_user){

        if(new_val == null){
            console.log('detected null in flow_change_managed_services() for new_val');
            return false;
        }
        let avatar = '<img src="/images/quote-builder/avatar_auto_funny.png"><br><br>';

        // helper function
        function update_flow_change_managed_services(new_val, old_val, switchToNew){
            console.log('update_flow_change_managed_services('+new_val+', '+old_val+', '+(switchToNew ? 'true' : 'false')+')');

            window.qb.selections['industries'] = {};
            window.qb.selections['industries'].value = switchToNew ? new_val : old_val;

            if(
                (new_val != 'Automotive' && switchToNew == true || new_val == 'Automotive' && switchToNew == false)
                && (new_val != old_val)
            ){
                //change back to the default pricing model
                window.qb.flow_change_managed_services_popup.close();
                window.qb.pricing_model = is_enterprise_user ? 'velocity' : 'commercial';
                $('#v-pills-managed-tab').hide();
                window.qb.selectMenuItem(window.qb.pricing_model);
                window.qb.updatePanelsBasedOnPricingModel(window.qb.pricing_model);
            }

            $('select[name="industries"]').select2().val(switchToNew ? new_val : old_val).trigger('change');
            $('select[name="industries"]').focus();
            // enable "Expert Live Advisor" or "Outsourced Agent Labor" based on Industry
            window.qb.after_render.outsourced_labor($('select[name="outsourced_labor"]'));
        }

        window.qb.flow_change_managed_services_popup = $.confirm({
            closeIcon: function(){
                return false; // prevent modal from closing
            },
            lazyOpen: true,
            title: avatar + 'Pricing Catalog',
            theme: 'supervan',
            content:
                  '<div style="text-align:left">'
                + 'Please note that some industries have different pricing catalogs, by changing the industry we must reset your quote to offer you the relevant catalog.'
                + '</div>',
            type: 'green',
            columnClass: 'col-md-6',
            buttons: {
                keep: {
                    text: 'Keep <strong>"'+old_val+ '"</strong>', // is_enterprise_user ? "Velocity Packages" : "Named User",
                    keys: ['esc'],
                    action: function(){
                        update_flow_change_managed_services(new_val, old_val, false);
                    }
                },
                change: {
                    text: 'Change to <strong>"'+new_val+ '"</strong>', // "Always On Advisor", // stay in managed services / Always On Advisor
                    class: 'btn-success',
                    action: function(){
                        update_flow_change_managed_services(new_val, old_val, true);
                    }
                },
            }
        });

        window.qb.flow_change_managed_services_popup.open();
        return false;

    },

    /**
     * allows for error-handling when switching values for specific fields
     * @param field
     * @param old_val
     * @param new_val
     * @returns {boolean}
     */
    validate_new_selection: function(field, old_val, new_val){

        // console.log('validate_new_selection() : ',[field, old_val, new_val]);
        if(field == 'industries'){
            /**
             * show a message when switching to/from automotive industry
             */

            //console.log('industries old_val: ' +old_val + ' new_val: ' +new_val);
            //console.log('[debug] industries curr val: '+$('select[name="industries"]').val());

            if(new_val == 'Automotive') {
                if (window.qb.isPartner(window.qb.data_account)) {
                    window.qb.missing_error('Unsupported Option',
                        'Thank you for using Quote Builder, you selected a Partner account and at this time QB does not support Automotive Industry for Partner accounts'
                    );
                    $('select[name="industries"]').select2().val('').trigger('change');
                    $('select[name="industries"]').focus();
                    return false;
                } else {
                    if(window.qb.isEnterpriseUser(window.qb.data_account)) {
                        // hide for Enterprise
                        $('#v-pills-managed-tab').hide();
                    } else {
                        // show Managed Services for Automotive (direct/non-Partner)
                        $('#v-pills-managed-tab').show();
                        console.log(' show v-pills-managed-tab for non-Enterprise');
                    }
                }
            }

            if(
                   (old_val =='Automotive' || (new_val == 'Automotive' && old_val && old_val.length > 0)) // changing from/to Automotive (from non-blank)
                && (old_val != new_val)
                && !window.qb.isPartner(window.qb.data_account) // doesn't apply to partners e.g. they get blocked from Automotive
            ){
                window.qb.flow_change_managed_services(old_val, new_val, window.qb.isEnterpriseUser(window.qb.data_account));
                return false;
            }

            //if (new_val == 'Automotive' && window.qb.pricing_model != 'managed') {
            if (
                   new_val == 'Automotive'
                && !window.qb.isPartner(window.qb.data_account)
                && !window.qb.isEnterpriseUser(window.qb.data_account)
            ){
                $('#v-pills-managed-tab').show();
                console.log(' show v-pills-managed-tab for non-Enterprise/partner for Automotive');
            } else {
                $('#v-pills-managed-tab').hide();
            }


            // enable "Expert Live Advisor" or "Outsourced Agent Labor" based on Industry
            window.qb.after_render.outsourced_labor($('select[name="outsourced_labor"]'));


         }

        return true;
    },

    update_presets: function(selections, single){
        var selection_fields = [];
        selections.forEach(function(sel) {
            selection_fields.push($('select[name="'+sel+'"]').val());
        });

        window.qb.all_fields(selection_fields.join(','), function(fields) {
            $('select[name="industries"], select[name="bundles"]').each(function(){
                var _this = $(this);
                var options = $(this).children('option');
                fields.selected_filters.forEach(function(i) {
                    options.each(function(item, key) {
                        if ($(key).attr('value') == i && i != 'default') {
                            _this.val(i);
                        }
                    });
                });
                window.qb.process_defaults(single);
            });

        }, single || null, selections);


        if(     window.qb.is_upsell
            && !window.qb.is_renewal
            && !window.qb.force_reset_term
        ) {
            console.log('disabling subscription_term for upsells in update_presets()');
            // disable subscription_term field for upsells that are not renewals
            // unless, it's an "upsell and reset/extend term"  e.g. window.qb.force_reset_term == TRUE
            $('label[for*="subscription_term"] select').prop('disabled', true).select2();
            $('label[for*="subscription_term"] span.select2').addClass('disabled');
        }


        // console.log('update_presets: function called with:');
        // console.log('update_presets: function selections: ', selections);
        // console.log('update_presets: function single: ', single);

    },

    process_defaults: function(single) {
        if (single) {
            window.qb.fill_preset(single);
        } else {
            Object.keys(this.monthly_total).forEach(function(k, i) {
                window.qb.fill_preset(k);
            });
        }
    },

    fill_preset: function(k) {
        console.log('in fill_presets: function(k) for '+ k);
        $('input#' + k + ', select#' + k + ', textarea#' + k + ', div[name="' + k + '"]').each(function() {
            if ($(this).children().length) {
                $(this).find('input,select,textarea').each(function() {
                    $(this).trigger($(this).attr('event'));
                });
            } else {
                $(this).trigger($(this).attr('event'));
            }
        });
    },

    update_total: function() {
        var total, total_fees = 0;
        /*$('#subscription_term').children('option:selected').each(function() {
            total = (window.qb.numberRound($(this).val()) || 0) * window.qb.update_monthly_total();
            total_fees = total + window.qb.update_onetime_total();
        });*/
        total = (window.qb.numberRound($('select[name="subscription_term"]').val()) || 0) * window.qb.update_monthly_total();
        total_fees = total + window.qb.update_onetime_total();
        $('span[name="quote_initial_fees"]').text(window.qb.numberWithCommas(total));
        $('span[name="quote_initial_subscription"]').text(window.qb.numberWithCommas(total_fees));
    },

    update_monthly_total: function() {
        var total = 0;
        var fixed = 0;
        var discountable = 0;
        var _this = this;

        let secondary_pricing_model_names = ['velocity_packages', 'managed_services'];


        //console.log('update_monthly_total() for _this.pricing_model  : ' + _this.pricing_model );

        if(_this.pricing_model === 'velocity') {
            // window.qb.resetRecurrentServices(['velocity_packages']);
            discountable = _this.numberRound(_this.monthly_total['velocity_packages']);
            fixed = 0;

        } else if(_this.pricing_model === 'managed'){
            discountable = _this.numberRound(_this.monthly_total['managed_services']);
            fixed = 0;

        } else { // commercial pricing model calculation

            Object.keys(this.monthly_total).forEach(function (k, i) {

                 if(!secondary_pricing_model_names.includes(k)) { // exclude non-commercial pricing models

                    // product_package, etc - check window.qb.monthly_total
                    // this is WAY too dynamic, I highly recommend we refactor this e.g. isolate the key scope - Ivan
                    let value = _this.monthly_total[k];
                    if (!k.includes('discount')) { // exclude the discount fields!

                        discountable += window.qb.numberRound(value) || 0;

                        //console.log('for ' + k +': discountable: ' + value);

                        /*
                        secondary_pricing_model_names.forEach(function (kk, ii){ // exclude
                            if(!k.includes(kk)){
                                console.log('for ' + k +': exclude key: ' +kk + ' for commercial pricing');
                                discountable += window.qb.numberRound(value) || 0;
                                // console.log('discountable += ' + (window.qb.numberRound(value) || 0) + ' : ' + k);
                            } else {
                                console.log('for ' + k +': include key: ' + kk + ' for commercial pricing');
                            }
                        });
                        */
                    }
                 }
            });
            Object.keys(this.fixed_total).forEach(function (k, i) {
                if(!secondary_pricing_model_names.includes(k)) {
                    var value = _this.fixed_total[k];
                    fixed += window.qb.numberRound(value);
                }
            });
        }

        /**
         DISCOUNT GROUPS

            Commercial Pricing Model:
              Platform Users  : product_package
              AI & Automation : ai_automation
              Expert Services : customer_success, outsourced_labor, partner_success

            Velocity Pricing Model:
              Velocity Packages: velocity_packages

            Managed Services/Always On Advisor Pricing Model:
            Always On Advisor: managed_services

         FIELDS:

            Commercial Pricing Model:
              platform_users_discount
              ai_automation_discount
              expert_services_ongoing_discount

            Velocity Pricing Model:
              velocity_packages_discount

            Managed Pricing Model:
              managed_services_discount
        **/

        // update group-level item discounts in the "My Quote" RHS panel
        const velocity_packages_price =  window.qb.numberRound(this.monthly_total['velocity_packages'] || 0);
        const managed_services_price =  window.qb.numberRound(this.monthly_total['managed_services'] || 0);
        const platform_users_price =  window.qb.numberRound(this.monthly_total['product_package'] || 0);
        const ai_automation_price =  window.qb.numberRound(this.monthly_total['ai_automation'] || 0);
        const expert_services_ongoing_price =  window.qb.numberRound(this.monthly_total['customer_success'] || 0)
            + window.qb.numberRound(this.monthly_total['outsourced_labor'] || 0)
            + window.qb.numberRound(this.monthly_total['on_demand_advisor'] || 0)
            + window.qb.numberRound(this.monthly_total['partner_success'] || 0);

        const platform_users_discount = platform_users_price * (window.qb.numberRound($('input[name="platform_users_discount"]').val(), true)/100 || 0);
        const velocity_packages_discount = velocity_packages_price * (window.qb.numberRound($('input[name="velocity_packages_discount"]').val(), true)/100 || 0);
        const managed_services_discount = managed_services_price * (window.qb.numberRound($('input[name="managed_services_discount"]').val(), true)/100 || 0);
        const ai_automation_discount = ai_automation_price * (window.qb.numberRound($('input[name="ai_automation_discount"]').val())/100 || 0);
        const expert_services_ongoing_discount = expert_services_ongoing_price * (window.qb.numberRound($('input[name="expert_services_ongoing_discount"]').val())/100 || 0);
        $('.platform-users-discount-price').text(window.qb.numberWithCommas(window.qb.numberRound(platform_users_discount, true)));
        $('.velocity-packages-discount-price').text(window.qb.numberWithCommas(window.qb.numberRound(velocity_packages_discount, true)));
        $('.managed-services-discount-price').text(window.qb.numberWithCommas(window.qb.numberRound(managed_services_discount, true)));
        $('.ai-automation-discount-price').text(window.qb.numberWithCommas(window.qb.numberRound(ai_automation_discount, true)));
        $('.expert-services-ongoing-discount-price').text(window.qb.numberWithCommas(window.qb.numberRound(expert_services_ongoing_discount, true)));

        // populate the POST struct data

        //window.qb.monthly_total.ai_automation

        /*
        window.qb.monthly_total.platform_users_discount = platform_users_discount;
        window.qb.monthly_total.ai_automation_discount = ai_automation_discount;
        window.qb.monthly_total.expert_services_ongoing_discount = expert_services_ongoing_discount;
        */

        // onetime_discount, monthly_discount

        window.qb.selections.monthly_discount = {};        //TODO remove this legacy dependency
        window.qb.selections.platform_users_discount = {};
        window.qb.selections.velocity_packages_discount = {};
        window.qb.selections.managed_services_discount = {};
        window.qb.selections.ai_automation_discount = {};
        window.qb.selections.expert_services_ongoing_discount = {};


        window.qb.selections.monthly_discount.value = $('input[name="platform_users_discount"]').val() || 0; //TODO remove this legacy dependency
        window.qb.selections.platform_users_discount.value = $('input[name="platform_users_discount"]').val() || 0;
        window.qb.selections.velocity_packages_discount.value = $('input[name="velocity_packages_discount"]').val() || 0;
        window.qb.selections.managed_services_discount.value = $('input[name="managed_services_discount"]').val() || 0;
        window.qb.selections.ai_automation_discount.value = $('input[name="ai_automation_discount"]').val() || 0;
        window.qb.selections.expert_services_ongoing_discount.value = $('input[name="expert_services_ongoing_discount"]').val() || 0;

        window.qb.monthly_total.platform_users_discount = window.qb.numberRound($('input[name="platform_users_discount"]').val()) || 0;
        window.qb.monthly_total.velocity_packages_discount = window.qb.numberRound($('input[name="velocity_packages_discount"]').val()) || 0;
        window.qb.monthly_total.managed_services_discount = window.qb.numberRound($('input[name="managed_services_discount"]').val()) || 0;
        window.qb.monthly_total.ai_automation_discount = window.qb.numberRound($('input[name="ai_automation_discount"]').val()) || 0;
        window.qb.monthly_total.expert_services_ongoing_discount = window.qb.numberRound($('input[name="expert_services_ongoing_discount"]').val()) || 0;


        $('span[name="quote_monthly_subtotal"]').text(window.qb.numberWithCommas(fixed + discountable));

        let discount = 0;
        // calculate and apply the discounts
        $.each(dynamically_updated_discount_fields[window.qb.pricing_model], function(k, field) {

            // get the display name from the input name
            // e.g. expert_services_ongoing_discount => expert-services-ongoing-discount-price
            field = field.replace(/_/g, '-')+'-price';
            // strip out all non-numeric characters except "."
            let field_value = $('.'+field).text().replace(/[^\d.-]/g,''); // A$1,027.00 => 1,027.00

            //console.log('discount field: '+field + ' : ' + field_value);

            // sum up all the discounts
            discount = discount + (window.qb.numberRound(field_value || 0));
        });

         //console.log('discountable = ' + discountable);
         //console.log('discount = ' + discount);
         //console.log('fixed = ' + fixed);

        //total = window.qb.tmp_total_packages_users - discount;
        if(_this.pricing_model === 'velocity'){
            total = discountable/6 - discount/6; // velocity packages are priced for 6 months (as opposed to monthly)
        } else {
            total = discountable - discount;
        }
        //total = (total+fixed * 100)/100;


        let monthlyTotalDisplay = window.qb.numberWithCommas(window.qb.numberRound(total, true));

        let package_number_of_months = _this.pricing_model === 'velocity' ? 6 : 12;

        let annualTotalDisplay = window.qb.numberWithCommas(window.qb.numberRound(total * package_number_of_months, true));

        $('span[name="quote_monthly_total_'+window.qb.pricing_model+'"]').text(monthlyTotalDisplay);
        $('span[name="quote_annual_total_'+window.qb.pricing_model+'"]').text(annualTotalDisplay);


        // console.log('quote_monthly_total = ' + total);

        // show the discount justification box if the reps are exceeding the pre-approved discount thresholds
        if(window.qb.is_discount_justification_required())
        {
            $('#discount_justification').prop('disabled', false);
            $('#discount_justification_text').text(window.qb.get_minimum_discount_justification_threshold_text(window.orderType));
            $('#discount_justification_panel').show(1000);

            let text_limit = 500;
            $('#discount_justification_remain').text(text_limit);
            $('#discount_justification').keyup(function() {
                $(this).val($(this).val().substring(0, text_limit));
                let text_length = $(this).val().length;
                let remain = text_limit - parseInt(text_length);
                $('#discount_justification_remain').text(remain);
            });


        } else {
            $('#discount_justification').prop('disabled', true);
            $('#discount_justification_text').text(window.qb.get_minimum_discount_justification_threshold_text(window.orderType));
            $('#discount_justification_panel').hide(1000);
        }

        return total;
    },
    /**
     * @returns float
     */
    get_minimum_discount_justification_threshold: function() {
        return window.orderType == 'partner'
            ? parseFloat($("#minimumDiscountApprovalThresholdPartner").val())
            : parseFloat($("#minimumDiscountApprovalThresholdDirect").val())
            ;
    },
    /**
     * @returns string
     */
    get_minimum_discount_justification_threshold_text: function(orderType) {
        return (((orderType == 'partner') ? 'Partner discounts over ' : 'Discounts over ')
            + window.qb.get_minimum_discount_justification_threshold() + '% require a justification.'
            + (orderType == 'partner' ? ' Note: Platform user pricing is pre-discounted 30% for partners.' : ''));

    },
    is_discount_justification_required: function(){
        if(window.requireDiscount == true){
            return true;
        }

        let pricing_model = window.qb.pricing_model;

        // const partner_discount_default_percentage_for_platform_users = 30;
        const minimumDiscountApprovalThreshold = window.qb.get_minimum_discount_justification_threshold();

        if (pricing_model == 'velocity') {

            if (window.qb.monthly_total.velocity_packages_discount >= minimumDiscountApprovalThreshold) {
                return true;
            }
        } else if (pricing_model == 'managed') {

            if (window.qb.monthly_total.managed_services_discount >= minimumDiscountApprovalThreshold) {
                return true;
            }
        } else if (pricing_model == 'commercial') {
            if (
                   parseFloat(window.qb.monthly_total.platform_users_discount) >= minimumDiscountApprovalThreshold
                || parseFloat(window.qb.monthly_total.ai_automation_discount) >= minimumDiscountApprovalThreshold
                || parseFloat(window.qb.monthly_total.expert_services_ongoing_discount) >= minimumDiscountApprovalThreshold
                || parseFloat($('#onetime_discount').val()) >= minimumDiscountApprovalThreshold

            ){
                return true;
            }
        }

        return false;
    },
    update_onetime_total: function() {
        var total = 0;
        var _this = this;
        Object.keys(this.onetime_total).forEach(function(k, i) {
            var value = _this.onetime_total[k];
            total += window.qb.numberRound(value);
        });
        var discount = parseFloat($('input[name="onetime_discount"]').val()) || 0;
        $('span[name="quote_onetime_subtotal"]').text(window.qb.numberWithCommas(window.qb.numberRound(total, true)));
        if (discount > -1) {
            var discountPrice = (total * (discount / 100));
            discountPrice = window.qb.numberRound(discountPrice);
            var discountDisplay = '';
            if(discountPrice == 0){
                discountDisplay = '\u2014'
            }
            else{
                discountDisplay = window.qb.numberWithCommas(window.qb.numberRound(discountPrice, true));
            }
            total = total - discountPrice;
            $('.discount-price-onetime').text(discountDisplay);
        }
        total = window.qb.numberRound(total * 100)/100;
        var otTotalDisplay = '';
        if(total == 0){
            otTotalDisplay = '\u2014'
        }
        else{
            otTotalDisplay = window.qb.numberWithCommas(window.qb.numberWithCommas(total, true));
        }
        $('span[name="quote_onetime_total"]').text(otTotalDisplay);
        return total;
    },

    update_expert_services_total: function(){
        var total = 0;
        total = (window.qb.numberRound($('select.op option:selected').attr('price')) || 0) + // customer success package
            (window.qb.numberRound($('select.ps option:selected').attr('price')) || 0) +    // partner success package
            (window.qb.numberRound($('select.oal option:selected').attr('price')) || 0) +  // outsourced agent labor
            (window.qb.numberRound($('select.ela option:selected').attr('price')) || 0);  // expert live advisor / LE Attendant
        var esValue = '';
        if(total == 0){
            esValue = '\u2014'
        }
        else{
            esValue = window.qb.numberWithCommas(window.qb.numberRound(total, true))
        }
        $('.total-expert-services').text(esValue);
    },

    update_ai_automation_total: function(){
        var total = 0;
        $('div[name="ai_automation"] input:checked').each(function() {
            total += window.qb.numberRound($(this).attr('price')) || 0;
        });
        $('label[name="conversation_builder"]').children('input:checked').each(function() {
            total += window.qb.numberRound($(this).attr('price')) || 0;
        });
        var aiValue = '';
        if(total == 0){
            aiValue = '\u2014'
        }
        else{
            aiValue = window.qb.numberWithCommas(window.qb.numberRound(total, true));
        }
        $('span.ai_auto_total').text(aiValue);
    },

    update_velocity_packages_total: function(){
        var total = 0;
        $('div[name="velocity_packages"] input:checked').each(function() {
            total += window.qb.numberRound($(this).attr('price')) || 0;
        });

        let val = '';
        if(total == 0){
            val = '\u2014'
        }
        else{
            val = window.qb.numberWithCommas(window.qb.numberRound(total, true));
        }
        $('span.velocity_packages_total').text(val);
    },

    update_managed_services_total: function(){
        var total = 0;
        $('div[name="managed_services"] input:checked').each(function() {
            total += window.qb.numberRound($(this).attr('price')) || 0;
        });

        let val = '';
        if(total == 0){
            val = '\u2014'
        }
        else{
            val = window.qb.numberWithCommas(window.qb.numberRound(total, true));
        }
        $('span.managed_services_total').text(val);
    },

    missing_error: function(title, message, buttons=null, extraValues={}, callback=null) {
        buttons = buttons || {
            ok: {
                text: "OK",
                btnClass: 'btn-primary',
                keys: ['enter'],
                action: function(){
                    if(callback){
                        callback();
                    }
                    //window.location.reload();
                }
            }
        };
        window.qb.popup_missing_error = $.confirm({
            lazyOpen: true,
            closeIcon: function(){
                location.reload(); // reload the page
                return false; // prevent modal from closing
            },
            title: window.qb.avatar + title,
            theme: 'supervan',
            content: message,
            buttons: buttons,
            extraValues: extraValues || {}
        });
        window.qb.popup_missing_error.open();
        window.qb.toggleLockAccAndOppControls(true); // re-enable the UI so they can change the Account, etc

    },
    validate_opportunity_stage_update(val){
        console.log('validate_opportunity_stage_update () with val: ' + val);

        if(window.qb.setOpportunity
            && window.qb.setOpportunity.StageName
            && window.qb.setOpportunity.StageName.charAt(0) > val.charAt(0)
        ) {
            // setting the stage name to lower than it used to be
            $('#bubble_update_opp_stage_name_error').text('Warning: Setting Stage Name to an earlier stage');

        } else {
            $('#bubble_update_opp_stage_name_error').text('');
        }

        if( // ask for Competitor for Stage 3+
            (val ==  '3. Education' || val ==  '4. Buy In' || val == '5. Validation' || val == '6. Contract')
          && (!window.qb.setOpportunity || window.qb.setOpportunity.Competitor_Name__c == null)
        ) {
            $('#bubble_update_opp_competitor_name_wrapper').show();
        } else {
            console.log('stage <3 selected OR competitor name already set, will not prompt for competitor: '
                + ' selected StageName: ' + val
            );

            $('#bubble_update_opp_competitor_name').val('');
            $('#bubble_update_opp_competitor_name_other').val('');
            $('#bubble_update_opp_competitor_name_wrapper').hide();
        }

        if( // ask for Rep Commit
            (val == '5. Validation' || val == '6. Contract')
            && (  !window.qb.setOpportunity
                || window.qb.setOpportunity.Rep_Commit__c == null
                || window.qb.setOpportunity.Rep_Commit__c == 'Pipeline' // should be set to something other than Pipeline now
            )
        ) {
            $('#bubble_update_opp_rep_commit_value_wrapper').show();
        } else {
            console.log('stage <5 selected OR rep commit is already set to something other than Pipeline, will not prompt for rep commit: '
                + ' selected StageName: ' + val
            );

            $('#bubble_update_opp_rep_commit_value_wrapper').val('');
            $('#bubble_update_opp_rep_commit_value_wrapper').hide();
        }

    },

    validate_opportunity_competitor_update(val){
        console.log('validate_opportunity_competitor_update () with val: ' + val);
        if(val ==  'Other' || val == '5. Validation') {
            $('#bubble_update_opp_competitor_name_other_wrapper').show();
        } else {
            $('#bubble_update_opp_competitor_name_other').val('');
            $('#bubble_update_opp_competitor_name_other_wrapper').hide();
        }
    },

    validate_opportunity_rep_commit_value_update(val){
        console.log('validate_opportunity_rep_commit_value_update () with val: ' + val);
        /*
        if(val ==  '') {
            $('#bubble_update_opp_rep_commit_value_wrapper').show();
        } else {
            $('#bubble_update_opp_rep_commit_value_wrapper').val('');
            $('#bubble_update_opp_rep_commit_value_wrapper').hide();
        }*/
    },

    submit_quote: function(target) {

        window.qb.order_id = null;

        var contactInfo = {};
        if (window.qb.validate_form().length > 0) {
            window.qb.missing_error('Missing Information', '<p style="color:white;">Please review your selections. Did you...</p><ul><li>Select an account?</li><li>Select an opportunity?</li><li>Select an Industry?</li><li>Select a billing contact?</li></ul>');
            return;
        }

        if($('#missing_data').is(":visible")){

            if($('#update_opp_close_date').val() && new Date($('#update_opp_close_date').val()).getTime() <= new Date().getTime()){
                window.qb.missing_error('Missing Information', '<p style="color:white;">Please set <strong>Close Date</strong> for Opportunity to a future date');
                $('#update_opp_close_date').focus();
                return;
            } else if($('#missing_data_vat_reg').is(':visible')
                && $('#update_account_vat').val().length == 0
                && $('#update_account_reg').val().length == 0
            ){
                window.qb.missing_error('Missing Information', '<p style="color:white;">Please provide a <strong>VAT or a Self Registration number</strong> for selected Account');
                $('#update_account_vat').focus();
                return;
            }

        }




        // Upsell with Effective Date that's AFTER the current contract's end date -- should not be allowed
        if(window.qb.is_upsell
            && !window.qb.is_renewal
            && !window.qb.data_contract_selected.SBQQ__Evergreen__c // evergreen contracts do not have an end date
            //&& ((new Date($('#effective_date').val()).getTime() <= new Date(window.qb.data_contract_selected.StartDate).getTime())
            //||  (new Date($('#effective_date').val()).getTime() >= new Date(window.qb.data_contract_selected.EndDate).getTime()))
            &&  (new Date($('#effective_date').val()).getTime() >= new Date(window.qb.data_contract_selected.EndDate).getTime())
        ) {

            window.qb.popup_contract_date_error = $.confirm({
                closeIcon: function(){
                    return false; // prevent modal from closing
                },
                lazyOpen: true,
                title: window.qb.avatar + 'Whoa!',
                theme: 'supervan',
                content: '<div style="text-align:left">'
                    + 'You selected an <strong>Effective Date</strong> that is beyond your customer\'s current term. That is not possible in the upsell process.'
                    + '<br><br>Click <strong>"Change Effective Date"</strong> to correct the effective date and continue with the Upsell process.'
                    + '<br><br>Click <strong>"Renewal"</strong> and we will transform this quote into a Renewal, you can continue building it from there.'
                    //+ Date(window.qb.data_contract_selected.StartDate) + ' - ' + window.qb.data_contract_selected.EndDate
                    //+'<br><br>'
                    +'</div>',
                type: 'green',
                columnClass: 'col-md-6',
                buttons: {
                    change: {
                        text: "Change Effective Date",
                        keys: ['esc'],
                        action: function(){
                            window.qb.popup_contract_date_error.close();
                        }
                    },
                    renewal: {
                        text: "Renewal",
                        class: 'btn-success',
                        action: function(){
                            window.qb.launchRenewalFlow(window.qb.data_account);
                        }
                    },
                }
            });

            window.qb.popup_contract_date_error.open();
            return false;


        }

        if(window.qb.is_discount_justification_required() && $('#discount_justification').val().length == 0){

            let buttons = { ok: {
                    text: "OK",
                    btnClass: 'btn-primary',
                    keys: ['enter'],
                    action: function () {
                        $('#discount_justification').focus();
                    }
                }
            };
            window.qb.missing_error('Missing Information', '<p style="color:white;">Please provide a discount justification</p>', buttons);
            return;
        } else if(window.qb.pricing_model == 'velocity' && window.qb.selections.velocity_packages === undefined){
            window.qb.missing_error('Missing Information', 'Please select a package before building a quote');
            return;

        } else if(window.qb.pricing_model == 'managed' && window.qb.selections.managed_services === undefined){
            window.qb.missing_error('Missing Information', 'Please make a selection before building a quote');
            return;
        }
        $('.info-field').each(function() {
            if (contactInfo[$(this).attr('id') || $(this).attr('name')]) {

            } else {
                if ($(this).attr('type') == 'checkbox') {
                    if ($(this).is(':checked')) {
                        contactInfo[$(this).attr('id') || $(this).attr('name')] = $(this).val();
                    }
                } else {
                    contactInfo[$(this).attr('id') || $(this).attr('name')] = $(this).val();
                }
            }
        });
        $(target).text('Building Quote...').prop('disabled', true);
        window.qb.loading_screen.display();

        if(window.qb.is_implementation_only){
            console.log('Implementation-only quote detected, resetting all recurrent services to 0');
            window.qb.resetRecurrentServices();
        }

        /*
        if(window.qb.pricing_model == 'velocity') {
            console.log('Velocity Pricing Model detected, executing clear_commercial_pricing_model_selections()');
            window.qb.clear_commercial_pricing_model_selections();
        }*/

        // make sure we override any incorrect defaults with an actual selected contract
        contactInfo.ammendContract = window.qb.data_contract_selected_id;

        window.qb.dataSubmitting = {
            quote: {
                monthly: window.qb.monthly_total,
                onetime: window.qb.onetime_total,
                fixed: window.qb.fixed_total
            },
            contact: contactInfo,
            opportunity: window.qb.is_new_upsell ? '' : $('#sf_opps').val(), // do not submit Oppty.Id if we are supposed to create a new one
            accountId: $('#sf_accounts').val(),
            selections: window.qb.selections,
            upsell: window.qb.is_upsell || false,
            is_new_upsell: window.qb.is_new_upsell || false,
            is_implementation_only: window.qb.is_implementation_only || false,
            is_rapid_deploy: window.qb.is_rapid_deploy || false,
            is_renewal: window.qb.is_renewal || false,
            is_new_renewal: window.qb.is_new_renewal || false,
            is_flat_renewal: window.qb.is_flat_renewal || false,
            is_upsell_co_term: window.qb.is_upsell_co_term|| false,
            is_upsell_reset: window.qb.is_upsell_reset | false,
            order_quote_type: window.qb.order_quote_type,
            billing_contact_id: $('#sf_contacts').val(),
            discount_justification: window.qb.is_discount_justification_required() ? $('#discount_justification').val() : null, // don't submit unless it's required
            update_opp_close_date: $('#update_opp_close_date').val() ? $('#update_opp_close_date').val() : null,
            update_account_vat: $('#update_account_vat').val() ? $('#update_account_vat').val() : null,
            update_account_reg: $('#update_account_reg').val() ? $('#update_account_reg').val() : null,
            update_account_industry: // update account industry if we have a new selection
                    ($('select[name="industries"]').val() && window.qb.data_account.LP_Industry__c != $('select[name="industries"]').val())
                        ? $('select[name="industries"]').val()
                        : null,
            pricing_model: window.qb.pricing_model,
        };


        // model-specific data massaging
        if(window.qb.pricing_model == 'velocity') {
            window.qb.dataSubmitting =
                window.qb.resetRecurrentServicesInProvidedData(
                    window.qb.dataSubmitting,
                    ['velocity_packages', 'velocity_packages_discount', 'billing_period', 'subscription_term', 'payment_terms', 'payment_method', 'industries'],
                    []
                );
        } else if(window.qb.pricing_model == 'managed') {
            window.qb.resetRecurrentServicesInProvidedData(window.qb.dataSubmitting,
                ['managed_services' , 'managed_services_discount', 'billing_period', 'subscription_term',
                    'payment_terms', 'payment_method', 'industries'],
                []);
        } else { // commercial
            window.qb.resetRecurrentServicesInProvidedData(window.qb.dataSubmitting,
                ['number_users', 'product_package', 'onetime_discount', 'platform_users_discount',
                    'ai_automation', 'ai_automation_discount', 'expert_services_ongoing_discount', 'on_demand_advisor',
                    'outsourced_labor', 'customer_success', 'partner_success', 'industries', 'implementations',
                    'sms_options_long', 'sms_options_short', 'sms_options_vanity', 'whatsapp_options_number',
                    'billing_period', 'subscription_term', 'payment_terms', 'payment_method'],

                ['managed_services', 'managed_services_discount', 'velocity_packages', 'velocity_packages_discount',
                    'billing_period', 'subscription_term', 'payment_terms', 'payment_method']);
        }

        $('a.close').click(function(e) {
            e.preventDefault();
            window.qb.loading_screen.dismiss();
        }).prop('disabled', true).addClass('disabled');

        window.qb.ajax_popup = null;

        $.ajax({
            url: window.quoteSubmitUrl,
            method: 'post',
            dataType: 'json',
            contentType: 'application/json',
            data: JSON.stringify(window.qb.dataSubmitting),

            statusCode: {
                504: function() {
                    $('.jconfirm').remove(); // close other popups
                    window.qb.loading_screen.dismiss();
                    // window.qb.loading_screen.done('#');
                    $(target).text('Build Quote').prop('disabled', false);
                    window.qb.ajax_popup = 'queued for processing';
                    $.confirm({
                        title: 'Quote queued for processing...',
                        theme: 'supervan',
                        content: 'Your Quote is taking longer than expected to build and has been queued for processing, please check your "Recent Orders" page in a few minutes',
                        type: 'green',
                        buttons: {
                            ok: {
                                text: "OK",
                                btnClass: 'btn-primary',
                                keys: ['enter'],
                                action: function(){
                                    $(target).text('Build Quote').prop('disabled', false);
                                }
                            }
                        }
                    });
                    // re-enable the overlay close button
                    $('a.close').prop('disabled', null).removeClass('disabled');

                },
                419: function() {
                    $('.jconfirm').remove(); // close other popups
                    window.qb.loading_screen.dismiss();
                    $(target).text('Build Quote').prop('disabled', false);
                    window.qb.ajax_popup = 'session timed out';
                    $.confirm({
                        title: 'Expired session',
                        theme: 'supervan',
                        // msg == 'CSRF token mismatch.'
                        content: 'Your session has expired, please reload the page and retry.',
                        type: 'green',
                        buttons: {
                            ok: {
                                text: "Reload Page",
                                btnClass: 'btn-primary',
                                keys: ['enter'],
                                action: function(){
                                    location.reload(); // reload the page
                                }
                            }
                        }
                    });


                }
            }

        }).done(function(response) {
            console.log(response.id);
            window.qb.order_id = response.id;
            //window.open(response.url);
            $.ajax({
                url: '/agent/order/status/' + response.id,
                method: 'get',
                contentType: 'application/json'
            }).done(function(status) {
                if (status.ready) {
                    window.qb.loading_screen.done(response.url, $('select[name="payment_method"]').val() == 'card' ? response.confirmation_url : null);
                    $(target).text('Build Quote').prop('disabled', false);

                    let event_category =  window.qb.dataSubmitting.upsell ? 'upsell' : 'regular';
                    let event_label = window.qb.dataSubmitting.upsell ? ((window.qb.dataSubmitting.upsell && window.qb.dataSubmitting.is_new_upsell) ? 'new upsell' : 'existing upsell') : '';
                    window.qb.logQuote(true, event_category, event_label);

                } else {
                    window.qb.loading_screen.dismiss();
                    if(window.qb.ajax_popup == null) { // no other popups open yet

                       // window.qb.popup_missing_error.close()
                        $.confirm({
                            title: 'Error creating quote.',
                            theme: 'supervan',
                            content: (status.reason ? status.reason : '') + "\n\nContact the administrator.",
                            type: 'green',
                            buttons: {
                                ok: {
                                    text: "OK",
                                    btnClass: 'btn-primary',
                                    keys: ['enter'],
                                    action: function () {
                                        $(target).text('Build Quote').prop('disabled', false);
                                    }
                                }
                            }
                        });

                        let event_category = window.qb.dataSubmitting.upsell ? 'upsell' : 'regular';
                        let event_label = window.qb.dataSubmitting.upsell ? ((window.qb.dataSubmitting.upsell && window.qb.dataSubmitting.is_new_upsell) ? 'new upsell' : 'existing upsell') : '';
                        window.qb.logQuote(false, event_category, event_label);
                    }

                }
                $('a.close').prop('disabled', null).removeClass('disabled');
            });

        }).fail(function(data) {
            window.qb.loading_screen.dismiss();
            // window.qb.loading_screen.done('#');
            $(target).text('Build Quote').prop('disabled', false);

            let msg = (data && typeof data.responseJSON !== "undefined" && typeof data.responseJSON.message !== "undefined")
                ? data.responseJSON.message
                : data;

            let msgSet = false;
            if(typeof msg === 'string' || msg instanceof String) {
                if (msg.includes('CSRF token mismatch')) {
                    msg = 'Your session has expired, please reload the page and retry.';
                    msgSet = true;
                } else if (msg.includes('Salesforce')) {
                    msg = 'A Salesforce error has occurred, please re-submit the quote by clicking on the "Build Quote" button again.';
                    msgSet = true;
                } else {
                    msg = '...';
                }
            }

            if(window.qb.ajax_popup == null) { // no other popups open yet
                $('.jconfirm').remove(); // close other popups
                $.confirm({
                    title: 'Error creating quote.',
                    theme: 'supervan',
                    content: msg,
                    type: 'green',
                    buttons: {
                        ok: {
                            text: "OK",
                            btnClass: 'btn-primary',
                            keys: ['enter'],
                            action: function () {
                                $(target).text('Build Quote').prop('disabled', false);
                            }
                        }
                    }
                });
                // re-enable the overlay close button
                $('a.close').prop('disabled', null).removeClass('disabled');

                console.log(data);
            }

        });
    },

    validate_form: function() {
        var errors = [];
        $('.error-text').hide();
        $(':required,select.required').each(function() {
            if ($(this).hasClass('disabled')) return;

            if (!$(this).val()) {
                errors.push(this);
                console.log($(this).attr('id'));
                $(this).parent().children('.error-text').show();
            }
        });
        return errors;
    },

    loading_screen: {
        display: function() {

            $('#order-column1').width('95%');

            $('.opp-update-message').hide();
            $('#reminder-message').hide();
            $('#one-time-services-message').hide();
            $('#chatbubble1').hide();
            $('#chatbubble2').hide();
            $('#chatbubble3').hide();

            $('#bubble_update_opp_competitor_name').val('');
            $('#bubble_update_opp_competitor_name_other').val('');
            $('#bubble_update_opp_competitor_name_wrapper').hide();
            $('#bubble_update_opp_competitor_name_other_wrapper').hide();
            $('#bubble_update_opp_rep_commit_value_wrapper').hide();

            $('.order-column :button').prop('disabled', false); // re-enable the Save buttons (in case of multiple quote builds w/o page reloads)


            $('.loading-screen').fadeIn(function() {
                $(this).find('.status').fadeIn('slow', function() {

                    var jab = $('<p>Instead of a few days 😀</p>');
                    jab.hide().addClass('jab');
                    $(this).after(jab);

                    setTimeout(function() {
                        jab.fadeIn('slow', function() {
                            setTimeout(function() {
                                $('.loading-screen ul').fadeIn();
                            }, 1000);
                        });
                    },3000);

                });
            });
        },
        done: function(url, page) {
            $('.loader, .loading-animation').hide();

            if(window.hasOneTimeServices){
                $('#one-time-services-message').show();
            } else {
                $('#one-time-services-message').hide(); // clear previous state
            }

            if (page && !window.qb.is_discount_justification_required()) {
                $('.or').css('display', 'block');
                if(window.qb.is_renewal || window.qb.is_upsell){
                    // the CC payment link is hidden for renewals and upsells
                    $('.loading-screen a.customer_url').hide();
                } else {
                    $('.customer_url').attr('href', page).css('display', 'block');
                }
            }
            if(window.qb.is_discount_justification_required()) {
                $('.discount-message').show();
                $('#reminder-message').hide();
            } else {
                $('.loading-screen a.btn').attr('href', url).css('display', 'block');
                /*.click(function() {
                    window.qb.loading_screen.dismiss();
                });*/
                $('#reminder-message').fadeIn("slow");
            }
            $('.jab').hide();

            $('#order-column1').width('40%');

            if(window.qb.is_discount_justification_required()) {
                $('.status').html('Oh, my!<br>');
            } else {
                $('.status').html('Cowabunga!<br>Your quote has been<br>successfully built and<br>the paperwork is ready<br><br>');
            }

            $('.opp-update-message').fadeIn("slow");


            setTimeout(function () {
                $('#chatbubble1').fadeIn("slow");
            }, 500);

            if(!window.qb.setOpportunity || window.qb.setOpportunity.StageName != '6. Contract') {
                // prompt for StageName update for StageName other than '6. Contract'
                setTimeout(function () {
                    if(window.qb.setOpportunity) { // pre-populate if we have data from existing opp
                        $('#bubble_update_opp_stage_name').val(window.qb.setOpportunity.StageName);
                    }
                    $('#chatbubble2').fadeIn("slow");
                }, 1500);

            }

            if(window.qb.load_chat_bubble_3 && !window.qb.isExpiredOpportunityCloseDate(window.qb.setOpportunity)) {
                // if it was expired, we already handled inline (via yellow box) so no need to nag them about it again
                setTimeout(function () {
                    if (window.qb.setOpportunity) { // pre-populate if we have data from existing opp
                        $('#bubble_update_opp_close_date').val(window.qb.setOpportunity.CloseDate);
                    } else {
                        $('#bubble_update_opp_close_date').val(new Date().toJSON().slice(0, 10));
                    }

                    console.log('fading in chatbubble3.... FROM quote_builder.js')
                    $('#chatbubble3').fadeIn("slow");
                }, 2500);
            }

        },
        dismiss: function() {


            $('.loading-screen').fadeOut(0, function() {
                $('.loader, .loading-animation').show();
                $('.status').text('Building your quote in just a few seconds...').hide();
                $('.discount-message').hide();
                $('.jab').remove();
                $('.loading-screen ul').hide();
                $('.loading-screen a.btn-success, .loading-screen a.customer_url').hide();
            });

            // location.reload(); // reload the page
        }
    },

    data_export_screen: {
        display: function() {
            $('.loading-data-export-screen').fadeIn('slow');
            $('.loading-data-export-screen .status').text('Exporting your quote data ...').fadeIn('slow', function() {
                setTimeout(function() {
                    $('.loading-screen ul').fadeIn();
                }, 1000);
            });
        },
        done: function(url) {

            $('a.close').click(function(e) {
                e.preventDefault();
                window.qb.data_export_screen.dismiss();
            }).prop('disabled', false).removeClass('disabled');


            $('.loader, .loading-animation').hide();
            $('.loading-data-export-screen .status').text('Your data is ready! 😀').fadeIn('slow', function() {
                setTimeout(function() {
                    $('.loading-screen ul').fadeIn();
                }, 1000);
            });
            $('.loading-data-export-screen a.btn').attr('href', url).css('display', 'block');
        },
        dismiss: function() {
            $('.loading-data-export-screen').fadeOut(0, function() {
                $('.loading-data-export-screen .loader, .loading-data-export-screen .loading-animation').show();
                $('.loading-data-export-screen .status').text('Exporting your quote data ...').hide();
                $('.loading-data-export-screen ul').hide();
                $('.loading-data-export-screen a.btn-success, .loading-data-export-screen a.customer_url').hide();
            });
        }
    },

    build_whatsapp_usage_statement: {
        display: function() {
            $('.download-whatsapp-usage-screen').fadeIn('slow');
            $('.download-whatsapp-usage-screen .status').text('Building a WhatsApp Usage Statement ...').fadeIn('slow', function() {
                setTimeout(function() {
                    $('.loading-screen ul').fadeIn();
                }, 1000);
            });
        },
        done: function(url) {

            // re-enable the close button
            $('a.close').click(function(e) {
                e.preventDefault();
                window.qb.build_whatsapp_usage_statement.dismiss();
            }).prop('disabled', false).removeClass('disabled');

            // hide "loading..." and replace with "ready" msg & button
            $('.loader, .loading-animation').hide();
            $('.download-whatsapp-usage-screen .status').text('Your Usage Statement is ready! 😀').fadeIn('slow', function() {
                setTimeout(function() {
                    $('.loading-screen ul').fadeIn();
                }, 1000);
            });
            $('.download-whatsapp-usage-screen a.btn').attr('href', url).css('display', 'block');
        },
        dismiss: function() {
            $('.download-whatsapp-usage-screen').fadeOut(0, function() {
                $('.download-whatsapp-usage-screen .loader, .download-whatsapp-usage-screen .loading-animation').show();
                $('.download-whatsapp-usage-screen .status').text('Building your usage statement ...').hide();
                $('.download-whatsapp-usage-screen ul').hide();
                $('.download-whatsapp-usage-screen a.btn-success, .download-whatsapp-usage-screen a.customer_url').hide();
            });
        }
    },

    location: {
        toggle: function(target) {
            var areaToShow = $(target).val();
            this.toggleVal(areaToShow);
        },
        toggleVal: function(areaToShow) {
            $('.location-fields').hide().find('input').prop('required', null);
            var c = $('.' + areaToShow + '-fields').show();
            c.find('input').each(function() {
                if ($(this).hasClass('required')) {
                    $(this).prop('required', true);
                }
            });
        }
    },

    orderType: {
        toggle: function(target) {
            var areaToShow = $(target).val();
            this.toggleVal(areaToShow);
        },
        toggleVal: function(areaToShow) {
            window.orderType = areaToShow;
            $('.type-fields').hide().find('input').prop('required', null);
            var c = $('.' + areaToShow + '-fields').show();
            c.find('input').each(function() {
                if ($(this).hasClass('required')) {
                    $(this).prop('required', true);
                }
            });
        }
    },

    /**
     * Triggered when an Opportunity is selected from the dropdown menu
     * @param target
     */
    updateCurrency: function(target) {

        let oppId = $(target).val();

        if(oppId == null || oppId.length <= 1){
            window.qb.setOpportunity = null;
            return;
        }
        console.log('updateCurrency for oppId:', oppId);

        // window.qb.request('get', '/api/pricing/opportunity/' + oppId, true, false, null, function(data) {

        let data = window.qb.data_opportunities.find(opp => opp.Id === oppId);

        window.qb.setOpportunity = data;
        window.qb.setCurrency = data.CurrencyIsoCode;
        window.qb.setPricebook = data.Pricebook2Id;

        console.log('Got data for Opportunity :' + oppId, window.qb.setOpportunity);

        console.log('updating pricing.... via: window.qb.all_fields(\'general,,basic\', function() {window.qb.process_defaults();});');
        window.qb.all_fields('general,,basic', function() {window.qb.process_defaults();});

        if (window.qb.setPricebook == null) {
            let msg = 'Missing PriceBook for Opportunity ' + window.qb.setOpportunity.Name + '. ';
            window.qb.missing_error('Missing Information',
                msg + window.qb.get_contact_field_ops_text(),
                window.qb.get_contact_field_ops_popup_button(msg)
            );
            return;
        }

        if (window.qb.setCurrency == null) {
            let msg = 'Missing Currency for Opportunity ' + window.qb.setOpportunity.Name + '. ';
            window.qb.missing_error('Missing Information',
                msg + window.qb.get_contact_field_ops_text(),
                window.qb.get_contact_field_ops_popup_button(msg)

            );
            return;
        }

        window.qb.checkOpportunityCloseDateAndShowAnUpdateWindowIfNeeded();

        $('select[name="industries"]').select2().trigger('change');

        if (window.qb.data_account.Legal_Entity__c == null) {
            window.qb.missing_error('Missing Information',
                'Legal entity must be selected on the account.<br><br><a class="btn btn-lg btn-primary" href="'+window.qb.data_account.web_link+'">Open in SalesForce.</a></strong>', {close: {text: 'Close'}});
            return;
        }


        // pre-select opportunity's default billing contact if it's one of the valid billing contacts for this account
        let billing_contact = jQuery.isEmptyObject(window.qb.setOpportunity)
            ? null
            : window.qb.data_contacts.find(contact => contact.Id === window.qb.setOpportunity.Billing_Contact__c);
        if (billing_contact) {
            $('select#sf_contacts').val(billing_contact.Id).trigger('change');
            console.log('Billing Details contact pre-selected/defaulted to Opp contact: ' + billing_contact.Name);
        } else{
            console.log('Billing Details contact has no default Opp billing contact');
        }
        //});
    },
    updateCountry: function(target) {
        var intl = $(target).val().toLowerCase() == 'united states' ? 'us' : 'international';
        window.qb.location.toggleVal(intl);

    },
    validateBillingContact: function(select){
        // console.log('validateBillingContact', select);
        if(select.options.length > 0) {
            if(select.options[select.selectedIndex]) {
                //let value = select.options[select.selectedIndex].value;
                //console.log('value', value);
                let corp_entity_mismatch = select.options[select.selectedIndex].getAttribute('corp_entity_mismatch');
                //console.log('corp_entity_mismatch', corp_entity_mismatch);
                if (corp_entity_mismatch == 1) {
                    $('#sf_contacts').val(null).trigger('change'); // reset selection
                    window.qb.missing_error('US/Canada Billing Contact is required for this Account', 'The selected SFDC Account is "LP Inc" but the selected Contact is outside of the US/Canada.<br><br><a class="btn btn-lg btn-primary" href="' + window.qb.data_account.web_link + '" target="_blank">Open in SalesForce.</a></strong>', {close: {text: 'Close'}});
                    return;
                }
            }
        }
    },

    /**
     * Do we have any renewal opportunities for the UI-selected account
     * @returns {boolean}
     */
    hasRenewalOpportunities: function() {
        let hasRenewalOpps = false;

        if(window.qb.data_opportunities) {
            window.qb.data_opportunities.forEach(function (v, k) {
                if (v.Type == 'Renewal') {
                    hasRenewalOpps = true;
                }
            });
        }
        return hasRenewalOpps;
    },
    toggleLockAccAndOppControls: function(enable=true){

        // console.log('toggleLockAccAndOppControls: ' + (enable ? 'unlocked' : 'locked'));

        if(enable){
            console.log('toggleLockAccAndOppControls - UNLOCKING fields');
            $('.sidebar-nav').removeClass('loading').css({opacity: 1});
            $('#sf_accounts').prop('disabled', false);
            $('#sf_opps').prop('disabled', false);
            // unlock both visible and hidden panels in case of race conditions that would otherwise leave some panels disabled
            $('.panel').removeClass('loading').css({opacity: 1});
        } else {
            console.log('toggleLockAccAndOppControls - LOCKING fields');
            // lock re-selection until this request is complete...
            $('.sidebar-nav').addClass('loading').css({opacity: 0.99});
            $('#sf_accounts').prop('disabled', true);
            $('#sf_opps').prop('disabled', true);
            $('.panel:visible').addClass('loading').css({opacity: 0.7});
        }
    },
    /*
    clear_commercial_pricing_model_selections() { return;


        window.qb.selections.whatsapp_options_number = {'value' : "0"};
        window.qb.selections.sms_options_long = {'value' : "0"};
        window.qb.selections.implementations = {'value' : []};
        window.qb.resetRecurrentServices();

        let reset_totals = [
            window.qb.monthly_total,
            window.qb.onetime_total,
            window.qb.fixed_total
        ];

        reset_totals.forEach(function(v, k) {
           Object.keys(v).forEach(
               e => (v[e] = 0)// console.log(`key=${e}  value=${v[e]}`)
           );
        });
    },*/

    reset_quote_data : function() {

        // force reset these as they tend to stay selected in window.qb.selections.* even when accounts get switched
        delete window.qb.selections.customer_success;
        delete window.qb.selections.partner_success;
        delete window.qb.selections.outsourced_labor;
        delete window.qb.selections.on_demand_advisor;
        delete window.qb.selections.managed_services;
        delete window.qb.selections.velocity_packages;

        window.qb.is_upsell = false;
        window.qb.is_renewal = false;
        window.qb.is_new_renewal = false;
        window.qb.is_flat_renewal = false;
        window.qb.is_upsell_co_term = false;
        window.qb.is_upsell_reset = false;

        window.qb.setQuoteTitle('Original');
    },
    /**
     * @param account SFDC Account object
     * @returns {boolean}
     */
    isEnterpriseUser(account) {

        if(!account || !account.owner){
            return false;
        } else {

            let ownerRole = account.owner.role.Name;
            let isNamedUser = account.owner.Named_User__c ? true : false;

            return (
                ownerRole.startsWith('Enterprise')
                && !isNamedUser
            );
        }
    },
    isPartner(data_account){
        if(data_account) {
            return (data_account.is_partner_indirect_sale || data_account.is_partner_self_sale);
        } else {
            return false;
        }
    },
    isDirect(data_account){
        if(data_account) {
            return data_account.is_direct;
        } else {
            return false;
        }
    },
    updatePanelsBasedOnPricingModel(pricing_model) {

        /*
        * if account.type is client OR partner w/ partner reseller
        * don't let them pick Velocity, show a popup.
        * Otherwise, can switchback and forth.
        **/

        // console.log('debug: updatePanelsBasedOnPricingModel() to '+pricing_model);

        if( pricing_model === 'velocity') {

            // console.log('Account.isPartner(): ', (new Account(window.qb.data_account)).isPartner());

            /**
             * As per ahecker on 2020.05.14:
             * if Account.Type=Client and/or there is valid contact, then no VELOCITY
             * if ANY other Account.Type except Reseller Partner, show VELOCITY
             * (all within context of we only get to this logic for Enterprise)
             */
            window.qb.waitForAccountData(window.qb.data_account, 'updatePanelsBasedOnPricingModel', function() {

                if (window.qb.data_account.is_client
                    || window.qb.data_account.Type == 'Client'
                    || window.qb.has_valid_contract
                    || (window.qb.data_account.Type == 'Partner' || window.qb.data_account.partner_account != null)
                ) {
                    let msg = '';
                    let title = 'Pricing Model';
                    if (!window.qb.isPartner(window.qb.data_account)) {
                        msg = 'Thank you for using Quote Builder, the account you selected is an Existing Customer and at this time QB only supports Velocity Packages for New Logo';
                    } else {
                        msg = 'Thank you for using Quote Builder, you selected a Partner account and at this time QB only supports Velocity Packages for Direct accounts';
                    }
                    window.qb.missing_error(title, msg, null, {}, window.qb.hide_all_panels);
                    return false;

                } else if (window.qb.data_opportunities.length == 0) {

                    // non-clients (SFDC Account.Type != Client) are expected to have existing open opportunities
                    // unless they have a contract (upsell / renewals)
                    if (!window.qb.data_account.is_client
                        && !window.qb.has_valid_contract
                    ) {

                        // console.log('window.qb.data_account', window.qb.data_account);
                        window.qb.missing_error('No Open Opportunities', 'This account has no open opportunities. Please create an opportunity in SalesForce or choose a different account.');
                        return true;
                    }

                }

                // default images for bubble3 (overridden for commercial below)
                let img = '';
                // World_Sales_Region__c : one of these [APAC, EMEA, Latin America, or North America]
                if(window.qb.data_account.World_Sales_Region__c == 'EMEA') {
                    img = 'jerry_haywood';
                } else if(window.qb.data_account.World_Sales_Region__c == 'APAC') {
                    img = 'andrew_cannington';
                } else {
                    // LATAM + North America
                    img = 'adam_canter';
                }
                $("#chatbubble3_image").attr("src", "/images/quote-builder/" + img + ".png");

            });

        }

        // scroll to the top
        // document.getElementById('top_anchor').scrollIntoView();
        // $('.clearable_panel').hide();
        // $('.sidebar').hide(); // hide all "my quote" panels/sidebars


        window.qb.hide_all_panels();
        $('#my_quote_sidebar_'+pricing_model).show('slide', {direction: "right"}); //show the right panel
        // console.log('showing #my_quote_sidebar_'+pricing_model);

        window.qb.update_monthly_total();
        window.qb.update_velocity_packages_total();
        window.qb.update_managed_services_total();

        $('#panel_account').show('slide');

        $('label[for*="sf_contacts"] span.select2').width('100%');

        if(pricing_model === 'commercial' || pricing_model === 'managed'){
            // override
            $("#chatbubble3_image").attr("src", "https://lh3.googleusercontent.com/a-/AAuE7mB4S06h-0zxGSOSLX6poXSSAHv60ENSLD1038rAYw=s64")

            if(pricing_model === 'commercial') {
                window.qb.update_ui_for_commercial();
            } else if(pricing_model === 'managed'){
                window.qb.update_ui_for_managed_services();
            }
            // commercial and managed use the same billing details
            window.qb.update_ui_billing_for_commercial();

        } else if (pricing_model === 'velocity'){
            window.qb.update_ui_for_velocity();
        } else {
            window.qb.missing_error('Unexpected Error', 'Unknown pricing model');
            return;
        }
        if(window.qb.is_renewal) {
            window.qb.updateEffectiveDateIfNeededForRenewals();
        }

    },
    hide_all_panels(){
        $('#panel_account').hide();
        $('.clearable_panel').hide();
        $('#panel_billing').hide();
        $('#discount_justification_panel').hide();
        document.getElementById('top_anchor').scrollIntoView();
    },
    update_ui_for_commercial: function(){
        //console.log('update_ui_for_commercial()');
        $('#panel_users').show('slide');
        $('#panel_ai').show('slide');
        $('#panel_services').show();
        $('#panel_billing').show();
    },

    update_ui_billing_for_commercial: function(){
        //console.log('update_ui_billing_for_commercial()');
        $('select[name="billing_period"] option[value="Upfront"]').remove();
        $('select[name="billing_period"]').val('Annual').prop('disabled', false).trigger('change');
        $('label[for*="billing_period"] span.select2').removeClass('disabled');

        $('select[name="subscription_term"]').val("12").prop('disabled', false).trigger('change');
        $('label[for*="subscription_term"] span.select2').removeClass('disabled');

        //$('select[name="payment_method"]').val('ACH/Wire Transfer').prop('disabled', false).trigger('change');
        $('select[name="payment_method"]').prop('disabled', false).trigger('change');
        $('label[for*="payment_method"] span.select2').removeClass('disabled');

        $('label[for*="sf_contacts search"]').css('line-height','30px');

        $('#upon_signature').prop('checked', true);
        $('#upon_signature').removeClass('disabled').prop('disabled', false);

        $('#effective_date').val(window.qb.formatDate(new Date())); // set to current date
        $('#effective_date').addClass('disabled').prop('disabled', true); // disable since upon_signature is checked

        $('.effective_date_upon_signature').hide();
        $('.effective_date').show();

        // $('label[for*="biling_contact"] span.select2').css('margin-top: 0');

        $('.payment_method').addClass('col-sm-6').removeClass('col-sm-4');
        // set payment_method to the same width as auto_renew above it for proper alignment
        $('label[for*="payment_method"] span.select2').width($('label[for*="auto_renew"] span.select2').width());

        // fixes the billing details input lengths
        $('.hidden_when_velocity').hide();
        $('.hidden_when_velocity').show();

    },

    update_ui_for_managed_services: function() {
        if (window.qb.pricing_model == 'managed') {
            //console.log('update_ui_for_managed_services()');

            window.qb.waitForAccountData(window.qb.data_account, 'update_ui_for_managed_services', function() {
                if (window.qb.isPartner(window.qb.data_account)) {
                    window.qb.missing_error('Unsupported Option',
                        'Thank you for using Quote Builder, you selected a Partner account and at this time QB does not support Always On Advisor pricing model for Partner accounts',
                        null, {}, window.qb.hide_all_panels
                    );
                    window.qb.hide_all_panels();
                    return false;
                }
            });

            window.qb.selections.on_demand_advisor = {value: ""}; // reset pre-existing values
            window.qb.selections.managed_services = {value: ""}; // reset pre-existing values


            $('#panel_users').hide();
            $('#panel_ai').hide();
            $('#panel_services').hide();
            $('#panel_velocity_packages').hide();

            $('#panel_account').show('slide');
            $('#panel_managed_services').show('slide');

            // fixes the billing details input lengths
            $('.hidden_when_velocity').hide();
            $('.hidden_when_velocity').show();

            $('label[for*="payment_method"] span.select2').width($('label[for*="billing_period"] span.select2').width() * 1.56);
            $('.payment_method').addClass('col-sm-6').removeClass('col-sm-4');

            $('label[for*="auto_renew"] span.select2').width($('label[for*="billing_period"] span.select2').width() * 1.56);
            $('.auto_renew').addClass('col-sm-6').removeClass('col-sm-4');

            $('#panel_billing').show();
        }
    },

    update_ui_for_velocity: function(){
        if(window.qb.pricing_model == 'velocity') {
            //console.log('update_ui_for_velocity()');
            //window.qb.clear_commercial_pricing_model_selections();

            //$('#my_quote_sidebar_velocity').show('slide', { direction: "right" });

            $('.hidden_when_velocity').hide();

            $('#panel_users').hide();
            $('#panel_ai').hide();
            $('#panel_services').hide();
            $('#panel_managed_services').hide();
            $('#panel_account').show('slide');
            $('#panel_velocity_packages').show('slide');

            $('.existing-customer').hide(); // no renewals/upsells for Enterprise/velocity

            let option = $('<option>');
            option.attr('value', 'Upfront').text('Upfront');
            $('select[name="billing_period"]').append(option).val('Upfront').prop('disabled', true).trigger('change');
            $('label[for*="billing_period"] span.select2').addClass('disabled');


            $('select[name="subscription_term"]').val("6").prop('disabled', true).trigger('change');
            $('label[for*="subscription_term"] span.select2').addClass('disabled');

            $('select[name="payment_method"]').val('ach').prop('disabled', true).trigger('change');
            $('label[for*="payment_method"] span.select2').addClass('disabled');

            $('.effective_date_upon_signature').show();
            $('.effective_date').hide();

            $('select[name="effective_date"]').val('Upon Signature').prop('disabled', true).trigger('change');
            $('label[for*="effective_date"] span.select2').addClass('disabled');

            $('label[for*="effective_date_upon_signature"]').css('line-height', '16px');
            $('label[for*="sf_contacts search"]').css('line-height', '24px');

            $('.payment_method').addClass('col-sm-4').removeClass('col-sm-6');
            $('label[for*="payment_method"] span.select2').width($('label[for*="billing_period"] span.select2').width());
            /*
            $('.payment_method').addClass('col-sm-4').removeClass('col-sm-6')
            $('.select2-container--default.select2-container--disabled .select2-selection--single').width(300);
            $('.select2-sf_contacts-container').width('100%');
            */
            $('#panel_billing').show();
        }

    },

    waitForAccountData: function (account_data, caller, callback){
        if(!account_data) {
            (async () => {
                while (!window.qb.data_account) {
                    console.log("debug: waiting for Account data to be available for "+caller+" in waitForAccountData() ...");
                    await new Promise(resolve => setTimeout(resolve, 200));
                }
                console.log("debug: got Account data in waitForAccountData(), proceeding...");
                callback();
            })();
        } else {
            callback();
        }
    },

    updateOpportunities: function(accountId) {         // window.qb.updateOpportunities($('#sf_opps').val())
        $('#placeholder-logo').hide();

        if(accountId.length <= 1){
            return;
        }
        //let CurrencyIsoCode = window.qb.data_opportunities.find(opp => opp.Id === window.qb.setOpportunity.Id).CurrencyIsoCode;
        try {
            window.qb.account_owner_role = window.qb.accounts.find(account => account.value == accountId).owner_role;
        } catch(e){
            console.log(e);
        }

        console.log('updateOpportunities for account:', accountId);
        // console.log('account_owner_role:', window.qb.account_owner_role);

        if (window.qb.isEnterpriseUser(window.qb.data_account)){
            window.qb.pricing_model = 'velocity';
        } else {
            window.qb.pricing_model = 'commercial';
        }

        window.qb.is_new = false;
        window.qb.is_change = false;

        window.qb.toggleLockAccAndOppControls(false);

        $('#sf_opps').html('');
        $('#sf_contacts').html('<option value="">Select billing contact...</option>');

        window.qb.reset_quote_data();

        // render the panels
        $('#pricing-model-nav').show(); // show the pricing model nav first
        $('#panel_account').show('slide'); // then, show the account panel (elements built in updateOpportunities: function)

        window.qb.toggleLockAccAndOppControls(false);

        //  get a list of opportunities for a specified account
        //window.qb.request('get', '/api/pricing/opportunities/' + accountId, false, false, null, function(data) {
        window.qb.request('get', '/api/pricing/account_v2/' + accountId, false, false, null, function(data) {

            window.qb.data_opportunities = data.opportunities;
            window.qb.data_account = data.account;
            window.qb.data_contacts = data.contacts;
            window.qb.data_pricing = data.pricing;

            window.qb.is_client = window.qb.data_account.is_client ? true : false;

            // update the industries dropdown based on account's industry
            $('select[name="industries"]').select2().val(window.qb.data_account.LP_Industry__c);
            $('select[name="industries"]').select2().trigger('change');
            window.qb.selections['industries'] = {};
            window.qb.selections['industries'].value = window.qb.data_account.LP_Industry__c;

            if(
                !window.qb.data_account.is_partner_indirect_sale
                && !window.qb.data_account.is_partner_self_sale
                && !window.qb.data_account.is_direct
            ){
                window.qb.missing_error(
                    'Account Type',
                    'The account you selected has an unsupported account type: '
                    + window.qb.data_account.Type
                    + '. Please select a different account or update the account in SFDC to one of the following supported account types:'
                    + ' Client, Prior Client, Prospect, Unpaid Trial, Reseller Partner, Active.'
                    +'<br><br><a class="btn btn-lg btn-primary" href="'+window.qb.data_account.web_link+'">Open in SalesForce.</a></strong>'
                );
                return true;
            }

            // $('#panel_account').show('slide');


            /**
             * OPPORTUNITY DATA PROCESSING
             */
            data.opportunities.forEach(function (v, k) {

                //if(v.Type != 'Renewal') { // remove Renewal Opps until we have a Renewal flow/process
                var option = $('<option>');
                option.attr('value', v.Id).text(v.Name); //debug:  + ' ' + v.Id + ' ' +  v.CloseDate + ' ' + v.Amount);
                // for v.Type = Renewal     renewals -- we could lock the renewal effective date for the OF/quote to v.CloseDate
                $('#sf_opps').append(option);
                //}

            });
            // console.log('# opportunities: ' + data.opportunities.length);
            if (data.opportunities.length > 0) {
                window.qb.updateCurrency($('#sf_opps')[0]);
            }

            /**
             * CONTACT DATA PROCESSING
             */
            window.qb.data_contacts = data.contacts;
            // console.log('contacts count:', data.contacts.length);

            data.contacts.forEach(function (v, k) {
               // console.log(v);

                // $('#sf_contacts option[value="Ivan C. Contract McTester"]').prop('selected',true);
                //$('#sf_contacts').val(v.Name); // Change the value or make some change to the internal state
                //$('#sf_contacts').trigger('change'); // Notify only Select2 of changes


                /*
                    if billing contact country is NOT - USA /Canada and account entity is INC = Error
                    i wouldn't make it a 'cannot select billing contact'
                    if do more a error after you selected the bad contact
                    and we do a nice POPUP
                    popup number 123454657
                */


                var option = $('<option>');
                option.attr('value', v.Id).text(v.Name);
                if (v.MailingAddress &&
                    v.FirstName &&
                    v.LastName &&
                    v.Email &&
                    v.Phone &&
                    v.MailingAddress.street &&
                    v.MailingAddress.city &&
                    v.MailingAddress.country
                ) {
                    // Do not change the option at all, let it add as usual.
                    if (v.MailingAddress.country.toLowerCase() == 'United States'.toLowerCase()
                        || v.MailingAddress.country.toLowerCase() == 'Canada'.toLowerCase()) {
                        if (v.MailingAddress.state && v.MailingAddress.postalCode) {
                            // pass e.g. have state & zip for US & Canada
                        } else {
                            option.prop('disabled', true);
                            option.attr('value', v.Id + '-invalid').text(v.Name + ' - Missing Info');
                            // Disable the option and mark it as missing info.
                        }
                    } else if (
                        v.MailingAddress.country.toLowerCase() != 'United States'.toLowerCase()
                        && v.MailingAddress.country.toLowerCase() != 'Canada'.toLowerCase()
                        && window.qb.data_account != null
                        && 'Legal_Entity__c' in window.qb.data_account
                        && window.qb.data_account.Legal_Entity__c == 'LP Inc'
                    ) { // outside US but under LP Inc

                        console.log('billing contact flagged for corp_entity_mismatch:', v.Id);
                        // LP Inc AND non-us/Canada
                        option.attr('value', v.Id + '-invalid').text(v.Name + ' *');
                        // option.prop('disabled', true);
                        option.attr('corp_entity_mismatch', '1');
                        //window.qb.missing_error('Missing Information', 'The selected account is missing the VAT and/or self registration number.<br><br><a class="btn btn-lg btn-primary" href="'+data.account.web_link+'">Open in SalesForce.</a></strong>', {close: {text: 'Close'}});
                        //return;

                    }
                } else {
                    option.prop('disabled', true);
                    option.attr('value', v.Id + '-invalid').text(v.Name + ' - Missing Info');
                    // Disable the option and mark it as missing info.
                }
                $('#sf_contacts').append(option);
            });

            $("#sf_contacts").val($("#sf_contacts option:first").val());

            // window.qb.getAccountDetails($('#sf_accounts'))
            window.qb.getAccountDetails(window.qb.data_account);
            window.qb.updatePanelsBasedOnPricingModel(window.qb.pricing_model); // finally render the rest of the elements
            window.qb.toggleLockAccAndOppControls(true);

            // pre-select opportunity's default billing contact if it's one of the valid billing contacts for this account
            if(!jQuery.isEmptyObject(window.qb.data_contacts) && !jQuery.isEmptyObject(window.qb.setOpportunity)) {
                let billing_contact = window.qb.data_contacts.find(contact => contact.Id === window.qb.setOpportunity.Billing_Contact__c);
                if (billing_contact) {
                    $('select#sf_contacts').val(billing_contact.Id).trigger('change');
                    console.log('Billing Details contact pre-selected/defaulted to Opp contact: ' + billing_contact.Name);
                } else {
                    console.log('Billing Details contact has no default Opp billing contact');
                }
            }

            if(window.qb.pricing_model == 'velocity') {
                $('#my_quote_sidebar_velocity').show('slide', {direction: "right"});
            } else if(window.qb.pricing_model == 'managed') {
                    $('#my_quote_sidebar_managed').show('slide', {direction: "right"});
            } else {
                $('#my_quote_sidebar_commercial').show('slide', {direction: "right"});
            }

        });

    },

    isSupportedBrowser: function() {
        return (
            navigator.userAgent.search("Chrome") >= 0
            || navigator.userAgent.search("Firefox") >= 0
        );
    },

    openTab: function(url) {
        window.open(url, '_blank');
    },

    selectMenuItem: function(itemName){
        let pricing_models = ['commercial', 'cpi', 'managed', 'velocity'];
        // console.log('selectMenuItem ' + itemName + ' from', pricing_models);
        pricing_models.forEach(function(item, index) {
            if(item !== itemName) {
                $('#v-pills-' + item + '-tab').attr('aria-selected', false);
                $('#v-pills-' + item + '-tab').removeClass('active');
            } else {
                $('#v-pills-' + item + '-tab').attr('aria-selected', true);
                $('#v-pills-' + item + '-tab').addClass('active');
            }
        });
    },

    updateAccountData: function(accountId, data){
        if(accountId && data) {
            console.log('in updateAccountData() -- setting Account Industry in SFDC for accoutId : ' + accountId, data);
            window.qb.request('post', '/agent/account/update/' + accountId, true, false, data, function (data) {
                console.log('/agent/account/update/' + accountId + ' returned', data);
            });
        }
    },

    validateIndustry: function(account_data, error_msg=''){
        if (!account_data.LP_Industry__c) {
            let msg = 'It looks like this account is missing an Industry in SFDC. No worries, please provide it here and QB will update it.<br><br>\n' +
                // force select2 to show above the .jconfirm modal & dark text color for placeholder
                '<style>.select2-container--open {z-index: 999999999} .select2-container--default .select2-selection--single .select2-selection__placeholder {color: #000000}</style>\n' +
                '<div id="missing_data_select_industry">\n' +
                '    <div id="missing_data_close_date" class="card-body">\n' +
                '        <select name="missing_data_industry" class="missing_data_industry"></select>' +
                '        <div style="color:#ffffff;padding-top:10px;">'+(error_msg ? error_msg : '') + '</div>'
                '    </div>' +
                '</div>'
            ;
            window.qb.missing_error('Oopsy Daisy!', msg, null, {},
                function(){
                    let val = $('select[name="missing_data_industry"]').val();
                    if(!val){
                        let error_msg = 'An industry is required to proceed.';
                        return window.qb.validateIndustry(account_data, error_msg);
                    }
                    let data = {};
                    data.LP_Industry__c = val;
                    window.qb.updateAccountData(window.qb.data_account.Id, data); // update the data in SFDC
                    $('select[name="industries"]').val(val).trigger('change');// update the UI
                }
            );
            $('select[name="missing_data_industry"]').focus();

            $('select[name="missing_data_industry"]').width(250).select2();

            for (const index in window.qb.data_account.industries) {
                let option = $('<option>');
                let val = window.qb.data_account.industries[index];

                option.attr('value', index).text(val);
                console.log('adding '+index+' : '+ val + ' to #missing_data_industry');
                $('select[name="missing_data_industry"]').append(option);
            }
            $('select[name="missing_data_industry"]').select2({
                placeholder: 'Select an Industry'
            }).val('').trigger('change');

        }
    },

    getAccountDetails: function(data) { // window.qb.data_account     window.qb.getAccountDetails($('#sf_accounts')[0]);

        console.log('getAccountDetails for account:', data.Id);

        window.qb.reset_quote_data();

        window.qb.data_account = data;
        // window.qb.account = new Account(window.qb.data_account);

        if (!data.Type) {
            let msg = 'Invalid Account "Type". ';
            window.qb.missing_error(
                'Invalid Account',
                msg + window.qb.get_contact_field_ops_text(),
                window.qb.get_contact_field_ops_popup_button(msg)
            );

        }
        let type = data.Type.toLowerCase().includes('partner') || data.Reseller_Parent__c ? 'partner' : 'direct';
        window.qb.orderType.toggleVal(type);
        $('#company_name').val(data.Name); // initialized, gets updated below in some cases

        window.qb.validateIndustry(data);

        //console.log(data, type);
        console.log('got account data, now triggering a pricing model update and price update for quote type:', type);
        console.log('hiding "Always On Advisor" by default');
        $('#v-pills-managed-tab').hide();

        if (window.qb.isEnterpriseUser(window.qb.data_account)){
            // hide the named user / commercial pricing model option for Enterprise sales reps
            $('#v-pills-commercial-tab').hide();
            // hide Always On Advisor
            $('#v-pills-managed-tab').hide();

            // force velocity pricing model
            window.qb.pricing_model = 'velocity';
            // window.qb.updatePanelsBasedOnPricingModel('velocity'); // locks the billing details fields for velocity
            if ($('#my_quote_sidebar_velocity').is(":hidden")) {
                $('#my_quote_sidebar_velocity').show('slide', {direction: "right"});
            }

        } else if(window.qb.data_account.LP_Industry__c == 'Automotive'){
            $('#v-pills-managed-tab').show();

        } else {

            if(window.qb.data_account && window.qb.data_account.owner.Named_User__c == true) {
                $('#v-pills-velocity-tab').show(); // show velocity packs / enterprise for special users flagged as "Named User", who should have access to both enterprise and Named User
            }
            // show everything for 'Commercial*' roles e.g. Marketing Manager, CEO, etc
            // make commercial the default
            $('#v-pills-commercial-tab').show();
            window.qb.pricing_model = 'commercial';
        }

        window.qb.selectMenuItem(window.qb.pricing_model);

        // this call forces the pricing updates for Partner (70%)
        // whenever the non-partner pricing is mistakenly displayed due to a race condition
        window.qb.update_presets(['industries']);

        window.qb.is_client = data.is_client ? true : false;

        window.qb.has_valid_contract = false;
        window.qb.data_contract_selected_id = null;
        window.qb.data_contract_selected = null;
        window.qb.data_contract_selected = null;

        data.contracts.forEach(function (contract) {
            if (
                contract.Status == "Active"
                && contract.subscriptions.length > 0 // has line items
            // && (new Date(contract.EndDate).getTime() > new Date().getTime()) // future end date -- unreliable
            ) {
                // if we have at least one active line item, the contract is active
                contract.subscriptions.forEach(function (sub) {
                    if (
                            sub.SBQQ__SubscriptionType__c == 'Evergreen' // Evergreen contract e..g no end date
                        || (new Date(sub.SBQQ__EndDate__c).getTime() > new Date().getTime()) // Renewable/Termed
                    ) {
                        window.qb.has_valid_contract = true;
                        // console.log('contract line item is valid: ' + sub.SBQQ__EndDate__c)
                    } else {
                         console.log('contract subscription line item is invalid: ', sub);
                    }
                });
            }
        });

        if(window.qb.pricing_model == 'commercial') { // todo move this logic out into a func

            window.qb.is_rapid_deploy =
                (data.LPSiteID1__c != null && data.Type == "Unpaid Trial" && !window.qb.has_valid_contract) ? true : false;

            if (window.qb.is_rapid_deploy) {
                window.qb.quote_type_label = 'Rapid Deploy Upgrade';
                window.qb.setQuoteTitle(window.qb.quote_type_label);
            }

            if (!window.qb.is_client && window.qb.has_valid_contract) {
                let msg = 'It appears that this account has a Contract but the Account Type is not set to "Client" in SalesForce.';
                window.qb.missing_error('Invalid Account data',
                    msg + window.qb.get_contact_field_ops_text(),
                    window.qb.get_contact_field_ops_popup_button(msg)
                );
                $('.quick-tip').hide();
            } else if (window.qb.is_client && !window.qb.has_valid_contract) {
                let msg = 'Please add a valid contract to the account. ';
                window.qb.missing_error('Sacrebleu !!!',
                    'It appears you are trying to do an upsell against this account, but there is a slight hiccup in that this account does not have a valid contract. '
                    + msg + window.qb.get_contact_field_ops_text(),
                    window.qb.get_contact_field_ops_popup_button(msg)
                );
                $('.quick-tip').hide();


            } else if (window.qb.data_opportunities.length == 0 && !window.qb.is_client && !window.qb.has_valid_contract) {
                // non-clients (SFDC Account.Type != Client) are expected to have existing open opportunities
                // unless they have a contract (upsell / renewals)
                // console.log('22 window.qb.data_account', window.qb.data_account);
                window.qb.missing_error('No Open Opportunities',
                    'This account has no open opportunities. Please create an opportunity in SalesForce or choose a different account.'
                );
                $('.quick-tip').hide();
                return true;

            } else if (window.qb.has_valid_contract) {

                // show the upsell tips
                $('.quick-tip').show();
                window.qb.launchExistingContractFlow(data);
            } else {
                window.qb.hideCustomerInfo(data);
                $('.quick-tip').hide();
            }
            if (type == 'direct') {
                $('#company_name').val(data.Name);
            } else {

                if (data.partner_account) {
                    $('#company_name').val(data.partner_account.Name);
                } else {
                    $('#company_name').val(data.Name);
                }
                $('#customer_name').val(data.Name);
            }
        }

    },
    setQuoteTitle(title, header_text='My Quote'){
        window.qb.quote_type_label = title;
        $('.sidebar-title').html(window.qb.quoteImg+' ' +header_text+ '<small style="font-size: 12px;"><br>&nbsp;&nbsp;'+title+'</small>');
        window.qb.order_quote_type = title.replace(header_text, '').trim();
        // console.log('quote title set to: '+ window.qb.order_quote_type);
    },
    getQuoteTitle(){
        return window.qb.order_quote_type;
    },

    setUpsellResetFlags(quoteTitle){
        window.qb.setQuoteTitle(quoteTitle);
        window.qb.is_upsell = true;
        window.qb.is_renewal = false;
        window.qb.is_new_renewal = false;
        window.qb.is_flat_renewal = false;
        window.qb.is_upsell_co_term = false;
        window.qb.is_upsell_reset = true;
    },

    /**
     * @param data account data including data.contracts
     */
    launchExistingContractFlow: function(data){

        console.log('in launchExistingContractFlow()');


        if (window.qb.data_account.contracts[0].SBQQ__Evergreen__c != true) {

            if (window.qb.data_account.RecordTypeId === '0121J000001QGl7QAG') {    // CAO / LPA / LP Auto
                // non-evergreen contract for
                let msg = 'Selected Contract is not supported at this time. ';
                window.qb.missing_error('Contract Information Error',
                    msg + window.qb.get_contact_field_ops_text(),
                    window.qb.get_contact_field_ops_popup_button(msg)
                );
                return;
            }
        }

        if(window.qb.data_account.contracts[0].SBQQ__Evergreen__c){
            window.qb.setUpsellResetFlags('LPA Migration');
            window.qb.launchUpsellFlow(data, true);
            return;
        }

        let upsell_coterm_button = {
            text: "Upsell Co-terminous",
                // keys: ['enter'],
                class: 'btn-success',
                action: function(){
                    window.qb.setUpsellResetFlags('Upsell Co-term');
                    window.qb.launchUpsellFlow(data, false);
                }
        };

        let upsell_reset_button = {
            text: "Upsell and Reset/Extend Term",
            // keys: ['enter'],
            class: 'btn-success',
            action: function(){
                window.qb.setQuoteTitle('Upsell & Reset/Extend');
                window.qb.is_upsell = true;
                window.qb.is_renewal = false;
                window.qb.is_new_renewal = false;
                window.qb.is_flat_renewal = false;
                window.qb.is_upsell_co_term = false;
                window.qb.is_upsell_reset = true;
                window.qb.launchUpsellFlow(data, true);
            }
        };
        let upsell_renewal_button = {
            text: "Renewal",
                // keys: ['esc'],
                action: function(){
                window.qb.launchRenewalFlow(data);
            }
        };
        /*,
        flat_renewal: {
            text: "Flat Renewal",
            // keys: ['esc'],
            action: function(){
                window.qb.is_upsell = true; // to force the Upsell Order Form
                window.qb.is_renewal = true;
                window.qb.is_new_renewal = window.qb.hasRenewalOpportunities() ? false : true;
                window.qb.is_flat_renewal = true;
                window.qb.displayContracts(data, false, true);
                $('label[for*="sf_opps"] select').select2().prop('required', null);


                $('#platform_users_panel').hide();
                $('#ai_and_automation_panel').hide();
                $('#expert_services_panel').hide();
            }
        }*/

        window.qb.popup_existing_contract = $.confirm({
            closeIcon: function(){
                location.reload(); // reload the page
                return false; // prevent modal from closing
            },
            lazyOpen: true,
            title: window.qb.avatar + '¡Ay, caramba!',
            theme: 'supervan',
            content: '<div style="text-align:left">'
                + 'We noticed that you selected one of our beloved existing customers. QB can help you in the following wonderful ways:'
                +'</div>',
            type: 'green',
            columnClass: 'col-md-6',
            buttons: {upsell_coterm_button, upsell_reset_button, upsell_renewal_button}
        });

        /*
        // close all other popups -- can be VERY problematic if we are error-detecting/preventing flows with another popup
        if(window.qb.popup_missing_error != null) {
            window.qb.popup_missing_error.close();
        }
        */
        window.qb.popup_existing_contract.open();

    },

    launchRenewalFlow: function(data){
        window.qb.setOpportunity = null;
        window.qb.setQuoteTitle('Renewal');
        window.qb.is_upsell = true; // to force the Upsell Order Form
        window.qb.is_renewal = true;
        window.qb.is_new_renewal = window.qb.hasRenewalOpportunities() ? false : true;
        window.qb.is_flat_renewal = false;
        window.qb.is_upsell_co_term = false;
        window.qb.is_upsell_reset = false;
        window.qb.displayContracts(data, false, true);

        console.log('(QB will use the Renewal Oppty) populated in #sf_opps');
        $('label[for*="sf_opps"] select').select2().prop('required', null);
        $('#select2-sf_opps-container').html('(QB will use the Renewal Oppty)');
        console.log($('#select2-sf_opps-container').val());
    },

    forceNewOppUpsellFlow: function(data, forceResetTerm) {
        window.qb.is_new = true;
        window.qb.is_change = false;
        window.qb.is_upsell = true;
        window.qb.is_new_upsell = true;
        window.qb.is_implementation_only = false;
        window.qb.displayContracts(data, false, forceResetTerm);

        //$('label[for*="sf_opps"] select').select2().prop('required', null);
        //$('#sf_opps').html(''); // make the Opportunity selection blank e.g. we are creating a new one
        console.log('(QB will create your Upsell Oppty) populated in #sf_opps');
        $('label[for*="sf_opps"] select').select2().prop('required', null);
        $('#select2-sf_opps-container').html('(QB will create your Upsell Oppty)');
        console.log($('#select2-sf_opps-container').val());
    },

    /**
     *
     * @param data account data including data.contracts
     * @param forceResetTerm
     */
    launchUpsellFlow: function(data, forceResetTerm=false){

        window.qb.force_reset_term = forceResetTerm;
        console.log('window.qb.setOpportunity: ', window.qb.setOpportunity);

        // filter out non upsell opps
        console.log('------------- FILTERING OUT OPPS THAT ARE NOT UPSELL ---------------');
        window.qb.data_opportunities.forEach(function(v, k) {
            if (window.qb.is_upsell && !window.qb.is_renewal && v.Type != 'Upsell') {
                console.log('removing ' + v.Type + ' opp type: ' + v.Name + ' : ' + v.Id);
                $("#sf_opps option[value=" + v.Id + "]").remove();
            }
        });
        // set the opp list to the updated list (select first available opp)
        window.qb.updateCurrency($('#sf_opps')[0]);

        if(window.qb.setOpportunity == null) {
            // non-clients (SFDC Account.Type != Client) are expected to have existing open opportunities
            // unless they have a contract (upsell / renewals)
            if (window.qb.is_client && window.qb.has_valid_contract) {
                console.log("No Existing Open Opportunities found under the selected account, triggering the 'NEW' flow");
                // this will skip the "NEW" vs "CHANGE" prompt and will go directly to "NEW"
                window.qb.forceNewOppUpsellFlow(data, forceResetTerm);
                return true;
            }
        }

        window.qb.popup_upsell = $.confirm({
            closeIcon: function(){
                location.reload(); // reload the page
                return false; // prevent modal from closing
            },
            lazyOpen: true,
            title: window.qb.avatar + 'Blimey !!!',
            theme: 'supervan',
            content: '<div style="text-align:left">'
                // +'You have selected an account marked as an existing customer.'
                // +'<br><br>'
                + 'To create a <strong>New Upsell Opportunity</strong>, click <strong>"NEW"</strong> - this will create a new Upsell Opportunity and Quote'
                +'<br><br>'
                + 'To create a new Quote on an <strong>Existing Upsell Opportunity</strong>, please click <strong>"CHANGE"</strong>'
                +'<ol style="text-align: left">'
                +'<br>'
                + 'Upsell Tips:'
                + '<li>The quote should represent the final total of what you want to sell (ie if the customer has 3 users and you wish to add 2 more, you should select "5")</li>'
                // + '<li>You can either co-term the upsell or reset the term</li>'
                + '<li>Your quote will show the existing products as "Cancelled" and the new add on products as "New"</li>'
                + '</ol></div>',
            type: 'green',
            columnClass: 'col-md-6',
            buttons: {
                confirm: {
                    text: "NEW",
                    // keys: ['enter'],
                    class: 'btn-success',
                    action: function(){
                        window.qb.setOpportunity = null;
                        window.qb.forceNewOppUpsellFlow(data, forceResetTerm);

                        /*
                        $.confirm({
                            title: 'Is this a Recurring or a One-time upsell?',
                            theme: 'supervan',
                            content: '<p style="color: #fff">Click `One-time` if this is for Professional Services / One-time Implementations only</p>',
                            type: 'green',
                            columnClass: 'col-md-6',
                            buttons: {
                                confirm: {
                                    text: "Recurring",
                                    keys: ['enter'],
                                    class: 'btn-success',
                                    action: function(){
                                        window.qb.is_new_upsell = true;
                                        window.qb.is_implementation_only = false;
                                        window.qb.displayContracts(data, false);
                                        window.qb.toggleRecurrentFormElements(true);
                                    }
                                },
                                cancel: {
                                    text: "One-time",
                                    keys: ['esc'],
                                    action: function(){
                                        window.qb.is_new_upsell = true;
                                        window.qb.is_implementation_only = true;
                                        // window.qb.displayContracts(data, false);

                                        // disable the Opp selection (new Opp to be created)
                                        $('label[for*="sf_opps"] select').prop('disabled', true).select2();
                                        $('label[for*="sf_opps"] span.select2').addClass('disabled');

                                        $("#sf_contacts").val($("#sf_contacts option:first").val());
                                        window.qb.toggleRecurrentFormElements(false);
                                    }
                                }
                            }
                        });
                        */




                    }
                },
                cancel: {
                    text: "CHANGE",
                    // keys: ['esc'],
                    action: function(){
                        // check for opp close date
                        //window.qb.checkOpportunityCloseDateAndShowAnUpdateWindowIfNeeded();
                        window.qb.is_new = false;
                        window.qb.is_change = true;
                        window.qb.is_upsell = true;
                        window.qb.is_new_upsell = false;
                        window.qb.is_implementation_only = false;
                        window.qb.displayContracts(data, true, forceResetTerm);
                        window.qb.checkOpportunityCloseDateAndShowAnUpdateWindowIfNeeded();

                        $('label[for*="sf_opps"] select').prop('disabled', null).select2();
                        $('label[for*="sf_opps"] span.select2').removeClass('disabled');

                    }
                }
            }
        });

        // close all other popups -- can be VERY problematic if we are error-detecting/preventing flows with another popup
        /*
        if(window.qb.popup_missing_error != null) {
            window.qb.popup_missing_error.close();
        }*/
        window.qb.popup_upsell.open();
    },

    isExpiredOpportunityCloseDate: function (opportunity){
        if (!opportunity) {
            return false;
        } else {
            return (opportunity.CloseDate == null || new Date(opportunity.CloseDate).getTime() <= new Date().getTime());
        }
    },

    /**
     * Make sure the selected Re-Used/Modify Opp has a future Close Date
     * @return boolean
     */
    checkOpportunityCloseDateAndShowAnUpdateWindowIfNeeded: function(){

        let showBox = false;

        // console.log('in checkOpportunityCloseDateAndShowAnUpdateWindowIfNeeded()');
        if(window.qb.setOpportunity) {

            // console.log('Opp close Date: ' + window.qb.setOpportunity.CloseDate);

            // pre-populate the chat bubble inline update field with the latest data
            if (document.getElementById('update_opp_close_date')) {
                $('#update_opp_close_date').val(window.qb.setOpportunity.CloseDate).trigger('change');
                // console.log('#update_opp_close_date set to ' + window.qb.setOpportunity.CloseDate);
            }

            if (  (window.qb.has_valid_contract && window.qb.is_change) // is_change, meaning "CHANGE" existing Opportunity was selected
                || !window.qb.has_valid_contract
            ) {

                if (window.qb.isExpiredOpportunityCloseDate(window.qb.setOpportunity)) {
                    $('table.table.update_opp_close_date').html('');
                    $('#update_opp_close_date').val(window.qb.setOpportunity.CloseDate).trigger('change');
                    $('#missing_data').show();
                    $('#missing_data_close_date').show()
                    showBox = true;
                }
            }
        } else {
            console.log('window.qb.setOpportunity is null, skipping Opp close Date checks');
        }

        if (window.qb.data_account.VAT__c == null && window.qb.data_account.Self_Registration__c == null && window.qb.data_account.Legal_Entity__c == 'BV Netherlands') {
            //window.qb.missing_error('Missing Information', 'The selected account is missing the VAT and/or self registration number.<br><br><a class="btn btn-lg btn-primary" href="'+window.qb.data_account.web_link+'">Open in SalesForce.</a></strong>', {close: {text: 'Close'}});
            $('#missing_data').show();
            $('#missing_data_vat_reg').show()
            showBox = true;
        }

        if(!showBox) {
            $('#update_opp_close_date').val('').trigger('change');
            $('table.table.update_opp_close_date').html('');
            $('#missing_data_close_date').val('');
            $('#missing_data_close_date').hide()
            $('#missing_data_vat_reg').val('');
            $('#missing_data_vat_reg').hide()
            $('#missing_data').hide();
        }

        return showBox;


    },

    /**
     * Hide all recurrent billing elements / only show the one-time elements
     */
    toggleRecurrentFormElements: function(show=true) {
        if(show) {
            $('.hidden_when_implementation_only').show();
            $('select[name="payment_terms"]').val('Due on receipt').prop('disabled', false).trigger('change');
            $('label[for*="payment_terms"] span.select2').removeClass('disabled');
        } else {
            // hide recurrent elements
            $('.hidden_when_implementation_only').hide();
            $('select[name="payment_terms"]').val('Due on receipt').prop('disabled', true).trigger('change');
            $('label[for*="payment_terms"] span.select2').addClass('disabled');
        }
    },
    /**
     * zero-out non one-time services (prevent from getting submitted)
     * used for "Implementation only" products
     */
    resetRecurrentServices: function(skip_keys=[]) {
        // reset recurrent/non one-time services billing elements to zeros
        $.each(window.qb.monthly_total, function(k, v) {
            if(!skip_keys.includes(k)) {
                console.log('in resetRecurrentServices() ' + k + ' with skip key count: '+ skip_keys.length);
                window.qb.monthly_total[k] = 0;
            } else {
                console.log('in resetRecurrentServices() SKIPPED: ' + k + ' with skip key count: '+ skip_keys.length);
            }
        });
        $.each(window.qb.fixed_total, function(k, v) {
            if(!skip_keys.includes(k)) {
                window.qb.fixed_total[k] = 0;
            }
        });
        window.qb.selections.ai_automation.value = [];
        window.qb.selections.number_users.value = "0";
        window.qb.selections.product_package.value = "";
    },

    /**
     * zero-out non one-time services (prevent from getting submitted)
     * in provided array
     * @param dataSubmitting array
     * @param skip_keys array
     * @param reset_keys array
     * @return array
     */
    resetRecurrentServicesInProvidedData: function(dataSubmitting, skip_keys=[], reset_keys=[]) {
        // reset recurrent/non one-time services billing elements to zeros
        $.each(dataSubmitting.quote, function(category, values) { // category: monthly, onetime, fixed
            $.each(values, function (k, v) {

                if (skip_keys.length>0 && !skip_keys.includes(k)) {
                   //console.log('in resetRecurrentServicesInProvidedData()::skip_keys for ' +category+ ' : '+ k + ' set to 0 with skip_keys count: ' + skip_keys.length);
                    dataSubmitting.quote[category][k] = 0;
                } else {
                   //console.log('in resetRecurrentServices()::skip_keys SKIPPED  for ' +category+ ' :' + k + ' with skip_keys count: ' + skip_keys.length);
                }

                if (reset_keys.length>0 && reset_keys.includes(k)) {
                    //console.log('in resetRecurrentServicesInProvidedData()::reset_keys for ' +category+ ' : '+ k + ' set to 0  with reset_keys count: ' + reset_keys.length);
                    dataSubmitting.quote[category][k] = 0;
                } else {
                    //console.log('in resetRecurrentServices()::reset_keys SKIPPED for ' +category+ ' :' + k + ' with reset_keys count: ' + reset_keys.length);
                }

            });
        });

        // reset or skip the selections used to build the quote
        $.each(dataSubmitting.selections, function (k, v) {

            if (skip_keys.length>0 && !skip_keys.includes(k)) {
                // console.log('in resetRecurrentServicesInProvidedData() selections key: : '+ k + ' not in "skip_keys", set to 0 or [] with skip_keys count: ' + skip_keys.length);
                dataSubmitting.selections[k] = dataSubmitting.selections[k].value instanceof Array ? {value: []} : {value: "0"};
            } else {
                //console.log('in resetRecurrentServices() skip_keys SKIPPED:  for selections :' + k + ' with skip_keys count: ' + skip_keys.length);
            }

            if (reset_keys.length>0 && reset_keys.includes(k)) {
                // console.log('in resetRecurrentServicesInProvidedData() reset_keys for selections : '+ k + ' set to 0  with reset_keys count: ' + reset_keys.length);
                dataSubmitting.selections[k] = dataSubmitting.selections[k].value instanceof Array ? {value: []} : {value: "0"};
            } else {
                //console.log('in resetRecurrentServices() reset_keys SKIPPED:  for selections :' + k + ' with reset_keys count: ' + reset_keys.length);
            }

        });

        /*
        if(window.qb.pricing_model != 'velocity'){
            // blank out selections
            $('div[name="velocity_packages"] input:checked').prop('checked', false);
            window.qb.callbacks.velocity_packages();
        }
        */

        /*
        window.qb.selections.ai_automation.value = [];
        window.qb.selections.number_users.value = "0";
        window.qb.selections.product_package.value = "";
        */
        return dataSubmitting;
    },



    /**
     * Display a list of contracts
     *
     * @param data object (expecting data.contracts if contracts are available)
     * @param opportunitySelectorEnabled boolean (enabled for existing upsells, disabled for new upsells)
     * @param forceResetTerm
     * @return boolean
     */
    displayContracts: function(data, opportunitySelectorEnabled=true, forceResetTerm=false) {

        if(window.qb.is_new){
            window.qb.setQuoteTitle(window.qb.getQuoteTitle() + ' (NEW)');
            //$('.sidebar-title').html($('.sidebar-title').html() + '<small style="font-size: 12px;"> (NEW)</small>');
        }else if(window.qb.is_change){
            window.qb.setQuoteTitle(window.qb.getQuoteTitle() + ' (CHANGE)');
            //$('.sidebar-title').html($('.sidebar-title').html() + '<small style="font-size: 12px;"> (CHANGE)</small>');
        }

        if (typeof data.contracts === "undefined" || (typeof data.contracts) !== 'object') {
            window.qb.is_upsell = false;
            window.qb.is_new_renewal = false;
            window.qb.is_renewal = false;
            window.qb.is_flat_renewal = false;
            return false;
        } else if(data.contracts.length == 0){
            window.qb.is_upsell = false;
            window.qb.is_new_renewal = false;
            window.qb.is_renewal = false;
            window.qb.is_flat_renewal = false;
            return false;
        }

        window.qb.data_contracts = data.contracts;

        let contracts = data.contracts;

        $('table.table.subs').html('');

        contracts.forEach(function(contract) {
            if (contract.subscriptions.length == 0) return;
            var contractTr = $('<tr>');
            contractTr.append('<th><label><input type="radio" name="ammendContract" onclick="window.qb.validateSelectedContract(value)" class="info-field" value="' + contract.Id + '"> Contract ' + contract.ContractNumber
                + ' <span style="font-weight: normal"> ('
                + window.qb.formatDate(contract.StartDate)
                +' - '+ (contract.EndDate ? window.qb.formatDate(contract.EndDate) : 'Evergreen')
                +')</span></label></th><td colspan="2"></td>'
            );
            $('table.table.subs').append(contractTr);
            contract.subscriptions.forEach(function(item) {

                // if (new Date(item.SBQQ__EndDate__c).getTime() > new Date().getTime()){

                var tr = $('<tr>');
                $('<td>&nbsp;&nbsp;&nbsp;' + item.ProductName + '</td>').appendTo(tr);
                $('<td>' + item.SBQQ__Quantity__c + '</td>').css('text-align', 'right').appendTo(tr);
                $('<td>' + (contract.symbol ? contract.symbol : '') + item.RMR_Quote_Line__c + '</td>').css('text-align', 'right').appendTo(tr);
                $('table.table.subs').append(tr);
            });
            window.qb.data_contract_selected_id = contract.Id; // sets the contract id to the last items on the list
            window.qb.data_contract_selected = contract;
        });

        $('input[name="ammendContract"]:first-child').prop('checked', true);  // checks off the last items on the list
        //window.qb.data_contract_selected_id = $('input[name="ammendContract"]').val();

        console.log('window.qb.data_contract_selected_id set to ' +  window.qb.data_contract_selected_id );

        window.qb.validateSelectedContract(window.qb.data_contract_selected_id);

        if(window.qb.is_renewal) {
            window.qb.updateEffectiveDateIfNeededForRenewals();
        } else {
            $('#upon_signature').prop('checked', true);
            $('#upon_signature').removeClass('disabled').prop('disabled', false); // leaves it enabled
            $('#effective_date').val(window.qb.formatDate(new Date(new Date()))); // set it to today's date
            $('#effective_date').prop('disabled', true);    // disable the effective date field by default
        }


        if(!opportunitySelectorEnabled) {
            $('label[for*="sf_opps"] select').prop('disabled', true).select2();
            $('label[for*="sf_opps"] span.select2').addClass('disabled');
        }
        $('label[for*="sf_contacts"] select').select2().prop('required', null);

        // the following disables the selection / forces the first choice:
        // $('label[for*="sf_contacts"] select').prop('disabled', true).select2().prop('required', null).removeClass('required');
        // $('label[for*="sf_contacts"] span.select2').addClass('disabled');

        $('.existing-customer').slideDown();

        if(forceResetTerm){
            $('#reset_term').prop('checked', true);
            $('#reset_term').prop('disabled', true);
            $('#reset_term').change();

        } else {
            // reset contract checkbox should be unchecked by default
            $('#reset_term').prop('checked', false)
            if(window.qb.is_upsell_co_term) {
                $('#reset_term').prop('disabled', true);
            } else {
                $('#reset_term').prop('disabled', false);
            }
            $('#reset_term').change();
        }


        return true;
    },

    /**
     *
     * @param date
     * @returns {string} in YYYY-mm-dd format
     */
    formatDate: function(date) {
        let d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2) {
            month = '0' + month;
        }
        if (day.length < 2) {
            day = '0' + day;
        }
        return [year, month, day].join('-');
    },

    validateSelectedContract: function(selectedId) {
        return true;
        if(!selectedId || !window.qb.data_account.contracts){
            return false;
        }

        window.qb.data_contract_selected_id = selectedId;
        console.log('contract selected: '+selectedId);

        // note: Array#some returns true as soon as any of the callbacks return true -- exactly what we need here :)
        window.qb.data_account.contracts.some(function(contract) {
            if (contract.Id == window.qb.data_contract_selected_id) {

                console.log('in validateSelectedContract(), checking contract: ' + contract.Id);

                window.qb.data_contract_selected = contract;

                if (window.qb.data_contract_selected.SBQQ__Evergreen__c != true) {

                    // check that ContractTerm is > 0
                    if (!contract.ContractTerm || contract.ContractTerm == 0 || contract.ContractTerm == "") {
                        let msg = 'Invalid Contract Term: ' + contract.ContractTerm + '. ';
                        window.qb.missing_error('Contract Information Error',
                            msg + window.qb.get_contact_field_ops_text(),
                            window.qb.get_contact_field_ops_popup_button(msg)
                        );
                        console.log('contract item', contract);
                        return true;
                    }

                    contract.subscriptions.some(function (item) {
                        /*

                        For upsells/renewals, when looking up previous subscriptions, we no longer validate or use
                        "Original Quote Line" data for  Quantity, End Date, Discount
                        -- we now look up this information from the Contract Subscriptions directly.
                        Sales reps should no longer get the popup for missing "Original Quote Line"
                        when selecting contracts.

                        if (item.SBQQ__OriginalQuoteLine__c == null) {
                            console.log('item.SBQQ__OriginalQuoteLine__c == null');

                            window.qb.missing_error('Missing Information',
                                'Missing "Original Quote Line" for some of the products on the Contract. '
                                + window.qb.get_contact_field_ops_text(),
                                window.qb.get_contact_field_ops_popup_button('Missing "Original Quote Line" for some of the products on the Contract')
                            );
                            return true;
                        }*/

                        // check for subscription term and valid start/end date e.g. (start can't be before end date)
                        if (new Date(item.SBQQ__EndDate__c).getTime() > new Date(item.SBQQ__StartDate__c).getTime()) {
                            window.qb.missing_error('Contract Information Error',
                                'Contract Subscription Line issue with Start Date before End Date. '
                                + window.qb.get_contact_field_ops_text(),
                                window.qb.get_contact_field_ops_popup_button('Contract Subscription Line issue with Start Date before End Date')
                            );
                            return true;
                        }
                    });
                }
            }
        });
        return true;
    },

    updateEffectiveDateIfNeededForRenewals: function (){

        window.qb.data_contracts.forEach(function (contract) {

            if (window.qb.data_contract_selected_id == contract.Id) {

                $('#upon_signature').prop('checked', false);
                $('#upon_signature').addClass('disabled').prop('disabled', true);

                // next day
                $('#effective_date').val(window.qb.formatDate(new Date(new Date(contract.SBQQ__ExpirationDate__c).getTime() + 86400000)));
                $('#effective_date').addClass('disabled').prop('disabled', true);

            } else {
                $('#upon_signature').prop('checked', true);
                $('#upon_signature').removeClass('disabled').prop('disabled', false);
            }
        });

    },
    hideCustomerInfo: function() {
        window.qb.is_upsell = false;
        window.qb.is_new_upsell = false;
        window.qb.is_renewal = false;
        window.qb.is_new_renewal = false;
        window.qb.is_flat_renewal = false;
        $('label[for*="sf_opps"] select').prop('disabled', null).select2();
        $('label[for*="sf_contacts"] select').prop('required', null).addClass('required');
        $('label[for*="sf_opps"] span.select2').removeClass('disabled');
        $('.existing-customer').slideUp();
    },
    logPricingRequest: function (){
        if(typeof gtag !== "undefined") {
            gtag('event', 'get-pricing', { // action
                'event_category': 'quote',
                'event_label': 'processed',
                'value': 1
            });
            console.log('Google Analytics: logged successful pricing request');
        } else {
            console.log('Google Analytics: unable to fire off logPricingRequest -- gtag is not defined');
        }
    },
    logQuote: function (success, event_category, event_label){
        if(typeof gtag !== "undefined") {
            gtag('event', (success ? 'quote_success' : 'quote_error'), {
                'event_category': event_category,
                'event_label': event_label,
                'value': 1
            });
            console.log('Google Analytics: logged ' + (!success ? 'un' : '') + 'successful quote generation');
        } else {
            console.log('Google Analytics: unable to fire off logQuote -- gtag is not defined');
        }
    },
    logUser: function (){
        // TODO implement
        gtag('event', 'user' , {
            'event_category': window.qb.dataSubmitting.upsell ? 'upsell' : 'regular',
            'event_label': (window.qb.dataSubmitting.upsell && window.qb.dataSubmitting.is_new_upsell) ? 'new upsell' : 'existing upsell',
            'value': 1
        });
    },

    opp_update_field: function (elementByIdName, field, value){
        console.log('setting ' + field + ' to ' + value);
        $('#'+elementByIdName).fadeOut();
        let data = {};
        data[field] = value;



        // for stage_name, we also have two other potential updates (prerequisites)
        if(field == 'stage_name') {
            let tmp = {};
            if ($('#bubble_update_opp_competitor_name').val()) {
                tmp[field] = value;
                tmp['competitor_name'] = $('#bubble_update_opp_competitor_name').val();
                if (tmp['competitor_name'] == 'Other') {
                    tmp['competitor_name_other'] = $('#bubble_update_opp_competitor_name_other').val();
                }
            }
            if ($('#bubble_update_opp_rep_commit_value').val()) {
                tmp['Rep_Commit__c'] = $('#bubble_update_opp_rep_commit_value').val();
            }

            window.qb.request('post', '/agent/order/update/' + window.qb.order_id + '/opportunity', true, false, tmp, function (tmp) {
                console.log('/api/order/update/' + window.qb.order_id + '/opportunity returned', tmp);
            });
        }

        window.qb.request('post', '/agent/order/update/' + window.qb.order_id + '/opportunity', true, false, data, function (data) {
            console.log('/api/order/update/' + window.qb.order_id + '/opportunity returned', data);
        });


        setTimeout(function() {
            if($('#chatbubble1').is(":hidden") && $('#chatbubble2').is(":hidden") && $('#chatbubble3').is(":hidden")) {
                $('.opp-update-message').fadeOut();
            }
        }, 3000);



    }


}
/*
$(function() {
    window.qb.api_token = window.apiToken;
    window.qb.all_fields('general,,basic', function() {
        window.qb.process_defaults();
    });
    //window.qb.update_presets(['industries','bundles']);
    $('body').on('click', '.disabled', function(e) {
        e.preventDefault();
    });

    window.qb.accounts = window.accounts_json;


    $('#panel_account').hide();
    $('.clearable_panel').hide();
    $('#panel_billing').hide();


});
*/

// NOTE:  this FILE is used for BOTH QB and CC Payment Flow

$(document).ready(function()
{
    $('[data-toggle="tooltip"]').tooltip();

});

