import React, {useEffect, useState} from 'react';
import './MissingDataPanel.css';
import { format as formatDate, addWeeks } from 'date-fns'
import {LEGAL_ENTITY_BV_NETHERLANDS, error_modal, DEFAULT_INLINE_OPPORTUNITY_UPDATES, info_modal} from "./QBApp";
import {DATE_FORMAT} from "./BillingPanel";
import Select from "react-select";

export default function MissingDataPanel(props) {

    const changeHandleAndValidate = (value, key) => {
        console.log('MissingDataPanel.js: updated '+key+' to ' + value)
        let newState = {...props.inlineOpportunityUpdates, [key] : value};
        // console.log('MissingDataPanel.js: updated props.inlineOpportunityUpdates was ', props.inlineOpportunityUpdates)
        // console.log('MissingDataPanel.js: updated new value added is ', {[key] : value})
        // console.log('MissingDataPanel.js: updated __newState__ to ', newState)
        props.setInlineOpportunityUpdates(newState, validateMissingData(newState)) // state callback
    }

    /**
     * @returns {number} error count
     */
    const validateMissingData = (newState) => {

        console.log('MissingDataPanel.js: validateMissingData', newState)

        let errors= {};
        if(isMissingVatAndRegistrationNumber(newState)){
            errors['vat_reg'] = info_modal(
                'Missing Information',
                'Please provide a <strong>VAT or a Self Registration number</strong> for selected Account',
                null, {}, null, false
            )
        } else {
            delete errors['vat_reg']
        }


        if(isMissingConversationalCommerceGrid(newState)){
            errors['conversational_commerce_grid'] = info_modal(
                'Missing Information',
                'Please select a <strong>Conversational Commerce Grid</strong> value',
                null, {}, null, false
            )
        } else {
            delete errors['conversational_commerce_grid']
        }


        /*
        if(isMissingLeadSource(newState)){
            errors['lead_source'] = info_modal(
                'Missing Information',
                'Please provide a <strong>Lead Source</strong> for selected Opportunity',
                null, {}, null, false
            )
        } else {
            delete errors['lead_source']
        }*/

        if (props.selectedOpportunity.Id && isExpiredCloseDate(newState.update_opp_close_date)){

            console.log('CLOSE DATE: ', newState.update_opp_close_date)

            errors['close_date'] = info_modal(
                'Missing Information',
                'Please set <strong>Close Date</strong> for Opportunity to a future date',
                null, {}, null, false
            )

            // console.log('MissingDataPanel.js: props.inlineOpportunityUpdates.update_opp_close_date is EXPIRED!!!!!!!!!!',newState.update_opp_close_date)
        } else {
            delete errors['close_date']
            // console.log('MissingDataPanel.js: props.inlineOpportunityUpdates.update_opp_close_date is NOT expired!!!!!!!!!!', newState.update_opp_close_date)
        }
        props.setMissingDataPanelErrors(errors) // overwrite prev. state since we validate on all changes/updates
        return Object.entries(errors).length;
    }


    const isMissingConversationalCommerceGrid = (newState) => {

        let state = newState ?? props.inlineOpportunityUpdates;

        if(Object.entries(props.selectedOpportunity).length === 0 || !('Id' in props.selectedOpportunity)) {
            // no opp / NEW opportunity to be created
            if(state.update_opp_conversational_commerce_grid.length === 0) {
                // no value
                return true
            }
        } else if(props.selectedOpportunity.Conversational_Commerce_Grid__c !== null) {
            return false
        }


        return !state.update_opp_conversational_commerce_grid || state.update_opp_conversational_commerce_grid.length === 0
    }

    /*
    const isMissingLeadSource = (newState=null) => {
        if(props.selectedOpportunity.LeadSource !== null) {
            return false
        }

        let state = newState ?? props.inlineOpportunityUpdates;
        return !state.update_opp_lead_source || state.update_opp_lead_source.length === 0
    }*/

    const isExpiredCloseDate = (closeDate) => {
        return new Date(closeDate).getTime() <= new Date().getTime()
    }

    const isMissingVatAndRegistrationNumber = (newState=null) => {
        let state = newState ?? props.inlineOpportunityUpdates;

        if(
              Object.entries(props.selectedAccount).length > 0
            // BV Account without VAT and Reg # on file
           && props.selectedAccount.Legal_Entity__c === LEGAL_ENTITY_BV_NETHERLANDS
           && (props.selectedAccount.VAT__c === null || props.selectedAccount.VAT__c.length === 0)
           && (props.selectedAccount.Registration_Tax_ID__c === null || props.selectedAccount.Registration_Tax_ID__c.length === 0)
            // VAT and Reg # haven't been provided in QB
           && state.update_account_vat.length === 0
           && state.update_account_reg.length === 0
        ){
            // console.log('MissingDataPanel.js:useEffect() isMissingVatAndRegistrationNumber() returned TRUE!!!')
            return true;
        }
        return false;
    }

    const getLeadSources = () => {

        let data = [];
        if(props.pricing && Object.entries(props.pricing).length>0 && props.pricing.lead_sources) {

            console.log('DEBUG:::: in getLeadSources(), pricing: ', props.pricing)

            for (let [key, value] of Object.entries(props.pricing.lead_sources.options)) {
                let item = {};
                item.value = value;
                item.label = key;
                data.push(item);
            }
        }
        return data
    }
    const getConversationalCommerceGridValues = () => {
        let data = []
        if(props.pricing && Object.entries(props.pricing).length>0 && props.pricing.conversational_commerce_grid_values) {
            console.log('DEBUG:::: in getConversationalCommerceGridValues(), pricing: ', props.pricing)
            for (let [key, value] of Object.entries(props.pricing.conversational_commerce_grid_values.options)) {
                let item = {}
                item.value = value
                item.label = key
                data.push(item)
            }
        }
        return data
    }

    useEffect(() => {

        props.setInlineOpportunityUpdates(DEFAULT_INLINE_OPPORTUNITY_UPDATES);

        /*
        console.log(`MissingDataPanel.js:useEffect() props.selectedOpportunity.CloseDate: `
            +props.selectedOpportunity.CloseDate);
        console.log(`MissingDataPanel.js:useEffect() Object.entries(props.selectedAccount).length: `
            +Object.entries(props.selectedAccount).length);
        console.log(`MissingDataPanel.js:useEffect() props.selectedAccount: `,
            props.selectedAccount);
        */

        // initialize default missing field data
        let defaultValues = {}

        if(Object.entries(props.selectedAccount).length > 0) {
            defaultValues.update_account_vat = props.selectedAccount.VAT__c ?? '';
            defaultValues.update_account_reg = props.selectedAccount.Registration_Tax_ID__c ?? '';
        }

        /*
        if(Object.entries(props.selectedOpportunity).length>0 && props.selectedOpportunity.Id) {
            // ignore placeholders by checking for Id

            // populate the current date or formatted existing date
            console.log('MissingDataPanel.js:useEffect() props.selectedOpportunity', props.selectedOpportunity)

            if("CloseDate" in props.selectedOpportunity) {
                defaultValues.update_opp_close_date = formatDate(
                    new Date(props.selectedOpportunity.CloseDate)
                    , DATE_FORMAT
                );
            }

        }*/

        if(Object.entries(defaultValues).length > 0) {

            console.log('MissingDataPanel.js:useEffect()  ...defaultValues: ', defaultValues)
            let newState = {
                ...props.inlineOpportunityUpdates
                , ...defaultValues
            };
            console.log('MissingDataPanel.js:useEffect() newState: ', newState)
            props.setInlineOpportunityUpdates(newState, validateMissingData(newState)) // state callback)
        }

        /*
        let errorCount = validateMissingData()
        console.log('MissingDataPanel.js:useEffect() validateMissingData() returned ' + errorCount)
        */
    }, [props.selectedOpportunity, props.selectedAccount]);

    return (
        props.selectedAccount.Id &&
       <>
           <div id="missing_data">
               <div className="card alert-warning border mb-3">

                   {"CloseDate" in props.selectedOpportunity && isExpiredCloseDate(props.selectedOpportunity.CloseDate)
                   &&
                       <div id="missing_data_close_date" className="card-body">
                           <div className="floatLeft">
                               <input type="date"
                                      className="form-control info-field"
                                      style={{width: 180}}
                                      id="update_opp_close_date"
                                      value={props.inlineOpportunityUpdates.update_opp_close_date !== ''
                                          ? props.inlineOpportunityUpdates.update_opp_close_date
                                          : formatDate(
                                              new Date(props.selectedOpportunity.CloseDate)
                                              , DATE_FORMAT
                                            )
                                      }
                                      onChange={() => changeHandleAndValidate(event.target.value, 'update_opp_close_date')}
                               />
                           </div>
                           <div style={{float: "left", width: 490, paddingLeft: 20}}>
                                   <span id="missing_data_message">Please set the "Close Date" to a future date for this
                                       Opportunity so that QB can update SFDC before you can proceed <span className="required">*</span>
                                   </span>
                           </div>
                       </div>
                   }

                   {isMissingVatAndRegistrationNumber({
                       update_account_vat : props.selectedAccount.VAT__c ?? '',
                       update_account_reg : props.selectedAccount.Registration_Tax_ID__c ?? ''
                   }) &&
                       <div id="missing_data_vat_reg" className="card-body floatLeft">
                           <div className="floatLeft">
                               <input type="input" className="form-control info-field" style={{width: 180}}
                                      id="update_account_vat" placeholder="VAT #"
                                      value={props.inlineOpportunityUpdates.update_account_vat}
                                      onChange={() => changeHandleAndValidate(event.target.value, 'update_account_vat')}
                               />
                               <input type="input" className="form-control info-field" style={{width: 180}}
                                      id="update_account_reg" placeholder="Self Registration #"
                                      value={props.inlineOpportunityUpdates.update_account_reg}
                                      onChange={() => changeHandleAndValidate(event.target.value, 'update_account_reg')}
                               />
                           </div>
                           <div className="floatLeft" style={{width: 490, padding: "20px 0 0 20px"}}>
                               <span id="missing_data_message">Please provide a VAT or a Registration Number for this
                                   Account so that QB can update SFDC before you can proceed
                                   <span className="required">*</span></span>
                           </div>
                       </div>
                   }

                   { isMissingConversationalCommerceGrid({update_opp_conversational_commerce_grid: props.selectedOpportunity?.Conversational_Commerce_Grid__c ?? ''}) &&
                       <div id="missing_data_lead_source_box" className="card-body floatLeft">
                           <div className="floatLeft">
                               <Select
                                   // isClearable
                                   placeholder={"Conversational Commerce Grid..."}
                                   id={"missing_data_lead_source"}
                                   value={{label: props.inlineOpportunityUpdates.update_opp_conversational_commerce_grid, value: props.inlineOpportunityUpdates.update_opp_conversational_commerce_grid}}
                                   onChange={(event) => changeHandleAndValidate(event.value, 'update_opp_conversational_commerce_grid')}
                                   options={getConversationalCommerceGridValues()}
                               />
                           </div>
                           <div style={{float: "left", width: 490, paddingLeft: 20, marginTop: -5}}>
                               <span id="missing_data_message">Please select a Conversational Commerce Grid value.<br />
                                   <i>If your opportunity relates to more than one value, please select the most relevant option.</i>
                                   <span className="required">*</span>
                               </span>
                           </div>


                       </div>
                   }

                   { /*isMissingLeadSource({update_opp_lead_source: props.selectedOpportunity?.LeadSource ?? ''}) &&
                       <div id="missing_data_lead_source_box" className="card-body floatLeft">
                           <div className="floatLeft">
                               <Select
                                   // isClearable
                                   placeholder={"Lead Source..."}
                                   id={"missing_data_lead_source"}
                                   value={{label: props.inlineOpportunityUpdates.update_opp_lead_source, value: props.inlineOpportunityUpdates.update_opp_lead_source}}
                                   onChange={(event) => changeHandleAndValidate(event.value, 'update_opp_lead_source')}
                                   options={getLeadSources()}
                               />
                           </div>
                           <div style={{float: "left", width: 490, paddingLeft: 20, marginTop: -5}}>
                               <span id="missing_data_message">Please provide a Lead Source for this
                                   Opportunity so that QB can update SFDC before you can proceed
                                   <span className="required">*</span>
                               </span>
                           </div>


                       </div>
                   */ }
               </div>
           </div>
       </>

    )
}


