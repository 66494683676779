import React, {useEffect, useState} from 'react';
import './ServicesPanel.css';
import Select from "react-select";
import {arraySum, formatPrice, INDUSTRY_AUTOMOTIVE, monthlyServicesInitialState, roundUp} from "./QBApp";
import {
    TOTALS_COMPONENT_KEY_CPI_EXPERT_ONETIME,
    TOTALS_COMPONENT_KEY_CPI_EXPERT_ONETIME_CONFIGURATION, TOTALS_COMPONENT_KEY_CPI_EXPERT_ONETIME_DEPLOYMENT,
    TOTALS_COMPONENT_KEY_CPI_EXPERT_ONETIME_TRAINING
} from "./cpi/CpiServicesPanel";

export default function ServicesPanel(props) {

    const [carets, setCarets] = useState({guided_sms: false, whatsapp : false});

    const [cpiOnetimeOptions, setCpiOnetimeOptions] = useState({
        cpi_deployment: {},
        cpi_training: {},
        cpi_configuration: {},
        cpi_onetime_messaging_channel: {}
    })

    const TOTALS_COMPONENT_KEY_MONTHLY = 'expert_services';
    const TOTALS_COMPONENT_KEY_ONETIME = 'onetime';

    // props.onetimeSelected
    const [servicesMonthlyTotal, setServicesMonthlyTotal] = useState(0);
    const [onetimeCheckedItems, setOnetimeCheckedItems] = useState({});
    const nbsp = '\u00A0'; // unicode no-breaking space

    /**
     *
     * @param industry
     * @param isPartner
     * @returns {[]}
     *      customer_success    default
     *      partner_success     (account type = partner, shown instead of customer_success ?)
     *      outsourced_labor    default (Tier 1-4)
     *      on_demand_advisor   (LPA, shown instead of outsourced_labor)
     *      ps_subscription     (available under everything)
     */
    const getServiceTypes = (industry='', isPartner = false) => {

        let items = []

        if(isPartner){
            items.push('partner_success')
        } else {
            items.push('customer_success')
        }

        if(industry === INDUSTRY_AUTOMOTIVE){
            items.push('on_demand_advisor')
        } else {
            items.push('outsourced_labor')
        }

        items.push('ps_subscription'); // partner and industry-independent

        console.log('ServicePanel.js:: getting serviceTypes for industry : ' + industry, items)

        return items
    }

    const toggleCaret = (e, key, oldVal) => {
        e.preventDefault();
        let newCarets = []
        newCarets[key] = oldVal === false ? true : false
        // console.log(' carets[   '+ key + '] : ' +  newCarets[key])
        setCarets({...carets, ...newCarets})

    }
    const caretController = (key) => (
        <a onClick={(e) => toggleCaret(e, key, carets[key])} href="#">
            <span style={{marginLeft:6,color:"#333333"}}>
                <i className={'fas fa-caret-' + (carets[key] ? 'down': 'right') + ' fa-lg'}></i>
            </span>
        </a>
    )

    const handleMonthlyServicesChange = (item_type, item, inputType='select', inputQty=0) => {

        console.log("in ServicesPanel::handleMonthlyServicesChange item_type, item : ", item_type, item);
        console.log("in ServicesPanel::handleMonthlyServicesChange props.pricing[item_type].options :", props.pricing[item_type].options);



        let removedProduct = false
        // update qty for numeric inputs
        if(inputType === 'number'){

            let selectedQuantities = {}
            if(inputQty == '' || inputQty === 0 || isNaN(inputQty)) {
                selectedQuantities = {...props.productSelectedQuantity}
                delete selectedQuantities[item.productCode]
                removedProduct = true
                props.setProductSelectedQuantity(selectedQuantities)
                console.log('selectedQuantities set to : ', selectedQuantities)
            } else {
                selectedQuantities = {...props.productSelectedQuantity, [item.productCode]: inputQty}
                console.log('selectedQuantities set to :: ', selectedQuantities)
                props.setProductSelectedQuantity(selectedQuantities)
            }
        }

        let price = getMonthlyServicePrice(item.price, item_type);

        let newVal = {...props.servicesMonthlyCostByType, [item_type] : (inputType === 'number' ? inputQty * price : price) };
        // console.log("in ServicesPanel:: props.servicesMonthlyCostByType: updated to ", newVal);
        props.setServicesMonthlyCostByType(newVal);
        // console.log("in ServicesPanel::setServicesMonthlyTotal: updated to ", arraySum(Object.values(props.servicesMonthlyCostByType)));
        setServicesMonthlyTotal(arraySum(Object.values(newVal)));


        let newServicesMonthlySelected = {}
        if(removedProduct) {
            // keep as is
            newServicesMonthlySelected = {...props.servicesMonthlySelected}
        } else {
            // hydrate selected item with data from the pricing API
            newServicesMonthlySelected =
                {...props.servicesMonthlySelected, [item_type]:
                        props.pricing[item_type].options[
                            item_type === 'ps_subscription'
                                ? item.productCode // from a number-based input
                                : item.value       // from a drop down
                            ]
                } // item.value e.g. 'tier1' for outsourced_labor
        }

        if(event.title == MONTHLY_SERVICES_PLACEHOLDER
            || (inputType === 'number' && (inputQty == '' || inputQty === 0 || isNaN(inputQty)))
        ) { // remove placeholder & empty items
            delete newServicesMonthlySelected[item_type];
        }
        props.setServicesMonthlySelected(newServicesMonthlySelected);
        console.log("in ServicesPanel::handleMonthlyServicesChange setServicesMonthlySelected: updated to ", newServicesMonthlySelected);

        let totals = {...props.totals};
        totals[TOTALS_COMPONENT_KEY_MONTHLY] = arraySum(Object.values(newVal));
        // console.log('ServicesPanel::handleMonthlyServicesChange() - updating props.setTotals() to ', totals);
        props.setTotals(totals);


    }

    function removeSelectedItems(selectedItems, key, item) {

        console.log('ServicesPanel.js::removeSelectedItems key & item:', key, item)

        // remove the previously set quantity from being tracked whenever we uncheck the product
        let selectedQuantities = {...props.productSelectedQuantity}
        delete selectedQuantities[item.productCode]
        props.setProductSelectedQuantity(selectedQuantities)

        // remove the product from the list of selected products
        delete selectedItems[item.productCode]
        let newVal = {...selectedItems}
        // update the selected quantities
        if(key in selectedQuantities) {
            handleQuantityUpdate(key, 0, item)
        }

        return newVal;
    }

    /**
     * @param isChecked
     * @param key
     * @param item
     * @param uncheckItemType used to force un-checking of all items of this type e.g. when we want to uncheck all items in a radio group
     */
    const handleOnetimeServicesChange = (isChecked, key, item, uncheckItemType='', isNested) => {

        // console.log("ServicesPanel::handleOnetimeServicesChange() onetimeCheckedItems: ", onetimeCheckedItems);

        let selectedItems = {...onetimeCheckedItems}
        let newVal = {}

        // uncheck all items of specified type if specified
        if(uncheckItemType !== '') {

            // invoke removeSelectedItems on each to ensure that recalculations are done
            Object.values(selectedItems).forEach(item => (
                item.type === uncheckItemType && removeSelectedItems(selectedItems, item.productCode, item)
            ))

            // create a filtered list & used that
            selectedItems = Object.entries(selectedItems).reduce((acc, [productCode, isCheckedItem]) => {
                if(props.onetimeSelected[productCode].type !== uncheckItemType) {
                    if(props.onetimeSelected[productCode].sub_type !== uncheckItemType) {
                        acc[productCode] = isCheckedItem;
                    }
                }
                return acc;
            }, {});
        }

        // console.log("ServicesPanel::handleOnetimeServicesChange() selectedItems/checkedItems: ", selectedItems);

        if(isChecked === false){
            newVal = removeSelectedItems(selectedItems, key, item)
        } else {
            newVal = {...selectedItems, [item.productCode]: isChecked};

            if(item.nested_items_type && !isNested) {
                const [[code, value]] = Object.entries(cpiOnetimeOptions[item.nested_items_type]).filter(([code, value]) => value.default_item);
                newVal = {...newVal, [code]: true};

                if(value.default_quantity > 0) {
                    handleQuantityUpdate(code, value.default_quantity, value)
                }

            }

            // update the selected quantities
            if(item.default_quantity > 0) {
                handleQuantityUpdate(key, item.default_quantity, item)
            }
        }

        // console.log("ServicesPanel::handleOnetimeServicesChange() checkedItems: ", newVal);
        setOnetimeCheckedItems(newVal);
        props.setOnetimeSelected(newVal);
        recalculateOnetimeServicesTotals(newVal);
    }

    const debug =() => {
        console.log('ServicesPanel.js props.pricing.cpi_onetime', props.pricing[TOTALS_COMPONENT_KEY_CPI_EXPERT_ONETIME])
        return true;
    }

    /**
     * @param items
     * @return returns total for onetime services
     */
    function recalculateOnetimeServicesTotals(items) {

        console.log('ServicePanel.js::recalculateOnetimeServicesTotals() items: ', items)

        let selected = [];
        let selectedTrainingPrices = [];
        let selectedDeploymentPrices = [];
        let selectedConfigurationPrices = [];
        let prices = Object.entries(props.pricing[TOTALS_COMPONENT_KEY_CPI_EXPERT_ONETIME].options).reduce( (arr, [key, item]) => {
            if (key in items && items[key] === true) {

                console.log('item.default_quantity, item:', item.default_quantity, item)
                arr.push(getOnetimeItemCost(item, key))
                selected = {...selected, [key]: item}
                console.log('ServicePanel.js::recalculateOnetimeServicesTotals() selected: ', selected)


                if(item.type==='cpi_training'){
                    selectedTrainingPrices.push(getOnetimeItemCost(item, key))
                }else if(item.type==='cpi_deployment') {
                    selectedDeploymentPrices.push(getOnetimeItemCost(item, key))
                }else if(item.type==='cpi_configuration') {
                    selectedConfigurationPrices.push(getOnetimeItemCost(item, key))
                }

            }

            props.setOnetimeSelected(selected);

            return arr;
        }, [])


        //////////////
        // props.setCpiExpertServicesOnetimeTotal(arraySum(prices))

        props.setCpiExpertServicesOnetimeTrainingTotal(arraySum(selectedTrainingPrices))
        props.setCpiExpertServicesOnetimeConfigurationTotal(arraySum(selectedConfigurationPrices))
        props.setCpiExpertServicesOnetimeDeploymentTotal(arraySum(selectedDeploymentPrices))
        //////////////



        let totals = {...props.totals};
        totals[TOTALS_COMPONENT_KEY_ONETIME] = arraySum(prices);


        //////////////
        totals[TOTALS_COMPONENT_KEY_CPI_EXPERT_ONETIME_TRAINING] = arraySum(selectedTrainingPrices);
        totals[TOTALS_COMPONENT_KEY_CPI_EXPERT_ONETIME_CONFIGURATION] = arraySum(selectedConfigurationPrices);
        totals[TOTALS_COMPONENT_KEY_CPI_EXPERT_ONETIME_DEPLOYMENT] = arraySum(selectedDeploymentPrices);
        //////////////


        props.setTotals(totals);

        console.log('CpiServicePanel.js::recalculateOnetimeServicesTotals() props.onetimeSelected: ', props.onetimeSelected)
        return arraySum(prices);
    }

    // PS Subscription product pricing has to be converted to monthly
    const getMonthlyServicePrice = (price, item_type) => item_type=== 'ps_subscription' ? price/12 : price

    const monthlyItems =

        ( Object.entries(props.pricing).length > 0
            && Object.entries(props.pricing['customer_success']).length > 0
        )
        ?
        Object.values(
            getServiceTypes(
                (props.selectedIndustry && Object.keys(props.selectedIndustry).length>0)
                ? props.selectedIndustry.value
                : ''
                , props.selectedAccount.isPartner
            )
        ).map( item_type => (
            <label htmlFor={item_type} name={item_type} className="monthlyServiceLabel" key={item_type+"_label"}>

                <div className="floatLeft monthlyServiceLabelText">{props.pricing[item_type].title}</div>

                <div className="floatLeft">

                    {(item_type === 'ps_subscription'

                        && Object.entries(props.pricing.ps_subscription.options).map( ([key, item]) => (
                            // log('item:::: ',item) &&


                            <>
                                <div key={key + '_qty_container'}
                                     style={{textAlign: 'right', minWidth: 157, float: "left"}}
                                    /*
                                        {key in onetimeCheckedItems
                                        ? {textAlign:'right',minWidth:157,float:"left",marginTop:-4}
                                        : {textAlign:'right',minWidth:157,float:"left",marginTop:-4,pointerEvents:"none",opacity:0.3}
                                    }*/
                                >


                                    {'PS Subscription Offering (up to 390 hours/year/offering)'}
                                </div>
                                <div style={{
                                    textAlign: 'right',
                                    minWidth: 157,
                                    width: 260,
                                    float: "right",
                                    marginTop: -4
                                }}>

                                    {nbsp + nbsp + item.unit} : {nbsp}
                                    <input type="number"
                                           className="numericInput"
                                           key={key + '_qty'}
                                           id={key + '_qty'}
                                           value={key in props.productSelectedQuantity ? props.productSelectedQuantity[key] : ''} //item.default_quantity}
                                           min={0}
                                           max={100}
                                           step={1}
                                           style={{
                                               marginRight: 8,
                                               textAlign: "center",
                                               opacity: (props.productSelectedQuantity[key] > 0 ? 1 : .6)
                                           }}
                                           onChange={(event) => handleMonthlyServicesChange(item.type, item, 'number', parseInt(event.target.value))}
                                    />
                                </div>
                            </>
                        ))


                    ) || <Select
                        //placeholder={"Loading..."}
                        id={item_type}
                        className="monthlyServiceSelect"
                        value={item_type in props.servicesMonthlySelected
                            ? {
                                'label': props.servicesMonthlySelected[item_type].title
                                , 'value': props.servicesMonthlySelected[item_type].value
                            }
                            : ''
                        }
                        onChange={(event) => handleMonthlyServicesChange(item_type, props.pricing[item_type].options[event.value])}
                        isSearchable={false}
                        placeholder={MONTHLY_SERVICES_PLACEHOLDER}
                        options={Object.entries(props.pricing[item_type].options).map(
                            function ([index, item]) {
                                item.value = index;
                                item.label = item.title;
                                return item;
                            }
                        )}
                    />
                    }
                </div>
                <span key={item_type+"_price_table_span"} className="price-table floatRight">{formatPrice(props.servicesMonthlyCostByType[item_type], props.currencySymbol,'ServicesPanel.js')}</span>


            </label>
        ))
        : ''
    ;

    /**
    const getScopeContactDetails = (itemName) => {

        if(props.apiData && props.apiData.account) {
            let imagePath = '';
            let new_product_sow_email = '';

            if (props.apiData.account.Legal_Entity__c == 'LP Inc') {
                imagePath = '/images/quote-builder/ken.png';
                new_product_sow_email = 'na-commercial-im-lp@liveperson.com';
            } else { // BV Netherlands and others
                imagePath = '/images/quote-builder/scott.png';
                new_product_sow_email = 'commercialisd-lp@liveperson.com';
            }

            let account_name = encodeURIComponent(props.apiData.account.Name ? props.apiData.account.Name : '(not selected)');
            let product_name = encodeURIComponent(itemName);
            let email_body = 'Hi,%0D%0A%0D%0ACan you please help me with the Package SOW for product ' + product_name + ' related to account ' + account_name + '?%0D%0A%0D%0ACheers!';
            let mailto = 'mailto:' + new_product_sow_email + '?subject=SOW Request for ' + product_name + ' product on ' + account_name + ' Account&body=' + email_body;

            // console.log('ServicesPanel::getScopeContactDetails() props.apiData.account.Legal_Entity__c: ' + props.apiData.account.Legal_Entity__c);

            return [mailto, imagePath];
        } else {
            return '';
        }

    }
    const SMS_PRODUCTS = {
        sms_options_long : props.pricing.sms_options_long ?? '',
        sms_options_short: props.pricing.sms_options_short ?? '',
        sms_options_vanity: props.pricing.sms_options_vanity ??'',
        whatsapp_options_number: props.pricing.whatsapp_options_number ?? ''
    };
    const guidedSmsBlock = (filter) =>
        <div className="sms-options options-container" style={{display:"block",marginLeft:30}}>
            {Object.entries(SMS_PRODUCTS).map(([k, v]) => (
                k.includes(filter) // e.g. sms VS whatsapp
                &&
                    <div key={k+'_qty_container'}>
                        <input type="number"
                               className="numericInput"
                               key={k+'_qty'}
                               id={k}
                               value={props.smsSelectedQuantity[k] || v.default}
                               min="0"
                               max={k.includes('whatsapp') ? null : SMS_MAX_QUANTITY} // WhatApp is allowed qty > 1 but not others (no Zuora support yet)
                               style={{marginRight:8,textAlign:"center"}}
                               onChange={( event ) => handleSmsQuantityUpdate(k, event.target.value)}
                        /> {v.title}
                    </div>


            ))
            }
        </div>;

    const handleSmsQuantityUpdate = (key, quantity) => {

        if(!key.includes('whatsapp') && quantity > 1){
            quantity = SMS_MAX_QUANTITY; // override and lock it to 1 MAX
        }

        let smsSelectedQuantities = {...props.smsSelectedQuantity, [key] : quantity}
        // e.g. {"sms_options_long":1,"sms_options_short":"5","sms_options_vanity":0}
        props.setSmsSelectedQuantity(smsSelectedQuantities)

        let smsTotal = 0;
        for (let [k, quantity] of Object.entries(smsSelectedQuantities)) {
            if(
                k.includes('sms') // e.g. sms only e.g. no whatsapp
                && 'guided_sms' in props.onetimeSelected    // sms checkbox is checked
            ) {
                smsTotal += (quantity * SMS_PRODUCTS[k].price)
            }
        }
        let newTotals = {...props.totals, ['sms'] : smsTotal}
        props.setTotals(newTotals)
    }
    **/
    const handleQuantityUpdate = (key, quantity, item) => {
        console.log('ServicesPanel.js handleQuantityUpdate : key, quantity : ' + key + ', '+ quantity)

        if(quantity > 10000){
            quantity = 10000
        }

        props.setProductSelectedQuantity((prevState) => ({...prevState, [key] : quantity}))

        let total = 0;
        for (let [k, qty] of Object.entries(props.productSelectedQuantity)) {
            total += getOnetimeItemCost(props.pricing[TOTALS_COMPONENT_KEY_CPI_EXPERT_ONETIME].options[k], k)
        }

        console.log('ServicesPanel.js handleQuantityUpdate() props.totals: ', props.totals)
        let updatedTotals = {...props.totals, [TOTALS_COMPONENT_KEY_ONETIME] : total}
        props.setTotals(updatedTotals);
        recalculateOnetimeServicesTotals(onetimeCheckedItems)

    }

    const getOnetimeItemCost = (item, key) => {

        // console.log('ServicesPanel.js getOnetimeItemCost item, key: ', item, key)

        if(!item){
            return 0
        }


        return (item.default_quantity === null)
            ? item.price
            :
            (
                key in props.productSelectedQuantity //&& (item.type === 'cpi_training')
                    ? (item.price * props.productSelectedQuantity[key])
                    : (item.price * ((item.default_quantity > 0) ? item.default_quantity : 1))
            )

    }

    const onetimeItems = (type, inputType='checkbox', elementName=null) =>
        debug() &&
        Object.entries(cpiOnetimeOptions[type]).map( ([key, item]) => (
            <>
                <label htmlFor={key} key={key + '_label'} className="price-table">

                    <div style={{float:"left",width:500,textOverflow:"ellipsis"}}>
                        <input type={inputType}
                               key={key + '_' + inputType}
                               name={elementName??key}
                               id={key}
                               value={key}
                               price={item.price}
                               className={'price-table' + (item.class === 'info_only disabled'? ' disabled' : '')}
                               checked={key in onetimeCheckedItems ? onetimeCheckedItems[key] : false}
                               onChange={
                                   (event) => handleOnetimeServicesChange(
                                       event.target.checked, key, item,
                                       inputType === 'radio' ? 'cpi_deployment' : ''
                                   )

                               }
                               disabled={item.class === 'info_only disabled' ? 'disabled' : ''}
                        />
                        {item.title}
                    </div>

                    {
                        'type' in item
                        && ((item.type == 'cpi_training' && item.default_quantity) || item.type == 'cpi_configuration')
                        && item.default_quantity
                        &&

                        <div key={key + '_qty_container'}
                             style={key in onetimeCheckedItems
                                 ? {textAlign:'right',minWidth:157,float:"left",marginTop:-4}
                                 : {textAlign:'right',minWidth:157,float:"left",marginTop:-4,pointerEvents:"none",opacity:0.3}
                             }
                        >
                            {    (   (item.unit === 'Attendees' || item.unit === 'Sessions')
                                    ? nbsp+nbsp
                                    : ' '
                            ) + item.unit} : {nbsp}
                            <input type="number"
                                   className="numericInput"
                                   key={key + '_qty'}
                                   id={key + '_qty'}
                                   value={key in props.productSelectedQuantity ? props.productSelectedQuantity[key] : item.default_quantity}
                                   min={0}
                                   max={10000}
                                   style={{marginRight: 8, textAlign: "center"}}
                                   onChange={(event) => handleQuantityUpdate(key, parseInt(event.target.value), item)}
                            />
                        </div>
                    }

                    {
                        <span id={key + '_pricebox'}
                              key={key + '_span'}
                              value={key}
                              price={item.price}
                              className={"price-table" + (onetimeCheckedItems[key] ? ' active' : '')}
                        >
                            {
                                formatPrice(getOnetimeItemCost(item, key), props.currencySymbol)
                            }
                        </span>
                    }

                    {
                        ((item.attachment && item.attachment.length > 0)
                            ?
                            <div className="sowImg price-table">
                                <a target="_blank" href={item.attachment}>
                                    <img src="/images/quote-builder/guru_logo.png" border="0" className="guruCard" />
                                </a>
                            </div>
                            : '')
                    }

                </label>
                {item.nested_items_type && onetimeCheckedItems[item.productCode] &&
                <div style={{backgroundColor: '#f8fafc'}}>
                    <h4 style={{margin: 0, padding: '18px 0 0 40px'}}>
                        Messaging channels
                    </h4>
                    {renderNestedOneTimeItems('radio', item.nested_items_type)}
                </div>
                }
            </>
        ));

    const renderNestedOneTimeItems = (inputType, elementName) =>
        Object.entries(cpiOnetimeOptions[elementName]).map(([key, item]) => (
            <label htmlFor={key} key={key + '_label'} className="price-table-nested">
                <div style={{ float: "left", width: '85%', textOverflow: "ellipsis" }}>
                    <input type={inputType}
                           key={key + '_' + inputType}
                           name={elementName ?? key}
                           id={key}
                           value={key}
                           price={item.price}
                           className={'price-table-nested' + (item.class === 'info_only disabled' ? ' disabled' : '')}
                           checked={key in onetimeCheckedItems ? onetimeCheckedItems[key] : false}
                           onChange={
                               (event) => handleOnetimeServicesChange(
                                   event.target.checked, key, item,
                                   inputType === 'radio' ? elementName : '',
                                   true
                               )

                           }
                           disabled={item.class === 'info_only disabled' ? 'disabled' : ''}
                    />
                    {item.title}
                </div>
                <span id={key + '_pricebox'}
                      key={key + '_span'}
                      value={key}
                      price={item.price}
                      className={"price-table" + (onetimeCheckedItems[key] ? ' active' : '')}
                >
                    {
                        formatPrice(getOnetimeItemCost(item, key), props.currencySymbol)
                    }
                </span>
            </label>
        ));

/*
    const onetimeItems =  (
            Object.entries(props.pricing).length > 0
            && Object.entries(props.pricing.cpi_onetime).length > 0
            && Object.entries(props.pricing.cpi_onetime.options).length > 0
        )
        ?
        Object.entries(props.pricing.cpi_onetime.options).map( ([key, item]) => (
            (item.type === 'cpi_training' || item.type === 'cpi_configuration') &&
            <label htmlFor={key} key={key + '_label'} className="price-table">

                <div style={{float:"left",width:506,textOverflow:"ellipsis"}}>
                    <input type="checkbox"
                           key={key + '_checkbox'}
                           name={key}
                           value={key}
                           price={item.price}
                           className={'price-table' + (item.class === 'info_only disabled'? ' disabled' : '')}
                           checked={key in onetimeCheckedItems ? onetimeCheckedItems[key] : false}
                           onChange={
                               (event) => handleOnetimeServicesChange(event.target.checked, key, item)

                           }
                           disabled={item.class === 'info_only disabled' ? 'disabled' : ''}
                    />
                    {item.title}
                </div>

                {
                    'type' in item
                    && ((item.type == 'cpi_training' && item.default_quantity) || item.type == 'cpi_configuration')
                    &&

                    <div key={key + '_qty_container'}
                         style={key in onetimeCheckedItems ? {textAlign:'right',minWidth:157,float:"left",marginTop:-4} : {textAlign:'right',minWidth:157,float:"left",marginTop:-4,pointerEvents:"none",opacity:0.3}}
                    >
                        {((item.unit === 'Attendees' || item.unit === 'Sessions') ? '\u00A0\u00A0 ' : ' ') + item.unit}: <input type="number"
                                                                                               className="numericInput"
                                                                                               key={key + '_qty'}
                                                                                               id={key + '_qty'}
                                                                                               value={key in props.productSelectedQuantity ? props.productSelectedQuantity[key] : item.default_quantity}
                                                                                               min={0}
                                                                                               max={10000}
                                                                                               style={{marginRight: 8, textAlign: "center"}}
                                                                                               onChange={(event) => handleQuantityUpdate(key, event.target.value, item)}
                    />
                    </div>
                }

                {
                    <span id={key + '_pricebox'}
                          key={key + '_span'}
                          value={key}
                          price={item.price}
                          className={"price-table" + (onetimeCheckedItems[key] ? ' active' : '')}
                    >
                            {
                                formatPrice(getOnetimeItemCost(item, key), props.currencySymbol)
                            }
                        </span>
                }

                {
                    ((item.attachment && item.attachment.length > 0)
                        ?
                        <div className="sowImg price-table">
                            <a target="_blank" href={item.attachment}>
                                <img src="/images/quote-builder/guru_logo.png" border="0" className="guruCard" />
                            </a>
                        </div>
                        : '')
                }

            </label>



        ))
        : ''
    ;
*/
    /**
    const old_onetimeItems =  (
            Object.entries(props.pricing).length > 0
            && Object.entries(props.pricing.implementations).length > 0
            && Object.entries(props.pricing.implementations.options).length > 0
        )
        ?
            Object.entries(props.pricing.implementations.options).map( ([key, item]) => (

                <label htmlFor={key} key={key + '_label'} className="price-table">

                    <input type="checkbox"
                           key={key + '_checkbox'}
                           name={key}
                           id={key}
                           value={key}
                           price={
                               (key === 'whatapp' && props.smsSelectedQuantity[key] > 1)
                                   ? (item.price * props.smsSelectedQuantity[key])
                                   : item.price
                           }
                           className={'price-table' + (item.class === 'info_only disabled'? ' disabled' : '')}
                           checked={key in onetimeCheckedItems ? onetimeCheckedItems[key] : false}
                           onChange={handleOnetimeServicesChange}
                           disabled={item.class === 'info_only disabled' ? 'disabled' : ''}
                    />

                    {item.title}

                    {item.class === 'info_only disabled'
                        ? ''
                        :
                            <span id={key + '_pricebox'}
                                  key={key + '_span'}
                                  value={key}
                                  price={item.price}
                                  className={"price-table" + (onetimeCheckedItems[key] ? ' active' : '')}
                            >
                                {formatPrice(item.price, props.currencySymbol, 'ServicesPanel.js')}
                            </span>
                    }


                    {(key == 'guided_sms')
                        ? (
                                <>
                                {onetimeCheckedItems[key] ? caretController(key) : ''}
                                {( onetimeCheckedItems[key]
                                    ? (carets.guided_sms ? guidedSmsBlock('sms') : '')
                                    : ''
                                )}
                                </>

                           )
                        : ''
                    }
                    {(key == 'whatsapp')
                        ? (
                                <>
                                {onetimeCheckedItems[key] ? caretController(key) : ''}
                                {( onetimeCheckedItems[key]
                                    ? (carets.whatsapp ? guidedSmsBlock('whatsapp') : '')
                                    : ''
                                )}
                                </>

                           )
                        : ''
                    }


                    {

                        (item.class === 'info_only disabled')
                        ?
                            <div className="contactUsScopeImg">
                                <a target="_blank" href={getScopeContactDetails(item.title)[0]}>
                                    <img src={getScopeContactDetails(item.title)[1]} border="0" />
                                </a>
                                <div className="contactUsScope">
                                    <a target="_blank" href={getScopeContactDetails(item.title)[0]}>
                                        contact us for scope
                                    </a>
                                </div>
                            </div>
                        :
                            ((item.attachment && item.attachment.length > 0)
                             ?   <div className="sowImg price-table" key={key + '_attachment'}>
                                    <a key={key+'_attachment_ahref'} target="_blank"
                                       href={item.attachment}>
                                        <img src="/images/quote-builder/sow_scope_icon.png" border="0" />
                                    </a>
                                </div>
                            : '')
                    }


                </label>



            ))
        : ''
    ;
    **/

    /* We no longer assign one-time services based on platform level, just offer hour-based catch-all one-time service as on Jan 2022


    useEffect(() => {
        // console.log('ServicesPanel::useEffect() props:', props);
        // console.log('ServicesPanel::useEffect() resetting props.setServicesMonthlyCostByType to default due to Opp update');
        props.setServicesMonthlyCostByType(monthlyServicesInitialState);
        // console.log('ServicesPanel::useEffect() resetting props.setServicesMonthlySelected to default due to Opp update');

        // reset monthly services
        props.setServicesMonthlySelected([]);

        //setOnetimeTotal(TBD... depends on defaults /level)


        // reset default Onetime selections based on the selected platform level

        if(props.filters && props.namedUserPlatformLevel.key) {
            let defaultOneTimeItemKeys = props.filters[props.namedUserPlatformLevel.key].implementations.default;
            let defaultOneTimeItems = {};
            let onetimeCheckedItems = [];
            defaultOneTimeItemKeys.forEach(function (v) {
                defaultOneTimeItems[v] = props.pricing.implementations.options[v];
                onetimeCheckedItems[v] = true;
            })
            props.setOnetimeSelected(defaultOneTimeItems);
            setOnetimeCheckedItems(onetimeCheckedItems);

            let recalculatedOnetimeTotal = recalculateOnetimeServicesTotals(onetimeCheckedItems);


            // recalculate Sms

            // (re-)initialize the default or latest SMS quantities
            let smsQuantities = [];
            let smsTotals = []
            for (let [k, v] of Object.entries(SMS_PRODUCTS)) {
                if(
                    k.includes('sms') // e.g. sms only e.g. no whatsapp
                    && 'guided_sms' in defaultOneTimeItems
                ) {
                    smsQuantities[k] = props.smsSelectedQuantity[k] ? props.smsSelectedQuantity[k] : v.default
                    smsTotals[k] = smsQuantities[k] * v.price // add up monthly SMS charges
                }
            }

            // console.log('ServicesPanel::useEffect() smsTotals ' ,smsTotals);
            let smsTotal = arraySum(smsTotals)
            // console.log('ServicesPanel::useEffect() smsTotal ' ,smsTotal);
            let newTotals = {...props.totals, ['sms']: smsTotal, ['onetime']: recalculatedOnetimeTotal}
            // console.log('ServicesPanel::useEffect() smsQuantities: ', smsQuantities)
            // console.log('ServicesPanel::useEffect() newTotals: ', newTotals)
            props.setTotals(newTotals)

            props.setSmsSelectedQuantity({...smsQuantities});
            setServicesMonthlyTotal(smsTotal) // initialized to just the SMS fees to start


        }


    },[props.selectedOpportunity, props.namedUserPlatformLevel, props.selectedIndustry]);

    */


    useEffect(() => {
        console.log('ServicePanel.js:: re-rendering after an api/account/opp update')

        // reset default Onetime selections
        props.setOnetimeSelected({})
        props.setProductSelectedQuantity({})

        setServicesMonthlyTotal(0);
        setOnetimeCheckedItems({});



        // reset totals
        let totals = {...props.totals};
        totals[TOTALS_COMPONENT_KEY_ONETIME] = 0;
        props.setTotals(totals);


    },[props.apiData, props.selectedAccount, props.selectedOpportunity]);


    useEffect(() => {
        console.log('ServicePanel.js:: re-rendering after a props.productSelectedQuantity update')
        recalculateOnetimeServicesTotals(onetimeCheckedItems)

    },[props.productSelectedQuantity]);


    useEffect(() => {
        if( !(Object.entries(props.pricing).length > 0
            && Object.entries(props.pricing.cpi_onetime).length > 0
            && Object.entries(props.pricing.cpi_onetime.options).length > 0)) return;


        Object.entries(props.pricing.cpi_onetime.options).forEach(([key, option]) => {
                for(let optionKey in cpiOnetimeOptions) {
                    const source = 'sub_type' in option ? 'sub_type' : 'type';
                    optionKey === option.type && setCpiOnetimeOptions((prevState) =>
                        ({...prevState, [option[source]]: {...prevState[option[source]], [key]: option}}))
                }
            }
        )
    }, [props.pricing, props.cpi_onetime?.options, props.cpi_onetime])

    return (
        <>
            <div className="panel panel-default clearable_panel" id="panel_services">
                <div className="panel-heading panel-green">Expert Services<span className="collapse-option"></span>
                </div>
                <div className="panel-body">


                            <a className="panel-description"
                               href="/images/quote-builder/Solution_Details.pdf" target="_blank">View
                                detailed descriptions</a>

                            <div id="expert_services_panel_monthly_services"
                                 className="hidden_when_implementation_only">
                                <h3>Monthly Services</h3>
                                <p className="panel-monthly-cost pull-up">Monthly Cost</p>

                                {props.flowType['isUpsell'] &&
                                    <div className="quick-tip hr-top-gray-solid">
                                        <strong>Quick Tip:</strong> Don't forget to re-add recurring packages this customer
                                        had previously
                                    </div>
                                }

                                {monthlyItems}
                            </div>
                            <div className="panel-mid">
                                <p className="subtotal">Monthly service total <span
                                    className="total-expert-services">
                                    {formatPrice(servicesMonthlyTotal, props.currencySymbol)}
                                </span></p>
                            </div>

                            <h3>One-time Services</h3>
                            <p className="panel-monthly-cost pull-up">One-Time Cost</p>

                            <div className="quick-tip hr-top-gray-solid displayNone">
                                <strong>Quick Tip:</strong> Only select NEW one-time packages
                            </div>

                            {onetimeItems('cpi_deployment', 'radio', 'cpi_deployment_group')}

                            <div style={{backgroundColor: '#f8fafc', borderBottom: '1px solid #cecece'}}>
                                {onetimeItems('cpi_configuration')}
                            </div>

                            <div className="quick-tip hr-top-gray-solid">
                                <h4>Training Packages</h4>
                            </div>
                                {onetimeItems('cpi_training')}


                    <div className="panel-footer">
                        <p className="subtotal">One-time service total
                            <span className="implementations_total">
                                {formatPrice(props.totals[TOTALS_COMPONENT_KEY_ONETIME], props.currencySymbol,'ServicesPanel.js')}
                            </span>
                        </p>
                    </div>

                </div>

            </div>
        </>

    )

}


export const MONTHLY_SERVICES_PLACEHOLDER = 'Choose a service'
export const SMS_MAX_QUANTITY = 1
