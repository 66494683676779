import React, {useEffect, useState} from 'react';
import './CpiServicesPanel.css'
import Select from "react-select";
import {arraySum, formatPrice, monthlyServicesInitialState, roundUp} from "../QBApp";

export default function CpiServicesPanel(props) {

    const [servicesMonthlyTotal, setServicesMonthlyTotal] = useState(0);
    const [onetimeCheckedItems, setOnetimeCheckedItems] = useState({});
    const nbsp = '\u00A0'; // unicode no-breaking space

    const [cpiOnetimeOptions, setCpiOnetimeOptions] = useState({
        cpi_deployment: {},
        cpi_training: {},
        cpi_configuration: {},
        cpi_onetime_messaging_channel: {}
    })

    /**
     * @param industry
     * @param isPartner
     * @returns {[]}
     */
    const getServiceTypes = (industry='', isPartner = false) => {
        let items = ['cpi_coe', 'cpi_esp', 'ps_subscription']
        //items.push('on_demand_advisor')
        console.log('CpiServicePanel.js:: getting serviceTypes for industry : ' + industry, items)
        return items
    }

    const toggleCaret = (e, key, oldVal) => {
        e.preventDefault();
        let newCarets = []
        newCarets[key] = oldVal === false ? true : false
        // console.log(' carets[   '+ key + '] : ' +  newCarets[key])
        setCarets({...carets, ...newCarets})

    }
    const caretController = (key) => (
        <a onClick={(e) => toggleCaret(e, key, carets[key])} href="#">
            <span style={{marginLeft:6,color:"#333333"}}>
                <i className={'fas fa-caret-' + (carets[key] ? 'down': 'right') + ' fa-lg'}></i>
            </span>
        </a>
    )

    const handleMonthlyServicesChange = (item_type, item, inputType='select', inputQty=0) => {
        console.log("in CpiServicesPanel::handleMonthlyServicesChange: item_type , inputType, inputQty, item", item_type , inputType, inputQty, item);

        let removedProduct = false
        // update qty for numeric inputs
        if(inputType === 'number'){

            let selectedQuantities = {}
            if(inputQty == '' || inputQty === 0 || isNaN(inputQty)) {
                selectedQuantities = {...props.cpiProductSelectedQuantity}
                delete selectedQuantities[item.productCode]
                removedProduct = true
                props.setCpiProductSelectedQuantity(selectedQuantities)
                console.log('selectedQuantities set to : ', selectedQuantities)
            } else {
                selectedQuantities = {...props.cpiProductSelectedQuantity, [item.productCode]: inputQty}
                console.log('selectedQuantities set to :: ', selectedQuantities)
                props.setCpiProductSelectedQuantity(selectedQuantities)
            }
        }




        let newVal = {...props.servicesMonthlyCostByType, [item_type] : ((inputType === 'number' || item_type === 'ps_subscription') ? inputQty * item.price : item.price) };
        // console.log("in CpiServicesPanel:: props.servicesMonthlyCostByType: updated to ", newVal);
        props.setServicesMonthlyCostByType(newVal);
        // console.log("in CpiServicesPanel::setServicesMonthlyTotal: updated to ", arraySum(Object.values(props.servicesMonthlyCostByType)));
        setServicesMonthlyTotal(props.getBillingPeriodPrice(arraySum(Object.values(newVal)), false, true));


        let newServicesMonthlySelected = {}
        if(removedProduct) {
            // keep as is
            newServicesMonthlySelected = {...props.servicesMonthlySelected}
        } else {
            // hydrate selected new item with data from the pricing API
            newServicesMonthlySelected = {
                ...props.servicesMonthlySelected,
                [item_type]: props.pricing[item_type].options[item.productCode]
            }
        }
        //let newServicesMonthlySelected = {...props.servicesMonthlySelected, [item_type] : props.pricing[item_type].options[item.value]}
        if(item.title === MONTHLY_SERVICES_PLACEHOLDER
            || (inputType === 'number' && (inputQty == '' || inputQty === 0 || isNaN(inputQty)))
        ) { // remove placeholder & empty items
            delete newServicesMonthlySelected[item_type];
        }
        props.setServicesMonthlySelected(newServicesMonthlySelected);
        console.log("in CpiServicesPanel::setServicesMonthlySelected: updated to ", newServicesMonthlySelected);

        // console.log('CpiServicesPanel::handleMonthlyServicesChange() - updating props.setTotals() to ', totals);
        props.setCpiExpertServicesRecurrentTotal(props.getBillingPeriodPrice(arraySum(Object.values(newVal)),false, true));


    }

    function removeSelectedItems(selectedItems, key, item) {

        console.log('CpiServicesPanel.js::removeSelectedItems key & item:', key, item)

        // remove the previously set quantity from being tracked whenever we uncheck the product
        let selectedQuantities = {...props.cpiProductSelectedQuantity}
        delete selectedQuantities[item.productCode]
        props.setCpiProductSelectedQuantity(selectedQuantities)

        // remove the product from the list of selected products
        delete selectedItems[item.productCode]
        let newVal = {...selectedItems}
        // update the selected quantities
        if(key in selectedQuantities) {
            handleQuantityUpdate(key, 0, item)
        }

        return newVal;
    }

    /**
     * @param isChecked
     * @param key
     * @param item
     * @param uncheckItemType used to force un-checking of all items of this type e.g. when we want to uncheck all items in a radio group
     */
    const handleOnetimeServicesChange = (isChecked, key, item, uncheckItemType='', isNested) => {

        let selectedItems = {...onetimeCheckedItems}
        let newVal = {}

        // uncheck all items of specified type if specified
        if(uncheckItemType !== '') {

            // invoke removeSelectedItems on each to ensure that recalculations are done
            Object.values(selectedItems).forEach(item => (
                item.type === uncheckItemType && removeSelectedItems(selectedItems, item.productCode, item)
            ))

            // create a filtered list & used that
            selectedItems = Object.entries(selectedItems).reduce((acc, [productCode, isCheckedItem]) => {
                if(props.onetimeSelected[productCode].type !== uncheckItemType) {
                    if(props.onetimeSelected[productCode].sub_type !== uncheckItemType) {
                        acc[productCode] = isCheckedItem;
                    }
                }
                return acc;
            }, {});
        }



        if(isChecked === false){
            newVal = removeSelectedItems(selectedItems, key, item)
        } else {
            newVal = {...selectedItems, [item.productCode]: isChecked};

            if(item.nested_items_type && !isNested) {
                const [[code, value]] = Object.entries(cpiOnetimeOptions[item.nested_items_type]).filter(([code, value]) => value.default_item);
                newVal = {...newVal, [code]: true};

                if(value.default_quantity > 0) {
                    handleQuantityUpdate(code, value.default_quantity, value)
                }
            }

            // update the selected quantities
            if(item.default_quantity > 0) {
                handleQuantityUpdate(key, item.default_quantity, item)
            }
        }


        // console.log("CpiServicesPanel::handleOnetimeServicesChange() checkedItems: ", newVal);
        setOnetimeCheckedItems(newVal);
        props.setOnetimeSelected(newVal);
        recalculateOnetimeServicesTotals(newVal);
    }


    /**
     * @param items
     * @return returns total for onetime services
     */
    function recalculateOnetimeServicesTotals(items) {

        console.log('CpiServicePanel.js::recalculateOnetimeServicesTotals() items: ', items)

        let selected = [];
        let selectedTrainingPrices = [];
        let selectedDeploymentPrices = [];
        let selectedConfigurationPrices = [];
        let prices = Object.entries(props.pricing[TOTALS_COMPONENT_KEY_CPI_EXPERT_ONETIME].options).reduce( (arr, [key, item]) => {
            if (key in items && items[key] === true) {

                console.log('item.default_quantity, item:', item.default_quantity, item)
                arr.push(getOnetimeItemCost(item, key))
                selected = {...selected, [key]: item}
                console.log('CpiServicePanel.js::recalculateOnetimeServicesTotals() selected: ', selected)

                if(item.type==='cpi_training'){
                    selectedTrainingPrices.push(getOnetimeItemCost(item, key))
                }else if(item.type==='cpi_deployment') {
                    selectedDeploymentPrices.push(getOnetimeItemCost(item, key))
                }else if(item.type==='cpi_configuration') {
                    selectedConfigurationPrices.push(getOnetimeItemCost(item, key))
                }
            }

            props.setOnetimeSelected(selected);

            return arr;
        }, [])

        props.setCpiExpertServicesOnetimeTotal(arraySum(prices))

        props.setCpiExpertServicesOnetimeTrainingTotal(arraySum(selectedTrainingPrices))
        props.setCpiExpertServicesOnetimeConfigurationTotal(arraySum(selectedConfigurationPrices))
        props.setCpiExpertServicesOnetimeDeploymentTotal(arraySum(selectedDeploymentPrices))

        let totals = {...props.totals};
        totals[TOTALS_COMPONENT_KEY_CPI_EXPERT_ONETIME_TRAINING] = arraySum(selectedTrainingPrices);
        totals[TOTALS_COMPONENT_KEY_CPI_EXPERT_ONETIME_CONFIGURATION] = arraySum(selectedConfigurationPrices);
        totals[TOTALS_COMPONENT_KEY_CPI_EXPERT_ONETIME_DEPLOYMENT] = arraySum(selectedDeploymentPrices);
        props.setTotals(totals);


        console.log('CpiServicePanel.js::recalculateOnetimeServicesTotals() props.onetimeSelected: ', props.onetimeSelected)
        return arraySum(prices);
    }

    function log(msg, object) { console.log(msg, object); return true}

    const monthlyItems =

        ( Object.entries(props.pricing).length > 0 && log('props.servicesMonthlySelected', props.servicesMonthlySelected))
        ?
        Object.values(
            getServiceTypes(
                (props.selectedIndustry && Object.keys(props.selectedIndustry).length>0)
                ? props.selectedIndustry.value
                : ''
                , props.selectedAccount.isPartner
            )
        ).map( item_type => (
            <label htmlFor={item_type} name={item_type} className="monthlyServiceLabel" key={item_type+"_label"}>

                <div className="floatLeft monthlyServiceLabelText">{props.pricing[item_type].title}</div>

                <div className="floatLeft">


                    {(item_type === 'ps_subscription'

                        && Object.entries(props.pricing.ps_subscription.options).map( ([key, item]) => (
                             // log('item:::: ',item) &&


                                <>
                                <div key={key + '_qty_container'}
                                    style={{textAlign:'right',minWidth:157,float:"left"}}
                                    /*
                                        {key in onetimeCheckedItems
                                        ? {textAlign:'right',minWidth:157,float:"left",marginTop:-4}
                                        : {textAlign:'right',minWidth:157,float:"left",marginTop:-4,pointerEvents:"none",opacity:0.3}
                                    }*/
                                >


                                    {'PS Subscription Offering (up to 390 hours/year/offering)'}
                                </div>
                                <div style={{textAlign:'right',minWidth:157, width: 260, float:"right",marginTop:-4}}>

                                    {nbsp+nbsp + item.unit} : {nbsp}
                                    <input type="number"
                                        className="numericInput"
                                        key={key + '_qty'}
                                        id={key + '_qty'}
                                        value={key in props.cpiProductSelectedQuantity ? props.cpiProductSelectedQuantity[key] : ''} //item.default_quantity}
                                        min={0}
                                        max={100}
                                        step={1}
                                        style={{marginRight: 8, textAlign: "center",opacity:(props.cpiProductSelectedQuantity[key]>0?1:.6)}}
                                        onChange={(event) => handleMonthlyServicesChange(item.type, item, 'number', parseInt(event.target.value))}
                                    />
                                </div>
                                </>
                        ))





                    )|| <Select
                        //placeholder={"Loading..."}
                        id={item_type}
                        className="monthlyServiceSelect"
                        value={item_type in props.servicesMonthlySelected
                            ? {
                                  'label': getShortProductDisplayName(props.servicesMonthlySelected[item_type].title)  // product.Name
                                , 'value': props.servicesMonthlySelected[item_type].value // product.productCode
                                }
                            : ''
                        }
                        onChange={(event) => handleMonthlyServicesChange(item_type, props.pricing[item_type].options[event.value])}
                        isSearchable={false}
                        placeholder={MONTHLY_SERVICES_PLACEHOLDER}
                        options={Object.entries(props.pricing[item_type].options).map(
                            function ([index, item]) {item.value=index;item.label=getShortProductDisplayName(item.title);return item;}
                            )}
                    />
                   }
                </div>
                <span key={item_type+"_price_table_span"} className="price-table floatRight">
                    {formatPrice(props.getBillingPeriodPrice(props.servicesMonthlyCostByType[item_type], false, true), props.currencySymbol)}
                </span>


            </label>
        ))
        : ''
    ;

    const handleQuantityUpdate = (key, quantity, item) => {
        console.log('CpiServicesPanel.js handleQuantityUpdate : key, quantity : ' + key + ', '+ quantity)

        if(quantity > 10000){
            quantity = 10000
        }

        // use unit cost to prorate the updated quantity cost
        let unitCost = roundUp(item.price / item.default_quantity)

        props.setCpiProductSelectedQuantity((prevState) => ({...prevState, [key] : quantity}))

        let total = 0;
        for (let [k, qty] of Object.entries(props.cpiProductSelectedQuantity)) {
            total += getOnetimeItemCost(props.pricing[TOTALS_COMPONENT_KEY_CPI_EXPERT_ONETIME].options[k], k)
        }

        console.log('CpiServicesPanel.js handleQuantityUpdate() props.totals: ', props.totals)
        props.setCpiExpertServicesOnetimeTotal(total)

        // recalculateOnetimeServicesTotals(onetimeCheckedItems)
    }

    const debug =() => {
        console.log('CpiServicesPanel.js props.pricing.cpi_onetime', props.pricing[TOTALS_COMPONENT_KEY_CPI_EXPERT_ONETIME])
        return true;
    }

    const getOnetimeItemCost = (item, key) => {

        // console.log('CpiServicesPanel.js getOnetimeItemCost item, key: ', item, key)

        if(!item){
            return 0
        }


        return (item.default_quantity === null)
            ? item.price
            :
                (
                    key in props.cpiProductSelectedQuantity //&& (item.type === 'cpi_training')
                        ? (item.price * props.cpiProductSelectedQuantity[key])
                        : (item.price * ((item.default_quantity > 0) ? item.default_quantity : 1))
                )

    }

    const onetimeItems = (type, inputType='checkbox', elementName=null) =>
        debug() &&
        Object.entries(cpiOnetimeOptions[type]).map( ([key, item]) => (
            item.type === type &&
            <>
                <label htmlFor={key} key={key + '_label'} className="price-table">

                    <div style={{float:"left",width:500,textOverflow:"ellipsis"}}>
                        <input type={inputType}
                               key={key + '_' + inputType}
                               name={elementName??key}
                               id={key}
                               value={key}
                               price={item.price}
                               className={'price-table' + (item.class === 'info_only disabled'? ' disabled' : '')}
                               checked={key in onetimeCheckedItems ? onetimeCheckedItems[key] : false}
                               onChange={
                                   (event) => handleOnetimeServicesChange(
                                       event.target.checked, key, item,
                                       inputType === 'radio' ? 'cpi_deployment' : ''
                                   )

                               }
                               disabled={item.class === 'info_only disabled' ? 'disabled' : ''}
                        />
                        {item.title}
                    </div>

                    {
                        'type' in item
                        && ((item.type == 'cpi_training' && item.default_quantity) || item.type == 'cpi_configuration')
                        && item.default_quantity
                        &&

                        <div key={key + '_qty_container'}
                             style={key in onetimeCheckedItems
                                 ? {textAlign:'right',minWidth:157,float:"left",marginTop:-4}
                                 : {textAlign:'right',minWidth:157,float:"left",marginTop:-4,pointerEvents:"none",opacity:0.3}
                             }
                        >
                            {    (   (item.unit === 'Attendees' || item.unit === 'Sessions')
                                    ? nbsp+nbsp
                                    : ' '
                            ) + item.unit} : {nbsp}
                            <input type="number"
                                   className="numericInput"
                                   key={key + '_qty'}
                                   id={key + '_qty'}
                                   value={key in props.cpiProductSelectedQuantity ? props.cpiProductSelectedQuantity[key] : item.default_quantity}
                                   min={0}
                                   max={10000}
                                   style={{marginRight: 8, textAlign: "center"}}
                                   onChange={(event) => handleQuantityUpdate(key, parseInt(event.target.value), item)}
                            />
                        </div>
                    }
                    {
                        <span id={key + '_pricebox'}
                              key={key + '_span'}
                              value={key}
                              price={item.price}
                              className={"price-table" + (onetimeCheckedItems[key] ? ' active' : '')}
                        >
                                {
                                    formatPrice(getOnetimeItemCost(item, key), props.currencySymbol)
                                }
                            </span>
                    }

                    {
                        ((item.attachment && item.attachment.length > 0)
                            ?
                            <div className="sowImg price-table">
                                <a target="_blank" href={item.attachment}>
                                    <img src="/images/quote-builder/guru_logo.png" border="0" className="guruCard" />
                                </a>
                            </div>
                            : '')
                    }

                </label>
                {item.nested_items_type && onetimeCheckedItems[item.productCode] &&
                <div style={{backgroundColor: '#f8fafc'}}>
                    <h4 style={{margin: 0, padding: '18px 0 0 40px'}}>
                        Messaging channels
                    </h4>
                    {renderNestedOneTimeItems('radio', item.nested_items_type)}
                </div>
                }
            </>
        ));

    const renderNestedOneTimeItems = (inputType, elementName) =>
        Object.entries(cpiOnetimeOptions[elementName]).map(([key, item]) => (
            <label htmlFor={key} key={key + '_label'} className="price-table-nested">
                <div style={{ float: "left", width: '85%', textOverflow: "ellipsis" }}>
                    <input type={inputType}
                           key={key + '_' + inputType}
                           name={elementName ?? key}
                           id={key}
                           value={key}
                           price={item.price}
                           className={'price-table-nested' + (item.class === 'info_only disabled' ? ' disabled' : '')}
                           checked={key in onetimeCheckedItems ? onetimeCheckedItems[key] : false}
                           onChange={
                               (event) => handleOnetimeServicesChange(
                                   event.target.checked, key, item,
                                   inputType === 'radio' ? elementName : '',
                                   true,
                               )

                           }
                           disabled={item.class === 'info_only disabled' ? 'disabled' : ''}
                    />
                    {item.title}
                </div>
                <span id={key + '_pricebox'}
                      key={key + '_span'}
                      value={key}
                      price={item.price}
                      className={"price-table" + (onetimeCheckedItems[key] ? ' active' : '')}
                >
                  {
                      formatPrice(getOnetimeItemCost(item, key), props.currencySymbol)
                  }
              </span>
            </label>
        ));

    useEffect(() => {
        console.log('CpiServicePanel.js:: re-rendering after an api/account/opp update')

        props.setServicesMonthlyCostByType(monthlyServicesInitialState);
        // reset monthly services
        props.setServicesMonthlySelected([])
        // reset default Onetime selections
        props.setOnetimeSelected({})
        props.setCpiProductSelectedQuantity({})

        setServicesMonthlyTotal(0);
        setOnetimeCheckedItems({});
        props.setOnetimeSelected({})

        /*
        // reset totals
        let totals = {...props.totals};
        totals[TOTALS_COMPONENT_KEY_CPI_AUTOMATION_PACKAGE] = 0;
        totals[TOTALS_COMPONENT_KEY_CPI_ADDONS] = 0;
        totals[TOTALS_COMPONENT_KEY_CPI_EXPERT_SERVICES] = 0;
        totals[TOTALS_COMPONENT_KEY_CPI_EXPERT_ONETIME] = 0;
        props.setTotals(totals);
        */



    },[props.apiData, props.selectedAccount, props.selectedOpportunity]);

    useEffect(() => {
        console.log('CpiServicePanel.js:: re-rendering after a props.cpiProductSelectedQuantity update')
        recalculateOnetimeServicesTotals(onetimeCheckedItems)

    },[props.cpiProductSelectedQuantity]);

    useEffect(() => {
        console.log('CpiServicePanel.js:: re-rendering after a props.billingDetails.billing_period update')
        // updates the monthly dropdowns with billing-period-specific pricing & updates the total
        Object.entries(props.servicesMonthlySelected).map(

            function ([key, item]) {handleMonthlyServicesChange(
                key,
                item,
                (item.type === 'ps_subscription' ? 'number' : 'select'),
                (item.productCode in props.cpiProductSelectedQuantity ? props.cpiProductSelectedQuantity[item.productCode] : 0)
            )}
        )

    },[props.billingDetails.billing_period]);

    useEffect(() => {
        if( !(Object.entries(props.pricing).length > 0
            && Object.entries(props.pricing.cpi_onetime).length > 0
            && Object.entries(props.pricing.cpi_onetime.options).length > 0)) return;


        Object.entries(props.pricing.cpi_onetime.options).forEach(([key, option]) => {
                for(let optionKey in cpiOnetimeOptions) {
                    const source = 'sub_type' in option ? 'sub_type' : 'type';
                    optionKey === option.type && setCpiOnetimeOptions((prevState) =>
                        ({...prevState, [option[source]]: {...prevState[option[source]], [key]: option}}))
                }
            }
        )
    }, [props.pricing, props.cpi_onetime?.options, props.cpi_onetime])

    return (
        <>
            <div className="panel panel-default clearable_panel" id="panel_services">
                <div className="panel-heading panel-green">Expert Services<span className="collapse-option"></span>
                </div>
                <div className="panel-body">

                    <div id="expert_services_panel_monthly_services"
                         className="hidden_when_implementation_only">
                        <h3>Recurrent Services</h3>
                        <p className="panel-monthly-cost pull-up">{props.billingDetails.billing_period.label} Cost</p>

                        {props.flowType['isUpsell'] &&
                            <div className="quick-tip hr-top-gray-solid">
                                <strong>Quick Tip:</strong> Don't forget to re-add recurring packages this customer
                                had previously
                            </div>
                        }

                        {monthlyItems}
                    </div>
                    <div className="panel-mid">
                        <p className="subtotal">Monthly service total <span
                            className="total-expert-services">
                            {formatPrice(servicesMonthlyTotal, props.currencySymbol)}
                        </span></p>
                    </div>

                    <div style={{marginTop:35}}></div>

                    <h3>One-time Services</h3>
                    <p className="panel-monthly-cost pull-up">One-Time Cost</p>

                    <div className="quick-tip hr-top-gray-solid displayNone" style={{textAlign:"center"}}>
                        <strong>Quick Tip:</strong> Only select NEW one-time packages
                    </div>

                        {onetimeItems('cpi_deployment', 'radio', 'cpi_deployment_group')}

                    <div style={{backgroundColor: '#f8fafc', borderBottom: '1px solid #cecece'}}>
                        {onetimeItems('cpi_configuration')}
                    </div>

                    <div className="quick-tip hr-top-gray-solid">
                        <h4>Training Packages</h4>
                    </div>
                        {onetimeItems('cpi_training')}

                    <div className="panel-footer">
                        <p className="subtotal">One-time service total
                            <span className="implementations_total">
                                {formatPrice(props.cpiExpertServicesOnetimeTotal, props.currencySymbol,'CpiServicesPanel.js')}
                            </span>
                        </p>
                    </div>

                </div>

            </div>
        </>

    )

}

export const TOTALS_COMPONENT_KEY_CPI_EXPERT_SERVICES = 'cpi_expert_services';
export const TOTALS_COMPONENT_KEY_CPI_EXPERT_ONETIME = 'cpi_onetime';
export const TOTALS_COMPONENT_KEY_CPI_EXPERT_ONETIME_TRAINING = 'cpi_onetime_training';
export const TOTALS_COMPONENT_KEY_CPI_EXPERT_ONETIME_CONFIGURATION = 'cpi_onetime_configuration';
export const TOTALS_COMPONENT_KEY_CPI_EXPERT_ONETIME_DEPLOYMENT = 'cpi_onetime_deployment';

export const MONTHLY_SERVICES_PLACEHOLDER = 'Choose a service'
export const SMS_MAX_QUANTITY = 1

export const getShortProductDisplayName = (name) => {
    let map =  {
        'Enhanced Support Package': 'ESP',
        'Conversation Operations Experts' : 'COE',
        'PS Subscription Offering':  '',
    }

    for (const key in map) {
        if (name.startsWith(key)) {
            return name.replace(key, map[key]);
        }
    }
    return name;

}
