import React, {useEffect, useState} from 'react';
import '../AiPanel.css';
import {formatPrice, arraySum, roundUp} from "../QBApp";
import CpiInteractionCostPanel from "./CpiInteractionCostPanel";
import {TOTALS_COMPONENT_KEY_CPI_ADDONS} from "./CpiAddOnsPanel";
import {TOTALS_COMPONENT_KEY_CPI_EXPERT_ONETIME, TOTALS_COMPONENT_KEY_CPI_EXPERT_SERVICES} from "./CpiServicesPanel";

export default function CpiAutomationLevelPanel(props) {

    const [selectedItem, setSelectedItem] = useState('');

    const NONE_ITEM = {none: {title: 'None', price: 0}}
    const handleChange = (item) => {
        console.log('CpiAutomationLevelPanel::handleChange() props.pricing ', props.pricing);
        // console.log('CpiAutomationLevelPanel::handleChange() event.target.name: ' + item.name);
        console.log('CpiAutomationLevelPanel::handleChange() item: ', item);


        //reCalculateTotals(event.target.value)
        let prices = Object.entries(props.pricing.cpi_automation_packages.options).reduce( (arr, [key, apiItem]) => {
            if (item.productId === apiItem.productId) {
                arr.push(props.getBillingPeriodPrice(item.price))

                console.log('CpiAutomationLevelPanel handleChange got price: ' + roundUp(props.getBillingPeriodPrice(item.price), 0))


                props.setCpiSelected({[key] : item})
                setSelectedItem(key); // key = productCode here
                console.log('CpiAutomationLevelPanel::handleChange() setCpiSelected to :', item)
            }
            return arr;
        }, [])

        console.log('CpiAutomationLevelPanel handleChange prices: ' , prices)

        let totals = {...props.totals};

        if(item.price === 0){ // item.title === "None"
            props.setCpiSelected(NONE_ITEM)
            setSelectedItem('none'); // key = productCode here
            props.setCpiAutomationLevelTotal(0)
            console.log('CpiAutomationLevelPanel::handleChange() - updating props.setCpiAutomationLevelTotal() to 0')
        } else {
            props.setCpiAutomationLevelTotal(arraySum(prices))
            console.log('CpiAutomationLevelPanel::handleChange() - updating props.setCpiAutomationLevelTotal() to ' +  arraySum(prices))
        }



    }

    /*
    const reCalculateTotals = (selectedItemKey) => {

        console.log('selectedItemKey : ', selectedItemKey)

        let prices = Object.entries(props.pricing.cpi_automation_packages.options).reduce( (arr, [key, item]) => {
            if (key === selectedItemKey) {
                arr.push(roundUp(props.getBillingPeriodPrice(item.price), 0));// list prices are monthly but we need to show annual package cost
            }
            props.setCpiSelected({[key] : item});
            console.log('CpiAutomationLevelPanel::reCalculateTotals() setCpiSelected to :', item)
            return arr;
        }, [])

        let totals = {...props.totals};
        totals[TOTALS_COMPONENT_KEY_CPI_AUTOMATION_PACKAGE] = arraySum(prices);

        //console.log('CpiAutomationLevelPanel::handleChange() - props.totals originally was ', props.totals)
        console.log('CpiAutomationLevelPanel::handleChange() - updating props.setTotals() to ', totals)
        props.setTotals(totals);
    }
    */
    const items =  (
            Object.entries(props.pricing).length > 0
            && Object.entries(props.pricing.cpi_automation_packages).length > 0
            && Object.entries(props.pricing.cpi_automation_packages.options).length > 0
        )
        ?
        (
            Object.entries({
                ... NONE_ITEM, // inject an option for "None"
                ...props.pricing.cpi_automation_packages.options
            })
            .map( ([key, item]) => (
                //item.SBQQ__BillingFrequency__c === billingPeriodFilter && // filter products for specified billing period
            <label htmlFor={key} key={key + '_label'} className="price-table">

                <input type="radio"
                       key={key + '_radio'}
                       name="cpi_automation_packages"
                       id={key}
                       value={key}
                       price={props.getBillingPeriodPrice(item.price)}
                       className="price-table"
                       checked={selectedItem === key || (key === 'none' && Object.entries(props.cpiSelected).length === 0)}
                       onChange={(event) => handleChange(item)}
                />

                {item.title}

                <span
                    id={key}
                    key={key}
                    value={key + '_span'}
                    price={props.getBillingPeriodPrice(item.price)}
                    className={"price-table" + (selectedItem === key ? ' active' : '')}
                >
                    {formatPrice(props.getBillingPeriodPrice(item.price), props.currencySymbol)}
                </span>

                {(item.attachment && item.attachment.length > 0) ?
                    <div className="sowImg price-table">
                        <a target="_blank" href={item.attachment}>
                            <img src="/images/quote-builder/guru_logo.png" border="0" className="guruCard" />
                        </a>
                    </div>
                    : ''
                }
            </label>
        )))
        : ''
    ;

    useEffect(() => {
        if(props.cpiSelected && selectedItem in props.cpiSelected) {
            handleChange(props.cpiSelected[selectedItem])
        }

    },[props.billingDetails.billing_period]);

    useEffect(() => {
        console.log('CpiAutomationLevelPanel::useEffect() props:', props);
        setSelectedItem('');
        props.setCpiSelected('');
        console.log('CpiAutomationLevelPanel::useEffect() reset setCheckedItems');


        /*
        // reset totals
        let totals = {...props.totals};
        totals[TOTALS_COMPONENT_KEY_CPI_AUTOMATION_PACKAGE] = 0;
        totals[TOTALS_COMPONENT_KEY_CPI_ADDONS] = 0;
        totals[TOTALS_COMPONENT_KEY_CPI_EXPERT_SERVICES] = 0;
        totals[TOTALS_COMPONENT_KEY_CPI_EXPERT_ONETIME] = 0;
        props.setTotals(totals);
        */

    },[props.apiData, props.selectedOpportunity]);


    return (

        <>
            <div className="panel panel-default clearable_panel" id="packages">
                <div className="panel-heading panel-peri">Conversational Cloud Automation

                    {props.selectedAccount && props.selectedAccount.isPartner &&
                    <span className="sub-title partner-fields">
                            &nbsp;&nbsp;(Prices already discounted 30% for partners)
                        </span>
                    }

                    <span className="collapse-option"></span>
                </div>
                <div className="panel-body">

                    <span className="panel-description"></span>
                    <p className="panel-monthly-cost">{props.billingDetails.billing_period.label} Cost</p>

                    { items }

                </div>

                <div className="panel-footer">
                    <p className="subtotal">Conversational Cloud Automation total <span className="packages_total">
                        {formatPrice(props.cpiAutomationLevelTotal, props.currencySymbol)}
                    </span>
                    </p>
                </div>
            </div>

        </>

    )
}


export const TOTALS_COMPONENT_KEY_CPI_AUTOMATION_PACKAGE = 'cpi_automation_package';
export const TOTALS_COMPONENT_KEY_CPI_INTERACTION_COST = 'cpi_interaction_cost';
export const TOTALS_COMPONENT_KEY_CPI_BILLING_PERIOD_TOTAL = 'cpi_billing_period_total';
export const TOTALS_COMPONENT_KEY_CPI_OVERAGE_COST = 'cpi_overage_cost';
export const TOTALS_COMPONENT_KEY_CPI_OVERAGE_COST_MARKED_UP = 'cpi_overage_marked_up';

