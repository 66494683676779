import React, { useState } from 'react';
import Select from 'react-select';
import './Account.css';

export default function Account(props) {

    // test accounts
    const [ defaultAccounts, setDefaultAccounts] = useState(['0011J00001OAxvkQAD', '0011J00001OAz0qQAD', '0011J00001OdJGKQA3']);
    const [ apiToken, setApiToken] = useState(window.apiToken);
    const [ accounts, setAccounts] = useState(window.accounts_json);
    const [ centerPanelVisible , setCenterPanelVisible] = useState(false);

    function handleAccountChange(e) {

        if(e.value === null){
            console.log('selected null @ Account');
            return;
        }
        props.changeLeftNavVisible(true);

        console.log("All preloaded accounts:", accounts);
        console.log("in Account.js account.Id selected:", e.value)

        // select account data from pre-loaded basic account data to use that for display/selection purposes
        const acc_data = accounts.find(e => e.value === e.value);
        acc_data.Id = acc_data.value;
        acc_data.Name = acc_data.label;

        // props.changeApiData(e.value);

        // setSelectedAccount(accountId);
        props.getApiData(e.value);
        // console.log('apiData set to :', data);


        //props.opportunities.map(function (item) {item.value=item.Id;item.label=item.Name;return item;})}





    };

    const styles = {
        menu: base => ({
            ...base,
            marginTop: 1
        })
    };

    return (

        <>
            <Select
                // isClearable
                id={"sf_accounts"}
                className={"sf_accounts_new"}
                // value={props.selectedAccount ? props.selectedAccount.value : ''}
                // value={TEMP_DEFAULT_ACC}
                onChange={handleAccountChange}
                options={accounts}
                placeholder={'Select an account...'}
                autoFocus={'true'}
                styles={styles}
            />

         </>
    )

}




