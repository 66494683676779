import {getContactFieldOpsPopupButton, getContactFieldOpsText} from "../modals/ModalUtil";
import {error_modal} from "../components/QBApp";

class OpportunityValidation {}

export function validateSalesforceOpportunityDataIntegrity(opportunity){
    if('Id' in opportunity) { // ensure it's an SFDC object / not a placeholder

        if (opportunity.CurrencyIsoCode === '' || opportunity.CurrencyIsoCode === null) {
            let msg = 'Missing Currency for Opportunity ' + opportunity.Name
            error_modal('Opportunity Information Error',
                msg + getContactFieldOpsText, getContactFieldOpsPopupButton(msg)
            )
            return false
        } else if (opportunity.Pricebook2Id === '' || opportunity.Pricebook2Id === null) {
            let msg = 'Missing PriceBook for Opportunity ' + opportunity.Name
            error_modal('Opportunity Information Error',
                msg + getContactFieldOpsText, getContactFieldOpsPopupButton(msg)
            )
            return false
        }

    }
    return true
}
