import React, {useState, useEffect} from 'react';
import ReactDOM from 'react-dom';
import Opportunity, {OPPORTUNITY_TYPE_ORIGINAL, OPPORTUNITY_TYPE_RENEWAL, OPPORTUNITY_TYPE_UPSELL} from "./Opportunity";
import './QBApp.css';
import PlatformUsers from "./PlatformUsers";
import AiPanel from "./AiPanel";
import ServicesPanel from "./ServicesPanel";
import Account from "./Account";
import BillingPanel, {
    BILLING_EFFECTIVE_DATE_UPON_SIGNATURE_INPUT,
    defaultEffectiveDate, defaultPricingValidUntil, getContractRenewalDate,
    getFieldDefaultValue as getBillingDetailsFieldDefaultValue
} from "./BillingPanel";
import test_account_api_data from '../tests/data/account_test.json';
// import test_account_api_data from '../tests/data/account.json';
// import test_account_api_data from '../tests/data/account_acura_columbus.json';
// import test_account_api_data from '../tests/data/account_aaa_life_insurance.json';
import DiscountJustificationPanel from "./DiscountJustificationPanel";
import MyQuoteSummaryPanel from "./MyQuoteSummaryPanel";
import LandingPanel from "./LandingPanel";
import {hasValidContract} from "../validation/ContractValidation";
import VelocityPackagesPanel, {TOTALS_COMPONENT_KEY_VELOCITY_PACKAGES} from "./VelocityPackagesPanel";
import PricingModelNavigation, {selectMenuItem} from "./PricingModelNavigation";
import {
    isDirect,
    isEnterpriseUser,
    isPartner,
    validateSalesforceAccountDataIntegrity
} from "../validation/AccountValidation";
import AlwaysOnAdvisorPackagesPanel, {TOTALS_COMPONENT_KEY_ALWAYS_ON_ADVISOR_PACKAGES} from "./AlwaysOnAdvisorPackagesPanel";
import {validateSalesforceOpportunityDataIntegrity} from "../validation/OpportunityValidation";
import {
    TOTALS_COMPONENT_KEY_CPI_BILLING_PERIOD_TOTAL, TOTALS_COMPONENT_KEY_CPI_OVERAGE_COST
} from "./cpi/CpiAutomationLevelPanel";
import CpiInteractionCostPanel, {
    getDivisionFactorForBillingPeriod
} from "./cpi/CpiInteractionCostPanel";
import CpiAddOnsPanel from "./cpi/CpiAddOnsPanel";
import CpiServicesPanel from "./cpi/CpiServicesPanel";
import {getFilteredItems} from "./cpi/CpiMyQuote";
import WhatsAppUsagePanel from "./whatsapp_usage/WhatsAppUsagePanel";
import WhatsAppSummaryPanel from "./whatsapp_usage/WhatsAppSummaryPanel";


export default function QBApp() {

    const [apiToken, setApiToken] = useState(window.apiToken);
    const [leftNavVisible, setLeftNavVisible] = useState(false);

    const [scrollListenerAdded, setScrollListenerAdded] = useState(false);
    const [floatWhenScrolling, setFloatWhenScrolling] = useState(false);
    const [showSmallScrollableQuote, setShowSmallScrollableQuote] = useState(false)

    const [selectedAccount, setSelectedAccount] = useState({});
    const [isWaitingForData, setIsWaitingForData] = useState(false); // loading wheel indicator

    const [opportunities, setOpportunities] = useState([]);
    const [selectedOpportunity, setSelectedOpportunity] = useState({});
    const [opportunitiesFilterTypes, setOpportunitiesFilterTypes] = useState([OPPORTUNITY_TYPE_ORIGINAL]);

    const [industries, setIndustries] = useState([]);
    const [selectedIndustry, setSelectedIndustry] = useState('');

    const [currency, setCurrency] = useState('USD');
    const [currencySymbol, setCurrencySymbol] = useState('$');

    const [pricingModel, setPricingModel] = useState(PRICING_MODEL_NAMED_USER);
    const [pricingModelAdditionalSettings, setPricingModelAdditionalSettings] = useState({});
    const [pricing, setPricing] = useState({});
    const [totals, setTotals] = useState([]);
    // 'namedUserTotal is independently managed separate from 'totals', which unfortunately get overwritten by children components
    const [namedUserTotal, setNamedUserTotal] = useState(0);
    const [cpiAutomationLevelTotal, setCpiAutomationLevelTotal] = useState(0);
    const [cpiAddOnsTotal, setCpiAddOnsTotal] = useState(0);
    const [cpiExpertServicesRecurrentTotal, setCpiExpertServicesRecurrentTotal] = useState(0);
    const [cpiExpertServicesOnetimeTotal, setCpiExpertServicesOnetimeTotal] = useState(0);
    const [cpiExpertServicesOnetimeDeploymentTotal, setCpiExpertServicesOnetimeDeploymentTotal] = useState(0);
    const [cpiExpertServicesOnetimeTrainingTotal, setCpiExpertServicesOnetimeTrainingTotal] = useState(0);
    const [cpiExpertServicesOnetimeConfigurationTotal, setCpiExpertServicesOnetimeConfigurationTotal] = useState(0);
    const [cpiPeriodMinimumFee, setCpiPeriodMinimumFee] = useState(0);


    const [discounts, setDiscounts] = useState({});
    const [hideDiscounts, setHideDiscounts] = useState(false);

    const [recurrentTotal, setRecurrentTotal] = useState(0);
    const [onetimeTotal, setOnetimeTotal] = useState(0);
    const [inlineOpportunityUpdates, setInlineOpportunityUpdates] = useState(DEFAULT_INLINE_OPPORTUNITY_UPDATES)

    const [quoteType, setQuoteType] = useState(QUOTE_FLOW_TYPE_ORIGINAL);
    const [flowType, setFlowType] = useState(DEFAULT_FLOW_STATE)
    const [useNewOpportunity, setUseNewOpportunity] = useState(false)

    const [minimumDiscountApprovalThresholdPartner, setMinimumDiscountApprovalThresholdPartner]
        = useState(parseFloat(window.minimumDiscountApprovalThresholdPartner))
    const [minimumDiscountApprovalThresholdDirect, setMinimumDiscountApprovalThresholdDirect]
        = useState(parseFloat(window.minimumDiscountApprovalThresholdDirect))
    const [discountJustification, setDiscountJustification] = useState('');
    const [requireDiscountJustification, setRequireDiscountJustification] = useState(false);

    const [missingDataPanelErrors, setMissingDataPanelErrors] = useState({})

    const [apiData, setApiData] = useState({})
    const [namedUserNumber, setNamedUserNumber] = useState(DEFAULT_NAMED_USER_PLATFORM_USERS_NUMBER)
    const [namedUserPlatformLevel, setNamedUserPlatformLevel] = useState('')

    const [defaultNamedUserPlatformLevel, setDefaultNamedUserPlatformLevel] = useState({});
    const [namedUserPlatformLevels, setNamedUserPlatformLevels] = useState([]);
    const [aiSelected, setAiSelected] = useState({});
    const [velocitySelected, setVelocitySelected] = useState('');

    const [cpiSelected, setCpiSelected] = useState('');
    const [cpiCreditsNumber, setCpiCreditsNumber] = useState(0); // an int
    const [cpiCreditsCost, setCpiCreditsCost] = useState({}); // an object
    const [cpiAddOnsSelected, setCpiAddOnsSelected] = useState({}); // an object
    const [cpiOnetimeSelected, setCpiOnetimeSelected] = useState('');
    const [cpiOverageCost, setCpiOverageCost] = useState(0);


    const [alwaysOnAdvisorSelected, setAlwaysOnAdvisorSelected] = useState('');
    const [onetimeSelected, setOnetimeSelected] = useState({});
    const [productSelectedQuantity, setProductSelectedQuantity] = useState({}); // one-time services for Named User
    const [servicesMonthlyCostByType, setServicesMonthlyCostByType] = useState(monthlyServicesInitialState);
    const [servicesMonthlySelected, setServicesMonthlySelected] = useState({});
    const [smsSelectedQuantity, setSmsSelectedQuantity] = useState({});
    const [cpiProductSelectedQuantity, setCpiProductSelectedQuantity] = useState({});


    const defaultCurrencyIsoCode = 'USD';

    const [costMonthlyNamedUser, setCostMonthlyNamedUser] = useState(0);

    const [billingDetails, setBillingDetails] = useState([]);
    const [billingContacts, setBillingContacts] = useState([]);
    const [contract, setContract] = useState({});// contract to be Amended in case of upsells/renewals
    const [contractId, setContractId] = useState('');// contract to be Amended in case of upsells/renewals
    const [resetTerm, setResetTerm] = useState('')

    const [browserWindowSize, setBrowserWindowSize] = useState(0)
    const MIN_WINDOW_WIDTH_FOR_WARNING = 1360

    const PS_SUBSCRIPTION_PRODUCT_CODE = 'LPPS-00007'; // used in CPI and NU pricing models


    const getSubscriptionTerm = () => {
        return billingDetails['subscription_term'] ?
            billingDetails['subscription_term'].value
            : getBillingDetailsFieldDefaultValue(pricing, 'subscription_term')?.value
    }

    const getBillingPeriodPrice = (annualPrice, discountIfPartner=true, useBillingPeriodBasedPricing=false) => {

        let billingPeriodDivisionFactor = 1; // default

        if(pricingModel === PRICING_MODEL_NAMED_USER) { // periodic billing for NU is only Monthly
            billingPeriodDivisionFactor = 1; // uses monthly already as a baseline
        } else { // e.g. CPI allows for monthly/quarterly/semi-annual/and annual
            if(useBillingPeriodBasedPricing) {
                // for other recurrent CPI products eg COE/ESP, adjust the pricing based on the billing period
                billingPeriodDivisionFactor =
                    getDivisionFactorForBillingPeriod(billingDetails.billing_period.label) // eg 2 for semi-annual
            } else {
                // for CPI 2.0 credits, always use annual pricing for everything
                billingPeriodDivisionFactor = 1;
            }
        }
        let billingPeriodPrice = annualPrice / billingPeriodDivisionFactor

        return discountIfPartner
            ? adjustPriceForDirectOrPartner(selectedAccount, billingPeriodPrice)
            : billingPeriodPrice
    }


    // Reset any & all previous data (happens after account gets re-loaded)
    const resetPreExistingQuoteData = (pricingModel=PRICING_MODEL_NAMED_USER, resetBillingDetails=true, resetFlow=true) => {

        console.log('QBApp.js:: resetPreExistingQuoteData for pricing model: ' + pricingModel)

        setDiscountJustification('')
        setVelocitySelected('')

        setCpiSelected('')
        setCpiCreditsNumber(0)
        setCpiCreditsCost({})
        setCpiAddOnsSelected({})
        setCpiOnetimeSelected('')

        setAlwaysOnAdvisorSelected('')
        setDiscounts({}) // reset discounts for all models

        setHideDiscounts(false)

        setInlineOpportunityUpdates(DEFAULT_INLINE_OPPORTUNITY_UPDATES)

        setServicesMonthlySelected({}) // used for CPI and NU
        setServicesMonthlyCostByType({})

        setProductSelectedQuantity({})      // NU-specific
        setCpiProductSelectedQuantity({})   // CPI-specific

        if(pricingModel === PRICING_MODEL_NAMED_USER) {
            setTotals([])
            setNamedUserNumber(DEFAULT_NAMED_USER_PLATFORM_USERS_NUMBER)

            if(resetFlow) { // when switching Opps, we do not need to reset the flow state e.g. if already in Upsell
                setUseNewOpportunity(false)
                setFlowType(DEFAULT_FLOW_STATE)
                setQuoteType(QUOTE_FLOW_TYPE_ORIGINAL)
                setContractId('')
            }
            setResetTerm('')
            setOpportunitiesFilterTypes([OPPORTUNITY_TYPE_ORIGINAL])
            if(resetBillingDetails) {
                //setBillingDetails([])
                setBillingDetails({['billing_period']: getBillingDetailsFieldDefaultValue(pricing, 'billing_period')})
            } else {
                // override any hardcoded values from previous pricing model
                setBillingDetails({...billingDetails, ['effective_date']: defaultEffectiveDate});
            }
        } else if (pricingModel === PRICING_MODEL_VELOCITY) {
            setTotals( {...totals, [TOTALS_COMPONENT_KEY_VELOCITY_PACKAGES]: 0})
        } else if (pricingModel === PRICING_MODEL_ALWAYS_ON_ADVISOR) {
            setTotals( {...totals, [TOTALS_COMPONENT_KEY_ALWAYS_ON_ADVISOR_PACKAGES]: 0})
        } else if (pricingModel === PRICING_MODEL_CPI) {

            setHideDiscounts(true)

            // CPI uses it's Own variable-level totals
            console.log('QBApp.js : resetting CPI Totals data to 0\'s')
            setCpiAutomationLevelTotal(0)
            setCpiAddOnsTotal(0)
            setCpiExpertServicesRecurrentTotal(0)
            setCpiExpertServicesOnetimeTotal(0)

        }
    }

    const getExpertServicesDiscount = () => {
        switch(pricingModel) {
            case PRICING_MODEL_NAMED_USER:
                return discounts['expert_services'] ? discounts['expert_services'] : 0;
                break;
            case PRICING_MODEL_CPI:
                return discounts['cpi_expert_services'] ? discounts['cpi_expert_services'] : 0;
                break;
            default:
                return 0;
                break;
        }
    }

    /**
     *  @returns {{}|*}
     *  @example {LP-MS1: 400, LP-MS17: 50, LP-MS2: 320, LPPS-00007: 1}
     */
    const getProductsSelectedQuantity = () => {

        switch(pricingModel) {
            case PRICING_MODEL_NAMED_USER:
                return Object.keys(productSelectedQuantity).length > 0 ? productSelectedQuantity : {}
                break;
            case PRICING_MODEL_CPI:
                return Object.keys(cpiProductSelectedQuantity).length > 0 ? cpiProductSelectedQuantity : {}
                break;
            default:
                return {};
                break;
        }
    }

    const contactInfo = {
        "hide_discounts": "hide_discounts", // hideDiscounts ? "hide_discounts" : null,
        "ammendContract": contractId ? contractId : null,  // "amend" is misspelled for legacy reasons :)

        "cpi_pooling_frequency": pricingModel === PRICING_MODEL_CPI
            ? (
                    billingDetails['pooling_frequency']
                ? billingDetails['pooling_frequency'].value
                : getBillingDetailsFieldDefaultValue(pricing, 'pooling_frequency')?.value
             )
            : null,

        "billing_period": billingDetails['billing_period'] ?
            billingDetails['billing_period'].value
            : getBillingDetailsFieldDefaultValue(pricing, 'billing_period')?.value,

        "subscription_term": getSubscriptionTerm(),

        "payment_terms": billingDetails['payment_terms'] ?
            billingDetails['payment_terms'].value
            : getBillingDetailsFieldDefaultValue(pricing, 'payment_terms')?.value,

        "payment_method": billingDetails['payment_method'] ?
            billingDetails['payment_method'].value
            : getBillingDetailsFieldDefaultValue(pricing, 'payment_method')?.value,

        "auto_renew": billingDetails['auto_renew'] ?
            billingDetails['auto_renew'].value
            : getBillingDetailsFieldDefaultValue(pricing, 'auto_renew')?.value,

        "upon_signature": //('upon_signature' in billingDetails && billingDetails['upon_signature'])
            ('effective_date' in billingDetails && billingDetails['effective_date'] === BILLING_EFFECTIVE_DATE_UPON_SIGNATURE_INPUT)
            ? 'on' : 'off' // upon_signature=off for all other cases as of Summer 2022 (previous logic: (flowType[QUOTE_FLOW_FLAG_IS_RENEWAL] ? 'off' : 'on'))
        ,
        "effective_date":
            flowType[QUOTE_FLOW_FLAG_IS_RENEWAL]
            ? getContractRenewalDate(contract)
            :
                ('effective_date' in billingDetails
                    // if the placeholder "Upon Signature" is used, override it with today's date
                    && billingDetails['effective_date'] !== BILLING_EFFECTIVE_DATE_UPON_SIGNATURE_INPUT
                )
                ? billingDetails['effective_date']
                : defaultEffectiveDate,

        //"effective_date_upon_signature": "Upon Signature",

        "pricing_valid": 'pricing_valid' in billingDetails
            ? billingDetails['pricing_valid']
            : defaultPricingValidUntil
    }

    const monthlyQuoteBreakdown = {
        "platform_users_discount": (
            pricingModel === PRICING_MODEL_NAMED_USER && discounts['users']) ? discounts['users'] : 0,
        "ai_automation_discount":
            (pricingModel === PRICING_MODEL_NAMED_USER && discounts['ai'] ) ? discounts['ai'] : 0,
        "expert_services_ongoing_discount": getExpertServicesDiscount(),
        "onetime_discount": 0,
        "velocity_packages_discount":
            (pricingModel === PRICING_MODEL_VELOCITY && discounts['velocity'])
            ? discounts['velocity'] : 0,
        "managed_services_discount":
            (pricingModel === PRICING_MODEL_ALWAYS_ON_ADVISOR && discounts[TOTALS_COMPONENT_KEY_ALWAYS_ON_ADVISOR_PACKAGES])
                ? discounts[TOTALS_COMPONENT_KEY_ALWAYS_ON_ADVISOR_PACKAGES] : 0,
        // "industries": 0,
        "product_package": (pricingModel === PRICING_MODEL_NAMED_USER && namedUserTotal) ? namedUserTotal : 0,
        "number_users": (pricingModel === PRICING_MODEL_NAMED_USER && namedUserNumber) ? namedUserNumber : 0,
        "conversation_builder": 0,
        "ai_automation": (pricingModel === PRICING_MODEL_NAMED_USER && totals['ai']) ? totals['ai'] : 0,
        "velocity_packages": (pricingModel === PRICING_MODEL_VELOCITY && totals['velocity']) ? totals['velocity'] : 0,
        "managed_services": (pricingModel === PRICING_MODEL_ALWAYS_ON_ADVISOR&& totals[TOTALS_COMPONENT_KEY_ALWAYS_ON_ADVISOR_PACKAGES]) ? totals[TOTALS_COMPONENT_KEY_ALWAYS_ON_ADVISOR_PACKAGES] : 0,

        "partner_success": (pricingModel === PRICING_MODEL_NAMED_USER
            && servicesMonthlyCostByType['partner_success']) ? servicesMonthlyCostByType['partner_success'] : 0,

        "on_demand_advisor": (pricingModel === PRICING_MODEL_NAMED_USER
            && servicesMonthlyCostByType['on_demand_advisor']) ? servicesMonthlyCostByType['on_demand_advisor'] : 0,

        "customer_success": (pricingModel === PRICING_MODEL_NAMED_USER
                && servicesMonthlyCostByType['customer_success']) ? servicesMonthlyCostByType['customer_success'] : 0,
        "outsourced_labor": (pricingModel === PRICING_MODEL_NAMED_USER
                && servicesMonthlyCostByType['outsourced_labor']) ? servicesMonthlyCostByType['outsourced_labor']: 0,
        "ps_subscription": ((pricingModel === PRICING_MODEL_NAMED_USER || pricingModel === PRICING_MODEL_CPI)
                && servicesMonthlyCostByType['ps_subscription']) ? servicesMonthlyCostByType['ps_subscription']: 0,


        "sms_options_long": (pricingModel === PRICING_MODEL_NAMED_USER && smsSelectedQuantity['sms_options_long'])
                ? smsSelectedQuantity['sms_options_long'] : "0" //"1"
        ,
        "sms_options_short": (pricingModel === PRICING_MODEL_NAMED_USER && smsSelectedQuantity['sms_options_short'])
                ? smsSelectedQuantity['sms_options_short']: "0" //"2"
        ,
        "sms_options_vanity": (pricingModel === PRICING_MODEL_NAMED_USER && smsSelectedQuantity['sms_options_vanity'])
                ? smsSelectedQuantity['sms_options_vanity']: "0 "// "3"
        ,
        "whatsapp_options_number": (pricingModel === PRICING_MODEL_NAMED_USER && smsSelectedQuantity['whatsapp_options_number'])
                ? smsSelectedQuantity['whatsapp_options_number']: "0"  // "4"
    }

    const onetimeQuoteBreakdown = {
        "implementations": (pricingModel === PRICING_MODEL_NAMED_USER && totals['onetime']) ? totals['onetime'] : 0,
    }

    const quoteSelectionsBreakDown = {
        "cpi_product": {
            "value": (pricingModel === PRICING_MODEL_CPI ? ('LP-00506') : '')
        },
        "cpi_discount_tier": {
            "value": (pricingModel === PRICING_MODEL_CPI ? cpiCreditsCost : {})
        },
        "cpi_overage_product": {
            "value": (pricingModel === PRICING_MODEL_CPI ? ('LP-00288 Usage') : '')
        },

        "cpi_overage_cost": {
            "value": pricingModel === PRICING_MODEL_CPI ? cpiOverageCost : 0
        },

        "cpi_product_discount": {
            "value": (pricingModel === PRICING_MODEL_CPI && discounts['cpi_billing_period_total']) ? discounts['cpi_billing_period_total'] : 0
        },

        "cpi_interaction_discount_schedule" : {
            "value": (pricingModel === PRICING_MODEL_CPI) ? cpiCreditsCost : {}
        },
        "cpi_interaction_discount": {
            "value": (pricingModel === PRICING_MODEL_CPI && discounts['cpi_billing_period_total']) ? discounts['cpi_billing_period_total'] : 0
        },
        "cpi_overage_product_discount": {
            "value": (pricingModel === PRICING_MODEL_CPI && discounts['cpi_overage_cost']) ? discounts['cpi_overage_cost'] : 0
        },
        "cpi_automation_packages_discount": {
            "value": (pricingModel === PRICING_MODEL_CPI && discounts['cpi_automation']) ? discounts['cpi_automation'] : 0
        },
        "cpi_addons_discount": {
            "value": (pricingModel === PRICING_MODEL_CPI && discounts['cpi_addons']) ? discounts['cpi_addons'] : 0
        },
        "cpi_expert_services_discount": {
            "value": (pricingModel === PRICING_MODEL_CPI && discounts['cpi_expert_services']) ? discounts['cpi_expert_services'] : 0
        },
        "cpi_onetime_training_discount": {
            "value": (pricingModel === PRICING_MODEL_CPI && discounts['cpi_onetime_training']) ? discounts['cpi_onetime_training'] : 0
        },
        "cpi_onetime_configuration_discount": {
            "value": (pricingModel === PRICING_MODEL_CPI && discounts['cpi_onetime_configuration']) ? discounts['cpi_onetime_configuration'] : 0
        },
        "cpi_onetime_deployment_discount": {
            "value": (pricingModel === PRICING_MODEL_CPI && discounts['cpi_onetime_deployment']) ? discounts['cpi_onetime_deployment'] : 0
        },

        "cpi_automation_packages": {
            "value": (pricingModel === PRICING_MODEL_CPI
                ? (
                    Object.keys(cpiSelected).length > 0 && Object.keys(cpiSelected)[0] !== 'none'
                        ? Object.keys(cpiSelected)[0]
                        : []
                  )
                : [])
            // ["LP-00366"] or ["none"]
        },
        "cpi_addons": {
            "value": (pricingModel === PRICING_MODEL_CPI ? Object.keys(cpiAddOnsSelected) : [])
            // ["LP-00389", "LPRC-00002"]
        },

        // cpi Onetime @see implementations e.g. Object.keys(onetimeSelected)
        // cpi Recurrent see getFinalQuoteSelections()

        "cpi_credits_number": {
            "value": (pricingModel === PRICING_MODEL_CPI && cpiCreditsNumber) ? cpiCreditsNumber : 0
            // "50000"
        },
        "cpi_product_selected_quantity": {
            "value":  (pricingModel === PRICING_MODEL_CPI ?
                (Object.keys(cpiProductSelectedQuantity).length > 0 ? cpiProductSelectedQuantity : {})
                : {})
            // {LP-MS1: 400, LP-MS17: 50, LP-MS2: 320}
        },
        "cpi_onetime": {
            "value": pricingModel === PRICING_MODEL_CPI
                ? Object.keys(onetimeSelected)
                : []
            // CPI  onetimeSelected e.g.:
            // {LP-MS1: {…}, LP-MS2: {…}}
            //
            // LP-MS1
            // {attachment: null, default_quantity: 400, descripti…}
            //
            // LP-MS2
            // {attachment: null, default_quantity: 320, descripti…}
        },
        "cpi_onetime_training": {
            "value": pricingModel === PRICING_MODEL_CPI
                ? getFilteredItems(onetimeSelected, 'cpi_training', 'productCode')
                : []
        },
        "cpi_onetime_configuration": {
            "value": pricingModel === PRICING_MODEL_CPI
                ? getFilteredItems(onetimeSelected, 'cpi_configuration', 'productCode')
                : []
        },
        "cpi_onetime_deployment": {
            "value": pricingModel === PRICING_MODEL_CPI
                ? getFilteredItems(onetimeSelected, 'cpi_deployment', 'productCode')
                : []
        },

        "monthly_discount": {
            "value": "0"    // legacy, keep as 0
        },
        "platform_users_discount": {
            "value": (pricingModel === PRICING_MODEL_NAMED_USER && discounts['users']) ? discounts['users'] : 0,
        },

        "velocity_packages": {
            "value":
            (pricingModel === PRICING_MODEL_VELOCITY ? velocitySelected : ''),
        },
        "velocity_packages_discount": {
            "value": (pricingModel === PRICING_MODEL_VELOCITY && discounts['velocity']) ? discounts['velocity'] : 0,
        },

        "managed_services": {
            "value":
                (pricingModel === PRICING_MODEL_ALWAYS_ON_ADVISOR ? alwaysOnAdvisorSelected : ''),
        },
        "managed_services_discount": {
            "value": (pricingModel === PRICING_MODEL_ALWAYS_ON_ADVISOR && discounts[TOTALS_COMPONENT_KEY_ALWAYS_ON_ADVISOR_PACKAGES]) ? discounts[TOTALS_COMPONENT_KEY_ALWAYS_ON_ADVISOR_PACKAGES] : 0,
        },

        "ai_automation_discount": {
            "value": (pricingModel === PRICING_MODEL_NAMED_USER && discounts['ai']) ? discounts['ai'] : 0,
        },
        "expert_services_ongoing_discount": {
            "value": getExpertServicesDiscount()
        },
        "ai_automation": {
            "value":
                (pricingModel === PRICING_MODEL_NAMED_USER ? Object.keys(aiSelected) : [])
            // ["concierge"]
        },
        "implementations": {
            "value": pricingModel === PRICING_MODEL_NAMED_USER || pricingModel === PRICING_MODEL_CPI
                ? Object.keys(onetimeSelected)
                : []
            // ["guided_messaging", "guided_sms", "whatsapp"]
        },
        "implementations_selected_quantity": { // NU and CPI
            "value":  getProductsSelectedQuantity()
        },
        "industries": {
            "value": selectedIndustry?.value ?? null   // "Entertainment & Media"
        },
        "onetime_discount": {
            "value": (pricingModel === PRICING_MODEL_NAMED_USER && discounts['onetime']) ? discounts['onetime'] : 0,
            // "4"
        },
        "number_users": {
            "value": (pricingModel === PRICING_MODEL_NAMED_USER && namedUserNumber) ? namedUserNumber : 0
            // "5"
        },
        "product_package": {
            "value":  (pricingModel === PRICING_MODEL_NAMED_USER ? namedUserPlatformLevel.key : '')
            // "premier"
        },
        "sms_options_long": {
            "value":  (pricingModel === PRICING_MODEL_NAMED_USER && smsSelectedQuantity['sms_options_long'])
                ? smsSelectedQuantity['sms_options_long'] : "0" //"1"
        },
        "sms_options_short": {
            "value":  (pricingModel === PRICING_MODEL_NAMED_USER && smsSelectedQuantity['sms_options_short'])
                ? smsSelectedQuantity['sms_options_short']: "0" //"2"
        },
        "sms_options_vanity": {
            "value":  (pricingModel === PRICING_MODEL_NAMED_USER && smsSelectedQuantity['sms_options_vanity'])
                ? smsSelectedQuantity['sms_options_vanity']: "0 "// "3"
        },
        "whatsapp_options_number": {
            "value":  (pricingModel === PRICING_MODEL_NAMED_USER && smsSelectedQuantity['whatsapp_options_number'])
                ? smsSelectedQuantity['whatsapp_options_number']: "0"  // "4"
        },
        /*
        "billing_period": {
            "value": "0"
        },
        "subscription_term": {
            "value": "0"
        },
        "payment_terms": {
            "value": "0"
        },
        "payment_method": {
            "value": "0"
        },
        */
    }

    const hasPsSubscriptionSelected = () => {
        return (
            PS_SUBSCRIPTION_PRODUCT_CODE in productSelectedQuantity
                && productSelectedQuantity[PS_SUBSCRIPTION_PRODUCT_CODE] > 0
            )
        ||  (PS_SUBSCRIPTION_PRODUCT_CODE in cpiProductSelectedQuantity
                && cpiProductSelectedQuantity[PS_SUBSCRIPTION_PRODUCT_CODE] > 0
            )
    }

    /**
     * adds Recurrent Expert Services eg:
     * customer_success and outsourced_labor if selected
     */
    function getFinalQuoteSelections () {
        let finalQuoteSelections = {...quoteSelectionsBreakDown}

        // e.g. 'customer_success', 'outsourced_labor', 'ps_subscription', etc
        for (let key in monthlyServicesInitialState){
            // console.log('QBApp.js::getFinalQuoteSelections, checking key, servicesMonthlySelected : ' + key, servicesMonthlySelected)
            if (key in servicesMonthlySelected) {

                if(servicesMonthlySelected[key].value){ // .value meaning it was selected from a drop down ,menu
                    finalQuoteSelections[key] = {value: servicesMonthlySelected[key].value};

                } else if (servicesMonthlySelected[key].type === 'ps_subscription' // ps_subscription is a numeric input field (not a drop down)
                    && hasPsSubscriptionSelected()
                ) {


                    // only add it to the final payload if the qty > 0
                    //if ((servicesMonthlySelected[key].productCode in productSelectedQuantity && productSelectedQuantity[servicesMonthlySelected[key].productCode] > 0)
                    // || (servicesMonthlySelected[key].productCode in cpiProductSelectedQuantity && cpiProductSelectedQuantity[servicesMonthlySelected[key].productCode] > 0))
                    // {
                        finalQuoteSelections[key] = {
                            value: servicesMonthlySelected[key].productCode // e.g. LPPS-00007
                        }
                    // }
                }

            }
        }
        console.log('QBApp.js::getFinalQuoteSelections, returning: ', finalQuoteSelections)
        return finalQuoteSelections;
    }


    const quoteData = {
        quote: {
            monthly: monthlyQuoteBreakdown,
            onetime: onetimeQuoteBreakdown,
            fixed: {
                "sms_options_long": Object.entries(pricing).length > 0 ? parseFloat(smsSelectedQuantity['sms_options_long'] * pricing.sms_options_long.price) : 0,
                "sms_options_short": Object.entries(pricing).length > 0 ? parseFloat(smsSelectedQuantity['sms_options_short'] * pricing.sms_options_short.price): 0,
                "sms_options_vanity": Object.entries(pricing).length > 0 ? parseFloat(smsSelectedQuantity['sms_options_vanity'] * pricing.sms_options_vanity.price) : 0
            }
        },
        contact: contactInfo,
        opportunity: "Id" in selectedOpportunity ? selectedOpportunity.Id : '', // do not submit Oppty.Id if we are supposed to create a new one
        accountId: selectedAccount.Id, // an account ID
        selections: getFinalQuoteSelections(),
        upsell: flowType[QUOTE_FLOW_FLAG_IS_UPSELL] || false,
        is_new_upsell: (useNewOpportunity && flowType[QUOTE_FLOW_FLAG_IS_UPSELL]) || false,
        is_implementation_only: false,
        is_rapid_deploy: window.qb.is_rapid_deploy || false,
        is_renewal: flowType[QUOTE_FLOW_FLAG_IS_RENEWAL] || false,
        is_new_renewal: (useNewOpportunity && flowType[QUOTE_FLOW_FLAG_IS_RENEWAL]) || false,
        is_flat_renewal: false,
        is_upsell_co_term: flowType[QUOTE_FLOW_FLAG_IS_UPSELL_CO_TERM] || false,
        is_upsell_reset: flowType[QUOTE_FLOW_FLAG_IS_UPSELL_RESET_TERM] | false,
        order_quote_type: quoteType,
        billing_contact_id: billingDetails.sf_contacts ? billingDetails.sf_contacts.value : '',
        discount_justification: requireDiscountJustification ? discountJustification : null,  // don't submit unless it's required
        update_opp_close_date: inlineOpportunityUpdates['update_opp_close_date'] ?? null,
        update_opp_lead_source: inlineOpportunityUpdates['update_opp_lead_source'] ?? null,
        update_opp_conversational_commerce_grid: inlineOpportunityUpdates['update_opp_conversational_commerce_grid'] ?? null,
        update_account_vat: inlineOpportunityUpdates['update_account_vat'] ?? null,
        update_account_reg: inlineOpportunityUpdates['update_account_reg'] ?? null,
        update_account_industry: // update account industry if we have a new selection
            (selectedIndustry
            && selectedIndustry.value
            && Object.entries(apiData).length > 0
            && apiData.account.LP_Industry__c != selectedIndustry.value) ? selectedIndustry.value : null,
        pricing_model: pricingModel,
        totals : totals,
        discounts : discounts
    }


    const handleScroll = e => {
        //console.log('window.pageYOffset is ' + window.pageYOffset)
        setFloatWhenScrolling(window.pageYOffset > 150)

        if(isSubmitButtonUnreachable()){
            setShowSmallScrollableQuote(true)
            console.log('isSubmitButtonUnreachable = TRUE, setShowSmallScrollableQuote(true)');
        }/* else if (isSubmitButtonReachable()){
            setShowSmallScrollableQuote(false)
            console.log('isSubmitButtonReachable = TRUE, setShowSmallScrollableQuote(false)');
        }*/
    }

    const handleResize = e => {

        setBrowserWindowSize(getBrowserWindowWidth())

        if(isSubmitButtonUnreachable()){
            setShowSmallScrollableQuote(true)
            console.log('isSubmitButtonUnreachable = TRUE, setShowSmallScrollableQuote(true)');
        } else if (isSubmitButtonReachable()){
            setShowSmallScrollableQuote(false)
            console.log('isSubmitButtonReachable = TRUE, setShowSmallScrollableQuote(false)');
        }
    }

    /*
        const handleScroll = () => {
            console.log('window.pageYOffset is ' + window.pageYOffset)
            setFloatWhenScrolling(window.pageYOffset > 150)
        }
    */

    const isFormDataValid = () => {

        console.log('validating the order object about to be submitted:', quoteData)

        console.log('pricingModel & velocitySelected, alwaysOnAdvisorSelected: '
            , {
            pricingModel: pricingModel,
            velocitySelected: velocitySelected,
            alwaysOnAdvisorSelected: alwaysOnAdvisorSelected
        })

        if(Object.entries(missingDataPanelErrors).length > 0){
            missingDataPanelErrors[Object.keys(missingDataPanelErrors)[0]].open() // popup the first error
            return false;
/*
        } else if(pricingModel === PRICING_MODEL_CPI && cpiSelected === ''){
            info_modal('Missing Information', 'Please select a Conversational Cloud Automation package')
            return false;
*/
        } else if(pricingModel === PRICING_MODEL_CPI && !(cpiCreditsNumber>0)){
            info_modal('Missing Information', 'Please select number of interaction credits')
            return false;

        } else if(pricingModel === PRICING_MODEL_VELOCITY && velocitySelected === ''){
            info_modal('Missing Information', 'Please select a package')
            return false;

        } else if(pricingModel === PRICING_MODEL_ALWAYS_ON_ADVISOR && alwaysOnAdvisorSelected === ''){
            info_modal('Missing Information', 'Please select a package')
            return false;

        } else if(Object.entries(selectedOpportunity).length === 0 && !useNewOpportunity){
            info_modal('Missing Information', 'Please select an Opportunity')
            return false;

        } else if(hasPsSubscriptionSelected() && getSubscriptionTerm() < 12){
            info_modal('Subscription Term',
                'The Subscription Term must be 12 or more months for PS Subscription Offerings to be selected')
            return false;

        } else if (requireDiscountJustification && discountJustification.length < 3) {
            let buttons = {
                ok: {
                    text: "OK",
                    btnClass: 'btn-primary',
                    keys: ['enter'],
                    action: function () {
                        //   $('#discount_justification').focus(); // TODO LEGACY
                    }
                }
            }
            info_modal('Missing Information', 'Please provide a discount justification', buttons);
            return false;

        } else if (!('sf_contacts' in billingDetails) || !billingDetails.sf_contacts || !billingDetails.sf_contacts.value) {
            let buttons = {
                ok: {
                    text: "OK",
                    btnClass: 'btn-primary',
                    keys: ['enter'],
                    action: function () {
                        //   $('#discount_justification').focus(); // TODO LEGACY
                    }
                }
            }
            info_modal('Missing Information', 'Missing a billing contact', buttons);
            return false;
        }

        return true;
    }

    const handleTimeout = () => {
        closeLoadingScreen();
        window.qb.ajax_popup = 'queued for processing';
        info_modal(
            'Quote queued for processing...',
            'Your Quote is taking longer than expected to build and has been queued for processing, please check your "Recent Orders" page in a few minutes'
        )

        // re-enable the overlay close button
        //$('a.close').prop('disabled', null).removeClass('disabled');
    }

    const closeLoadingScreen = () => {
        $('.jconfirm').remove(); // close other popups
        $('.loading-screen').hide();
        $('.jab').remove();
        $('#submit-quote').text('Build Quote').prop('disabled', false);
    }

    const handleSessionTimeout = () => {
        closeLoadingScreen();
        let buttons = {
            ok: {
                text: "Reload Page",
                    btnClass: 'btn-primary',
                    keys: ['enter'],
                    action: function () {
                    location.reload(); // reload the page
                }
            }
        };

        window.qb.ajax_popup = 'session timed out';
        error_modal(
            'Expired session',
            'Your session has expired, please reload the page and retry.',
            buttons
        );
    }
    const handleGeneralProcessingError = () => {

        window.qb.loading_screen.dismiss();
        // window.qb.loading_screen.done('#');
        $('#submit-quote').text('Build Quote').prop('disabled', false);

        let msg = (data && typeof data.responseJSON !== "undefined" && typeof data.responseJSON.message !== "undefined")
            ? data.responseJSON.message
            : data;


        if (typeof msg === 'string' || msg instanceof String) {
            if (msg.includes('CSRF token mismatch')) {
                msg = 'Your session has expired, please reload the page and retry.';

            } else if (msg.includes('Salesforce')) {
                msg = 'A Salesforce error has occurred, please re-submit the quote by clicking on the "Build Quote" button again.';

            } else {
                msg = "Contact the administrator. \n\n" + msg;
            }
        }

        if (window.qb.ajax_popup == null) { // no other popups open yet
            $('.jconfirm').remove(); // close other popups
            info_modal('Error creating quote.', msg);
            closeLoadingScreen();

            let isUpsell = flowType[QUOTE_FLOW_FLAG_IS_UPSELL];   // TODO - support upsell
            let isNewUpsell = isUpsell && useNewOpportunity;// TODO - support upsell

            let event_category = isUpsell ? 'upsell' : 'regular';
            let event_label = isUpsell ? ((isUpsell && isNewUpsell) ? 'new upsell' : 'existing upsell') : '';
            window.qb.logQuote(false, event_category, event_label);

        }

    }

    const postQuoteData = (event) => {

        console.log('in postQuoteData()')
        event.preventDefault();

        if (!isFormDataValid()) {
            console.log('postQuoteData isFormDataValid() validation failed');
            return;
        }

        // TODO rm LEGACY - "$(" and "window."
        if(useNewOpportunity){
            window.qb.load_chat_bubble_3 = true
        } else if(inlineOpportunityUpdates['update_opp_close_date'].length > 0) {
            // prevent legacyJS from triggering this popup if we already collected this data inline (yellow box)
            window.qb.load_chat_bubble_3 = false
        }

        $('#submit-quote').text('Building Quote...').prop('disabled', true);
        window.qb.loading_screen.display();
        $('a.close').click(function (e) {
            e.preventDefault();
            window.qb.loading_screen.dismiss();
        }).prop('disabled', true).addClass('disabled');


        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'X-CSRF-TOKEN': window.csrf_token
            },
            body: JSON.stringify(quoteData)
        }
        fetch('/agent/order', requestOptions).then(async response => {
            const data = await response.json();

            // check for error response
            if (!response.ok) {

                switch (response.status) {
                    case 504:
                        handleTimeout()
                        break
                    case 419:
                        handleSessionTimeout()
                        break
                    default:
                        handleGeneralProcessingError()
                        break
                }


                // get error message from body or default to response status
                const error = (data && data.message) || response.status;
                return Promise.reject(error);
            }

            // TODO: LEGACY -- populates the required legacy field for chat bubble updates
            //  to process Opportunity close date, stage updates,etc
            window.qb.order_id = data.id;

            // follow up request (upon success)
            fetch('/agent/order/status/' + data.id,
                {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'X-CSRF-TOKEN': window.csrf_token
                    }
                }
            ).then(async response => {
                const orderStatus = await response.json();

                // check for error response
                if (!response.ok) {

                    switch (response.status) {
                        case 504:
                            handleTimeout()
                            break
                        case 419:
                            handleSessionTimeout()
                            break
                        default:
                            handleGeneralProcessingError()
                            break
                    }
                    $('a.close').prop('disabled', null).removeClass('disabled');

                    // get error message from body or default to response status
                    const error = (orderStatus && orderStatus.message) || response.status;
                    return Promise.reject(error);
                }
                if (orderStatus.ready === false) {
                    handleTimeout();
                    return;
                }


                console.log('billingDetails: ', billingDetails);
                console.log('CC link: ' + data.url + data.confirmation_url);

                // ALL GOOD
                window.qb.loading_screen.done(data.url, (billingDetails.payment_method && (billingDetails.payment_method.value === 'card')) ? data.confirmation_url : null);
                $('#submit-quote').text('Build Quote').prop('disabled', false);

                let isUpsell = flowType[QUOTE_FLOW_FLAG_IS_UPSELL];
                let isNewUpsell = useNewOpportunity;

                let event_category = isUpsell ? 'upsell' : 'regular';
                let event_label = isUpsell ? ((isUpsell && isNewUpsell) ? 'new upsell' : 'existing upsell') : '';
                window.qb.logQuote(true, event_category, event_label);
                $('a.close').prop('disabled', null).removeClass('disabled');

            })


        }).catch(error => {
            closeLoadingScreen();
            console.error('There was an error!', error);
            info_modal('Error creating quote', ''+ "\n\nContact the administrator.")

        });
    }

    useEffect(() => {
        if (!isSupportedBrowser()) {
            error_modal('Unsupported Browser', 'Your web browser is currently not supported by Quote Builder. Please use Google Chrome or Firefox. Thank you!')
            $("body *").prop('disabled', true)
            return false
        }
    })

    // Similar to componentDidMount and componentDidUpdate:
    useEffect(() => {
        console.log('QBApp.js useEffect() 2 for selectedOpportunity change -- calling resetPreExistingQuoteData for updatedOpportunity')
        resetPreExistingQuoteData(pricingModel,true, false)
    },[selectedOpportunity])

    // Similar to componentDidMount and componentDidUpdate:
    useEffect(() => {

        if (!scrollListenerAdded) {
            setScrollListenerAdded(true);
            window.addEventListener('scroll', (e) => handleScroll(e));
            window.addEventListener('resize', (e) => handleResize(e));

            console.log('QbApp::useEffect() document.body.clientWidth:', document.body.clientWidth)
            handleResize() // on load
        }

        console.log('QBApp.js useEffect() discounts: ', discounts);


        // require a discount if we have a discount value for any of the used pricing models (non-reset)?

        console.log('QBApp.js useEffect() getTotalKeysByPricingModel(pricingModel)', getTotalKeysByPricingModel(pricingModel))



        /*
        let requireDiscount = Object.values(getTotalKeysByPricingModel(pricingModel))
            .some(function (discountKey) {
                return (discountKey in discounts) && (discounts[discountKey] >=
                    (isPartner(selectedAccount) ? minimumDiscountApprovalThresholdPartner : minimumDiscountApprovalThresholdDirect))
            })
        */
        // require a discount justification
        let requireDiscount = Object.keys(discounts)
            .some(function(key){
                return (key !== TOTALS_COMPONENT_KEY_CPI_OVERAGE_COST) // exclude OVERAGE MARKUP (not a discount)
                && discounts[key] >=
                    (isPartner(selectedAccount)
                        ? minimumDiscountApprovalThresholdPartner
                        : minimumDiscountApprovalThresholdDirect
                    )
            });

        console.log('QBApp.js useEffect() requireDiscount ', requireDiscount)


        /*
        let requireDiscount = false;
        let discountKey =
            pricingModel === PRICING_MODEL_ALWAYS_ON_ADVISOR
                ? 'managed_services' // discount and totals keys all use the pricing model names except this pricing model for legacy reasons
                : pricingModel;
        if(discountKey in discounts && discounts[discountKey] >=
            (isPartner(selectedAccount)
                ? minimumDiscountApprovalThresholdPartner
                : minimumDiscountApprovalThresholdDirect
            )
        ) {
            requireDiscount = true;
        }
        */

        setRequireDiscountJustification(requireDiscount);
        // TODO rm dependency -- used by window.qb.loading_screen.done() in QBApp.js via quote_builder.js
        // it's legacy hook to is_discount_justification_required() function in quote_builder.js
        // which controls whether or not to show OF and CC links after a quote is build based on discount approval needs
        window.requireDiscount = requireDiscount;

        console.log('QBApp.js useEffect() minimum discount threshold: ',
            isPartner(selectedAccount) ? minimumDiscountApprovalThresholdPartner : minimumDiscountApprovalThresholdDirect)
        console.log('QBApp.js useEffect() requireDiscountJustification: ', requireDiscount)
        console.log('QBApp.js useEffect() discounts: ', discounts)
        console.log('QBApp.js useEffect() pricingModel: ', pricingModel)
        console.log('QBApp.js useEffect() getTotalKeysByPricingModel(pricingModel): ', getTotalKeysByPricingModel(pricingModel))


        // Update the document title using the browser API
        // document.title = `opportunities count: ${opportunities.length}`;
        /*
        console.log(`QBApp.js:useEffect() apiData : `, apiData);
        console.log(`QBApp.js:useEffect() selected Account in QBApp.js::useEffect() :` + selectedAccount);
        console.log(`QBApp.js:useEffect() opportunities count: ${opportunities.length}`);
        console.log(`QBApp.js:useEffect() currency : `, currency);
        console.log(`QBApp.js:useEffect() defaultNamedUserPlatformLevel : `, defaultNamedUserPlatformLevel);
        console.log(`QBApp.js:useEffect() namedUserPlatformLevels : `, namedUserPlatformLevels);
        console.log(`QBApp.js:useEffect() pricing : `, pricing);
        */

        $('.collapse-option').unbind('click')
        $('.collapse-option').click(function(){
            $(this).toggleClass('collapsed');
            $(this).parent().next().slideToggle();
        });

    }, [discounts, selectedAccount, pricingModel])


    function changeLeftNavVisible(flag) {

        console.log('Setting setLeftNavVisible(' + (flag ? 'true' : 'false') + ') via changeLeftNavVisible()');
        setLeftNavVisible(flag);
    }

    function changeSelectedAccount(apiData) {

        if (Object.entries(apiData).length > 0) {

            let accountData = apiData.account

            // hydrate the selectedAccount object with additional flags & data as needed:
            accountData['isEnterpriseUser'] = isEnterpriseUser(apiData.account)
            accountData['isPartner'] = isPartner(apiData.account)
            accountData['isDirect'] = isDirect(apiData.account)
            accountData['hasValidContract'] = hasValidContract(apiData.account.contracts)
            accountData['hasRenewalOpportunities'] = hasRenewalOpportunities(apiData.opportunities);
            accountData['filteredOpportunities']= {};
            accountData.filteredOpportunities[OPPORTUNITY_TYPE_ORIGINAL]
                = getOpportunitiesByType(apiData.opportunities, OPPORTUNITY_TYPE_ORIGINAL);
            accountData.filteredOpportunities[OPPORTUNITY_TYPE_UPSELL]
                = getOpportunitiesByType(apiData.opportunities, OPPORTUNITY_TYPE_UPSELL);
            accountData.filteredOpportunities[OPPORTUNITY_TYPE_RENEWAL]
                = getOpportunitiesByType(apiData.opportunities, OPPORTUNITY_TYPE_RENEWAL);

            setSelectedAccount(accountData);
            console.log('selectedAccount : ', accountData);

            let industries = [];
            for (let [key, value] of Object.entries(accountData.industries)) {
                let item = {};
                item.value = value;
                item.label = key;
                industries.push(item);
            }

            // console.log('selectedAccount.industries.mapped : ', industries_tmp);
            setIndustries(industries)

            console.log('selectedIndustry set to : ', accountData.LP_Industry__c)
            setSelectedIndustry({'label': accountData.LP_Industry__c, 'value': accountData.LP_Industry__c})

        }
    }

    function updatePlatformUserData(apiData, currency) {
        if (Object.entries(apiData).length > 0) {
            // Named User Packages

            // console.log('updatePlatformUserData() apiData : ', apiData);
            // console.log('updatePlatformUserData() apiData[\'pricing\'] : ', apiData['pricing']);

            // User Platform defaults and list building:
            let default_package_key = apiData['pricing'][currency]['product_package']['default'];
            // let defaultNamedUserPlatformLevel = '';
            let platform_levels = [];

            for (let [key, value] of Object.entries(apiData['pricing'][currency]['product_package']['options'])) {
                let item = {};
                item.key = key;
                item.value = value.productId;
                item.price = adjustPriceForDirectOrPartner(apiData.account, value.price);
                item.label = value.title.replace('$$price$', apiData['pricing'][currency]['currency_symbol'] + item.price)
                item.level = value.title.replace(' - $$price$ per user', '');
                platform_levels.push(item);
                if (key === default_package_key) {
                    setDefaultNamedUserPlatformLevel(item);
                    console.log('updatePlatformUserData() default_platform_level set to: ', item);
                }
            }
            setNamedUserPlatformLevels(platform_levels);
            console.log('QBApp.js updatePlatformUserData() platform_levels:', platform_levels);
            console.log('QBApp.js updatePlatformUserData() using currency: ' + currency);
            return platform_levels;


        } else {
            console.log('QBApp.js updatePlatformUserData() apiData : NONE (yet))');
        }
    }

    /*
        function waitForApiData(data, caller, callback){
            if(!("pricing" in data)) {

                (async () => {
                    while (!("pricing" in apiData)) {

                        console.log('apiData....................', apiData);
                        console.log(": waiting for API data to be available for "+caller+" in waitForAccountData() ...");
                        await new Promise(resolve => setTimeout(resolve, 200));
                    }
                    console.log(": got API data in waitForApiData(), proceeding...");
                    callback();
                })();
            } else {
                callback();
            }
        }
    */

    function updateCurrency(currencyIsoCode, pricingData={}) {

        console.log('QBApp.js in updateCurrency()')

        if (!currencyIsoCode) {
            console.log('MISSING currencyIsoCode in updateCurrency(), DEFAULTING TO USD!!!!!!!!!!!!!!!')
        }

        currencyIsoCode = currencyIsoCode ? currencyIsoCode : 'USD';

        setCurrency(currencyIsoCode);

        //waitForApiData(apiData, 'QBApp.js::updateCurrency()', function() {
        if(Object.entries(pricingData).length > 0) {
            // use the passed in optional pricing object (before it's available from useState)

            setCurrencySymbol(pricingData[currencyIsoCode]['currency_symbol']);
            console.log('QBApp.js updateCurrency() setCurrencySymbol() to ' + pricingData[currencyIsoCode]['currency_symbol']);
            setPricing(pricingData[currencyIsoCode]);
            console.log('QBApp.js updateCurrency() setPricing() to '+currencyIsoCode, pricingData[currencyIsoCode]);


        } else if ("pricing" in apiData) {
            console.log('QBApp.js updateCurrency() to ' + currencyIsoCode);
            setCurrencySymbol(apiData['pricing'][currencyIsoCode]['currency_symbol']);
            console.log('QBApp.js updateCurrency() setCurrencySymbol() to ' + apiData['pricing'][currencyIsoCode]['currency_symbol']);
            setPricing(apiData['pricing'][currencyIsoCode]);
            console.log('QBApp.js updateCurrency() setPricing() to '+currencyIsoCode, apiData['pricing'][currencyIsoCode]);
        } else {
            console.log('QBApp.js updateCurrency() -- apiData[\'pricing\'] NOT set just yet...:', apiData)
            console.log('QBApp.js updateCurrency() -- pricing:', pricing)
        }
        //});

    }

    function changeOpportunities(apiData) {
        let opportunities = apiData.opportunities
        setOpportunities(opportunities);
        if (Object.keys(opportunities).length > 0) {
            let defaultOpp = opportunities[0];
            defaultOpp.label = defaultOpp.Name;
            defaultOpp.value = defaultOpp.Id;


            if(!validateSalesforceOpportunityDataIntegrity(defaultOpp)){
                return false
            }

            setSelectedOpportunity(defaultOpp);

            updateCurrency(defaultOpp.CurrencyIsoCode, apiData.pricing);

            console.log('QbApp.js:changeOpportunities() SETTING......... : props.updateCurrency() to currency : '
                + defaultOpp.CurrencyIsoCode,
                apiData.pricing[defaultOpp.CurrencyIsoCode]
            )


        } else {


            // default to account currency whenever we did not get a currency from the Opportunity
            let currencyIsoCode = apiData.account.CurrencyIsoCode
                ? apiData.account.CurrencyIsoCode
                : defaultCurrencyIsoCode;

            console.log('QbApp.js:changeOpportunities() SETTING......... : props.setPricing() to currency : '
                + currencyIsoCode,
                apiData.pricing[currencyIsoCode]
            )
            updateCurrency(currencyIsoCode, apiData.pricing);
        }
    }

    /**
     * TODO: MOVE TO UTIL
     * Is specified element fully visible in view port (or above/below viewport)
     * @param el
     * @returns {boolean|boolean}
     */
    function isScrolledIntoView(el) {
        if(!el){
            return true
        }
        let rect = el.getBoundingClientRect();
        let elemTop = rect.top;
        let elemBottom = rect.bottom;

        // Only completely visible elements return true:
        let isVisible = (elemTop >= 0) && (elemBottom <= window.innerHeight);
        // Partially visible elements return true:
        //isVisible = elemTop < window.innerHeight && elemBottom >= 0;
        return isVisible;
    }

    /**
     * TODO: MOVE TO UTIL
     * detect if user is not able to see/reach the Submit button because of a small viewport
     */
    function isSubmitButtonUnreachable(){
                // scrolled to the bottom
        return isScrolledIntoView(document.getElementById('footerCheck'))
                // but not seeing the Submit button e.g. when view port is tiny (small res. screens)
        && !isScrolledIntoView(document.getElementById('submit-quote'))

    }

    /**
     * TODO: MOVE TO UTIL
     * detect if user is not able to see/reach the Submit button because of a small viewport
     */
    function isSubmitButtonReachable(){
        // scrolled to the submit button
        return isScrolledIntoView(document.getElementById('submit-quote'))
    }

    /**
     *
     * @param newPricingModel
     * @param additionalSettings
     * @returns {boolean}
     */
    const handlePricingModelChange = (newPricingModel, additionalSettings={} ) => {

        console.log('handlePricingModelChange() newPricingModel = ' + newPricingModel + 'w/ additionalSettings: ', additionalSettings)
        setPricingModelAdditionalSettings(additionalSettings);

/*
        if(newPricingModel === PRICING_MODEL_CPI){
            setPricingModel(PRICING_MODEL_NAMED_USER)
            selectMenuItem(PRICING_MODEL_NAMED_USER)
            console.log('pricingModel set to ' + PRICING_MODEL_NAMED_USER)
            return false
        } else
 */
        if(validatePricingModelChange(pricingModel, newPricingModel)) {
            resetPreExistingQuoteData(newPricingModel, additionalSettings.resetBillingDetails ?? false)
            setPricingModel(newPricingModel)
            selectMenuItem(newPricingModel)
            console.log('pricingModel set to ' + newPricingModel)
            return true

        } else {
            selectMenuItem(pricingModel)
            console.log('pricingModel is still ' + pricingModel)
            return false
        }
    }

    function validatePricingModelChange(oldPricingModel, newPricingModel) {
        if(newPricingModel === PRICING_MODEL_ALWAYS_ON_ADVISOR) {
            if (selectedAccount.isPartner) {
                let msg = 'Thank you for using Quote Builder, you selected a Partner account and at this time QB does not support Always On Advisor pricing model for Partner accounts';
                info_modal('Unsupported Option', msg)
                setPricingModel(PRICING_MODEL_NAMED_USER) // reset back to default
                return false
            }
        } else if(newPricingModel === PRICING_MODEL_VELOCITY) {

            /**
             * As per ahecker on 2020.05.14:
             * if Account.Type=Client and/or there is valid contact, then no VELOCITY
             * if ANY other Account.Type except Reseller Partner, show VELOCITY
             * (all within context of we only get to this logic for Enterprise)
             */

            if (selectedAccount.hasValidContract || selectedAccount.is_client) {
                let msg = 'Thank you for using Quote Builder, the account you selected is an Existing Customer and at this time QB only supports Velocity Packages for New Logo';
                info_modal('Pricing Model', msg)
                setPricingModel(PRICING_MODEL_NAMED_USER) // reset back to default
                return false
            } else if (selectedAccount.isPartner || selectedAccount.is_partner_indirect_sale) {
                let msg = 'Thank you for using Quote Builder, you selected a Partner account and at this time QB only supports Velocity Packages for Direct accounts';
                info_modal('Pricing Model', msg)
                setPricingModel(PRICING_MODEL_NAMED_USER) // reset back to default
                return false
            } else if (selectedAccount.RecordTypeId === '0121J000001QGl7QAG') {    // CAO / LPA / LP Auto
                // non-evergreen contract for
                let msg = 'Selected Automotive account type is not supported for selected pricing model at this time'
                info_modal('Pricing Model', msg)
                setPricingModel(PRICING_MODEL_NAMED_USER) // reset back to default
                return false
            }
        }
        return true
    }

    return (


        <>

            {(browserWindowSize < MIN_WINDOW_WIDTH_FOR_WARNING) &&
            <>

                <div id="missing_data" style={{marginBottom: -16, textAlign:"center"}}>
                    <div className="card alert-warning border mb-3">
                        <div id="browser_window_size_message" className="card-body">
                                Your browser window is smaller than optimal to use QB.
                                Please Maximize your browser window or Zoom Out.

                        </div>
                    </div>
                </div>

            </>
            }

            {
                selectedAccount.Id &&
                (
                pricingModel === PRICING_MODEL_WHATSAPP_USAGE &&
                <WhatsAppSummaryPanel
                    floatWhenScrolling={floatWhenScrolling}
                    showSmallScrollableQuote={showSmallScrollableQuote}
                    isWaitingForData={isWaitingForData}
                    selectedAccount={selectedAccount}
                    pricingModelAdditionalSettings={pricingModelAdditionalSettings}
                    whatsAppUsage={apiData.recent_whatsapp_usage}
                    billingDetails={billingDetails}
                    isWaitingForData={isWaitingForData}

                />
                ||
                <MyQuoteSummaryPanel
                    pricing={pricing}
                    discounts={discounts}
                    setHideDiscounts={setHideDiscounts}
                    hideDiscounts={hideDiscounts}
                    setDiscounts={setDiscounts}
                    totals={totals}
                    namedUserTotal={namedUserTotal}
                    setNamedUserTotal={setNamedUserTotal}
                    currencySymbol={currencySymbol}
                    namedUserNumber={namedUserNumber}
                    namedUserPlatformLevel={namedUserPlatformLevel}
                    aiSelected={aiSelected}
                    servicesMonthlySelected={servicesMonthlySelected}
                    onetimeSelected={onetimeSelected}
                    smsSelectedQuantity={smsSelectedQuantity}
                    postQuoteData={postQuoteData}
                    setRecurrentTotal={setRecurrentTotal}
                    setOnetimeTotal={setOnetimeTotal}
                    quoteType={quoteType}
                    floatWhenScrolling={floatWhenScrolling}
                    showSmallScrollableQuote={showSmallScrollableQuote}
                    isWaitingForData={isWaitingForData}
                    useNewOpportunity={useNewOpportunity}
                    flowType={flowType}
                    pricingModel={pricingModel}
                    selectedOpportunity={selectedOpportunity}
                    selectedAccount={selectedAccount}
                    productSelectedQuantity={productSelectedQuantity}

                    // used for CpiMyQuote:
                    // servicesMonthlySelected={servicesMonthlySelected} // re-used across models
                    cpiCreditsNumber={cpiCreditsNumber}
                    cpiCreditsCost={cpiCreditsCost}
                    setCpiCreditsNumber={setCpiCreditsNumber}
                    cpiProductSelectedQuantity={cpiProductSelectedQuantity}
                    setCpiProductSelectedQuantity={setCpiProductSelectedQuantity}
                    cpiAddOnsSelected={cpiAddOnsSelected}
                    setCpiAddOnsSelected={setCpiAddOnsSelected}
                    cpiSelected={cpiSelected}
                    setCpiSelected={setCpiSelected}
                    cpiOnetimeSelected={cpiOnetimeSelected}
                    setCpiOnetimeSelected={setCpiOnetimeSelected}
                    billingDetails={billingDetails}
                    cpiAutomationLevelTotal={cpiAutomationLevelTotal}
                    setCpiAutomationLevelTotal={setCpiAutomationLevelTotal}
                    cpiAddOnsTotal={cpiAddOnsTotal}
                    setCpiAddOnsTotal={setCpiAddOnsTotal}
                    cpiExpertServicesRecurrentTotal={cpiExpertServicesRecurrentTotal}
                    setCpiExpertServicesRecurrentTotal={setCpiExpertServicesRecurrentTotal}
                    cpiExpertServicesOnetimeTotal={cpiExpertServicesOnetimeTotal}
                    setCpiExpertServicesOnetimeTotal={setCpiExpertServicesOnetimeTotal}
                    cpiExpertServicesOnetimeTrainingTotal={cpiExpertServicesOnetimeTrainingTotal}
                    setCpiExpertServicesOnetimeTrainingTotal={setCpiExpertServicesOnetimeTrainingTotal}
                    cpiExpertServicesOnetimeConfigurationTotal={cpiExpertServicesOnetimeConfigurationTotal}
                    setCpiExpertServicesOnetimeConfigurationTotal={setCpiExpertServicesOnetimeConfigurationTotal}
                    cpiExpertServicesOnetimeDeploymentTotal={cpiExpertServicesOnetimeDeploymentTotal}
                    setCpiExpertServicesOnetimeDeploymentTotal={setCpiExpertServicesOnetimeDeploymentTotal}
                    getBillingPeriodPrice={getBillingPeriodPrice}
                    cpiPeriodMinimumFee={cpiPeriodMinimumFee}

                    // used by data export
                    contactInfo={contactInfo}

                />
                )
            }


            <div className={"col-lg-2 col-md-3 sidebar-nav navPanelPadding" + (floatWhenScrolling ? ' fix-sidebar' : '')}>

                <div className="sidebar-body">
                    <div className="row">

                        <div className="marginRight20">
                            <div className="marginBottom8 bold" style={{marginTop:8}}>Let's get Started!</div>
                            <div className="marginBottom8">Account:</div>

                            <Account
                                isVisible={leftNavVisible}
                                changeLeftNavVisible={changeLeftNavVisible}
                                apiToken={apiToken}
                                changeSelectedAccount={changeSelectedAccount}
                                // changeApiData={changeApiData}
                                setSelectedAccount={setSelectedAccount}
                                getApiData={getApiData}
                            />

                        </div>


                        <PricingModelNavigation
                            leftNavVisible={leftNavVisible}
                            isWaitingForData={isWaitingForData}
                            apiData={apiData}
                            pricingModel={pricingModel}
                            handlePricingModelChange={handlePricingModelChange}
                            selectedAccount={selectedAccount}
                            selectedIndustry={selectedIndustry}
                            flowType={flowType}
                        />



                    </div>
                </div>
            </div>


            <div className="pane-main">
                <div className="container mainPane">

                    {

                        !selectedAccount.Id &&
                        <>
                            <LandingPanel
                                topOpportunities={window.top_opps}
                                isWaitingForData={isWaitingForData}
                            />
                        </>


                        || selectedAccount.Id &&


                        <>
                        <div>
                            <div className="col-sm-12 inner-column" style={{paddingBottom:30}}>


                                {

                                    (pricingModel === PRICING_MODEL_WHATSAPP_USAGE

                                        &&
                                        !isWaitingForData &&
                                        <WhatsAppUsagePanel
                                            whatsAppUsage={apiData.recent_whatsapp_usage}
                                            pricingModelAdditionalSettings={pricingModelAdditionalSettings}
                                            setPricingModelAdditionalSettings={setPricingModelAdditionalSettings}
                                            billingDetails={billingDetails}
                                            setBillingDetails={setBillingDetails}
                                            billingContacts={billingContacts}
                                            apiData={apiData}
                                        />
                                    )

                                    || (
                                        <Opportunity
                                            accountId={selectedAccount.Id}
                                            opportunities={opportunities}
                                            industries={industries}
                                            selectedIndustry={selectedIndustry}
                                            setSelectedIndustry={setSelectedIndustry}
                                            currency={currency}
                                            updateCurrency={updateCurrency}
                                            selectedAccount={selectedAccount}
                                            apiData={apiData}
                                            setPricing={setPricing}

                                            // Opportunity selection, changes currency, which in turn changes the Platform currency
                                            setNamedUserPlatformLevels={setNamedUserPlatformLevels}
                                            setDefaultNamedUserPlatformLevel={setDefaultNamedUserPlatformLevel}
                                            updatePlatformUserData={updatePlatformUserData}
                                            selectedOpportunity={selectedOpportunity}
                                            setSelectedOpportunity={setSelectedOpportunity}
                                            setPricingModel={setPricingModel}

                                            setOpportunitiesFilterTypes={setOpportunitiesFilterTypes}
                                            opportunitiesFilterTypes={opportunitiesFilterTypes}

                                            isWaitingForData={isWaitingForData}

                                            // for ContractPanel:
                                            flowType={flowType}
                                            setFlowType={setFlowType}
                                            quoteType={quoteType}
                                            setQuoteType={setQuoteType}
                                            useNewOpportunity={useNewOpportunity}
                                            setUseNewOpportunity={setUseNewOpportunity}
                                            contract={contract}
                                            setContract={setContract}
                                            setContractId={setContractId}
                                            resetTerm={resetTerm}
                                            setResetTerm={setResetTerm}
                                            billingDetails={billingDetails} // used when we reset contract term in ContractPanel
                                            setBillingDetails={setBillingDetails} // used when we reset contract term in ContractPanel

                                            // for MissingDataPanel:
                                            setInlineOpportunityUpdates={setInlineOpportunityUpdates}
                                            missingDataPanelErrors={missingDataPanelErrors}
                                            setMissingDataPanelErrors={setMissingDataPanelErrors}
                                            inlineOpportunityUpdates={inlineOpportunityUpdates}

                                            handlePricingModelChange={handlePricingModelChange}
                                        />
                                    )
                                }

                                {pricingModel === PRICING_MODEL_NAMED_USER
                                && <>
                                    <PlatformUsers
                                        currency={currency}
                                        selectedAccount={selectedAccount}
                                        apiData={apiData}
                                        namedUserNumber={namedUserNumber}
                                        setNamedUserNumber={setNamedUserNumber}
                                        setNamedUserPlatformLevel={setNamedUserPlatformLevel}
                                        namedUserPlatformLevels={namedUserPlatformLevels}
                                        defaultNamedUserPlatformLevel={defaultNamedUserPlatformLevel}
                                        setNamedUserPlatformLevels={setNamedUserPlatformLevels}
                                        setDefaultNamedUserPlatformLevel={setDefaultNamedUserPlatformLevel}

                                        updatePlatformUserData={updatePlatformUserData}
                                        costMonthlyNamedUser={costMonthlyNamedUser}
                                        setCostMonthlyNamedUser={setCostMonthlyNamedUser}
                                        currencySymbol={currencySymbol}
                                        selectedOpportunity={selectedOpportunity}
                                        totals={totals}
                                        setTotals={setTotals}
                                        namedUserTotal={namedUserTotal}
                                        setNamedUserTotal={setNamedUserTotal}
                                        flowType={flowType}
                                    />

                                    <AiPanel
                                        flowType={flowType}
                                        currencySymbol={currencySymbol}
                                        namedUserNumber={namedUserNumber}
                                        pricing={pricing}
                                        totals={totals}
                                        setTotals={setTotals}
                                        aiSelected={aiSelected}
                                        setAiSelected={setAiSelected}
                                        apiData={apiData}
                                    />


                                    <ServicesPanel
                                        currencySymbol={currencySymbol}
                                        pricing={pricing}
                                        apiData={apiData}
                                        selectedAccount={selectedAccount}
                                        selectedOpportunity={selectedOpportunity}
                                        selectedIndustry={selectedIndustry}
                                        servicesMonthlySelected={servicesMonthlySelected}
                                        setServicesMonthlySelected={setServicesMonthlySelected}
                                        onetimeSelected={onetimeSelected}
                                        setOnetimeSelected={setOnetimeSelected}
                                        productSelectedQuantity={productSelectedQuantity}
                                        setProductSelectedQuantity={setProductSelectedQuantity}
                                        totals={totals}
                                        setTotals={setTotals}
                                        smsSelectedQuantity={smsSelectedQuantity}
                                        setSmsSelectedQuantity={setSmsSelectedQuantity}
                                        namedUserPlatformLevel={namedUserPlatformLevel}
                                        filters={apiData['filters']}
                                        servicesMonthlyCostByType={servicesMonthlyCostByType}
                                        setServicesMonthlyCostByType={setServicesMonthlyCostByType}
                                        flowType={flowType}

                                        cpiExpertServicesOnetimeTrainingTotal={cpiExpertServicesOnetimeTrainingTotal}
                                        setCpiExpertServicesOnetimeTrainingTotal={setCpiExpertServicesOnetimeTrainingTotal}
                                        cpiExpertServicesOnetimeConfigurationTotal={cpiExpertServicesOnetimeConfigurationTotal}
                                        setCpiExpertServicesOnetimeConfigurationTotal={setCpiExpertServicesOnetimeConfigurationTotal}
                                        cpiExpertServicesOnetimeDeploymentTotal={cpiExpertServicesOnetimeDeploymentTotal}
                                        setCpiExpertServicesOnetimeDeploymentTotal={setCpiExpertServicesOnetimeDeploymentTotal}
                                    />
                                  </>
                                }

                                {pricingModel === PRICING_MODEL_CPI
                                && <>
                                    <CpiInteractionCostPanel
                                        apiData={apiData}
                                        pricing={pricing}
                                        currency={currency}
                                        currencySymbol={currencySymbol}
                                        billingDetails={billingDetails}
                                        setBillingDetails={setBillingDetails}
                                        resetTerm={resetTerm}
                                        flowType={flowType}
                                        contract={contract}
                                        pricingModel={pricingModel}
                                        totals={totals}
                                        setTotals={setTotals}
                                        discounts={discounts}
                                        setDiscounts={setDiscounts}
                                        cpiCreditsNumber={cpiCreditsNumber}
                                        setCpiCreditsNumber={setCpiCreditsNumber}
                                        cpiCreditsCost={cpiCreditsCost}
                                        setCpiCreditsCost={setCpiCreditsCost}
                                        setCpiOverageCost={setCpiOverageCost}
                                        selectedAccount={selectedAccount}
                                        setCpiPeriodMinimumFee={setCpiPeriodMinimumFee}
                                        cpiPeriodMinimumFee={cpiPeriodMinimumFee}
                                    />

                                    {/* CPI Automation panel was removed for QB 3.5.0 CPI 2.0 updates
                                    <CpiAutomationLevelPanel
                                        currencySymbol={currencySymbol}
                                        pricing={pricing}
                                        totals={totals}
                                        setTotals={setTotals}
                                        cpiSelected={cpiSelected}
                                        setCpiSelected={setCpiSelected}
                                        apiData={apiData}
                                        selectedOpportunity={selectedOpportunity}
                                        billingDetails={billingDetails}
                                        getBillingPeriodPrice={getBillingPeriodPrice}
                                        cpiAutomationLevelTotal={cpiAutomationLevelTotal}
                                        setCpiAutomationLevelTotal={setCpiAutomationLevelTotal}
                                        selectedAccount={selectedAccount}

                                    />
                                    */
                                    }


                                    <CpiAddOnsPanel
                                        flowType={flowType}
                                        currencySymbol={currencySymbol}
                                        pricing={pricing}
                                        totals={totals}
                                        setTotals={setTotals}
                                        cpiAddOnsSelected={cpiAddOnsSelected}
                                        setCpiAddOnsSelected={setCpiAddOnsSelected}
                                        apiData={apiData}
                                        billingDetails={billingDetails}
                                        getBillingPeriodPrice={getBillingPeriodPrice}
                                        cpiAddOnsTotal={cpiAddOnsTotal}
                                        setCpiAddOnsTotal={setCpiAddOnsTotal}
                                        selectedAccount={selectedAccount}

                                    />
                                    <CpiServicesPanel
                                        currencySymbol={currencySymbol}
                                        pricing={pricing}
                                        apiData={apiData}
                                        selectedAccount={selectedAccount}
                                        selectedOpportunity={selectedOpportunity}
                                        selectedIndustry={selectedIndustry}
                                        servicesMonthlySelected={servicesMonthlySelected}
                                        setServicesMonthlySelected={setServicesMonthlySelected}
                                        onetimeSelected={onetimeSelected}
                                        setOnetimeSelected={setOnetimeSelected}
                                        totals={totals}
                                        setTotals={setTotals}
                                        cpiProductSelectedQuantity={cpiProductSelectedQuantity}
                                        setCpiProductSelectedQuantity={setCpiProductSelectedQuantity}
                                        namedUserPlatformLevel={namedUserPlatformLevel}
                                        filters={apiData['filters']}
                                        servicesMonthlyCostByType={servicesMonthlyCostByType}
                                        setServicesMonthlyCostByType={setServicesMonthlyCostByType}
                                        flowType={flowType}
                                        billingDetails={billingDetails}
                                        getBillingPeriodPrice={getBillingPeriodPrice}
                                        cpiExpertServicesRecurrentTotal={cpiExpertServicesRecurrentTotal}
                                        setCpiExpertServicesRecurrentTotal={setCpiExpertServicesRecurrentTotal}
                                        cpiExpertServicesOnetimeTotal={cpiExpertServicesOnetimeTotal}
                                        setCpiExpertServicesOnetimeTotal={setCpiExpertServicesOnetimeTotal}
                                        cpiExpertServicesOnetimeTrainingTotal={cpiExpertServicesOnetimeTrainingTotal}
                                        setCpiExpertServicesOnetimeTrainingTotal={setCpiExpertServicesOnetimeTrainingTotal}
                                        cpiExpertServicesOnetimeConfigurationTotal={cpiExpertServicesOnetimeConfigurationTotal}
                                        setCpiExpertServicesOnetimeConfigurationTotal={setCpiExpertServicesOnetimeConfigurationTotal}
                                        cpiExpertServicesOnetimeDeploymentTotal={cpiExpertServicesOnetimeDeploymentTotal}
                                        setCpiExpertServicesOnetimeDeploymentTotal={setCpiExpertServicesOnetimeDeploymentTotal}
                                    />
                                </>
                                }

                                {pricingModel === PRICING_MODEL_VELOCITY
                                    && <>
                                        <VelocityPackagesPanel
                                            currencySymbol={currencySymbol}
                                            pricing={pricing}
                                            totals={totals}
                                            setTotals={setTotals}
                                            velocitySelected={velocitySelected}
                                            setVelocitySelected={setVelocitySelected}
                                            apiData={apiData}
                                            selectedOpportunity={selectedOpportunity}
                                            billingDetails={billingDetails}
                                            setBillingDetails={setBillingDetails}
                                        />
                                    </>
                                }
                                {pricingModel === PRICING_MODEL_ALWAYS_ON_ADVISOR
                                    && <>
                                        <AlwaysOnAdvisorPackagesPanel
                                            currencySymbol={currencySymbol}
                                            pricing={pricing}
                                            totals={totals}
                                            setTotals={setTotals}
                                            alwaysOnAdvisorSelected={alwaysOnAdvisorSelected}
                                            setAlwaysOnAdvisorSelected={setAlwaysOnAdvisorSelected}
                                            apiData={apiData}
                                            selectedOpportunity={selectedOpportunity}
                                        />
                                    </>
                                }

                                {
                                    pricingModel !== PRICING_MODEL_WHATSAPP_USAGE
                                    &&
                                    <BillingPanel
                                        apiData={apiData}
                                        pricing={pricing}
                                        billingDetails={billingDetails}
                                        setBillingDetails={setBillingDetails}
                                        setBillingContacts={setBillingContacts}
                                        resetTerm={resetTerm}
                                        flowType={flowType}
                                        contract={contract}
                                        pricingModel={pricingModel}
                                        velocitySelected={velocitySelected}
                                    />
                                }

                                {   requireDiscountJustification
                                    &&
                                        <DiscountJustificationPanel
                                            discountJustification={discountJustification}
                                            setDiscountJustification={setDiscountJustification}
                                        />

                                }


                            </div>
                        </div>




                        </>
                    }



                </div>
            </div>



        </>



    );


    function getApiData (accountId, defaultAccountData={}){

        // legacy - hides the CC payment link for upsells
        window.qb.is_upsell = false

        if(!accountId){
            console.log ('in useApiData(), got accountId: (blank) , returning false');
            return false;
        }

        console.log ('in useApiData(), got accountId: ' + accountId);
        console.log ('in useApiData(), got defaultAccountData: ', defaultAccountData);

        let url = `/api/pricing/account_v2/${accountId}?api_token=${window.apiToken}`;
        console.log(`fetching ${url}`)

        // props.changeSelectedAccount(initialAccountData);

        //////////////////////////////////
        // DEBUG -- SPEED up loading for DEV
        const LOCAL_DEBUG = false
        if(LOCAL_DEBUG && !window.location.origin.endsWith('.liveperson.com')) { // can only be enabled locally
            // sample url: /api/pricing/account_v2/0011J00001OAxvkQAD?api_token=aCpvYTf60Mpi9Kz67eNqqmRKcVUJYaWvAEffyYmAhsrfr1kJSgWLl0cXucmG
            let data = test_account_api_data;
            console.log('USING TEST API DATA -- useApiData got : ', data);
            setPricing(data.pricing[currency]);
            setApiData(data);
            changeSelectedAccount(data);
            console.log('selectedAccount.value: ' + selectedAccount.value);
            changeOpportunities(data);
            return data;
        }
        //
        ////////////////////////////////////////////////////////////
        setIsWaitingForData(true);


        window.qb.logPricingRequest();

        handlePricingModelChange(PRICING_MODEL_NAMED_USER); // default to NU while waiting for account data
        fetch(url)
            .then((response) => {
                return response.json();
            })
            .then(data => {
                console.log('useApiData got : ', data);

                resetPreExistingQuoteData()

                if(!validateSalesforceAccountDataIntegrity(data.account, data)){
                    return false
                }


                let accountCurrencyIsoCode = data.account.CurrencyIsoCode;
                setApiData(data);
                setPricing(data.pricing[accountCurrencyIsoCode]); // default to Account currency

                handlePricingModelChange(
                    isEnterpriseUser(data.account)
                        ? PRICING_MODEL_VELOCITY
                        : PRICING_MODEL_NAMED_USER
                    , {resetBillingDetails: true})

                // force a currency update based on account change (in addition to Opp switching elsewhere)
                updateCurrency(accountCurrencyIsoCode, data.pricing); // setCurrency, setPricing
                changeSelectedAccount(data);    // setSelectedAccount , setSelectedIndustry
                console.log('selectedAccount.Id: ' + data.account.Id);
                setSelectedAccount(data.account);
                setIsWaitingForData(false);
                changeOpportunities(data); // setSelectedOpportunity updateCurrency (setCurrency, setCurrencyIsoCode, setPricing)

                console.log('QBApp.js::getApiData() - setIsWaitingForData() set to  FALSE')


                legacyChatBubble3(data.account)

                // todo -- move to Account.js:
                // setCenterPanelVisible(true);

                validateIndustry(data.account);

                return data;

            }).catch(error => {
                console.log('ERROR:', error);
                // todo -- move to Account.js:
                //setCenterPanelVisible(false);
            });
    }




    // TODO pull this out elsewhere e.g. object-specific validation
    function hasRenewalOpportunities(opportunities){
        let hasRenewalOpportunities = false;
        opportunities.forEach(function (opportunity) {
            if (opportunity.Type === 'Renewal') {
                hasRenewalOpportunities = true
            }
        })
        return hasRenewalOpportunities
    }

    function getOpportunitiesByType(opportunities, type){
        let filteredOpps = []
        opportunities.forEach(function (opportunity) {
            if (opportunity.Type === type) {
                filteredOpps.push(opportunity)
            }
        })
        return filteredOpps
    }

    // TODO: legacy (rewrite it)
    function validateIndustry(account_data, error_msg=''){
            if (!account_data.LP_Industry__c) {
                let msg = 'It looks like this account is missing an Industry in SFDC. No worries, please provide it here and QB will update it.<br><br>\n' +
                    // force select2 to show above the .jconfirm modal & dark text color for placeholder
                    '<style>.select2-container--open {z-index: 999999999} .select2-container--default .select2-selection--single .select2-selection__placeholder {color: #000000}</style>\n' +
                    '<div id="missing_data_select_industry">\n' +
                    '    <div id="missing_data_close_date" class="card-body">\n' +
                    '        <select name="missing_data_industry" class="missing_data_industry"></select>' +
                    '        <div style="color:#ffffff;padding-top:10px;">'+(error_msg ? error_msg : '') + '</div>'
                '    </div>' +
                '</div>'
                ;
                error_modal('Oopsy Daisy!', msg, null, {},
                    function(){
                        let val = $('select[name="missing_data_industry"]').val();
                        if(!val){
                            let error_msg = 'An industry is required to proceed.';
                            return validateIndustry(account_data, error_msg);
                        }
                        let data = {};
                        data.LP_Industry__c = val;
                        window.qb.updateAccountData(account_data.Id, data); // update the data in SFDC
                        $('select[name="industries"]').val(val).trigger('change');// update the UI

                        // force UI update
                        setSelectedIndustry({'label': data.LP_Industry__c, 'value': data.LP_Industry__c});
                    },
                    true, true
                );
                $('select[name="missing_data_industry"]').focus();
                $('select[name="missing_data_industry"]').width(200).select2();

                for (const index in account_data.industries) {
                    let option = $('<option>');
                    let val = account_data.industries[index];

                    option.attr('value', index).text(val);
                    console.log('adding '+index+' : '+ val + ' to #missing_data_industry');
                    $('select[name="missing_data_industry"]').append(option);
                }
                $('select[name="missing_data_industry"]').select2({
                    placeholder: 'Select an Industry'
                }).val('').trigger('change');


        }
    }

    /**
     * TODO: LEGACY CODE
     * used to display chatbubble3 img based on account info
     */
    function legacyChatBubble3(accountData){

        // default images for bubble3 (overridden for commercial below)
        let img = '';
        // World_Sales_Region__c : one of these [APAC, EMEA, Latin America, or North America]
        if(accountData.World_Sales_Region__c == 'EMEA') {
            img = 'jerry_haywood';
        } else if(accountData.World_Sales_Region__c == 'APAC') {
            img = 'andrew_cannington';
        } else {
            // LATAM + North America
            img = 'adam_canter';
        }
        console.log('QBApp.js::legacyChatBubble3() img: ', img)

        $("#chatbubble3_image").attr("src", "/images/quote-builder/" + img + ".png");

    }
}

/**
 *
 * @param amount
 * @param currencySymbol
 * @returns {string}
 */
export const formatPrice = (amount, currencySymbol='', debug='NOT SET', zeroSymbol=`\u2014`) => {

    if(!amount || isNaN(amount)){
        return zeroSymbol;
    }
    // strip out any commas (formatting might be present)
    amount = amount.toString().replace(',', '');

    if(isNaN(parseFloat(amount))){
        return amount;
    }

    amount = roundUp(Number(truncateFloat(amount)), 2).toFixed(2); // always round up decimals and get 2 decimals here
    // add commas and replace trailing .00's for whole dollar amounts when completely round
    let num = amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",").replace('.00', '');
    num = (!isNaN(parseFloat(num)) && currencySymbol.length > 0) ? (currencySymbol + num) : num;

    // console.log('formatPrice() returning : '+num + ' ( from '+debug + ' ) for amount: ', amount);

    return num;
}

/**
 * @param num The number to round
 * @param precision The number of decimal places to preserve
 *
 * The issue without this func is that parseFloat(1.035).toFixed(2)  =>> "1.03" instead of "1.04"
 *
 */
export const roundUp = (num, decimals=2) => {

    if(isNaN(num) || num === '' || num === null) {
        return 0;
    }

    function util(num,decimals){
        return Number(Math.round(num+'e'+decimals)+'e-'+decimals);
    }
    return util(util(num,decimals+1),decimals);

    // precision = Math.pow(10, precision)
    // * 10 / 10 is to resolve a bug with JavaScript roundUp(1.11) => 1.12 because of 1.13 * 100 = 112.99999999...
    // return Math.ceil(Math.round( num * precision * 10)/10) / precision
}

/**
 * @param num The number to round
 * @param precision The number of decimal places to preserve
 *
 * 1.25100000  ===> 1.26
 * 1.25900000  ===> 1.26
 * 1.25099999  ===> 1.25
 *
 */
export const roundMarkedUpOverage = (num, decidingDecimalPlace=3) => {
    if(countDecimalPlaces(num) >= decidingDecimalPlace){
        // check if the deciding decimal is a zero
        if(num.toString()[num.toString().indexOf(".")+decidingDecimalPlace] === "0"){
            return truncateFloat(num, 2) // return everything before the decidingDecimalPlace
        } else {

            return truncateFloat(num, 2)
                // increment by a cent for non-zero decidingDecimalPlace-nth place
                + (num.toFixed(2) > num.toFixed(3) ? 0.00 : 0.01)
        }

    }
    return roundUp(num, 2)
}

export const countDecimalPlaces = (num) => {

    if(isNaN(num) || num === '' || num === null) {
        return 0;
    }

    let text = num.toString();
    let index = text.indexOf(".");
    return index == -1 ? 0 : (text.length - index - 1);
}

/**
 * @param num The number to round
 * @param precision The number of decimal places to preserve
 * 2.0000009999967006 => 2
 *
 */
export const truncateFloat = (num, precision=2) => {
    return parseFloat(parseFloat(num).toFixed(precision).replace('.00',''))
}


/**
 * @param amount
 * @param currencySymbol
 * @returns {string} e.g. 1,000,000.55 => 1000000.55
 */
export const formatInputPrice = (amount) => {
    // \u2014 is a double dash
    // we are replacing commas greedily here to remove multiple commas from large numbers e.g. 1,000,000
    return formatPrice(amount).replace(`\u2014`, '').replace(/,/g, '');
}


export const arraySum = arr => arr.reduce((a,b) => parseFloat(a) + parseFloat(b), 0)
export const isVowel = x  => (/[aeiouAEIOU]/.test(x.charAt(0)))

export const monthlyServicesInitialState = {
    'customer_success': 0,
    'outsourced_labor': 0,
    'partner_success': 0,
    'on_demand_advisor': 0,
    'cpi_coe': 0,
    'cpi_esp': 0,
    'ps_subscription': 0,
};

export const LEGAL_ENTITY_BV_NETHERLANDS = 'BV Netherlands';

export const QB_AVATAR_IMG = '<img style="border-radius: 50%;height: 100px" src="/images/quote-builder/vader.png">'

export const QUOTE_FLOW_TYPE_ORIGINAL = 'Original'
export const QUOTE_FLOW_TYPE_LPA_MIGRATION = 'LPA Migration'
export const QUOTE_FLOW_TYPE_UPSELL_RESET_EXTEND = 'Upsell & Reset/Extend'
export const QUOTE_FLOW_TYPE_UPSELL_CO_TERM = 'Upsell Co-term'
export const QUOTE_FLOW_TYPE_RENEWAL = 'Renewal'


export const QUOTE_FLOW_FLAG_IS_ORIGINAL = 'isOriginal'
export const QUOTE_FLOW_FLAG_IS_UPSELL = 'isUpsell'
export const QUOTE_FLOW_FLAG_IS_RENEWAL = 'isRenewal'
export const QUOTE_FLOW_FLAG_IS_NEW_RENEWAL = 'isNewRenewal'
export const QUOTE_FLOW_FLAG_IS_FLAT_RENEWAL = 'isFlatRenewal'
export const QUOTE_FLOW_FLAG_IS_UPSELL_CO_TERM = 'isUpsellCoTerm'
export const QUOTE_FLOW_FLAG_IS_UPSELL_RESET_TERM = 'isUpsellResetTerm'

export const DEFAULT_FLOW_STATE = {'isOriginal': true, 'isUpsell': false};
export const DEFAULT_INLINE_OPPORTUNITY_UPDATES = {
    update_opp_close_date: '',
    update_opp_lead_source: '',
    update_account_vat: '',
    update_account_reg: '',
    update_opp_conversational_commerce_grid: '',
}

const DEFAULT_NAMED_USER_PLATFORM_USERS_NUMBER = 5

export const PRICING_MODEL_NAMED_USER = 'commercial'
export const PRICING_MODEL_VELOCITY = 'velocity'
export const PRICING_MODEL_CPI = 'cpi'
export const PRICING_MODEL_ALWAYS_ON_ADVISOR = 'managed'
export const PRICING_MODEL_WHATSAPP_USAGE = 'whatsapp_usage'

export const INDUSTRY_AUTOMOTIVE = 'Automotive'

export const PARTNER_PRICING_MULTIPLIER = 0.7

export const adjustPriceForDirectOrPartner = (selectedAccount, price, returnWholeNumbersIfNoDecimals=true, decimalPlacesForNonWholeNumbers=2) => {

    // console.log('QBApp : adjustPriceForDirectOrPartner price, selectedAccount', price, selectedAccount.isPartner)
    price = parseFloat(price)
    let adjustedPrice = selectedAccount && selectedAccount.isPartner
        ? price * PARTNER_PRICING_MULTIPLIER
        : price

    // console.log('QBApp : adjustPriceForDirectOrPartner adjustedPrice', adjustedPrice)

    return roundUp(adjustedPrice, decimalPlacesForNonWholeNumbers).toFixed(
        (returnWholeNumbersIfNoDecimals && (adjustedPrice % 1 === 0))
            ? 0
            : decimalPlacesForNonWholeNumbers
    )
}

/**
 * keys used for totals and discounts across models
 * @type {function({PRICING_MODEL_ALWAYS_ON_ADVISOR: string[], PRICING_MODEL_VELOCITY: string[], PRICING_MODEL_NAMED_USER: (string)[]}): *}
 */
export const getTotalKeysByPricingModel = (pricingModel) => {
    let totalKeysByPricingModel = []
    totalKeysByPricingModel[PRICING_MODEL_NAMED_USER] = ['users', 'ai', 'expert_services', 'onetime', 'sms'];
    totalKeysByPricingModel[PRICING_MODEL_VELOCITY] = [TOTALS_COMPONENT_KEY_VELOCITY_PACKAGES];
    totalKeysByPricingModel[PRICING_MODEL_ALWAYS_ON_ADVISOR] = [TOTALS_COMPONENT_KEY_ALWAYS_ON_ADVISOR_PACKAGES];
    totalKeysByPricingModel[PRICING_MODEL_CPI] = [TOTALS_COMPONENT_KEY_CPI_BILLING_PERIOD_TOTAL];

    return totalKeysByPricingModel[pricingModel] ?? []

}


export const getDefaultPricingModel = (selectedAccount) => {
    if(selectedAccount.isEnterpriseUser){
        return PRICING_MODEL_VELOCITY
    } else {
        return PRICING_MODEL_NAMED_USER
    }

}



export const info_modal = (title, message, buttons=null, extraValues={}, callback=null, forceOpen=true)  => {
    return error_modal(title, message, buttons, extraValues, callback, forceOpen, true)
}
export const error_modal = (title, message, buttons=null, extraValues={}, callback=null, forceOpen=true,
                            allowCloseWithoutReload=false)  => {
    buttons = buttons || {
        ok: {
            text: "OK",
            btnClass: 'btn-primary',
            keys: ['enter'],
            action: function(){
                if(callback){
                    callback();
                }
                if(!allowCloseWithoutReload) {
                    window.location.reload();
                }
            }
        }
    }
    window.qb.popup_missing_error = $.confirm({
        lazyOpen: true,
        closeIcon: function(){
            if(allowCloseWithoutReload){
                return true; // allow popup to be closed and for the user to stay on the same page without a reload
            } else {
                location.reload(); // reload the page
                return false; // prevent modal from closing
            }
        },
        title: title.includes('<img')
            ? title                                 // custom avatar image was used
            : QB_AVATAR_IMG + '<br><br>' + title,   // use s default avatar image
        theme: 'supervan',
        content: message,
        buttons: buttons,
        columnClass: 'col-md-6', // default's col-md-4 (might be alittle too narrow)
        extraValues: extraValues || {}
    });
    if(forceOpen) {
        window.qb.popup_missing_error.open();
    } else {
        return window.qb.popup_missing_error;
    }


}

export const CPI_CONVERSATIONAL_CLOUD_ADD_ON_ADVANCED_AI_PRODUCT_CODE = 'LP-00507';

export function getDynamicCpiItemPrice (item, totals) {
    return item.productCode === CPI_CONVERSATIONAL_CLOUD_ADD_ON_ADVANCED_AI_PRODUCT_CODE
        ? .3 * totals[TOTALS_COMPONENT_KEY_CPI_BILLING_PERIOD_TOTAL]
        : item.price
}

export const isSupportedBrowser = () => {
    return (
        navigator.userAgent.search("Chrome") >= 0
        || navigator.userAgent.search("Firefox") >= 0
    );
}

export const getBrowserWindowHeight = () => {
    let D = document;
    return Math.max(
        D.body.scrollHeight, D.documentElement.scrollHeight,
        D.body.offsetHeight, D.documentElement.offsetHeight,
        D.body.clientHeight, D.documentElement.clientHeight
    );
}

export const getBrowserWindowWidth = () => {
    let D = document;
    return Math.max(
        D.body.scrollWidth, D.documentElement.scrollWidth,
        D.body.offsetWidth, D.documentElement.offsetWidth,
        D.body.clientWidth, D.documentElement.clientWidth
    );
}



// load react
window.addEventListener("load", function(){
    if (document.getElementById('qb_app')) {
        console.log('React loading...');
        ReactDOM.render(<QBApp />, document.getElementById("qb_app"));
        console.log('React loaded via "qb_app" element Id');
/*
        if (typeof gtag === "undefined") {
            // google analytics block
            const script = document.createElement('script');
            script.src = "https://www.googletagmanager.com/gtag/js?id=UA-277946-52";
            script.async = true;
            document.body.appendChild(script);
            window.dataLayer = window.dataLayer || [];

            function gtag() {
                dataLayer.push(arguments);
            }

            gtag('js', new Date());
            gtag('config', 'UA-277946-52');
            console.log('Google Analytics loaded from React');
        }
*/


    } else {
        console.log('React not able to load...');
    }
});

/*
new Date().toJSON().slice(0, 10)  => "2020-05-29"   pretty cool
*/

