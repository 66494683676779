import React, { useState } from 'react';
import './DiscountJustificationPanel.css';

export default function DiscountJustificationPanel(props) {

    const MAX_CHARS = 500;
    const [charsLeft, setCharsLeft] = useState(MAX_CHARS);

    const handleChange = (event) => {
        let input = event.target.value
        setCharsLeft(MAX_CHARS - input.length)
        props.setDiscountJustification(input.substring(0, MAX_CHARS))
    }

    return (
        <>
            <div className="panel panel-default" id="discount_justification_panel_v2">
                <div className="panel-heading panel-darkgrey">Discount Justification<span
                    className="collapse-option"></span></div>
                <div className="panel-body" style={{padding: '15px 15px 0'}}>
                    <div className="row row-spacer2">
                        <div className="col-xl-12">
                            <p><span id="discount_justification_text_v2_span"></span> <span className="required">*</span> Please
                                provide a justification for the extravagant discount amount 😁 </p>
                            <textarea onChange={handleChange}
                                      className="form-control required"
                                      id="discount_justification_v2"
                                      maxLength={MAX_CHARS}
                                      rows="3"
                                      value={props.discountJustification}
                            />
                            <div className="remainingCharacters">
                                <span id="discount_justification_remain">{charsLeft}</span> characters remaining
                            </div>
                        </div>
                    </div>
                </div>
                <div className="panel-footer">

                </div>
            </div>
        </>

    )

}




