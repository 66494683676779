import React, {useEffect, useState} from 'react';
import './CpiAddOnsPanel.css';
import {formatPrice, arraySum, getDynamicCpiItemPrice} from "../QBApp";

export default function CpiAddOnsPanel(props) {

    const [checkedItems, setCheckedItems] = useState({});

    const handleChange = (isChecked, item) => {
        // updating an object instead of a Map
        let newVal = {...checkedItems, [item.productCode] : isChecked };
        console.log("AddOnsPanel::handleChange() checkedItems: ", newVal);
        console.log("AddOnsPanel::handleChange() setCpiAddOnsSelected: ", item);
        setCheckedItems(newVal);
        //props.setCpiAddOnsSelected(item)

        let selected = [];
        let prices = Object.entries(props.pricing.cpi_addons.options).reduce( (arr, [key, item]) => {
            if (key in newVal && newVal[key] === true) {
                arr.push(props.getBillingPeriodPrice(getDynamicCpiItemPrice(item, props.totals)));
                selected = {...selected, [key]: item}
            }
            props.setCpiAddOnsSelected(selected);
            return arr;
        }, [])


        props.setCpiAddOnsTotal(arraySum(prices));


    }

    const items =  (
               Object.entries(props.pricing).length > 0
            && Object.entries(props.pricing.cpi_addons).length > 0
            && Object.entries(props.pricing.cpi_addons.options).length > 0
        )
        ?
            Object.entries(props.pricing.cpi_addons.options).map( ([key, item]) => (
                <label htmlFor={key} key={key + '_label'} className="price-table">

                    <input type="checkbox" key={key + '_checkbox'} name={key} id={key} value={key} price={props.getBillingPeriodPrice(item.price)} className="price-table"
                           checked={key in checkedItems ? checkedItems[key] : false}
                           onChange={(event) => handleChange(event.target.checked, item)}
                    />
                    {item.title}
                    <span
                        id={key}
                        key={key}
                        value={key + '_span'}
                        price={getDynamicCpiItemPrice(item, props.totals)}
                        className={"price-table" + (checkedItems[key] ? ' active' : '')}
                    >
                        {formatPrice(props.getBillingPeriodPrice(getDynamicCpiItemPrice(item, props.totals)), props.currencySymbol,'AddOnsPanel.js')}
                    </span>

                    {(item.attachment && item.attachment.length > 0) ?
                        <div className="sowImg price-table">
                            <a target="_blank" href={item.attachment}>
                                <img src="/images/quote-builder/guru_logo.png" border="0" className="guruCard" />
                            </a>
                        </div>
                        : ''
                    }
                </label>
            ))
        : ''
    ;


    useEffect(() => {
        console.log('AddOnsPanel::useEffect() props:', props);
        setCheckedItems({});
        props.setCpiAddOnsSelected({});
        console.log('AddOnsPanel::useEffect() reset setCheckedItems');

        /*
        // reset totals
        let totals = {...props.totals};
        totals[TOTALS_COMPONENT_KEY_CPI_AUTOMATION_PACKAGE] = 0;
        totals[TOTALS_COMPONENT_KEY_CPI_ADDONS] = 0;
        totals[TOTALS_COMPONENT_KEY_CPI_EXPERT_SERVICES] = 0;
        totals[TOTALS_COMPONENT_KEY_CPI_EXPERT_ONETIME] = 0;
        props.setTotals(totals);
        */

    },[props.apiData]);


    useEffect(() => {
        Object.entries(props.cpiAddOnsSelected).reduce( (arr, [key, item]) => {
            handleChange(true, item)
        }, [])

    },[props.billingDetails.billing_period]);

    return (

        <>
            <div className="panel panel-default hidden_when_implementation_only clearable_panel" id="panel_ai">
                <div className="panel-heading panel-orange">Conversational Cloud Add Ons

                    {props.selectedAccount && props.selectedAccount.isPartner &&
                    <span className="sub-title partner-fields">
                            &nbsp;&nbsp;(Prices already discounted 30% for partners)
                        </span>
                    }

                    <span className="collapse-option"></span>
                </div>
                <div className="panel-body">
                    { props.flowType.isUpsell &&
                        <div className="quick-tip hr-top-gray-solid">
                            <strong>Quick Tip:</strong> Don't forget to re-add recurring packages this customer had
                            previously
                        </div>
                    }
                    <span className="panel-description"></span>
                    <p className="panel-monthly-cost">{props.billingDetails.billing_period.label} Cost</p>

                    { items }

                </div>
                <div className="panel-footer">
                    <p className="subtotal">Conversational Cloud Add Ons total <span className="ai_auto_total">
                        {formatPrice(props.cpiAddOnsTotal, props.currencySymbol,'AddOnsPanel.js')}
                    </span></p>
                </div>
            </div>
        </>

    )
}

export const TOTALS_COMPONENT_KEY_CPI_ADDONS = 'cpi_addons'


