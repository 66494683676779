import React, { useState, useEffect} from 'react';
import {formatPrice} from "../QBApp";
import Select from "react-select";
import "./WhatsAppUsage.css"

export default function WhatsAppUsagePanel(props) {

    const [selectedMonths, setSelectedMonths] = useState({})
    const [totalSum, setTotalSum] = useState(0)
    const [filteredItems, setFilteredItems] = useState([])

    const handleSelectedMonths = (months) => {

        if(Object.values(months)[0] === undefined){
            console.log('handleSelectedMonths() NOT set to ', months)
            return false
        }
        console.log('handleSelectedMonths() set to ', months)
        setSelectedMonths(months);
        let totalSum = 0;
        let filteredItems = [];
        Object.entries(props.whatsAppUsage.usage)
            .filter((item) => {
                if ((item[1].month + '-' + item[1].year) === props.pricingModelAdditionalSettings.selectedMonth) {
                    console.log('filtered IN', item[1]);
                    totalSum = totalSum + parseFloat(item[1].total);
                    filteredItems.push(item[1]);
                }
            })
        setFilteredItems(filteredItems);
        setTotalSum(totalSum)

        let additionalSettings = {selectedMonth: Object.values(months)[0]};
        props.setPricingModelAdditionalSettings(additionalSettings);


    }

    const handleAccountContactChange = (event) => {
        console.log('WhatsAppUsagePanel.js:handleAccountContactChange() event: ', event);
        let sf_contacts = {'label' : event.Name, 'value' : event.Id}
        props.setBillingDetails({...props.billingDetails, sf_contacts})
    }

    /*
    const monthOptions = [
        {label: '1', value: 1},
        {label: '2', value: 2},
        {label: '3', value: 3},
        {label: '4', value: 4},
        {label: '5', value: 5},
        {label: '6', value: 6},
        {label: '7', value: 7},
        {label: '8', value: 8},
        {label: '9', value: 9},
        {label: '10', value: 10},
        {label: '11', value: 11},
        {label: '12', value: 12},
    ];*/

    const whatsAppUsageMonthsYearsOptions =
        Object.entries(props.apiData).length > 0 &&
        Object.entries(props.apiData.recent_whatsapp_usage.months_years).length > 0
            ? Object.entries(props.apiData.recent_whatsapp_usage.months_years).map(([key, item]) => (
                {value: key, label : item}
            ))
            : {}

    useEffect(()=>{

        handleSelectedMonths([props.pricingModelAdditionalSettings.selectedMonth])


        console.log('WhatsAppUsagePanel::useEffect()(0)')
    },[props.pricingModelAdditionalSettings.selectedMonth])

    useEffect(()=>{
        console.log('WhatsAppUsagePanel::useEffect()(1)')
        console.log(filteredItems);

    },[filteredItems])


    return (
        Object.entries(props.whatsAppUsage.usage).length > 0 &&
        <>


            <div className="panel panel-default" id="panel_account">
                <div className="panel-heading panel-inst"><strong>Before building a usage statement</strong> please select
                    period and contact details.
                </div>
                <div className="panel-body padding1515">
                    <div className="row detail-boxes" disabled={props.isWaitingForData}>

                        <div className="col-sm-5">
                            <div className="inputLabel inputLabelFloatLeft">Usage Period <span className="required">*</span>
                                <Select
                                    // isClearable
                                    placeholder={"Loading..."}
                                    id={"wa_month_year"}
                                    className={"wa_month_year"}
                                    value={
                                        {
                                            value: selectedMonths,
                                            label: props.apiData.recent_whatsapp_usage.months_years[selectedMonths]
                                        }
                                    }
                                    onChange={(e) => handleSelectedMonths([e.value])}
                                    options={whatsAppUsageMonthsYearsOptions}
                                />
                            </div>
                        </div>
                        <div className="col-sm-5" style={{marginBottom:20,marginLeft:138}}>
                            <label htmlFor="sf_contacts">Customer Contact <span
                                className="required">*</span>

                                <Select
                                    id="sf_contacts"
                                    placeholder={"Select customer contact..."}
                                    onChange={(e) => handleAccountContactChange(e)}
                                    isSearchable={false}
                                    value={props.billingDetails['sf_contacts']}
                                    //value={
                                    // billingDetails <=== has the contact ID
                                    //getAccountContacts() ? ((option) => option.isFirstValidOption === true) : {}}
                                    options={props.billingContacts}

                                ></Select>

                                {/*<code>{JSON.stringify(billingContacts)}</code>*/}
                                <p className="error-text">Customer contact is required.</p>
                            </label>


                        </div>
                    </div>

                </div>
                <div className="panel-footer" style={{padding: 0,backgroundColor:'#ffffff', borderTop:0}}></div>
            </div>

            <div className="panel panel-default" id="panel_billing">
                <div className="panel-heading panel-peri">
                    WhatsApp Usage -
                    &nbsp; {getShortMonthNameFromMmDashYyyy(props.pricingModelAdditionalSettings.selectedMonth)}
                    &nbsp; {props.pricingModelAdditionalSettings.selectedMonth.split('-')[1]}

                    <span className="collapse-option"></span>
                </div>
                <div className="panel-body">
                    <div className="row">
                        <div className="col-md-12">

                            <table className="table subs">
                                <thead>
                                <tr>
                                    <th>Send Month</th>
                                    <th style={{textAlign:'right'}}>Units Delivered</th>
                                    <th style={{textAlign:'right'}}>Country/Region</th>
                                    <th style={{textAlign:'right'}}>Unit Price</th>
                                    <th style={{textAlign:'right'}}>Total</th>
                                </tr>
                                </thead>

                                <tbody>
                                {   Object.entries(props.whatsAppUsage.usage).length > 0 &&


                                Object.entries(filteredItems).map((item) => (

                                        <tr key={item[1].id}>
                                            <td>&nbsp;&nbsp;&nbsp;{item[1].month}/{item[1].year}</td>
                                            <td style={{textAlign:'right'}}>{formatPrice(item[1].units)}</td>
                                            <td style={{textAlign:'right'}}>{item[1].destination}</td>
                                            <td style={{textAlign:'right'}}>${item[1].unit_price}</td>
                                            <td style={{textAlign:'right'}}>{formatPrice(item[1].total,'$')}</td>
                                        </tr>

                                ))}
                                </tbody>
                            </table>
                            <h3 style={{padding:"20px 5px 10px 5px", background: "#f2f2f2"}}>
                                <p style={{fontWeight:800,width:600,textAlign:"center", margin: "0 auto 10px"}}>
                                    Total: {formatPrice(totalSum, '$')}
                                </p>
                            </h3>
                        </div>
                    </div>
                </div>
            </div>
        </>

    )

}

/**
 * 10-2022 => Oct
 * @param str
 * @returns {string}
 */
export const getShortMonthNameFromMmDashYyyy = (str) => {

    if(typeof str !== 'undefined') {
        let splitStr = str.split('-')
        return (new Date(splitStr[0] + '-01-' + splitStr[1]).toString().split(' ')[1])
    } else {
        return '';
    }
}



