import React, { useState, useEffect} from 'react';
import Select from "react-select";
import './PlatformUsers.css';
import * as NumericInput from "react-numeric-input";
import {arraySum, formatPrice, adjustPriceForDirectOrPartner} from './QBApp';
import {isPartner} from "../validation/AccountValidation";

// dependencies: isOriginal==true ==> quick-tip hide(), otherwise show
// props: currency, selectedAccount, pricing

export default function PlatformUsers(props) {
    const TOTALS_COMPONENT_KEY = 'users';

    const [namedUserPlatformLevel, setNamedUserPlatformLevel] = useState('');

    useEffect(() => {
        // Update the document title using the browser API
        // console.log('PlatformUsers useEffect() props:', props);

        if(Object.keys(props.namedUserPlatformLevels).length>0){


            if(props.namedUserPlatformLevel === ''){
                // not set yet, set to default
                handleNamedUserPlatformLevelChange(props.namedUserPlatformLevels[1]);
                // console.log('named user platform level: ', props.namedUserPlatformLevel);
            } else if(props.namedUserPlatformLevel !== props.namedUserPlatformLevels[1]){

                /*
                console.log('DEBUG local: ' + props.namedUserPlatformLevel.label +
                ' updating to ' + props.namedUserPlatformLevels[1].label
                + ' while currency is actually: ' + props.currency);
                */
/*
                // extract the default named user platform level from the API data for the Opp's currency
                for(let item of Object.entries(props.namedUserPlatformLevels)){
                    if (item.key === props.apiData['pricing'][props.currency]['product_package']['default']) {
                        // the default is no longer valid (from higher state update)
                        handleNamedUserPlatformLevelChange(item)
                        // console.log('changed named user platform level: ', item);
                    }
                }
*/

                for (let [key, value] of Object.entries(props.apiData['pricing'][props.currency]['product_package']['options'])) {
                    let item = {};
                    item.key = key;
                    item.value = value.productId;
                    item.price = adjustPriceForDirectOrPartner(props.selectedAccount, value.price)
                    item.label = value.title.replace('$$price$', props.apiData['pricing'][props.currency]['currency_symbol'] + item.price)
                    item.level = value.title.replace(' - $$price$ per user', '');
                    if (key === props.apiData['pricing'][props.currency]['product_package']['default']) {

                        // the default is no longer valid (from higher state update)
                        handleNamedUserPlatformLevelChange(item)
                        // console.log('changed named user platform level: ', item);
                    }
                }

            }

        }
    }, [props.selectedOpportunity, props.apiData, props.defaultNamedUserPlatformLevel]);

    // recalculates the total
    function handleNamedUserPlatformLevelChange(selectedOption){
        // console.log('in handleNamedUserPlatformLevelChange(), selected: ', selectedOption);
        props.setNamedUserPlatformLevel(selectedOption);
        setNamedUserPlatformLevel(selectedOption);
        // console.log('in handleNamedUserPlatformLevelChange(), props.currencySymbol: '+ props.currencySymbol);
        // console.log('in handleNamedUserPlatformLevelChange(), selectedOption.price: '+ selectedOption.price);
        let total = selectedOption.price * props.namedUserNumber;
        reCalculateTotal(total);
    }

    // recalculates the total
    function handleNamedUserNumber(selectedOption){
        // console.log('in handleNamedUserNumber(), selected: ', selectedOption);
        props.setNamedUserNumber(selectedOption);
        let total = namedUserPlatformLevel.price * selectedOption;
        reCalculateTotal(total);
    }

    function reCalculateTotal(total){
        props.setNamedUserTotal(total)
    }

    return (
        <>
            <div className="panel panel-default hidden_when_implementation_only clearable_panel" id="panel_users">
                <div className="panel-heading panel-peri">Platform Users
                    {props.selectedAccount.isPartner &&
                        <span className="sub-title partner-fields">
                            &nbsp;&nbsp;(Prices already discounted 30% for partners)
                        </span>
                    }
                    <span className="collapse-option"></span>

                </div>
                <div className="panel-body">
                    <a className="panel-description"
                       href="/images/quote-builder/Solution_Details.pdf" target="_blank">View
                        detailed descriptions</a>
                    <p className="panel-monthly-cost">Monthly Cost</p>


                    {props.flowType.isUpsell &&
                        <div className="quick-tip hr-top-gray-solid">
                            <strong>Quick Tip:</strong> Please select the final total of what you want to sell (ie if the
                            customer has 3 users and you wish to add 2 more, you should select "5")
                        </div>
                    }


                    <div className="row row-spacer1 borderTop0">
                        <div className="col-sm-7 text-left">
                            {/*{'props.apiData['pricing'][props.currency]['product_package']['default']'}*/}
                            <Select
                                // isClearable
                                placeholder={"Loading..."}
                                id={"named_user_platform_level"}
                                value={namedUserPlatformLevel ?? props.namedUserPlatformLevels[1]}
                                onChange={handleNamedUserPlatformLevelChange}
                                isSearchable={false}
                                options={props.namedUserPlatformLevels}
                                styles={{float:"left"}}
                            />

                            <div className="floatLeft" style={{width: 60, minWidth: 60, marginLeft: 20}}>
                                <NumericInput
                                    className="form-control NumericInput userNumberInput"
                                    onChange={handleNamedUserNumber}
                                    value={props.namedUserNumber}
                                    min={ 1 }
                                    step={ 1 }
                                    precision={ 0 }
                                    size={ 5 }
                                    required
                                    inputMode="numeric"
                                    style={{
                                        input: {
                                            height: "38px"
                                        }
                                    }}
                                />
                            </div>
                            <div className="floatLeft text-left" style={{paddingLeft:7, paddingTop: 7}}> Users</div>
                        </div>

                        <div className="col-sm-2 text-left">
                        </div>
                        <div className="col-sm-3">
                            <p className="random-total-label">
                                <span className="total-packages-users price-table">
                                    {formatPrice(props.namedUserTotal, props.currencySymbol, 'PlatformUsers.js')}
                                </span>
                            </p>
                        </div>
                    </div>
                </div>
                <div className="panel-footer">
                    <p className="subtotal">Platform Users total
                        <span className="total-packages-users">
                            {formatPrice(props.namedUserTotal, props.currencySymbol, 'PlatformUsers.js')}
                        </span>
                    </p>
                </div>
            </div>
        </>

    )

}

/*
1px solid #dddddd;
float: left;
background: none;
*/

