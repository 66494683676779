class ContractValidation {}


export function hasValidContract (contracts) {
    let hasValidContract = false;

    console.log('in hasValidContract() with contracts param: ', contracts)

    contracts.forEach(function (contract) {
        if (
            contract.Status == "Active"
            && contract.subscriptions.length > 0 // has line items
            // && (new Date(contract.EndDate).getTime() > new Date().getTime()) // future end date -- unreliable
        ) {
            // if we have at least one active line item, the contract is active
            contract.subscriptions.forEach(function (sub) {
                if (
                    sub.SBQQ__SubscriptionType__c == 'Evergreen' // Evergreen contract e..g no end date
                    || (new Date(sub.SBQQ__EndDate__c).getTime() > new Date().getTime()) // Renewable/Termed
                ) {
                    hasValidContract = true
                    // console.log('contract line item is valid: ' + sub.SBQQ__EndDate__c)
                } else {
                    console.log('contract subscription line item is invalid: ', sub);
                }
            });
        }
    });

    return hasValidContract
}

