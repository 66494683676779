import React, {useEffect, useState} from 'react';
import {formatPrice} from "./QBApp";
import './LandingPanel.css';

export default function LandingPanel(props) {

    const [logoStyle, setLogoStyle] = useState('');

    let i = 0;


    useEffect(() => {
        setLogoStyle((props.isWaitingForData)
            ? ' pulse'
            : ''
        )
    });

    return (

        <>
        <div id="placeholder-logo">

            {!props.isWaitingForData ?
                <>
                <h3 style={{textAlign:"center"}}>Your Top Forecasted Opportunities this Quarter</h3>
                <table className="table opportunityTable">
                    <thead>
                    <tr>
                        <th style={{width:10}}>#</th>
                        <th style={{width:360}}>Opportunity</th>
                        <th style={{width:100}}>Amount</th>
                        <th style={{width:100}}>RMR</th>
                        <th style={{width:100}}>Stage</th>
                        <th style={{width:100}}>Close Date</th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        (props.topOpportunities && Object.entries(props.topOpportunities).length > 0
                                    ? Object.entries(props.topOpportunities).map(([key, topOpp]) => (
                                <tr key={key}>
                                    <th>{++i}</th>
                                    <td className="headerNoWrap">{topOpp.Name}</td>
                                    <td>{formatPrice(topOpp.Amount, topOpp.CurrencyIsoCode)}</td>
                                    <td>{formatPrice(topOpp.Forecast_RMR_Bookings__c, topOpp.CurrencyIsoCode)}</td>
                                    <td className="headerNoWrap">{topOpp.StageName}</td>
                                    <td className="headerNoWrap">{topOpp.CloseDate}</td>
                                </tr>
                            ))
                                :
                                <tr>
                                    <td colSpan="5" style={{overflow:"hidden",whiteSpace:"nowrap",padding:50,textAlign:"center",color:"#444444"}}>
                                        <i>No data available</i>
                                        <hr style={{marginTop:50}} />
                                    </td>
                                </tr>
                        )



                    }
                    </tbody>
                </table>
                </>
                : ''

            }

            <div style={{margin:"50px 0 50px 0"}}>
                <img className={'img-responsive img-center' + logoStyle} style={{width:250}} src="/images/quote-builder/lp_logo_large.png?c=@buster" />
            </div>
        </div>


         </>
    )

}




