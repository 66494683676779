import {
    arraySum, CPI_CONVERSATIONAL_CLOUD_ADD_ON_ADVANCED_AI_PRODUCT_CODE,
    formatInputPrice,
    formatPrice, getDynamicCpiItemPrice,
    roundUp, truncateFloat
} from "../QBApp";
import React, {useEffect, useState} from "react";
import {hr, lineItems} from "../MyQuoteSummaryPanel";
import {
    TOTALS_COMPONENT_KEY_CPI_BILLING_PERIOD_TOTAL,
    TOTALS_COMPONENT_KEY_CPI_OVERAGE_COST,
    TOTALS_COMPONENT_KEY_CPI_OVERAGE_COST_MARKED_UP
} from "./CpiAutomationLevelPanel";
import {getDivisionFactorForBillingPeriod, getFrequencyNumberFromLabel} from "./CpiInteractionCostPanel";

export default function CpiMyQuote(props) {

    /*
    HOC props:
                            totals={props.totals}
                            onetimeTotal={onetimeTotal}
                            discounts={props.discounts}
                            currencySymbol={props.currencySymbol}

                            servicesMonthlySelected={props.servicesMonthlySelected} // re-used across models
                            cpiCreditsNumber={props.cpiCreditsNumber}
                            setCpiCreditsNumber={props.setCpiCreditsNumber}
                            cpiProductSelectedQuantity={props.cpiProductSelectedQuantity}
                            setCpiProductSelectedQuantity={props.setCpiProductSelectedQuantity}
                            cpiAddOnsSelected={props.cpiAddOnsSelected}
                            setCpiAddOnsSelected={props.setCpiAddOnsSelected}
                            cpiSelected={props.cpiSelected}
                            setCpiSelected={props.setCpiSelected}
                            cpiOnetimeSelected={props.cpiOnetimeSelected}
                            setCpiOnetimeSelected={props.setCpiOnetimeSelected}
                            setDiscounts={props.setDiscounts}
                            setRecurrentTotal={props.setRecurrentTotal}
                            setOnetimeTotal={props.setOnetimeTotal}
                            onetimeSelected={props.onetimeSelected}

                            billingDetails={props.billingDetails}
                            cpiAutomationLevelTotal={props.cpiAutomationLevelTotal}
                            setCpiAutomationLevelTotal={props.setCpiAutomationLevelTotal}
                            cpiAddOnsTotal={props.cpiAddOnsTotal}
                            setCpiAddOnsTotal={props.setCpiAddOnsTotal}
                            cpiExpertServicesRecurrentTotal={props.cpiExpertServicesRecurrentTotal}
                            setCpiExpertServicesRecurrentTotal={props.setCpiExpertServicesRecurrentTotal}
                            cpiExpertServicesOnetimeTotal={props.cpiExpertServicesOnetimeTotal}
                            setCpiExpertServicesOnetimeTotal={props.setCpiExpertServicesOnetimeTotal}
                            cpiExpertServicesOnetimeTrainingTotal={props.cpiExpertServicesOnetimeTrainingTotal}
                            setCpiExpertServicesOnetimeTrainingTotal={props.setCpiExpertServicesOnetimeTrainingTotal}
                            cpiExpertServicesOnetimeConfigurationTotal={props.cpiExpertServicesOnetimeConfigurationTotal}
                            setCpiExpertServicesOnetimeConfigurationTotal={props.setCpiExpertServicesOnetimeConfigurationTotal}
                            cpiExpertServicesOnetimeDeploymentTotal={props.cpiExpertServicesOnetimeDeploymentTotal}
                            setCpiExpertServicesOnetimeDeploymentTotal={props.setCpiExpertServicesOnetimeDeploymentTotal}
                            cpiPeriodMinimumFee={props.cpiPeriodMinimumFee}

                            getBillingPeriodPrice={props.getBillingPeriodPrice}
                        />
     */

    const [percentageDiscounts, setPercentageDiscounts] = useState({}); // discount e.g. 20 for 20% discount
    const [recurrentTotal, setRecurrentTotal] = useState(0);


    const [carets, setCarets] = useState({
        cpi_interaction_cost : false,
        cpi_automation_package: false,
        cpi_addons: false,
        cpi_expert_services: false,
        cpi_onetime_training: false,
        cpi_onetime_configuration: false,
        cpi_onetime_deployment: false,
    });



    const lineItems = (selectedProp, currencySymbol, isRecurrent=true) => {
        return Object.entries(selectedProp).length > 0
            ? Object.entries(selectedProp).map(([key, item]) => (
                <div key={key} style={{width:240}}>
                    <div style={{minWidth:160,width:160,height:22,overflow:"hidden",float:"left"}}>
                        {
                            (item.type === 'cpi_training' || item.type === 'cpi_deployment' ||item.type === 'ps_subscription')
                            ? (props.cpiProductSelectedQuantity[item.productCode] || item.default_quantity || 1)
                            : (
                                item.productCode in props.cpiProductSelectedQuantity // e.g. for Custom One Time Expert Services
                                    ? props.cpiProductSelectedQuantity[item.productCode]
                                    : 1
                              )
                        }
                        &nbsp;{item.title.replace("Deployment:", "").replace("Training:", "").trim()}
                    </div>
                    <div style={{minWidth:70,width:70,height:22,float:"right",textAlign:"right",paddingRight:10}}>
                        {formatPrice(
                            isRecurrent
                                ? props.getBillingPeriodPrice(
                                        (item.type === 'ps_subscription')
                                            ? item.price * (props.cpiProductSelectedQuantity[item.productCode] || item.default_quantity || 1)
                                            : getDynamicCpiItemPrice(item, props.totals) // for CPI-SKUs that are $0 default but have a volume based dynamic price
                                    , true, true)
                                // one-time fees do not need to reflect the billing period
                                : item.price * (props.cpiProductSelectedQuantity[item.productCode] || item.default_quantity || 1)
                            , currencySymbol
                        )}
                    </div>
                </div>
            ))
            : '';
    }
    const CPI_RECURRENT_TOTALS = {
        'cpi_billing_period_total': props.totals.cpi_billing_period_total,
        'cpi_automation' : props.cpiAutomationLevelTotal,
        'cpi_addons': props.cpiAddOnsTotal,
        'cpi_expert_services': props.cpiExpertServicesRecurrentTotal
    }
    /*
    const CPI_ONETIME_TOTALS = {
        'cpi_onetime': props.cpiOnetimeSelected,
        'cpi_onetime_training': getFilteredItems(props.cpiOnetimeSelected, 'cpi_training'),
        'cpi_onetime_configuration': getFilteredItems(props.cpiOnetimeSelected, 'cpi_configuration'),
        'cpi_onetime_deployment': getFilteredItems(props.cpiOnetimeSelected, 'cpi_deployment'),
    }

    function getItemsTotal(items){
        let prices = Object.entries(items).reduce( (arr, [key, item]) => {
            arr.push(item.price);
            return arr;
        }, [])
        return arraySum(prices);
    }

     */

    function recalculateTotals(forceUpdateDollarKey= null, forceUpdateDollarValue=null) {

        console.log('props.onetimeSelected : ', props.onetimeSelected)


        // update selected items pricing based on dynamic pricing SKUs if any
        let selected = [];
        let newVal = {...props.cpiAddOnsSelected}
        Object.entries(props.cpiAddOnsSelected).reduce( (arr, [key, item]) => {
            item.price = props.getBillingPeriodPrice(getDynamicCpiItemPrice(item, props.totals));
            selected = {...selected, [key]: item}
            props.setCpiAddOnsSelected(selected);
            return arr;
        }, [])



        // console.log('MyQuoteSummaryPanel.js::recalculateTotals() props.totals:', props.totals)
        // populate the amount after discount with default pre-discounted values if the key is not there yet
        // e.g. we have logic for amountsAfterDiscounts.* || *Total below... that shouldn't be needed
        /*
        for(let key in props.totals){
            if(!key in amountsAfterDiscounts){
                let val = props.totals[key];
                console.log('MyQuoteSummaryPanel.js::recalculateTotals() setting '+key+' in amountsAfterDiscounts to '+val)
                amountsAfterDiscounts[key] = val;
            }
        }*/
        let amountsAfterDiscounts = [];

        for(let key in CPI_RECURRENT_TOTALS){

            let discountAmount = truncateFloat(
            props.discounts[key] && key !== 'cpi_billing_period_total' // cpi_billing_period_total already includes discount
                ? (getTotalAmountByKey(key) - (getTotalAmountByKey(key) * props.discounts[key] / 100))
                : getTotalAmountByKey(key)
            );

            discountAmount = isNaN(discountAmount) ? 0 : discountAmount
            amountsAfterDiscounts[key] = discountAmount

            // console.log('key::::::::::::' , key, getTotalAmountByKey(key), amountsAfterDiscounts[key])
        }


        if(forceUpdateDollarKey !== null && forceUpdateDollarValue !== null){
            amountsAfterDiscounts[forceUpdateDollarKey] = truncateFloat(forceUpdateDollarValue);
        }

        let recurrent = arraySum(Object.values(amountsAfterDiscounts))

        // console.log('-----------monthly:', monthly);
        // console.log('-----------arraySum(Object.values(amountsAfterDiscounts)):', arraySum(Object.values(amountsAfterDiscounts)));

        let newRecurrent = (recurrent > 0 && !isNaN(recurrent)) ? recurrent : 0

        props.setRecurrentTotal(newRecurrent ?? 0)
        // props.setOnetimeTotal(CPI_ONETIME_TOTALS['cpi_onetime'] ?? 0 )

        //props.setCpiExpertServicesOnetimeTrainingTotal(getItemsTotal(getFilteredItems(props.cpiOnetimeSelected,'cpi_training')))
        //props.setCpiExpertServicesOnetimeDeploymentTotal(getItemsTotal(getFilteredItems(props.cpiOnetimeSelected,'cpi_deployment')))


        setRecurrentTotal(newRecurrent);

        console.log('amountsAfterDiscounts: ', amountsAfterDiscounts)

    }


    const handlePercentageDiscount = (key, value) => {
        // e.g. users => 5  (meaning 5% discount on platform users)
        // console.log('CpiMyQuote.js:handlePercentageDiscount() for: ' + key + ' = ' + value);
        // shallow copy it with spread operator to force the parent component to re-render
        let discounts = {...props.discounts}    // % discounts e.g. {users: "10"} for 10%

        discounts[key] = parseFloat(value);
        props.setDiscounts(discounts);

        // console.log('CpiMyQuote.js:handlePercentageDiscount() props.discounts set to: ', discounts);

        // percentage discount provided
        let tmp = {...percentageDiscounts};
        // console.log('CpiMyQuote.js:handlePercentageDiscount() percentageDiscounts (percentage) {...percentageDiscounts}: ', tmp);
        tmp[key] = parseFloat(value); // e.g. {users : 10} for "10%"
        setPercentageDiscounts(tmp) // local state for field display


        /*
            cpi_addons  0
            cpi_automation_package  0
            cpi_billing_period_total    49920
            cpi_expert_services 0
            cpi_interaction_cost    0.96
            cpi_onetime_training 0
            cpi_onetime_configuration 0
            cpi_onetime_deployment 0
            cpi_overage_cost    0.77
            cpi_overage_marked_up   0.77
         */

        let amount = getTotalAmountByKey(key)
        
        // force total dollar discount updates when %ages get manually updated
        recalculateTotals(key, (amount - (amount * value/100)));

    }

    const getTotalAmountByKey = (key) => {
        let amount = 0
        switch (key) {
            case 'cpi_automation':
                amount = props.cpiAutomationLevelTotal
                break;
            case 'cpi_addons':
                amount = props.cpiAddOnsTotal
                break;
            case 'cpi_expert_services':
                amount = props.cpiExpertServicesRecurrentTotal
                break;
            case 'cpi_onetime':
                amount = props.cpiExpertServicesOnetimeTotal
                break;
            case 'cpi_onetime_training':
                amount = props.cpiExpertServicesOnetimeTrainingTotal
                break;
            case 'cpi_onetime_configuration':
                amount = props.cpiExpertServicesOnetimeConfigurationTotal
                break;
            case 'cpi_onetime_deployment':
                amount = props.cpiExpertServicesOnetimeDeploymentTotal
                break;
            case 'cpi_billing_period_total':
                amount = props.totals.cpi_billing_period_total
                break;

        }
        return amount
    }

    const handleDollarDiscount = (key, value) => {

        console.log('CpiMyQuote.js:handleDollarDiscount() - key: '+key+' value: ' + value);

        // convert $ to % and update relevant fields
        let amount = getTotalAmountByKey(key) // dollar amount entered (after discount)
        let percentageValue = (100 - (100 * parseFloat(value) / amount)).toFixed(8)

        console.log('CpiMyQuote.js:handleDollarDiscount() - converted dollar amount: '+value+' to percentage: ' + percentageValue);
        // shallow copy it with spread operator to force the parent component to re-render
        let discounts = {...props.discounts}    // % discounts e.g. {users: "10"} for 10%
        discounts[key] = parseFloat(percentageValue);
        props.setDiscounts(discounts);

        // force total dollar discount updates when post-discount dollar amounts get manually updated
        recalculateTotals(key, value);
    }

    const toggleCaret = (e, key, oldVal) => {
        e.preventDefault();
        let newCarets = []
        newCarets[key] = oldVal === false ? true : false
        // console.log(' carets[   '+ key + '] : ' +  newCarets[key])
        setCarets({...carets, ...newCarets})

    }
    const caretController = (key) => (
        <a onClick={(e) => toggleCaret(e, key, carets[key])} href="#">
            <span style={{marginLeft:6,color:"#cccccc"}}>
                <i className={'fas fa-caret-' + (carets[key] ? 'down': 'right') + ' fa-lg'}></i>
            </span>
        </a>
    )

    const subTotalBlock = (props, key, style, totalOverrideValue=null) => {
        return <div style={style}>
            <span className="subtotalHeader">Subtotal</span>
            <span className="floatRight">
                {props.currencySymbol}
                <input type="number"
                       className="numericInput dollarInput"
                       id={key+"__subtotal"}
                       value={formatInputPrice(

                           totalOverrideValue===null
                                         ? discountedTotal(key)
                                         : totalOverrideValue
                       )}
                       style={{marginRight: 0}}
                       onChange={(event) => handleDollarDiscount(key, event.target.value)}
                />
            </span>
        </div>
    }

    const discountedTotal = (key) => {
        return props.discounts[key]
                ? (getTotalAmountByKey(key) - (getTotalAmountByKey(key) * props.discounts[key] / 100))
                : getTotalAmountByKey(key)
    }

    /*
    props.discounts
    props.totals
    props.currencySymbol
     */


    /**
     *
     * @param key e.g. training|deployment
     */
    const onetimeBlock = (key, title) => {
        return <div style={{marginBottom:5,marginTop:5}}><i><span style={{textTransform: 'capitalize'}}>{title}</span> (onetime)</i>

            {caretController('cpi_onetime_'+key)}
            {!carets['cpi_onetime_' + key]
            &&
            <>
                <span className="total-packages-onetime2 floatRight">
                    {formatPrice(getTotalAmountByKey('cpi_onetime_' + key), props.currencySymbol)}
                </span>
            </>

            ||
            <>

                <div>
                    {lineItems(
                        getFilteredItems(props.onetimeSelected, 'cpi_' + key)
                        , props.currencySymbol
                        , false
                    )
                    }
                </div>
                <div style={{marginTop: 10}}>
                    <input type="number"
                           id={"onetime__discount_"+key}
                           className="numericInput"
                           style={{marginRight: 0, textAlign:'center'}}
                           onChange={(event) => handlePercentageDiscount('cpi_onetime_'+key, event.target.value)}
                           value={props.discounts['cpi_onetime_' + key] || ''}
                    /> % Discount
                </div>
                {subTotalBlock(props, 'cpi_onetime_'+key, {marginTop: 5})}
            </>
            }

        </div>
    }

    useEffect(() => {
        console.log('CpiMyQuote.js::useEffect() re-rendering & recalculateTotals() because totals/discounts changed')
        recalculateTotals()
    },[
        props.cpiCreditsNumber,
        props.totals,
        props.cpiProductSelectedQuantity,
        props.cpiAutomationLevelTotal,
        props.cpiAddOnsTotal,
        props.cpiExpertServicesRecurrentTotal,
        props.cpiExpertServicesOnetimeTotal,
        props.discounts
     ]);

    return (
        <>
            <div className="row">
                <div className="col-xl-12 paddingLRNone marginTop8">
                    Products Purchased <span className="floatRight">{props.billingDetails?.billing_period?.value} Fee</span>
                    <hr className="hr-solid" style={{margin: "0 0 5px 0"}} />

                    {
                        <div style={{marginBottom:5}}>
                            <i>Interaction Cost</i>
                            {
                                //caretController('cpi_interaction_cost')
                            }
                            { // !carets.cpi_interaction_cost
                            // &&
                            <>
                                <span className="total-packages-users2 floatRight">
                                    {formatPrice(roundUp(props.totals[TOTALS_COMPONENT_KEY_CPI_BILLING_PERIOD_TOTAL]), props.currencySymbol)}
                                </span>
                            </>

                            // ||
                            && <>
                                <p className="subtotal">
                                    {
                                        formatPrice(props.cpiCreditsNumber
                                            * (12/getDivisionFactorForBillingPeriod(props.billingDetails?.pooling_frequency?.value))
                                        )


                                    } {props.billingDetails?.pooling_frequency?.value} Credits
                                    <span className="total-packages-users2">
                                        {
                                            formatPrice(

                                                props.totals[TOTALS_COMPONENT_KEY_CPI_BILLING_PERIOD_TOTAL]
                                                /*
                                                props.totals[TOTALS_COMPONENT_KEY_CPI_INTERACTION_COST] > 0
                                                    ? (props.cpiCreditsNumber * props.totals[TOTALS_COMPONENT_KEY_CPI_INTERACTION_COST])
                                                    : 0
                                                */
                                                , props.currencySymbol
                                            )

                                        }
                                    </span>
                                    <br/>Overage @ {formatPrice(
                                        //  props.discounts[TOTALS_COMPONENT_KEY_CPI_OVERAGE_COST]
                                        //? (props.totals[TOTALS_COMPONENT_KEY_CPI_OVERAGE_COST] + (props.totals[TOTALS_COMPONENT_KEY_CPI_OVERAGE_COST] * props.discounts[TOTALS_COMPONENT_KEY_CPI_OVERAGE_COST] / 100))
                                    props.totals[TOTALS_COMPONENT_KEY_CPI_OVERAGE_COST_MARKED_UP]
                                        ? props.totals[TOTALS_COMPONENT_KEY_CPI_OVERAGE_COST_MARKED_UP]
                                        : props.totals[TOTALS_COMPONENT_KEY_CPI_OVERAGE_COST]
                                    , props.currencySymbol)}
                                </p>
                                {/*
                                <input type="number"
                                       className="numericInput"
                                       id={TOTALS_COMPONENT_KEY_CPI_INTERACTION_COST + "__discount"}
                                       value={roundUp(props.discounts[TOTALS_COMPONENT_KEY_CPI_BILLING_PERIOD_TOTAL]) || ''}
                                       style={{marginRight: 0, textAlign: 'center'}}
                                       disabled={true}
                                    //onChange={( event ) => handlePercentageDiscount(TOTALS_COMPONENT_KEY_CPI_BILLING_PERIOD_TOTAL, event.target.value)}
                                /> % Discount

                                {subTotalBlock(props, TOTALS_COMPONENT_KEY_CPI_BILLING_PERIOD_TOTAL, {marginTop: 5},
                                    props.totals[TOTALS_COMPONENT_KEY_CPI_BILLING_PERIOD_TOTAL]
                                )}
                                */}

                            </>
                            }
                        </div>
                    }
{/*
                    {hr}
                    {


                        <div style={{marginBottom: 5}}><i>Automation</i>
                            {caretController('cpi_automation')}
                            {!carets.cpi_automation
                            &&
                            <>
                                <span className="total-packages-automation floatRight">
                                    {formatPrice(props.cpiAutomationLevelTotal, props.currencySymbol)}
                                </span>
                            </>

                            ||
                            <>
                                <div>
                                    {lineItems(props.cpiSelected, props.currencySymbol)}
                                </div>

                                <div style={{marginTop: 10}}>
                                    <input type="number"
                                           id="automation_discount"
                                           className="numericInput"
                                           style={{marginRight: 0, textAlign: 'center'}}
                                           onChange={(event) => handlePercentageDiscount('cpi_automation', event.target.value)}
                                           value={props.discounts.cpi_automation || ''}
                                    /> % Discount
                                </div>

                                {subTotalBlock(props, 'cpi_automation', {marginTop: 5})}
                            </>
                            }
                        </div>
                    }
*/}
                    {hr}
                    {
                        <div style={{marginBottom: 5}}><i>Add-Ons</i>
                            {caretController('cpi_addons')}
                            {!carets.cpi_addons
                            &&
                            <>
                                    <span className="total-packages-exp2 floatRight">
                                        {formatPrice(props.cpiAddOnsTotal, props.currencySymbol)}
                                    </span>
                            </>

                            ||
                            <>
                                {lineItems(props.cpiAddOnsSelected, props.currencySymbol)}

                                <div style={{marginTop: 10}}>
                                    <input type="number"
                                           id="cpi_addons__discount2"
                                           className="numericInput"
                                           style={{marginRight: 0, textAlign: 'center'}}
                                           onChange={(event) => handlePercentageDiscount('cpi_addons', event.target.value)}
                                           value={props.discounts.cpi_addons || ''}
                                    /> % Discount
                                </div>

                                {subTotalBlock(props, 'cpi_addons', {marginTop: 5})}
                            </>
                            }
                        </div>

                    }
                    {hr}
                    {

                        <div style={{marginBottom: 5}}><i>Expert Services (recurring)</i>
                            {caretController('cpi_expert_services')}
                            {!carets.cpi_expert_services
                            &&
                            <>
                            <span className="total-packages-exp2 floatRight">
                                {formatPrice(props.cpiExpertServicesRecurrentTotal, props.currencySymbol)}
                            </span>
                            </>

                            ||
                            <>
                                {lineItems(props.servicesMonthlySelected, props.currencySymbol)}

                                <div style={{marginTop: 10}}>
                                    <input type="number"
                                           id="expert_services__discount2"
                                           className="numericInput"
                                           style={{marginRight: 0, textAlign: 'center'}}
                                           onChange={(event) => handlePercentageDiscount('cpi_expert_services', event.target.value)}
                                           value={props.discounts.cpi_expert_services || ''}
                                    /> % Discount
                                </div>
                                {subTotalBlock(props, 'cpi_expert_services', {marginTop: 5})}
                            </>
                            }
                        </div>
                    }

                </div>

                <div className="col-xl-12 col-lg-4 paddingLRNone">

                    <hr className="hr-dotted" style={{margin:"0 0 5px 0"}}/>
                    <p className="cost-totals">
                        {props.billingDetails?.billing_period?.value} total
                        <span name="quote_monthly_total_commercial2">
                            {formatPrice(recurrentTotal
                                , props.currencySymbol)
                            }
                        </span>
                    </p>

                </div>
                <div className="col-xl-12 col-lg-4 paddingLRNone">

                    {hr}
                    {onetimeBlock('configuration', 'Custom Services')}
                    {hr}
                    {onetimeBlock('deployment', 'Implementation')}
                    {hr}
                    {onetimeBlock('training', 'Training')}
                    {hr}

                    <p className="cost-totals">Onetime Services total
                        <span name="quote_onetime_total_v2">
                            {
                                formatPrice(
                                    discountedTotal('cpi_onetime_deployment')
                                    + discountedTotal('cpi_onetime_configuration')
                                    + discountedTotal('cpi_onetime_training')
                                    , props.currencySymbol
                                )
                            }
                        </span>
                    </p>
                    <p className="cost-totals">Total Contract value
                        <span name="quote_onetime_total_v2">
                            {
                                formatPrice(
                                    discountedTotal('cpi_onetime_deployment')
                                    + discountedTotal('cpi_onetime_configuration')
                                    + discountedTotal('cpi_onetime_training')
                                    +
                                    (
                                      recurrentTotal // reflects billing period, so needs to be normalized back to monthly
                                    / getFrequencyNumberFromLabel(props.billingDetails?.billing_period.value) // e.g. get 12 for Annual
                                    * props.billingDetails?.subscription_term?.value // eg "24" for 24 month term
                                    )
                                , props.currencySymbol)
                            }
                        </span>
                    </p>


                </div>
            </div>
        </>
        )

}

/**
 *
 * @param items
 * @param type
 * @param extractKeyValue e.g. for all filtered items only get values for a specific key
 * @returns {*[]}
 */
export function getFilteredItems(items, type, extractKeyValue=null){
    return Object.entries(items).reduce( (arr, [key, item]) => {
        if(item.type===type){
            if(extractKeyValue !==null){
                arr.push(item[extractKeyValue])
            } else {
                arr.push(item);
            }
        }
        return arr;
    }, [])
}
