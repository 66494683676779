import React, {useEffect, useState} from 'react';
import Select from 'react-select';
import '../BillingPanel.css';
import './CpiInteractionCostPanel.css';
import { format as formatDate, addWeeks } from 'date-fns'
import {
    adjustPriceForDirectOrPartner,
    formatInputPrice,
    formatPrice,
    QUOTE_FLOW_FLAG_IS_RENEWAL, roundMarkedUpOverage,
    roundUp
} from "../QBApp";

import NumericInput from 'react-numeric-input'; // es5
//import * as NumericInput from "react-numeric-input"; // or TypeScript

import {
    TOTALS_COMPONENT_KEY_CPI_INTERACTION_COST,
    TOTALS_COMPONENT_KEY_CPI_OVERAGE_COST,
    TOTALS_COMPONENT_KEY_CPI_BILLING_PERIOD_TOTAL,
    TOTALS_COMPONENT_KEY_CPI_OVERAGE_COST_MARKED_UP,
} from "./CpiAutomationLevelPanel";



/**
 *
 * Billing field rules, overrides, etc are documented at
 * https://docs.google.com/spreadsheets/d/1OUlodB26G0NBYePkvPofceo9tO21UVEG4DqG5kWygEE/edit#gid=0
 *
 */
export default function CpiInteractionCostPanel(props) {

    // using local states for totals and discount
    // to avoid higher order component (HOC) state useEffect overlaps
    // these values can be pulled to HOC props when the calculations are done (on submit)
    const [localTotals, setLocalTotals] = useState([]);
    const [localDiscounts, setLocalDiscounts] = useState([]);

    const CPI_CREDITS_MIN = {
        'Monthly' : 5,
        'Quarterly' : 15,
        'Semi-Annual' : 30,
        'Annual' : 60,
    }
    const CPI_CREDITS_MAX = 999999999

    const handleCpiCreditsNumberChange = (credits) => {
        credits = parseInt(credits > 0 ? credits : 0)
        credits = credits < 0 ? 0 : credits
        credits = credits > CPI_CREDITS_MAX ? CPI_CREDITS_MAX : credits
        //credits = credits < CPI_CREDITS_MIN[props.billingDetails.billing_period.label] ? CPI_CREDITS_MIN[props.billingDetails.billing_period.label] : credits
        props.setCpiCreditsNumber(credits)
        calculateUnitPrice(credits, props.billingDetails.pooling_frequency.label, props.billingDetails.billing_period.label)
    }

    /**
     *  credits = normalized monthly rate card credit number/range
     *              e.g. 60,000 annually POOLED credits would need to look up
     *              5,000 range (60,000 / 12)
     */
    const calculateUnitPrice = (credits, poolingFrequencyLabel, billingPeriodLabel, discounts=localDiscounts) => {

        if(!billingPeriodLabel){
            billingPeriodLabel = getFieldDefaultValue(props.pricing,'billing_period').value
        }

        console.log('CpiInteractionCostPanel: calculateUnitPrice() for credits: ' + credits
            + ' pooling_frequency: ' + poolingFrequencyLabel
            + ' billing_period: ' + billingPeriodLabel
        )

        let matchedUnitPrice = getUnitPrice(props.apiData.pricing[props.currency], credits, poolingFrequencyLabel, billingPeriodLabel)

        console.log('CpiInteractionCostPanel: calculateUnitPrice() CPI Discount schedule matched in getUnitPrice():', matchedUnitPrice);

        matchedUnitPrice = {...matchedUnitPrice,
            ['price']: adjustPriceForDirectOrPartner(props.selectedAccount, matchedUnitPrice.price),
            ['price_with_long_decimals']: adjustPriceForDirectOrPartner(props.selectedAccount, matchedUnitPrice.price, true, 7)
        }

        console.log('CpiInteractionCostPanel: calculateUnitPrice() CPI Discount schedule matched in getUnitPrice() after adjustPriceForDirectOrPartner:', matchedUnitPrice);
        props.setCpiCreditsCost(matchedUnitPrice)

        recalculateTotals(localDiscounts, credits)

        let totals = {...localTotals}    // % discounts e.g. {users: "10"} for 10%
        totals[TOTALS_COMPONENT_KEY_CPI_INTERACTION_COST] = parseFloat(matchedUnitPrice.price);
        // totals[TOTALS_COMPONENT_KEY_CPI_BILLING_PERIOD_TOTAL] =


        totals[TOTALS_COMPONENT_KEY_CPI_OVERAGE_COST+'_FULL_DECIMALS'] =
            CPI_OVERAGE_BASE_MARKUP *
            localTotals[TOTALS_COMPONENT_KEY_CPI_BILLING_PERIOD_TOTAL]/credits/ normalizedFrequencyFactor()

        totals[TOTALS_COMPONENT_KEY_CPI_OVERAGE_COST] = roundUp(
            CPI_OVERAGE_BASE_MARKUP *
            localTotals[TOTALS_COMPONENT_KEY_CPI_BILLING_PERIOD_TOTAL]/credits/ normalizedFrequencyFactor()
            ///(credits * getDivisionFactorForBillingPeriod(props.billingDetails.billing_period.label))
        )
        totals[TOTALS_COMPONENT_KEY_CPI_OVERAGE_COST_MARKED_UP] = roundMarkedUpOverage(totals[TOTALS_COMPONENT_KEY_CPI_OVERAGE_COST+'_FULL_DECIMALS'])



        console.log('totals[TOTALS_COMPONENT_KEY_CPI_OVERAGE_COST] : ' + totals[TOTALS_COMPONENT_KEY_CPI_OVERAGE_COST+'_FULL_DECIMALS'],
            " = " + localTotals[TOTALS_COMPONENT_KEY_CPI_BILLING_PERIOD_TOTAL] + " x "+ getDivisionFactorForBillingPeriod(props.billingDetails.billing_period.label) +" / "+ credits,
            + (localTotals[TOTALS_COMPONENT_KEY_CPI_BILLING_PERIOD_TOTAL] * getDivisionFactorForBillingPeriod(props.billingDetails.billing_period.label) / credits)
        );
        console.log('setLocalTotals: ', totals)
        setLocalTotals(totals);

    }

    const handleSubscriptionTermChange = (event) => {
        console.log('BillingPanel.js:handleSubscriptionTermChange() event: ', event)
        let subscription_term = {'label' : event.label, 'value' : event.value}
        props.setBillingDetails({...props.billingDetails, subscription_term})
    }

    const handleBillingPeriodChange = (event, recalculate=true) => {
        console.log('BillingPanel.js:handleBillingPeriodChange() event: ', event);
        let billing_period = {'label' : event.label, 'value' : event.value}
        props.setBillingDetails({...props.billingDetails, billing_period})

        // reset to minimum credits if the previous amount was below minimum
        if(props.cpiCreditsNumber < CPI_CREDITS_MIN[event.label]) {
            props.setCpiCreditsNumber(CPI_CREDITS_MIN[event.label])
        }
        if(recalculate) {
            calculateUnitPrice(props.cpiCreditsNumber, props.billingDetails.pooling_frequency.label, billing_period.label)
        }

    }
    const handlePoolingFrequencyChange = (event) => {
        console.log('BillingPanel.js:handlePoolingFrequencyChange() event: ', event)

        let pooling_frequency = {'label' : event.label, 'value' : event.value}
        // force update the billing period as well
        let billing_period = {'label' : event.label, 'value' : event.value}

        let creditsNumber = props.cpiCreditsNumber;
        // reset to minimum credits if the previous amount was below minimum
        if(props.cpiCreditsNumber < CPI_CREDITS_MIN[event.label]) {
            creditsNumber = CPI_CREDITS_MIN[event.label];
            props.setCpiCreditsNumber(creditsNumber)
        }

        handleBillingPeriodChange(billing_period, false);
        props.setBillingDetails({...props.billingDetails, pooling_frequency, billing_period})

        calculateUnitPrice(creditsNumber, pooling_frequency.label, billing_period.label)

    }

    /*
    // we now restrict invalid selections within dropdown menu instead
    const isValidPoolingFrequencyVsBillingPeriod = (pooling_frequency, billing_period) => {
        // billing period HAS TO BE >= pooling frequency
        if(getFrequencyNumberFromLabel(billing_period) >= getFrequencyNumberFromLabel(pooling_frequency)){
            return true
        }
        return false
    }*/

    const getFieldDefaultOptions = (fieldName) => {
        return Object.entries(props.pricing[fieldName].options).map(
            function ([index, value]) {
                // console.log(fieldName + ' -- options: ' + 'value: ' + value +  ' label: ' + index);

                let isDisabled = false
                if(fieldName === 'billing_period'){
                    if('pooling_frequency' in props.billingDetails
                        && getFrequencyNumberFromLabel(props.billingDetails.pooling_frequency.value) > getFrequencyNumberFromLabel(value)
                    ){
                        isDisabled = true
                    }
                }
                return {'value': index, 'label' : value, isDisabled: isDisabled}
            }
        )
    }

    const handleDollarDiscount = (key, value) => {

        console.log('CpiInteractionCostPanel.js:handleDollarDiscount() - key: '+key+' value: ' + value);
        console.log('CpiInteractionCostPanel.js:handleDollarDiscount() - getPrice(): ', getPrice());

        // convert $ to % and update relevant fields
        // let amount = localTotals[key]; // PRIOR dollar amount entered (after discount)
        let percentageValue = 0
        if(key === TOTALS_COMPONENT_KEY_CPI_BILLING_PERIOD_TOTAL) {
             percentageValue = (100 - (100 * parseFloat(value) / getPrice())).toFixed(8)
            //console.log('CpiInteractionCostPanel.js:handleDollarDiscount() - parseFloat(value) / amount: ', (parseFloat(value) / getPrice()));
        } else if(key === TOTALS_COMPONENT_KEY_CPI_OVERAGE_COST) {
            percentageValue = (
                100 * (parseFloat(value) - localTotals[TOTALS_COMPONENT_KEY_CPI_OVERAGE_COST])
                /localTotals[TOTALS_COMPONENT_KEY_CPI_OVERAGE_COST]
            ).toFixed(8)

            console.log('CpiInteractionCostPanel.js:handleDollarDiscount() - parseFloat(value) & overage unit cost',parseFloat(value), localTotals[TOTALS_COMPONENT_KEY_CPI_OVERAGE_COST]);
        }


        console.log('CpiInteractionCostPanel.js:handleDollarDiscount() - converted dollar amount: '+value+' to percentage: ' + percentageValue);
        // shallow copy it with spread operator to force the parent component to re-render
        let discounts = {...localDiscounts}    // % discounts e.g. {cpi_billing_period_total: "10"} for 10%
        discounts[key] = parseFloat(percentageValue);
        setLocalDiscounts(discounts);

        // force total dollar discount updates when post-discount dollar amounts get manually updated
        let totals = {...localTotals}

        if(key === TOTALS_COMPONENT_KEY_CPI_BILLING_PERIOD_TOTAL) {
            // update the period total amount only when the total is updated

            totals[TOTALS_COMPONENT_KEY_CPI_BILLING_PERIOD_TOTAL] = parseFloat(value)

            totals[TOTALS_COMPONENT_KEY_CPI_OVERAGE_COST+'_FULL_DECIMALS'] =
                value/ props.cpiCreditsNumber/ normalizedFrequencyFactor()

            totals[TOTALS_COMPONENT_KEY_CPI_OVERAGE_COST] = roundUp(
                //value * getDivisionFactorForBillingPeriod(props.billingDetails.billing_period.label)
                value/ props.cpiCreditsNumber/ normalizedFrequencyFactor()
            )

            totals[TOTALS_COMPONENT_KEY_CPI_OVERAGE_COST_MARKED_UP] =
                discounts[TOTALS_COMPONENT_KEY_CPI_OVERAGE_COST]
                    ? roundMarkedUpOverage((totals[TOTALS_COMPONENT_KEY_CPI_OVERAGE_COST+'_FULL_DECIMALS']
                        + (totals[TOTALS_COMPONENT_KEY_CPI_OVERAGE_COST+'_FULL_DECIMALS']          // mark up
                        * discounts[TOTALS_COMPONENT_KEY_CPI_OVERAGE_COST] / 100)
                    ))
                    : roundMarkedUpOverage(totals[TOTALS_COMPONENT_KEY_CPI_OVERAGE_COST+'_FULL_DECIMALS'])



        } else if(key === TOTALS_COMPONENT_KEY_CPI_OVERAGE_COST) {

            // marked up overage pricing cannot be below effective unit price
            totals[TOTALS_COMPONENT_KEY_CPI_OVERAGE_COST_MARKED_UP] =

                localDiscounts[TOTALS_COMPONENT_KEY_CPI_OVERAGE_COST]

                    ? ( // we have a discount so calculate accordingly
                            roundUp(value) < getEffectiveUnitPrice()
                        ? getEffectiveUnitPrice()
                        : roundUp(value)
                    )
                    : (value < getEffectiveUnitPrice() ? getEffectiveUnitPrice() : value) // we have no discount so use the same value as the base number

        }

        setLocalTotals(totals);

        console.log('CpiInteractionCostPanel.js:handleDollarDiscount() setLocalTotals(totals): ', totals)

        // recalculateTotals(discounts)
        // force total dollar discount updates when %ages get manually updated
        // calculateUnitPrice(props.cpiCreditsNumber, props.billingDetails.pooling_frequency.label, props.billingDetails.billing_period.label, discounts)


    }

    const handlePercentageDiscount = (key, value, allowNegative=true) => {

        //console.log('CpiInteractionCostPanel:: handlePercentageDiscount() key: ' +  key + ' value: '+ value)

        if(!allowNegative && value === '-'){
            value = 0
        }

        // override with the allowed minimum if needed
        if(key === TOTALS_COMPONENT_KEY_CPI_OVERAGE_COST && value < MIN_MARKUP_PERCENTAGE_THRESHOLD){
            value = MIN_MARKUP_PERCENTAGE_THRESHOLD
        }

        // e.g. users => 5  (meaning 5% discount on platform users)
        // console.log('CpiInteractionCostPanel.js:handlePercentageDiscount() for: ' + key + ' = ' + value);
        // shallow copy it with spread operator to force the parent component to re-render
        let discounts = {...localDiscounts}    // % discounts e.g. {users: "10"} for 10%
        discounts[key] = parseFloat(value);
        setLocalDiscounts(discounts);

        if(key === TOTALS_COMPONENT_KEY_CPI_BILLING_PERIOD_TOTAL) {
            let total = (getPrice() - (getPrice() * discounts[key] / 100))
            let totals = {...localTotals}
            totals[key] = total;

            // console.log('CpiInteractionCostPanel:: handlePercentageDiscount() totals: ', totals)
            setLocalTotals(totals);
            recalculateTotals(discounts, props.cpiCreditsNumber, totals)
        } else if(key === TOTALS_COMPONENT_KEY_CPI_OVERAGE_COST) {
            // no need to update totals
            recalculateTotals(discounts, props.cpiCreditsNumber)
        }

        // force total dollar discount updates when %ages get manually updated
        // calculateUnitPrice(props.cpiCreditsNumber, props.billingDetails.pooling_frequency.label, props.billingDetails.billing_period.label, discounts)

    }

    /** get CPI credits sub-total price before discounts */
    const getPrice = (price='', credits='') => {

        price = price ? price : props.cpiCreditsCost.price_with_long_decimals;
        price = isNaN(price) ? 0 : price;
        credits = credits ? credits : props.cpiCreditsNumber;
        credits = isNaN(credits) ? 0 : credits;

        let returnPrice = parseFloat(price) * parseInt(credits) * normalizedFrequencyFactor();
        props.setCpiPeriodMinimumFee(returnPrice);
        /*
        console.log('CpiInteractionCostPanel.js: getPrice() normalizedFrequencyFactor : ' + normalizedFrequencyFactor())
        console.log('CpiInteractionCostPanel.js: getPrice() returnPrice : ' + returnPrice)
        console.log('CpiInteractionCostPanel.js: getPrice() '  + props.billingDetails?.pooling_frequency?.value)
        console.log('CpiInteractionCostPanel.js: getPrice() '  + props.billingDetails?.billing_period?.value)
        */
        return returnPrice


    }
    const normalizedFrequencyFactor = () => {
        return  (
            /*
            getDivisionFactorForBillingPeriod( // eg Quarterly = 4
                'pooling_frequency' in props.billingDetails
                    ? props.billingDetails.pooling_frequency.value
                    : POOLING_FREQUENCY_MONTHLY_SELECT_OPTION.value
            )
            /getDivisionFactorForBillingPeriod( // eg Semi-Annual = 2
                'billing_period' in props.billingDetails
                    ? props.billingDetails.billing_period.value
                    : BILLING_PERIOD_MONTHLY_SELECT_OPTION.value
            )
            // so multiply by 2 in this example
            */


            // updated logic now that we only show monthly credits
            12/getDivisionFactorForBillingPeriod( // eg Semi-Annual = 2
                'billing_period' in props.billingDetails
                    ? props.billingDetails.billing_period.value
                    : BILLING_PERIOD_MONTHLY_SELECT_OPTION.value
            )
        )
    }

    /**
     *
     * @param discounts allow values that may have just been updated (but not available via local state just yet) to be provided
     * @param credits
     * @param localTotals allow values that may have just been updated (but not available via local state just yet) to be provided
     */
    const recalculateTotals = (discounts = localDiscounts, credits=props.cpiCreditsNumber, overrideTotals=null) => {
        let total =  discounts[TOTALS_COMPONENT_KEY_CPI_BILLING_PERIOD_TOTAL]
            ? (getPrice() - (getPrice() * discounts[TOTALS_COMPONENT_KEY_CPI_BILLING_PERIOD_TOTAL] / 100))
            : getPrice()

        total = isNaN(total) ? 0 : total

        console.log('CpiInteractionCostPanel recalculateTotals() discounts ', discounts )
        console.log('CpiInteractionCostPanel recalculateTotals() getPrice() ', getPrice() )
        console.log('CpiInteractionCostPanel recalculateTotals() total ', total)


        total = roundUp(total)
        let totals = overrideTotals ? {...overrideTotals} : {...localTotals}
        totals[TOTALS_COMPONENT_KEY_CPI_BILLING_PERIOD_TOTAL] = parseFloat(total);

        totals[TOTALS_COMPONENT_KEY_CPI_OVERAGE_COST+'_FULL_DECIMALS'] = CPI_OVERAGE_BASE_MARKUP *
              total
            / props.cpiCreditsNumber
            / normalizedFrequencyFactor()

        totals[TOTALS_COMPONENT_KEY_CPI_OVERAGE_COST] =
            roundUp( CPI_OVERAGE_BASE_MARKUP *
                total
                / props.cpiCreditsNumber
                / normalizedFrequencyFactor()
            )
        totals[TOTALS_COMPONENT_KEY_CPI_OVERAGE_COST_MARKED_UP] =

            discounts[TOTALS_COMPONENT_KEY_CPI_OVERAGE_COST] // do we have a discount?

                ? roundMarkedUpOverage(
                discounts[TOTALS_COMPONENT_KEY_CPI_OVERAGE_COST]
                    ? totals[TOTALS_COMPONENT_KEY_CPI_OVERAGE_COST+'_FULL_DECIMALS']
                        + (totals[TOTALS_COMPONENT_KEY_CPI_OVERAGE_COST+'_FULL_DECIMALS']
                           * discounts[TOTALS_COMPONENT_KEY_CPI_OVERAGE_COST]/100
                        )
                    : totals[TOTALS_COMPONENT_KEY_CPI_OVERAGE_COST+'_FULL_DECIMALS']
                )
                : totals[TOTALS_COMPONENT_KEY_CPI_OVERAGE_COST] // no discount, no need to calculate (may introduce rounding errors)


        setLocalTotals(totals);


        console.log('CpiInteractionCostPanel recalculateTotals() totals ', totals)

        // return totals
        // calculateUnitPrice(props.cpiCreditsNumber, props.billingDetails.pooling_frequency.label, props.billingDetails.billing_period.label)

    }


    const getEffectiveUnitPrice = () =>
        localDiscounts[TOTALS_COMPONENT_KEY_CPI_BILLING_PERIOD_TOTAL]
            ? roundMarkedUpOverage((1 - localDiscounts[TOTALS_COMPONENT_KEY_CPI_BILLING_PERIOD_TOTAL] / 100) * localTotals[TOTALS_COMPONENT_KEY_CPI_INTERACTION_COST])
            : roundMarkedUpOverage(localTotals[TOTALS_COMPONENT_KEY_CPI_INTERACTION_COST])


    useEffect(() => {
        let data = {}

        // pricing model specific default overrides (fields locked as well in-place below)
        data['pooling_frequency'] = POOLING_FREQUENCY_MONTHLY_SELECT_OPTION
        data['subscription_term'] = BILLING_SUBSCRIPTION_TERM_12_MONTHS_SELECT_OPTION
        data['payment_method'] = BILLING_PAYMENT_METHOD_ACH_WIRE_SELECT_OPTION

        let billingDetails = {...props.billingDetails, ...data}
        props.setBillingDetails(billingDetails)
        console.log('CpiInteractionCostPanel.js:useEffect() props.billingDetails ', billingDetails);

        //props.setCpiCreditsNumber(CPI_CREDITS_MIN[BILLING_PERIOD_MONTHLY_SELECT_OPTION.label])
        props.setCpiCreditsNumber('')
        calculateUnitPrice(0, billingDetails.pooling_frequency.label, billingDetails.billing_period.label)
        recalculateTotals()

    }, [props.apiData, props.flowType, props.pricingModel]);

    useEffect(() => {
        // recalculate totals whenever related fields are updated
        // calculateUnitPrice(props.cpiCreditsNumber, props.billingDetails.pooling_frequency.label, props.billingDetails.billing_period.label)
        recalculateTotals(localDiscounts)

    }, [
        props.cpiCreditsNumber,
        props.cpiCreditsCost,
        props.billingDetails,
        //    localDiscounts,
    ])

    useEffect(() => {

        let totals = {...props.totals, ...localTotals}
        props.setTotals(totals)
        console.log('CpiInteractionCostPanel.js:useEffect() setting props.totals ', totals);

        let discounts = {...props.discounts, ...localDiscounts}
        props.setDiscounts(discounts)
        console.log('CpiInteractionCostPanel.js:useEffect() setting props.discounts ', discounts)

        if(localTotals[TOTALS_COMPONENT_KEY_CPI_OVERAGE_COST_MARKED_UP] > 0) {
            props.setCpiOverageCost(Number(localTotals[TOTALS_COMPONENT_KEY_CPI_OVERAGE_COST_MARKED_UP]).toFixed(2));
        }

    }, [
        localTotals,
        localDiscounts,
    ])




    return (
        Object.entries(props.pricing).length > 0 &&
        <>
            <div className="panel panel-default" id="panel_billing" style={{fontSize:15}}>
                <div className="panel-heading panel-darkgrey">Interaction Cost Calculator

                    {props.selectedAccount && props.selectedAccount.isPartner &&
                        <span className="sub-title partner-fields">
                            &nbsp;&nbsp;(Prices already discounted 30% for partners)
                        </span>
                    }

                    <span className="collapse-option"></span>
                </div>
                <div className="panel-body">

                    <div className="quick-tip hr-top-gray-solid">
                        <strong>Quick Tip:</strong> The Billing Period should be the same or greater than the Pooling Frequency.
                    </div>

                    <div className="row row-spacer2">

                        <div className="col-sm-4">
                            <label htmlFor="pooling_frequency" className="mt-3 select-mt">
                                <span className="smallerLabelText">Pooling Frequency</span>
                                <Select
                                    id="pooling_frequency"
                                    onChange={handlePoolingFrequencyChange}
                                    isSearchable={false}
                                    value={
                                        props.billingDetails['pooling_frequency']
                                        ?? getFieldDefaultValue(props.pricing,'pooling_frequency')
                                    }
                                    options={getFieldDefaultOptions('pooling_frequency')}
                                    isDisabled={false}
                                ></Select>
                            </label>
                        </div>
                        <div className="col-sm-4">
                            <label htmlFor="billing_period" className="mt-3 select-mt">
                                Billing Period
                                <Select
                                    id="billing_period"
                                    // placeholder={"Loading..."}
                                    onChange={handleBillingPeriodChange}
                                    isSearchable={false}
                                    value={
                                        props.billingDetails['billing_period']
                                        ?? BILLING_PERIOD_MONTHLY_SELECT_OPTION
                                    }
                                    options={getFieldDefaultOptions('billing_period')}
                                    isDisabled={false}
                                ></Select>
                            </label>
                        </div>
                        <div className="col-sm-4">
                            <label htmlFor="subscription_term" className="mt-3 select-mt">
                                Subscription Term
                                <Select
                                    id="subscription_term"
                                    placeholder={"Loading..."}
                                    onChange={handleSubscriptionTermChange}
                                    isSearchable={false}
                                    value={
                                                props.billingDetails['subscription_term']
                                                ?? getFieldDefaultValue(props.pricing,'subscription_term')
                                    }
                                    options={getFieldDefaultOptions('subscription_term')}
                                    isDisabled={false}
                                ></Select>
                            </label>
                        </div>


                        <div className="col-sm-4 text-left">
                            <label htmlFor="credits" className="mt-3 select-mt">
                            <div>
                                <span className="smallerLabelText">
                                    {/*props.billingDetails.pooling_frequency
                                        ? props.billingDetails.pooling_frequency.value
                                        : POOLING_FREQUENCY_MONTHLY_SELECT_OPTION.value
                                    As of Summer 2022, all credit entry is in Monthly units
                                    */} Monthly Credits
                                </span>
                                <NumericInput
                                    id="credits"
                                    className="form-control NumericInput userNumberInput"
                                    onChange={(e) => handleCpiCreditsNumberChange(e)}
                                    value={
                                        (props.cpiCreditsNumber ? props.cpiCreditsNumber : '')
                                        /*
                                            (props.billingDetails.billing_period && CPI_CREDITS_MIN[props.billingDetails.billing_period.label]
                                                ? CPI_CREDITS_MIN[props.billingDetails.billing_period.label]
                                                : CPI_CREDITS_MIN['Annual']
                                            )
                                        ) || ''
                                        */
                                    }
                                    /*
                                    min={
                                        (
                                        props.cpiCreditsNumber > 0
                                        && props.billingDetails.billing_period
                                        && CPI_CREDITS_MIN[props.billingDetails.billing_period.label]
                                        )
                                            ? CPI_CREDITS_MIN[props.billingDetails.billing_period.label]
                                            : 0
                                    }*/
                                    step={ 1 }
                                    max={CPI_CREDITS_MAX}
                                    precision={ 0 }
                                    size={ 8 }
                                    required
                                    inputMode="numeric"
                                    style={{height: 38}}
                                />
                            </div>
                            </label>
                        </div>

                        <div className="col-sm-4 text-left">
                            <label htmlFor="unit_price" className="mt-3 select-mt smallerLabelText">
                                Unit List Price
                            </label>
                            <div id="unit_price" className="bold boxed-text"  style={{backgroundColor:'#f2f2f2'}}>
                                {formatPrice(Object.entries(props.cpiCreditsCost).length > 0 ? props.cpiCreditsCost.price : 0, props.currencySymbol, null , 0)}
                            </div>
                        </div>

                        <div className="col-sm-4 text-left">
                            <label htmlFor="ppf" className="mt-3 select-mt smallerLabelText">
                                {
                                    (props.billingDetails.billing_period
                                        ? props.billingDetails.billing_period.value
                                        : BILLING_PERIOD_MONTHLY_SELECT_OPTION.value
                                    )
                                } Price
                            </label>
                            <div id="ppf" className="bold boxed-text" style={{backgroundColor:'#f2f2f2'}}>

                                {
                                    Object.entries(props.cpiCreditsCost).length
                                    ? formatPrice(getPrice(), props.currencySymbol, null, 0)
                                    //? formatPrice(props.cpiCreditsCost.price * props.cpiCreditsNumber, props.currencySymbol, null, 0)
                                    : 0
                                }
                            </div>
                        </div>

                    </div>

                </div>

                <div className="quick-tip hr-top-gray-solid">
                    <strong>Quick Tips:</strong>
                    <div style={{paddingLeft:10}}>
                        1) Costs displayed below are rounded up to the nearest two decimal places
                        <br /> 2) Overage Price includes 20% Markup
                    </div>
                </div>

                <div className="panel-footer" style={{backgroundColor:'#ffffff',fontSize:15}}>

                    <div className="float-left" style={{width:140,marginTop:6}}>
                        {
                            (props.billingDetails.billing_period ? props.billingDetails.billing_period.value : BILLING_PERIOD_MONTHLY_SELECT_OPTION.value)
                        } Min. Fee:
                    </div>

                    <div className="float-left" style={{width: 100, marginTop:6, fontWeight:'bold'}}>
                        {formatPrice(getPrice(), props.currencySymbol)}
                    </div>

                    <div className="float-left" style={{paddingLeft:"7%"}}>
                        <input type="number"
                               id={TOTALS_COMPONENT_KEY_CPI_BILLING_PERIOD_TOTAL+"__discount"}
                               className="numericInput text-center bg-white"
                               style={{minHeight:36.5,width:47,marginRight: 0, textAlign:(localDiscounts[TOTALS_COMPONENT_KEY_CPI_BILLING_PERIOD_TOTAL] > 0 ? 'left' : 'center')}}
                               onChange={(event) => handlePercentageDiscount(TOTALS_COMPONENT_KEY_CPI_BILLING_PERIOD_TOTAL, event.target.value)}
                               value={localDiscounts[TOTALS_COMPONENT_KEY_CPI_BILLING_PERIOD_TOTAL] || ''}
                               placeholder="0"
                               onFocus={(e) => e.target.placeholder = ""}
                               onBlur={(e) => e.target.placeholder = "0"}
                               step={0.01}
                        /> % Discount
                    </div>



                    <span className="floatRight bg-white">

                            {
                                (props.billingDetails.billing_period ? props.billingDetails.billing_period.value : BILLING_PERIOD_MONTHLY_SELECT_OPTION.value)
                            } Min. Total:&nbsp;&nbsp;

                            {props.currencySymbol} &nbsp;&nbsp;
                        <input type="number"
                               // pattern="\d+((\.|,)\d+)?"
                               className="cpiNumericInput cpiDollarInput"
                               id={TOTALS_COMPONENT_KEY_CPI_BILLING_PERIOD_TOTAL+"__subtotal"}
                               onChange={(event) => handleDollarDiscount(TOTALS_COMPONENT_KEY_CPI_BILLING_PERIOD_TOTAL, event.target.value)}
                               value={localTotals[TOTALS_COMPONENT_KEY_CPI_BILLING_PERIOD_TOTAL] || ''}
                               style={{marginRight: 0,backgroundColor:'#fffff'}}
                               width={133}
                               step={0.01}
                        />


                    </span>

                </div>
                <div style={{clear:"both"}}></div>
                <div className="panel-footer marginTop8" style={{backgroundColor:'#ffffff',paddingBottom:49}}>

                    <div className="float-left" style={{width:140,marginTop:6}}>
                        Effective Unit Price:
                    </div>

                    <div className="float-left" style={{marginRight:0,width: 100,fontWeight:'bold',marginTop:6}}>
                        {formatPrice( getEffectiveUnitPrice(), props.currencySymbol,'CpiInteractionCostPanel.js')}
                        {/*localTotals[TOTALS_COMPONENT_KEY_CPI_OVERAGE_COST] ? <sup><strong>*</strong></sup> : ''*/}

                    </div>

                    <div className="float-left" style={{paddingLeft:"7%",marginTop:2}}>
                        <input type="number"
                               id={TOTALS_COMPONENT_KEY_CPI_OVERAGE_COST+"__discount"}
                               className="numericInput text-center bg-white"
                               style={{minHeight:36.5,marginTop:-2,width:47,marginRight: 0, textAlign:(localDiscounts[TOTALS_COMPONENT_KEY_CPI_OVERAGE_COST] > 0 ? 'left' : 'center')}}
                               onChange={(event) => handlePercentageDiscount(TOTALS_COMPONENT_KEY_CPI_OVERAGE_COST, parseFloat(event.target.value))}
                               value={localDiscounts[TOTALS_COMPONENT_KEY_CPI_OVERAGE_COST] || ''}
                               placeholder="0"
                               onFocus={(e) => e.target.placeholder = ""}
                               onBlur={(e) => e.target.placeholder = "0"}
                               step={.1}
                               min={MIN_MARKUP_PERCENTAGE_THRESHOLD}
                        /> % Add'l Overage Markup
                        {/*
                        <br />
                        <span style={{fontSize:13,backgroundColor:'#ffffff',marginTop:-2}}>(20%+ markup recommended)</span>
                        */}
                    </div>


                    <span className="floatRight bg-white">

                        Overage Cost: &nbsp;&nbsp;

                        {props.currencySymbol}&nbsp;&nbsp;
                        <input type="number"
                               className="cpiNumericInput cpiDollarInput"
                               id={TOTALS_COMPONENT_KEY_CPI_OVERAGE_COST+"__subtotal"}
                               value={localTotals[TOTALS_COMPONENT_KEY_CPI_OVERAGE_COST_MARKED_UP]
                                   ? formatPrice(localTotals[TOTALS_COMPONENT_KEY_CPI_OVERAGE_COST_MARKED_UP], '', null, 0)
                                   : ''}
                               style={{marginRight: 0,backgroundColor:'#fffff'}}
                               step={0.01}
                               width={133}
                               min={localTotals[TOTALS_COMPONENT_KEY_CPI_OVERAGE_COST] ? getEffectiveUnitPrice() : 0}
                               onChange={(event) => handleDollarDiscount(TOTALS_COMPONENT_KEY_CPI_OVERAGE_COST, event.target.value)}
                        />

                    </span>


                </div>
                <div style={{clear:"both"}}></div>

            </div>
        </>

    )



}

export const getFieldDefaultValue = (pricing, fieldName) => {
    if(pricing[fieldName]) {
        // console.log('DEFAULT FIELD VALUE for : ' + fieldName + ' : ' + pricing[fieldName].options[pricing[fieldName].default])
        return {
            'label': pricing[fieldName].options[pricing[fieldName].default],
            'value': pricing[fieldName].default
        }
    }
}

export const DATE_FORMAT = 'yyyy-MM-dd'
export const defaultPricingValidUntil = formatDate(addWeeks(new Date(), 2), DATE_FORMAT)
export const BILLING_PAYMENT_METHOD_ACH_WIRE_SELECT_OPTION = {label: 'ACH / Wire Transfer', value: 'ach'}
export const BILLING_SUBSCRIPTION_TERM_6_MONTHS_SELECT_OPTION = {label: '6 Months', value: 6}
export const BILLING_SUBSCRIPTION_TERM_12_MONTHS_SELECT_OPTION = {label: '12 Months', value: 12}

export const BILLING_PERIOD_MONTHLY_SELECT_OPTION = {label: 'Monthly', value: 'Monthly'} // used in CPI
export const POOLING_FREQUENCY_MONTHLY_SELECT_OPTION = {label: 'Monthly', value: 'Monthly'} // used in CPI

export const getUnitPrice = (pricing, credits, poolingFrequencyLabel, billingPeriodLabel) => {
    /*
    console.log('CpiInteractionCostPanel.js :: getUnitPrice() pricing: ', pricing,
        'billingPeriodLabel: ', billingPeriodLabel,
        'getFrequencyNumberFromLabel(billingPeriodLabel): ', getFrequencyNumberFromLabel(billingPeriodLabel),
        'gpricing.cpi_discount_schedules[getFrequencyNumberFromLabel(billingPeriodLabel)]: ', pricing.cpi_discount_schedules[getFrequencyNumberFromLabel(billingPeriodLabel)]
    )
    */
    credits = formatInputPrice(credits) // strip out commas etc

    // let normalizedMonthlyRateCardVolume = credits/getFrequencyNumberFromLabel(poolingFrequencyLabel)

    let normalizedMonthlyRateCardVolume = credits * 12 // as of Summer 2022 CPI 2.0 - credits get entered as monthly and we use annual rate cards

    //console.log('getUnitPrice --- credits : ' + credits + 'normalizedMonthlyRateCardVolume: ' + normalizedMonthlyRateCardVolume);
    //console.log(pricing.cpi_discount_schedules[getFrequencyNumberFromLabel(billingPeriodLabel)])

    for (let [k, v] of Object.entries(
        // props.apiData.pricing[props.currency].cpi_discount_schedules[getFrequencyNumberFromLabel(billingPeriodLabel)]
        pricing.cpi_discount_schedules[getFrequencyNumberFromLabel(billingPeriodLabel)]
    )) {

        // console.log(normalizedMonthlyRateCardVolume, v); return

        // sample v = {Id: "aDN1J0000004ClfWAE", min: 162000, max: 173000, price: 0.81}
        if(
            normalizedMonthlyRateCardVolume === v.min
            || normalizedMonthlyRateCardVolume === v.max
            || (normalizedMonthlyRateCardVolume > v.min && (normalizedMonthlyRateCardVolume < v.max || v.max === true))
        ) {
            return v // matched
        }
        /*
        else {
            console.log(credits, poolingFrequencyLabel, getFrequencyNumberFromLabel(poolingFrequencyLabel), v)
            console.log('NO MATCH FOUND FOR '+ normalizedMonthlyRateCardVolume)
            return v
        }*/
    }

    return {} // no match
}

const getFrequencyLabelFromNumber = (number) => {
    let inverse = Object.assign({}, ...Object.entries(POOLING_FREQUENCY).map(([a, b]) => ({[b]: a})))
    return inverse[number]
}
/**
 * Annual => 12, Quarterly => 3
 * @param label
 * @returns {*}
 */
export const getFrequencyNumberFromLabel = (label) => {
    return POOLING_FREQUENCY[label]
}


export const getDivisionFactorForBillingPeriod = (billing_period) => {
    let map  = {
        'Monthly' : 12,
        'Quarterly' : 4,
        'Semi-Annual' : 2,
        'Annual' : 1,
    }
    return map[billing_period];
}

export const POOLING_FREQUENCY = {
    'Monthly' : 1,
    'Quarterly' : 3,
    'Semi-Annual' : 6,
    'Annual' : 12,
}
// CPI Overage is by default marked up by 20% as base, can be marked up more as needed
export const CPI_OVERAGE_BASE_MARKUP = 1.2;
export const MIN_MARKUP_PERCENTAGE_THRESHOLD = -16.6 // +  -16.6% gets us back to the original, pre +20% markup price
