import React, {useEffect, useState} from 'react';
import './AiPanel.css';
import {formatPrice, arraySum} from "./QBApp";

export default function VelocityPackagesPanel(props) {

    const [selectedItem, setSelectedItem] = useState('');

    const handleChange = (event) => {
        console.log('VelocityPackagesPanel::handleChange() props.pricing ', props.pricing);
        console.log('VelocityPackagesPanel::handleChange() event.target.name: ' + event.target.name);
        console.log('VelocityPackagesPanel::handleChange() event.target.value: ', event.target.value);
        setSelectedItem(event.target.value);

        let velocitySelected = '';
        let prices = Object.entries(props.pricing.velocity_packages.options).reduce( (arr, [key, item]) => {
            if (key === event.target.value) {
                arr.push(item.price);
                velocitySelected = key
            }
            props.setVelocitySelected(velocitySelected);
            console.log('VelocityPackagesPanel::handleChange() setVelocitySelected to :', velocitySelected)
            return arr;
        }, [])


        let totals = {...props.totals};
        totals[TOTALS_COMPONENT_KEY_VELOCITY_PACKAGES] = arraySum(prices);

        //console.log('VelocityPackagesPanel::handleChange() - props.totals originally was ', props.totals)
        //console.log('VelocityPackagesPanel::handleChange() - updating props.setTotals() to ', totals)
        props.setTotals(totals);

    }



    const items =  (
               Object.entries(props.pricing).length > 0
            && Object.entries(props.pricing.velocity_packages).length > 0
            && Object.entries(props.pricing.velocity_packages.options).length > 0
        )
        ?
            Object.entries(props.pricing.velocity_packages.options).map( ([key, item]) => (
                // eg key=LP-00398
                <label htmlFor={key} key={key + '_label'} className="price-table">

                    <input type="radio"
                           key={key + '_radio'}
                           name="velocity_packages"
                           id={key}
                           value={key}
                           price={item.price}
                           className="price-table"
                           checked={selectedItem === key}
                           onChange={handleChange}
                    />

                    {item.title}
                    <span
                        id={key}
                        key={key}
                        value={key + '_span'}
                        price={item.price}
                        className={"price-table" + (selectedItem === key ? ' active' : '')}
                    >
                        {formatPrice(item.price, props.currencySymbol,'VelocityPackagesPanel.js')}
                    </span>

                    {(item.attachment && item.attachment.length > 0) ?
                        <div className="sowImg price-table">
                            <a target="_blank"
                               href={item.attachment}
                            >
                               <img src="/images/quote-builder/guru_logo.png" border="0" className="guruCard" />
                            </a>
                        </div>
                        : ''
                    }
                </label>
            ))
        : ''
    ;


    useEffect(() => {
        console.log('VelocityPackagesPanel::useEffect() props:', props);
        setSelectedItem('');
        props.setVelocitySelected('');
        console.log('VelocityPackagesPanel::useEffect() reset setCheckedItems');
    },[props.apiData, props.selectedOpportunity]);


    return (

        <>
            <div className="panel panel-default clearable_panel" id="panel_velocity_packages">
                <div className="panel-heading panel-orange">Velocity Packages<span className="collapse-option"></span>
                </div>
                <div className="panel-body">

                    <span className="panel-description"></span>
                    <p className="panel-monthly-cost">Package Cost</p>

                    { items }

                </div>

                <div className="panel-footer">
                    <p className="subtotal">Velocity Packages total <span className="velocity_packages_total">
                        {formatPrice(props.totals[TOTALS_COMPONENT_KEY_VELOCITY_PACKAGES], props.currencySymbol,'VelocityPackagesPanel.js')}
                    </span>
                    </p>
                </div>
            </div>

        </>

    )
}


export const TOTALS_COMPONENT_KEY_VELOCITY_PACKAGES = 'velocity';
/**
 *
 * @param productName
 * @returns {number}
 */
export const getVelocityTermInMonthsFromProductName = (productName) => {
    console.log('in getVelocityTermInMonthsFromProductName() productName: ', productName, productName.includes('Lite') ? 4 : 6)
    return productName.includes('Lite') ? 4 : 6
}
