import React, {useEffect, useState} from 'react';
import Select from 'react-select';
import './Opportunity.css';
import QBApp, {
    getDefaultPricingModel,
    INDUSTRY_AUTOMOTIVE,
    info_modal, PRICING_MODEL_NAMED_USER,
    QUOTE_FLOW_FLAG_IS_ORIGINAL,
    QUOTE_FLOW_FLAG_IS_RENEWAL,
    QUOTE_FLOW_FLAG_IS_UPSELL, QUOTE_FLOW_TYPE_ORIGINAL,
    QUOTE_FLOW_TYPE_RENEWAL
} from "./QBApp";
import ContractPanel from "./ContractPanel";
import MissingDataPanel from "./MissingDataPanel";
import {validateSalesforceOpportunityDataIntegrity} from "../validation/OpportunityValidation";



export default function Opportunity(props) {

    // Similar to componentDidMount and componentDidUpdate:
    useEffect(() => {
        // Update the document title using the browser API
        // document.title = `opportunities count: ${opportunities.length}`;
        // console.log(`Opportunity.js:useEffect()`);

        if(Object.keys(props.apiData).length > 0) {

            /*
            console.log('Opportunity.js:useEffect() selectedIndustry : ', props.selectedIndustry);
            console.log('Opportunity.js:useEffect() props.selectedOpportunity : ', props.selectedOpportunity);

            console.log('Opportunity.js:useEffect() props.apiData : ',  props.apiData);
            console.log('Opportunity.js:useEffect() props.currency : ' + props.currency);
            */

            props.setPricing(props.apiData.pricing[props.currency])


            //console.log('Opportunity.js:useEffect() props.apiData[\'pricing\'][props.currency] : ', props.apiData['pricing'][props.currency]);
            // console.log('Opportunity.js:useEffect() updating props.setPricing()');
            if(props.selectedIndustry === ''){
                handleIndustryChange(props.apiData.account.LP_Industry__c)
            }
            /* done in QBAPp.js:
            if(props.selectedOpportunity == '' && Object.keys(props.apiData.opportunities).length>0){
                let defaultOpp = props.apiData.opportunities[1];
                defaultOpp.label = defaultOpp.Name;
                defaultOpp.value = defaultOpp.Id;
                handleOpportunityChange(defaultOpp);
            }*/

            console.log('CALLING......... : props.updatePlatformUserData(props.apiData, '+props.currency + ' );', props.apiData);

            props.updatePlatformUserData(props.apiData, props.currency);
            // setPlatformUserDataCache(platform_levels);

            /*
            if(Object.keys(props.namedUserPlatformLevels).length>0) {
                props.setDefaultNamedUserPlatformLevel(props.namedUserPlatformLevels[1]);
                console.log('in Opportunity.js :: changed named user platform level: ', props.namedUserPlatformLevels[1]);
            }*/


        }

    }, [props.selectedOpportunity, props.apiData, props.currency, props.flowType,
        props.opportunities,
        // opportunities get re-filtered in parent component (or other children) based on flow
        // e.g.for Renewals we only show Renewal Opportunities
        props.opportunitiesFilterTypes
    ]); // only update if any of the above get updated


    function handleOpportunityChange(e) {

        if(e.value === null){
            console.log('selected null @ Opportunity');
            return;
        }

        if(!validateSalesforceOpportunityDataIntegrity(e)){
            return false
        }

        props.setSelectedOpportunity(e);
        console.log("Opportunity selected:", e.value)
        // loadAccountOpportunities(e.value);
        props.updateCurrency(e.CurrencyIsoCode);
        console.log("Opportunity CurrencyIsoCode:" + e.CurrencyIsoCode);





        //setPricing(props.apiData['pricing'][currencyCode]);
        //console.log('Opportunity.js: handleOpportunityChange() set pricing to ', props.apiData['pricing'][currencyCode]);

    };

    function getFilteredOpportunities() {
        if(props.flowType[QUOTE_FLOW_FLAG_IS_RENEWAL]) {
            return props.selectedAccount.filteredOpportunities[OPPORTUNITY_TYPE_RENEWAL]
        } else if(props.flowType[QUOTE_FLOW_FLAG_IS_UPSELL]) {
            return props.selectedAccount.filteredOpportunities[OPPORTUNITY_TYPE_UPSELL]
        } else {
            // Original Opportunities
            return props.selectedAccount.filteredOpportunities[OPPORTUNITY_TYPE_ORIGINAL]
        }
    }


    function handleIndustryChange(e) {

        if(e === null){ // no account industry in SFDC
            return
        }

        console.log('Opportunity.js:: in handleIndustryChange(), debug ', props.selectedAccount.isPartner)
        console.log('Opportunity.js:: in handleIndustryChange(), debug ', e.value)

        if (props.selectedAccount.isPartner && e.value == INDUSTRY_AUTOMOTIVE) {

            if(props.selectedIndustry.value === INDUSTRY_AUTOMOTIVE){
                // if the partner account Opportunity somehow already has an Automotive industry, reset it in UI
                console.log('Opportunity.js:: in handleIndustryChange(), force resetting industry to blank from '+INDUSTRY_AUTOMOTIVE)
                props.setSelectedIndustry('')
            }

            let msg = 'Thank you for using Quote Builder, you selected a Partner account and at this time QB does not support Automotive Industry for Partner accounts';
            info_modal('Unsupported Option', msg)
            return false
        } else {
            let newIndustryObject = {'label' : e.value, 'value': e.value};

            if(     props.selectedIndustry && props.selectedIndustry.value === INDUSTRY_AUTOMOTIVE && e.value !== INDUSTRY_AUTOMOTIVE
                ||  props.selectedIndustry && props.selectedIndustry.value !== INDUSTRY_AUTOMOTIVE && e.value === INDUSTRY_AUTOMOTIVE
            ) {
                // when switching to/from Automotive -- show the pricing catalog popup notification
                const AUTOMOTIVE_AVATAR_IMG = '<img src="/images/quote-builder/avatar_auto_funny.png">';
                let msg = 'Please note that some industries have different pricing catalogs, by changing the industry we must reset your quote to offer you the relevant catalog.'
                let buttons = {
                    keep: {
                        text: 'Keep <strong>"'+props.selectedIndustry.value+ '"</strong>', // is_enterprise_user ? "Velocity Packages" : "Named User",
                        keys: ['esc'],
                        action: function(){
                            // nothing to change here, keep everything as is...
                            console.log('Opportunity.js:: in handleIndustryChange(), kept existing industry')
                        }
                    },
                    change: {
                        text: 'Change to <strong>"'+e.value+ '"</strong>', // "Always On Advisor", // stay in managed services / Always On Advisor
                        class: 'btn-success',
                        action: function(){
                            props.setSelectedIndustry(newIndustryObject);
                            props.setPricingModel(getDefaultPricingModel(props.selectedAccount))
                            console.log('Opportunity.js:: in handleIndustryChange(), updated industry to ', newIndustryObject)
                        }
                    },
                }
                info_modal(
                    AUTOMOTIVE_AVATAR_IMG + '<br><br>' + 'Pricing Catalog',
                    msg,
                    buttons
                )
                return false
            } else {

                // base case -- e.g. just switch to selected industry without errors/warnings/info popups
                console.log('Opportunity.js:: in handleIndustryChange(), setting props.setSelectedIndustry() to ', newIndustryObject)
                props.setSelectedIndustry(newIndustryObject)
                return true
            }
        }
    }

    return (
        <>
            {/**}
            <hr />
            { 'selected acc: ' + props.accountId }
            <hr />
            { '#opps: ' +  props.opportunities.length }
            <hr />
            { 'selected opp: ' +  selectedOpportunity.value }
            <hr />

            <pre>
                pricing data
                { <code> props.apiData['pricing'] ? props.apiData['pricing'][currency] : '' </code>}
            </pre>
            **/}

            <div className="panel panel-default" id="panel_account">
                <div className="panel-heading panel-inst"><strong>Before building your quote</strong> please select
                    order form details.
                </div>
                <div className="panel-body padding1515">
                    <div className="row detail-boxes" disabled={props.isWaitingForData}>

                        <div className="col-sm-7">
                            <div className="inputLabel inputLabelFloatLeft">Opportunity <span className="required">*</span>
                                <Select
                                    // isClearable
                                    placeholder={"Loading..."}
                                    id={"sf_opps"}
                                    className={"sf_opps"}
                                    value={props.selectedOpportunity}
                                    onChange={handleOpportunityChange}
                                    // missing Id signified a placeholder - meaning we want to lock it to just that option
                                    isDisabled={("Id" in props.selectedOpportunity) ? false : true}
                                    options={getFilteredOpportunities()}
                                />
                            </div>

                        </div>
                        <div className="col-sm-5">
                            <div className="inputLabel inputLabelFloatRight">Industry <span className="required">*</span>
                                <Select
                                    // isClearable
                                    placeholder={props.selectedAccount ? 'Select an option' : "Loading..."}
                                    id={"sf_industries"}
                                    className={"sf_industries"}
                                    options={props.industries}
                                    value={props.selectedIndustry}
                                    onChange={handleIndustryChange}
                                />
                            </div>
                        </div>
                    </div>


                    <MissingDataPanel
                        selectedOpportunity={props.selectedOpportunity}
                        selectedAccount={props.selectedAccount}
                        inlineOpportunityUpdates={props.inlineOpportunityUpdates}
                        setInlineOpportunityUpdates={props.setInlineOpportunityUpdates}
                        missingDataPanelErrors={props.missingDataPanelErrors}
                        setMissingDataPanelErrors={props.setMissingDataPanelErrors}
                        pricing={props.apiData.pricing[props.currency]}

                    />

                    <ContractPanel
                        isWaitingForData={props.isWaitingForData}
                        flowType={props.flowType}
                        setFlowType={props.setFlowType}
                        apiData={props.apiData}
                        quoteType={props.quoteType}
                        setQuoteType={props.setQuoteType}
                        selectedAccount={props.selectedAccount}
                        selectedOpportunity={props.selectedOpportunity}
                        setSelectedOpportunity={props.setSelectedOpportunity}
                        useNewOpportunity={props.useNewOpportunity}
                        setUseNewOpportunity={props.setUseNewOpportunity}
                        setContractId={props.setContractId}
                        setContract={props.setContract}
                        contract={props.contract}
                        resetTerm={props.resetTerm}
                        setResetTerm={props.setResetTerm}
                        billingDetails={props.billingDetails} // used when we reset contract term
                        setBillingDetails={props.setBillingDetails} // used when we reset contract term

                        // filter Opportunities based on the Contract flow e.g. Type=Renewal only for Renewal flow etc
                        opportunitiesFilterTypes={props.opportunitiesFilterTypes}
                        setOpportunitiesFilterTypes={props.setOpportunitiesFilterTypes}
                        handlePricingModelChange={props.handlePricingModelChange}
                    />

                </div>
                <div className="panel-footer" style={{padding: 0,backgroundColor:'#ffffff', borderTop:0}}></div>

            </div>










        </>
    )


}

export const OPPORTUNITY_TYPE_ORIGINAL = 'Original';
export const OPPORTUNITY_TYPE_RENEWAL = 'Renewal';
export const OPPORTUNITY_TYPE_UPSELL = 'Upsell';




