/**
 * First we will load all of this project's JavaScript dependencies which
 * includes React and other helpers. It's a great starting point while
 * building robust, powerful web applications using React + Laravel.
 */

import * as React from 'react';
require('./bootstrap');
require('./quote_builder');
require('./account');

require('jquery-confirm');
require('jquery-password-requirement-checker/dist/jquery.password-requirements-checker');


/**
 * Next, we will create a fresh React component instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

require('./components/QBApp');
