import React, {useEffect} from 'react';
import {
    INDUSTRY_AUTOMOTIVE,
    PRICING_MODEL_ALWAYS_ON_ADVISOR,
    PRICING_MODEL_CPI,
    PRICING_MODEL_NAMED_USER,
    PRICING_MODEL_VELOCITY, PRICING_MODEL_WHATSAPP_USAGE, QUOTE_FLOW_FLAG_IS_ORIGINAL
} from "./QBApp";

export default function PricingModelNavigation(props) {


    useEffect(() => {
        /*
        console.log('PricingModelNavigation::useEffect()')
        console.log('PricingModelNavigation::useEffect(): debug props.selectedIndustry.value:'+ props.selectedIndustry.value,
            (!(props.selectedAccount.isEnterpriseUser) && props.selectedIndustry.value === INDUSTRY_AUTOMOTIVE)
        )
        */
    }, [
        props.leftNavVisible,   // initial load/reload
        props.selectedAccount,  // reloads the nav to reflect the account data
        props.pricingModel,     // filters the nav options
        props.isWaitingForData, // locks/unlocks hte nav
        props.selectedIndustry, // when switching to/from Automotive
    ]);


    const recentOrders = (limit=3) => {
        return Object.entries(props.apiData).length > 0 &&
            Object.entries(props.apiData.recent_account_orders).length > 0
            ? Object.entries(props.apiData.recent_account_orders).slice(0, limit).map(([key, item]) => (
                (item.error || item.is_pending_approval)
                    ?
                    <div className="recentOrderLineItem" key={key}>
                        <hr style={{margin:"8px 0 8px 0",padding:0}}/>
                        OF#{item.order_id} {item.error ? item.error : '(pending approval)'}
                    </div>
                    :
                    <div className="recentOrderLineItem" key={key}>
                        <hr style={{margin:"8px 0 8px 0",padding:0}}/>
                        OF#{item.order_id} {item.rmr} <br/>
                        {item.date_created}
                        &nbsp; { item.pdf_link ? <a href={item.pdf_link} target="_blank">PDF</a> : ''}
                        {item.cc_link ? '|' : ''}
                        { item.cc_link ? <a href={item.cc_link} target="_blank">CC Link</a> : ''}
                    </div>
            ))
            
            : NO_RECENT_ITEMS_BLOCK

    }

    const whatsAppUsage = (limit=6) => {
        return Object.entries(props.apiData).length > 0 &&
        Object.entries(props.apiData.recent_whatsapp_usage.months_years).length > 0
            ? Object.entries(props.apiData.recent_whatsapp_usage.months_years).slice(0, limit).map(([key, item]) => (
                <div className="recentOrderLineItem" key={key}>
                    <hr style={{margin:"8px 0 8px 0",padding:0}}/>
                    <a href={"#"}
                       onClick={e => props.handlePricingModelChange(PRICING_MODEL_WHATSAPP_USAGE, {selectedMonth: key})}
                    >{item}</a><br />
                </div>
            ))

            : NO_RECENT_ITEMS_BLOCK
    }


    const NO_RECENT_ITEMS_BLOCK = <div className="recentOrderLineItem" key={'recentOrderLineItem'}>
        <hr style={{margin:"8px 0 8px 0",padding:0}}/>
        <i>(no recent items)</i><br />
    </div>


    return (

        <>
            <div id="pricing-model-nav" className={
                (props.leftNavVisible ? 'displayBlock' : 'displayNone')
                + (props.isWaitingForData ? ' disabledBlock' : '')
            }>
                Pricing Model:
                <div className="nav flex-column nav-pills" id="pricing-menu" role="tablist"
                     aria-orientation="vertical">

                    {props.flowType[QUOTE_FLOW_FLAG_IS_ORIGINAL] &&
                        <a className={"nav-link" + (props.pricingModel === PRICING_MODEL_CPI ? ' active' : '')}
                           id="v-pills-cpi-tab" data-toggle="tab" href="#v-pills-cpi"
                           role="tab" selection="cpi"
                           aria-selected={props.pricingModel === PRICING_MODEL_CPI ? 'true' : 'false'}
                            // onClick={e=>props.handlePricingModelChange(props.pricingModel)}
                           onClick={e => props.handlePricingModelChange(PRICING_MODEL_CPI)}
                        >CPI</a>
                    }


                    {//!(props.selectedAccount.isEnterpriseUser) && # open NU option up to everyone
                        <>
                            {props.selectedIndustry.value === INDUSTRY_AUTOMOTIVE &&
                            <a className={"nav-link" + (props.pricingModel === PRICING_MODEL_ALWAYS_ON_ADVISOR ? ' active' : '')}
                               id="v-pills-managed-tab" data-toggle="tab"
                               href="#v-pills-managed" role="tab" selection="managed"
                               onClick={e => props.handlePricingModelChange(PRICING_MODEL_ALWAYS_ON_ADVISOR)}
                               aria-selected={props.pricingModel === PRICING_MODEL_ALWAYS_ON_ADVISOR ? 'true' : 'false'}>
                                Always On Advisor</a>
                            }

                        <a className={"nav-link" + (props.pricingModel === PRICING_MODEL_NAMED_USER ? ' active' : '')}
                           id="v-pills-commercial-tab" data-toggle="tab"
                           href="#v-pills-commercial" role="tab" selection="commercial"
                           onClick={e => props.handlePricingModelChange(PRICING_MODEL_NAMED_USER)}
                           aria-selected={props.pricingModel === PRICING_MODEL_NAMED_USER ? 'true' : 'false'}>
                            Named User</a>
                        </>
                    }

                    <a className={"nav-link" + (props.pricingModel === PRICING_MODEL_VELOCITY ? ' active' : '')} id="v-pills-velocity-tab" data-toggle="tab"
                       href="#v-pills-velocity" role="tab" selection="velocity"
                       onClick={e=>props.handlePricingModelChange(PRICING_MODEL_VELOCITY)}
                       aria-selected={props.pricingModel === PRICING_MODEL_VELOCITY ? 'true' : 'false'}>Velocity Packages</a>

                </div>

                {Object.entries(props.apiData).length > 0 &&
                <div id="recent_orders navPanelPadding">
                    <br/>Recent Orders: <br/>
                    <div style={{margin: "2px 0 0 8px"}}>
                        {recentOrders()}
                    </div>
                </div>
                }

                {Object.entries(props.apiData).length > 0 &&
                <div id="recent_orders navPanelPadding">
                    <br/>
                    {Object.entries(props.apiData.recent_whatsapp_usage.months_years).length > 0 ?
                        <a href="#"
                           onClick={() => props.handlePricingModelChange(PRICING_MODEL_WHATSAPP_USAGE, {selectedMonth: Object.keys(props.apiData.recent_whatsapp_usage.months_years)[0]})}>Recent
                            WhatsApp Usage:</a>
                        : <>Recent WhatsApp Usage:</>
                    }
                    <br/>
                    <div style={{margin: "2px 0 0 8px"}}>
                        {whatsAppUsage()}
                    </div>
                </div>
                }

            </div>



         </>
    )

}

export function selectMenuItem (pricingModel){
    let pricing_models = ['commercial', 'cpi', 'managed', 'velocity'];
    // console.log('selectMenuItem ' + itemName + ' from', pricing_models);
    pricing_models.forEach(function(item, index) {
        if(item !== pricingModel) {
            $('#v-pills-' + item + '-tab').attr('aria-selected', false);
            $('#v-pills-' + item + '-tab').removeClass('active');
        } else {
            $('#v-pills-' + item + '-tab').attr('aria-selected', true);
            $('#v-pills-' + item + '-tab').addClass('active');
        }
    });
}

export const MONTH_MM_NUMBER_TO_NAME_SHORT = {
    '01': "Jan",
    '02': "Feb",
    '03': "Mar",
    '04': "Apr",
    '05': "May",
    '06': "Jun",
    '07': "Jul",
    '08': "Aug",
    '09': "Sep",
    '10': "Oct",
    '11': "Nov",
    '12': "Dec"
};


