import React, {useEffect, useState} from 'react';
import '../MyQuoteSummaryPanel.css';
import {
    formatPrice, info_modal
} from "../QBApp";
import CONTAINER_MIN_WIDTH_WITHOUT_SCROLLBAR from "../MyQuoteSummaryPanel"
import {getShortMonthNameFromMmDashYyyy} from "./WhatsAppUsagePanel";

export default function WhatsAppSummaryPanel(props) {

    const [selectedMonths, setSelectedMonths] = useState({})
    const [totalSum, setTotalSum] = useState(0)
    const [filteredItems, setFilteredItems] = useState([])

    const HARDCODED_CURRENCY_SYMBOL = '$'

    const handleSelectedMonths = (months) => {
        setSelectedMonths(months);
        let totalSum = 0;
        let filteredItems = [];
        if('selectedMonth' in props.pricingModelAdditionalSettings && props.pricingModelAdditionalSettings.selectedMonth.length > 0) {
            Object.entries(props.whatsAppUsage.usage)
                .filter((item) => {
                    if ((item[1].month + '-' + item[1].year) === props.pricingModelAdditionalSettings.selectedMonth) {
                        console.log('filtered IN', item[1]);
                        totalSum = totalSum + parseFloat(item[1].total);
                        filteredItems.push(item[1]);
                    }
                })
        }
        setFilteredItems(filteredItems);
        setTotalSum(totalSum)
    }


    const generateUsageStatement = () => {

        if(totalSum === 0){
            info_modal('Data Issue', 'Cannot generate a blank report')
            return false;
        }
        if(!props.billingDetails.sf_contacts
            || !('value' in props.billingDetails.sf_contacts)
            || props.billingDetails.sf_contacts.value.length === 0){
            info_modal('Missing Data', 'PLease select a contact before proceeding')
            return false;
        }


        let payload =  {
            AccountId: props.selectedAccount.Id,
            CurrencySymbol: HARDCODED_CURRENCY_SYMBOL,
            billingContact: props.billingDetails.sf_contacts.value,
            lineItems: filteredItems,
            totalSum: totalSum,
            selectedMonth: props.pricingModelAdditionalSettings.selectedMonth,

        }

        console.log('generateUsageStatement() - posting: ', payload);

        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'X-CSRF-TOKEN': window.csrf_token
            },
            body: JSON.stringify(payload)
        };

        window.qb.build_whatsapp_usage_statement.display();

        fetch('/build_whatsapp_usage_statement', requestOptions)

            .then(async response => {

                const data = await response.json();
                console.log('got: ', data);
                window.qb.build_whatsapp_usage_statement.done(
                    '/download_whatsapp_usage_statement/?s3_file_path=' +
                    encodeURIComponent(data.s3_file_path)
                );

            })
            // .then(data => setPostId(data.id))
            .catch(error => {
                console.log('ERROR:', error)
            })
    }


    useEffect(() => {
        console.log('WhatsAppSummaryPanel::useEffect()')

        if(props.pricingModelAdditionalSettings.selectedMonth) {
            handleSelectedMonths([props.pricingModelAdditionalSettings.selectedMonth])
        } else {
            handleSelectedMonths([])
        }

    },[props.selectedAccount,
        props.pricingModelAdditionalSettings.selectedMonth,
        props.pricingModel
    ]);




    return (
        !props.isWaitingForData &&
        props.pricingModelAdditionalSettings &&
        'selectedMonth' in props.pricingModelAdditionalSettings &&
        <>
            <div className="row" id="myQuoteContainer">


                <div id="my_quote_sidebar_commercial"
                     className={
                         'col-lg-2 col-md-3 sidebar clearable_panel '
                         + (props.floatWhenScrolling ? ' fix-sidebar' : '')
                     }
                     style={
                         props.showSmallScrollableQuote
                             ? {minWidth:CONTAINER_MIN_WIDTH_WITHOUT_SCROLLBAR,
                                 direction:"rtl",
                                 overflowY:"auto",
                                 height: (window.innerHeight-20)
                             }
                             : {minWidth:CONTAINER_MIN_WIDTH_WITHOUT_SCROLLBAR}
                     }
                >


                    <div className="sidebar-header2">
                        <div className="myQuote">
                            <div className="floatLeft">
                                <img src="/images/quote-builder/Quote-Icon.png" />
                            </div>
                            <div className="floatLef myQuoteText">
                                <div>WhatsApp Usage</div>
                                <div className="subtitle">
                                    {getShortMonthNameFromMmDashYyyy(props.pricingModelAdditionalSettings.selectedMonth)}
                                    &nbsp; {props.pricingModelAdditionalSettings.selectedMonth.split('-')[1]}

                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="sidebar-body" style={{fontSize:"13px",clear: "both"}}>
                                <div className="row">
                                    <div className="col-xl-12 paddingLRNone marginTop8">
                                        Country <span className="floatRight">Total</span>
                                        <hr className="hr-solid" style={{margin: "0 0 5px 0"}} />

                                        <div>
                                            {lineItems(filteredItems, HARDCODED_CURRENCY_SYMBOL)}
                                        </div>

                                    </div>

                                    <div className="col-xl-12 col-lg-4 paddingLRNone">

                                        <hr className="hr-dotted" style={{margin:"0 0 5px 0"}}/>
                                        <p className="cost-totals">Period total
                                            <span name="quote_annual_total_commercial2">
                                                {formatPrice(totalSum, HARDCODED_CURRENCY_SYMBOL)}
                                            </span>
                                        </p>
                                    </div>
                                </div>


                        <button type="submit"
                                className="btn btn-lg btn-default mt-3 submit-quote"
                                id="submit-quote"
                                onClick={generateUsageStatement}
                                disabled={props.isWaitingForData}
                        >Build Usage Statement</button>



                    </div>
                </div>
            </div>

        </>

    )

}

export const hr = <hr className="hr-solid" style={{margin: "5px 0 0 0"}} />

export function lineItems (items, currencySymbol) {
    return Object.entries(items).length > 0
        ? Object.entries(items).map(([key, item]) => (
            <div key={key} style={{width:230}}>
                <div style={{minWidth:150,width:150,height:22,overflow:"hidden",float:"left"}}>{item.destination}</div>
                <div style={{minWidth:70,width:70,height:22,float:"right",textAlign:"right",paddingRight:0}}>{formatPrice(item.total, currencySymbol)}</div>
            </div>

        ))
        : '';
}


