import {isVowel} from "../components/QBApp";

export class ModalUtil {}

    /**
     * I'm trying to do [qb flow] for account
     * [SFDC account]/[ACCOUNT NAME], but QB
     * detetected that my contract sub is having
     * issues [insert issue]
     */
    export const getContactFieldOpsText = (account, opportunity, quoteType, quote_issue='', return_string='text') => {
        let email_address = 'revops-lp@liveperson.com';
        let quote_type = encodeURIComponent(quoteType);
        let account_name = encodeURIComponent(account.Name);
        let account_url = account.web_link;

        let opp_url = (opportunity && Object.entries(opportunity).length>0) ? encodeURIComponent(opportunity.web_link) : '(not selected)';
        quote_issue = encodeURIComponent(quote_issue);
        let br = '%0D%0A%0D%0A';
        let email_body = 'Hi,'
            +br+'I am trying to build '
            + (quote_type
                ? (isVowel(quote_type) ? 'an ' : 'a ') +(quote_type ? quote_type+' ' : '')
                : 'a '
            )
            +'Quote for account ' + account_name + ' but QB detected the following issue: '
            +br+ quote_issue
            +br+ 'SFDC Account link: '+account_url
            +(opportunity
                ? ((opp_url && opp_url!='undefined') ? ('%0D%0ASFDC Opportunity link: '+opp_url) : '')
                : ''
            )
            +br+'Cheers!';
        let mailto = 'mailto:'+email_address+'?subject=QB Quote Request for ' + account_name + ' Account&body=' + email_body;

        let text = ' Please reach out to the awesome field ops team '
            + '<br>to update this information before building a Quote.';

        if(return_string == 'text'){
            return text;
        } else if(return_string == 'mailto'){
            return mailto;
        }
    }

    export const getContactFieldOpsPopupButton = (account, opportunity, quoteType, quote_issue) => {
        let mailto = getContactFieldOpsText(account, opportunity, quoteType, quote_issue, 'mailto');

        let contactButton = {
                text: "Email RevOps",
                btnClass: 'btn-primary',
                keys: ['enter'],
                action: function(){
                    window.open(mailto);
                    location.reload();
                }
            }

        return {contactButton}

        /*
        return whatsAppUsageButton !== null
            ? {contactButton, whatsAppUsageButton}
            : {contactButton}
        */
    }
