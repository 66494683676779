import {getContactFieldOpsPopupButton, getContactFieldOpsText} from "../modals/ModalUtil";
import {error_modal, PRICING_MODEL_WHATSAPP_USAGE} from "../components/QBApp";
import {hasValidContract} from "./ContractValidation";

class AccountValidation {}
/*

{
	"attributes": {
		"type": "Account",
		"url": "/services/data/v49.0/sobjects/Account/001q000001FmfvgAAB"
	},
	"Reseller_Parent__c": null,
	"Id": "001q000001FmfvgAAB",
	"Name": "QB Refactoring USD Partner original 2",
	"Type": "Client",
	"Phone": "(212) 609-4234",
	"Website": "www.liveperson.com",
	"OwnerId": "0051J000006owYTQAY",
	"Industry": null,
	"ParentId": null,
	"Legal_Entity__c": "LP Inc",
	"LPSiteID1__c": null,
	"VAT__c": null,
	"Registration_Tax_ID__c": null,
	"Zuora_Legal_Entity_ID__c": null,
	"LP_Industry__c": "Telco",
	"RecordTypeId": "012000000000gWOAAY",
	"World_Sales_Region__c": "North America",
	"CurrencyIsoCode": "USD",
	"is_prospect": false,
	"is_client": true,
	"is_unpaid_trial": false,
	"current_contract": {
		"attributes": {
			"type": "Contract",
			"url": "/services/data/v49.0/sobjects/Contract/800q00000004TcAAAU"
		},
		"ContractNumber": "00060054",
		"StartDate": "2020-04-01 00:00:00",
		"EndDate": "2021-03-31 00:00:00",
		"SBQQ__ExpirationDate__c": "2021-03-31 00:00:00",
		"AccountId": "001q000001FmfvgAAB",
		"Status": "Active",
		"ContractTerm": 12,
		"Id": "800q00000004TcAAAU",
		"SBQQ__RenewalTerm__c": 12,
		"SBQQ__Opportunity__c": "006q000000KBrETAA1",
		"SBQQ__Quote__c": "aDwq00000000C8QCAU",
		"CurrencyIsoCode": "USD",
		"Billing_Contact__c": "003q0000016ewCZAAY",
		"SBQQ__RenewalOpportunity__c": "006q000000KCsWSAA1",
		"SBQQ__Evergreen__c": false,
		"subscription_count": 1,
		"currency": "USD",
		"symbol": "$",
		"is_legacy_lpa": false,
		"billing_contact": {
			"attributes": {
				"type": "Contact",
				"url": "/services/data/v49.0/sobjects/Contact/003q0000016ewCZAAY"
			},
			"Id": "003q0000016ewCZAAY",
			"Salutation": null,
			"Name": "Ignas Brazdeikis",
			"FirstName": "Ignas",
			"LastName": "Brazdeikis",
			"Title": null,
			"AccountId": "001q000001FmfpnAAB",
			"Phone": "(212) 619-4234",
			"Email": "dmorgan@liveperson.com",
			"MobilePhone": null,
			"OwnerId": "0051J000006owYTQAY",
			"MailingAddress": {
				"city": "New York",
				"country": "United States",
				"geocodeAccuracy": null,
				"latitude": null,
				"longitude": null,
				"postalCode": "10018",
				"state": "New York",
				"street": "475 10th Ave"
			},
			"Contact_SiteID__c": null
		},
		"subscriptions": [{
			"attributes": {
				"type": "SBQQ__Subscription__c",
				"url": "/services/data/v49.0/sobjects/SBQQ__Subscription__c/aE5q000000003KHCAY"
			},
			"Id": "aE5q000000003KHCAY",
			"Name": "SUB-0038113",
			"SBQQ__Product__c": "01t1J00000BVyKMQA1",
			"SBQQ__Quantity__c": 5,
			"SBQQ__Account__c": "001q000001FmfvgAAB",
			"SBQQ__ProrateMultiplier__c": 12,
			"SBQQ__SubscriptionEndDate__c": null,
			"SBQQ__EndDate__c": "2021-03-31 00:00:00",
			"SBQQ__Contract__c": "800q00000004TcAAAU",
			"RMR_Quote_Line__c": 626.5,
			"SBQQ__OriginalQuoteLine__c": "aDsq00000000RQuCAM",
			"SBQQ__Discount__c": null,
			"SBQQ__AdditionalDiscountAmount__c": null,
			"SBQQ__PartnerDiscount__c": 30,
			"SBQQ__SubscriptionType__c": "Renewable",
			"ProductName": "LiveEngage Premium",
			"product": {
				"attributes": {
					"type": "Product2",
					"url": "/services/data/v49.0/sobjects/Product2/01t1J00000BVyKMQA1"
				},
				"Name": "LiveEngage Premium",
				"Id": "01t1J00000BVyKMQA1",
				"SBQQ__ChargeType__c": "Recurring",
				"Family": "Commercial - Named Users",
				"ProductCode": "LPC-00002",
				"Description": "Unlimited live agent interactions and up to 1000 bot DAR per user\r\nIncludes: Basic features and\r\nApple Business Chat\r\nGoogle RCS\r\nSecure Forms\r\nUser targeting capabilities (web only)\r\nAuthenticated conversations ( web + in-app only)\r\nAgent Groups and LOB\r\nCRM Integrations (ZenDesk, Microsoft Dynamics, Sugar, Salesforce)\r\nSSO for Agents\r\nAdvanced reporting with full edit rights\r\nReal-time customer sentiment dashboard\r\nDedicated Account Manager",
				"Product_Deck_URL__c": null,
				"SBQQ__SortOrder__c": 730
			}
		}]
	},
	"web_link": "https://liveperson--staging.my.salesforce.com/001q000001FmfvgAAB",
	"owner_role": "Commercial - MM - New Business - INT - AE",
	"is_partner_indirect_sale": false,
	"is_partner_self_sale": false,
	"is_direct": true,
	"industries": {
		"Automotive": "Automotive",
		"Education": "Education",
		"Energy / Utilities": "Energy / Utilities",
		"Entertainment & Media": "Entertainment & Media",
		"FinServ": "FinServ",
		"Government/NGOs": "Government/NGOs",
		"Healthcare": "Healthcare",
		"Information Technology": "Information Technology",
		"Manufacturing/Wholesale": "Manufacturing/Wholesale",
		"Professional Services": "Professional Services",
		"Real Estate": "Real Estate",
		"Retail & E-commerce": "Retail & E-commerce",
		"Telco": "Telco",
		"Transportation & Logistics": "Transportation & Logistics",
		"Travel & Hospitality": "Travel & Hospitality"
	},
	"opportunities": [{
		"attributes": {
			"type": "Opportunity",
			"url": "/services/data/v49.0/sobjects/Opportunity/006q000000KtJv5AAF"
		},
		"Id": "006q000000KtJv5AAF",
		"Name": "QB Refactoring USD Partner original 2 - Amendment - Upsell",
		"AccountId": "001q000001FmfvgAAB",
		"CloseDate": "2020-06-22",
		"Effective_Date__c": "2020-06-22",
		"Amount": 19965,
		"Type": "Upsell",
		"OwnerId": "0051J000006owYTQAY",
		"Billing_Contact__c": "003q0000016ewCZAAY",
		"CurrencyIsoCode": "USD",
		"Billing_Frequency__c": "Annual",
		"Pricebook2Id": "01s00000000011oAAA",
		"Zuora_Entity_ID__c": "2c92c0f966a9b7430166ad5141b404e1",
		"StageName": "1. Plan/Create",
		"CreatedDate": "2020-06-22T19:06:59.000+0000",
		"QB_Order_ID__c": "1938",
		"Forecast_RMR_Bookings__c": 0,
		"Forecast_One_Time_Fee__c": 17280,
		"Expected_RMR__c": 895,
		"Upsell_Downsell_RMR__c": 268,
		"Next_Steps__c": null,
		"Competitor_Name__c": null,
		"Other_Competitor__c": null,
		"RecordTypeId": "012000000000iQ1AAI"
	}, {
		"attributes": {
			"type": "Opportunity",
			"url": "/services/data/v49.0/sobjects/Opportunity/006q000000KCsWSAA1"
		},
		"Id": "006q000000KCsWSAA1",
		"Name": "USD Partner original 2 - Upsell upon Renewal",
		"AccountId": "001q000001FmfvgAAB",
		"CloseDate": "2021-03-31",
		"Effective_Date__c": "2021-04-01",
		"Amount": 10740,
		"Type": "Upsell upon Renewal",
		"OwnerId": "0051J000006owYTQAY",
		"Billing_Contact__c": "003q0000016ewCZAAY",
		"CurrencyIsoCode": "USD",
		"Billing_Frequency__c": "Annual",
		"Pricebook2Id": "01s00000000011oAAA",
		"Zuora_Entity_ID__c": "2c92c0f966a9b7430166ad5141b404e1",
		"StageName": "1. Plan/Create",
		"CreatedDate": "2020-03-16T18:16:07.000+0000",
		"QB_Order_ID__c": null,
		"Forecast_RMR_Bookings__c": 10740,
		"Forecast_One_Time_Fee__c": 0,
		"Expected_RMR__c": 895,
		"Upsell_Downsell_RMR__c": 895,
		"Next_Steps__c": null,
		"Competitor_Name__c": null,
		"Other_Competitor__c": null,
		"RecordTypeId": "012000000000iQ1AAI"
	}, {
		"attributes": {
			"type": "Opportunity",
			"url": "/services/data/v49.0/sobjects/Opportunity/006q000000KBrETAA1"
		},
		"Id": "006q000000KBrETAA1",
		"Name": "USD Partner original 2 - Test - Original",
		"AccountId": "001q000001FmfvgAAB",
		"CloseDate": "2020-03-16",
		"Effective_Date__c": "2020-04-01",
		"Amount": 7518,
		"Type": "Original",
		"OwnerId": "0051J000006owYTQAY",
		"Billing_Contact__c": "003q0000016ewFCAAY",
		"CurrencyIsoCode": "USD",
		"Billing_Frequency__c": "Annual",
		"Pricebook2Id": "01s00000000011oAAA",
		"Zuora_Entity_ID__c": "2c92c0f966a9b7430166ad5141b404e1",
		"StageName": "Closed Won",
		"CreatedDate": "2020-02-26T16:28:09.000+0000",
		"QB_Order_ID__c": "4787",
		"Forecast_RMR_Bookings__c": 7518,
		"Forecast_One_Time_Fee__c": 0,
		"Expected_RMR__c": 627,
		"Upsell_Downsell_RMR__c": 627,
		"Next_Steps__c": "Mar 16, 2020 - (Cathal Malone) Testing Partner Self deals\r\nMar 04, 2020 - (Dan Morgan) 159",
		"Competitor_Name__c": null,
		"Other_Competitor__c": null,
		"RecordTypeId": "012000000000iQ1AAI"
	}],
	"open_opportunities": [{
		"attributes": {
			"type": "Opportunity",
			"url": "/services/data/v49.0/sobjects/Opportunity/006q000000KCsWSAA1"
		},
		"Id": "006q000000KCsWSAA1",
		"Name": "USD Partner original 2 - Upsell upon Renewal",
		"AccountId": "001q000001FmfvgAAB",
		"CloseDate": "2021-03-31",
		"Effective_Date__c": "2021-04-01",
		"Amount": 10740,
		"Type": "Upsell upon Renewal",
		"OwnerId": "0051J000006owYTQAY",
		"Billing_Contact__c": "003q0000016ewCZAAY",
		"CurrencyIsoCode": "USD",
		"Billing_Frequency__c": "Annual",
		"Pricebook2Id": "01s00000000011oAAA",
		"Zuora_Entity_ID__c": "2c92c0f966a9b7430166ad5141b404e1",
		"StageName": "1. Plan/Create",
		"CreatedDate": "2020-03-16T18:16:07.000+0000",
		"QB_Order_ID__c": null,
		"Forecast_RMR_Bookings__c": 10740,
		"Forecast_One_Time_Fee__c": 0,
		"Expected_RMR__c": 895,
		"Upsell_Downsell_RMR__c": 895,
		"Next_Steps__c": null,
		"Competitor_Name__c": null,
		"Other_Competitor__c": null,
		"RecordTypeId": "012000000000iQ1AAI",
		"label": "USD Partner original 2 - Upsell upon Renewal",
		"value": "006q000000KCsWSAA1"
	}, {
		"attributes": {
			"type": "Opportunity",
			"url": "/services/data/v49.0/sobjects/Opportunity/006q000000KtJv5AAF"
		},
		"Id": "006q000000KtJv5AAF",
		"Name": "QB Refactoring USD Partner original 2 - Amendment - Upsell",
		"AccountId": "001q000001FmfvgAAB",
		"CloseDate": "2020-06-22",
		"Effective_Date__c": "2020-06-22",
		"Amount": 19965,
		"Type": "Upsell",
		"OwnerId": "0051J000006owYTQAY",
		"Billing_Contact__c": "003q0000016ewCZAAY",
		"CurrencyIsoCode": "USD",
		"Billing_Frequency__c": "Annual",
		"Pricebook2Id": "01s00000000011oAAA",
		"Zuora_Entity_ID__c": "2c92c0f966a9b7430166ad5141b404e1",
		"StageName": "1. Plan/Create",
		"CreatedDate": "2020-06-22T19:06:59.000+0000",
		"QB_Order_ID__c": "1938",
		"Forecast_RMR_Bookings__c": 0,
		"Forecast_One_Time_Fee__c": 17280,
		"Expected_RMR__c": 895,
		"Upsell_Downsell_RMR__c": 268,
		"Next_Steps__c": null,
		"Competitor_Name__c": null,
		"Other_Competitor__c": null,
		"RecordTypeId": "012000000000iQ1AAI",
		"label": "QB Refactoring USD Partner original 2 - Amendment - Upsell",
		"value": "006q000000KtJv5AAF"
	}],
	"valid_billing_contacts": [{
		"attributes": {
			"type": "Contact",
			"url": "/services/data/v49.0/sobjects/Contact/003q0000016ewFCAAY"
		},
		"Id": "003q0000016ewFCAAY",
		"Salutation": null,
		"Name": "Willis Morgan",
		"FirstName": "Willis",
		"LastName": "Morgan",
		"Title": null,
		"AccountId": "001q000001FmfvgAAB",
		"Phone": "(212) 619-4234",
		"Email": "dmorgan@liveperson.com",
		"MobilePhone": null,
		"OwnerId": "0051J000006owYTQAY",
		"MailingAddress": {
			"city": "New York",
			"country": "United States",
			"geocodeAccuracy": null,
			"latitude": null,
			"longitude": null,
			"postalCode": "10018",
			"state": "New York",
			"street": "475 10th Ave"
		},
		"Contact_SiteID__c": null
	}],
	"parent_account": null,
	"contracts": [{
		"attributes": {
			"type": "Contract",
			"url": "/services/data/v49.0/sobjects/Contract/800q00000004TcAAAU"
		},
		"ContractNumber": "00060054",
		"StartDate": "2020-04-01 00:00:00",
		"EndDate": "2021-03-31 00:00:00",
		"SBQQ__ExpirationDate__c": "2021-03-31 00:00:00",
		"AccountId": "001q000001FmfvgAAB",
		"Status": "Active",
		"ContractTerm": 12,
		"Id": "800q00000004TcAAAU",
		"SBQQ__RenewalTerm__c": 12,
		"SBQQ__Opportunity__c": "006q000000KBrETAA1",
		"SBQQ__Quote__c": "aDwq00000000C8QCAU",
		"CurrencyIsoCode": "USD",
		"Billing_Contact__c": "003q0000016ewCZAAY",
		"SBQQ__RenewalOpportunity__c": "006q000000KCsWSAA1",
		"SBQQ__Evergreen__c": false,
		"subscription_count": 1,
		"currency": "USD",
		"symbol": "$",
		"is_legacy_lpa": false,
		"billing_contact": {
			"attributes": {
				"type": "Contact",
				"url": "/services/data/v49.0/sobjects/Contact/003q0000016ewCZAAY"
			},
			"Id": "003q0000016ewCZAAY",
			"Salutation": null,
			"Name": "Ignas Brazdeikis",
			"FirstName": "Ignas",
			"LastName": "Brazdeikis",
			"Title": null,
			"AccountId": "001q000001FmfpnAAB",
			"Phone": "(212) 619-4234",
			"Email": "dmorgan@liveperson.com",
			"MobilePhone": null,
			"OwnerId": "0051J000006owYTQAY",
			"MailingAddress": {
				"city": "New York",
				"country": "United States",
				"geocodeAccuracy": null,
				"latitude": null,
				"longitude": null,
				"postalCode": "10018",
				"state": "New York",
				"street": "475 10th Ave"
			},
			"Contact_SiteID__c": null
		},
		"subscriptions": [{
			"attributes": {
				"type": "SBQQ__Subscription__c",
				"url": "/services/data/v49.0/sobjects/SBQQ__Subscription__c/aE5q000000003KHCAY"
			},
			"Id": "aE5q000000003KHCAY",
			"Name": "SUB-0038113",
			"SBQQ__Product__c": "01t1J00000BVyKMQA1",
			"SBQQ__Quantity__c": 5,
			"SBQQ__Account__c": "001q000001FmfvgAAB",
			"SBQQ__ProrateMultiplier__c": 12,
			"SBQQ__SubscriptionEndDate__c": null,
			"SBQQ__EndDate__c": "2021-03-31 00:00:00",
			"SBQQ__Contract__c": "800q00000004TcAAAU",
			"RMR_Quote_Line__c": 626.5,
			"SBQQ__OriginalQuoteLine__c": "aDsq00000000RQuCAM",
			"SBQQ__Discount__c": null,
			"SBQQ__AdditionalDiscountAmount__c": null,
			"SBQQ__PartnerDiscount__c": 30,
			"SBQQ__SubscriptionType__c": "Renewable",
			"ProductName": "LiveEngage Premium",
			"product": {
				"attributes": {
					"type": "Product2",
					"url": "/services/data/v49.0/sobjects/Product2/01t1J00000BVyKMQA1"
				},
				"Name": "LiveEngage Premium",
				"Id": "01t1J00000BVyKMQA1",
				"SBQQ__ChargeType__c": "Recurring",
				"Family": "Commercial - Named Users",
				"ProductCode": "LPC-00002",
				"Description": "Unlimited live agent interactions and up to 1000 bot DAR per user\r\nIncludes: Basic features and\r\nApple Business Chat\r\nGoogle RCS\r\nSecure Forms\r\nUser targeting capabilities (web only)\r\nAuthenticated conversations ( web + in-app only)\r\nAgent Groups and LOB\r\nCRM Integrations (ZenDesk, Microsoft Dynamics, Sugar, Salesforce)\r\nSSO for Agents\r\nAdvanced reporting with full edit rights\r\nReal-time customer sentiment dashboard\r\nDedicated Account Manager",
				"Product_Deck_URL__c": null,
				"SBQQ__SortOrder__c": 730
			}
		}]
	}],
	"owner": {
		"attributes": {
			"type": "User",
			"url": "/services/data/v49.0/sobjects/User/0051J000006owYTQAY"
		},
		"Id": "0051J000006owYTQAY",
		"FirstName": "Daniel",
		"LastName": "Morgan",
		"Name": "Daniel Morgan",
		"Email": "dmorgan@liveperson.com",
		"Phone": null,
		"Title": "Analyst",
		"CompanyName": null,
		"IsActive": true,
		"UserRoleId": "00E2T0000029GtOUAU",
		"Named_User__c": false,
		"role": {
			"attributes": {
				"type": "UserRole",
				"url": "/services/data/v49.0/sobjects/UserRole/00E2T0000029GtOUAU"
			},
			"Id": "00E2T0000029GtOUAU",
			"Name": "Commercial - MM - New Business - INT - AE",
			"ParentRoleId": "00E2T0000029GtJUAU"
		}
	},
	"isEnterpriseUser": false,
	"isPartner": false,
	"isDirect": true,
	"hasValidContract": true,
	"hasRenewalOpportunities": false,
	"filteredOpportunities": {
		"Original": [],
		"Upsell": [{
			"attributes": {
				"type": "Opportunity",
				"url": "/services/data/v49.0/sobjects/Opportunity/006q000000KtJv5AAF"
			},
			"Id": "006q000000KtJv5AAF",
			"Name": "QB Refactoring USD Partner original 2 - Amendment - Upsell",
			"AccountId": "001q000001FmfvgAAB",
			"CloseDate": "2020-06-22",
			"Effective_Date__c": "2020-06-22",
			"Amount": 19965,
			"Type": "Upsell",
			"OwnerId": "0051J000006owYTQAY",
			"Billing_Contact__c": "003q0000016ewCZAAY",
			"CurrencyIsoCode": "USD",
			"Billing_Frequency__c": "Annual",
			"Pricebook2Id": "01s00000000011oAAA",
			"Zuora_Entity_ID__c": "2c92c0f966a9b7430166ad5141b404e1",
			"StageName": "1. Plan/Create",
			"CreatedDate": "2020-06-22T19:06:59.000+0000",
			"QB_Order_ID__c": "1938",
			"Forecast_RMR_Bookings__c": 0,
			"Forecast_One_Time_Fee__c": 17280,
			"Expected_RMR__c": 895,
			"Upsell_Downsell_RMR__c": 268,
			"Next_Steps__c": null,
			"Competitor_Name__c": null,
			"Other_Competitor__c": null,
			"RecordTypeId": "012000000000iQ1AAI",
			"label": "QB Refactoring USD Partner original 2 - Amendment - Upsell",
			"value": "006q000000KtJv5AAF"
		}],
		"Renewal": []
	}
}


 */


export function isEnterpriseUser(account) {
    if(!account || !account.owner){
        return false;
    } else {

        let ownerRole = account.owner.role.Name;
        let isNamedUser = account.owner.Named_User__c ? true : false;

        return (
            ownerRole.startsWith('Enterprise')
            && !isNamedUser
        );
    }
}
export function isPartner(account){
    if(account) {
        return (account.is_partner_indirect_sale || account.is_partner_self_sale);
    } else {
        return false;
    }
}

export function isResellerPartner(account){ // not self sale
    if(account) {
        return (account.is_partner_indirect_sale);
    } else {
        return false;
    }
}

export function isDirect(account){
    if(account) {
        return account.is_direct;
    } else {
        return false;
    }
}


export const hasWhatsAppUsageData = (apiData) => {
    return Object.entries(apiData).length > 0
        && Object.entries(apiData.recent_whatsapp_usage.months_years).length > 0
}

export const getWhatsAppUsageButton = (apiData) => {
    return {
        text: "Review WhatsApp Usage",
        // keys: ['esc'],
        action: function () {
            props.handlePricingModelChange(PRICING_MODEL_WHATSAPP_USAGE, {selectedMonth: Object.keys(apiData.recent_whatsapp_usage.months_years)[0]})
            //callback() // existingContractPopup.close()
            window.qb.popup_missing_error.close();
        }
    }
}


export function validateSalesforceAccountDataIntegrity(account, apiData){
    
    /**
     * Object.entries(props.apiData).length > 0 &&
     Object.entries(props.apiData.recent_whatsapp_usage.months_years).length > 0
     */

    if(account.Type === '' || account.Type === null) {
        let msg = 'Invalid Account Type Data'
        error_modal('Account Information Error',
            msg + getContactFieldOpsText(account, null, null, msg), getContactFieldOpsPopupButton(account, null, null, msg)
        )
        return false
    } else if(
        !account.is_partner_indirect_sale
        && !account.is_partner_self_sale
        && !account.is_direct
    ) {
        error_modal(
            'Account Type',
            'The account you selected has an unsupported account type: '
            + account.Type
            + '. Please select a different account or update the account in SFDC to one of the following supported account types:'
            + ' Client, Prior Client, Prospect, Unpaid Trial, Reseller Partner, Active.'
            +'<br><br><a class="btn btn-lg btn-primary" href="'+account.web_link+'">Open in SalesForce.</a></strong>'
        )
        return false


    } else if(account.Legal_Entity__c === null) {
        let msg = 'Legal entity must be selected on the account in Salesforce. '

        let buttons = getContactFieldOpsPopupButton(account, null, null, msg)

        if(hasWhatsAppUsageData(apiData)){
            buttons = {...buttons, ['whatsApp'] : getWhatsAppUsageButton(apiData)}
        }

        error_modal('Account Information Error',
            msg + getContactFieldOpsText(account, null, null, msg),
            buttons
        )
        return false

    /* MSA logic was disabled as requested by RevOps in August 2022
      } else if(isEnterpriseUser(account) && !hasValidContract(account.contracts) && !account.MSA_Status__c) {
        error_modal(
            'Missing MSA',
            'Please send out the MSA to your customer prior to building your quote'
        )
        return false
     */
    }



    return true
}
