import React, { useState, useEffect} from 'react';
import {
    DEFAULT_FLOW_STATE,
    error_modal, PRICING_MODEL_WHATSAPP_USAGE, QB_AVATAR_IMG,
    QUOTE_FLOW_FLAG_IS_FLAT_RENEWAL, QUOTE_FLOW_FLAG_IS_NEW_RENEWAL, QUOTE_FLOW_FLAG_IS_ORIGINAL,
    QUOTE_FLOW_FLAG_IS_RENEWAL,
    QUOTE_FLOW_FLAG_IS_UPSELL,
    QUOTE_FLOW_FLAG_IS_UPSELL_CO_TERM, QUOTE_FLOW_FLAG_IS_UPSELL_RESET_TERM,
    QUOTE_FLOW_TYPE_LPA_MIGRATION, QUOTE_FLOW_TYPE_RENEWAL,
    QUOTE_FLOW_TYPE_UPSELL_CO_TERM,
    QUOTE_FLOW_TYPE_UPSELL_RESET_EXTEND,
} from './QBApp';
import {getContactFieldOpsText, getContactFieldOpsPopupButton} from "../modals/ModalUtil";
import {DATE_FORMAT} from "./BillingPanel";
import {format as formatDate} from "date-fns";
import {OPPORTUNITY_TYPE_ORIGINAL, OPPORTUNITY_TYPE_RENEWAL, OPPORTUNITY_TYPE_UPSELL} from "./Opportunity";

export default function ContractPanel(props) {

    const [forceResetTerm, setForceResetTerm] = useState(false)
    const [showPanel, setShowPanel] = useState(false)

    function hasFilteredOpportunities(quoteFlowFlag='') {

        if (quoteFlowFlag == QUOTE_FLOW_FLAG_IS_RENEWAL
            || props.flowType[QUOTE_FLOW_FLAG_IS_RENEWAL]) {
            return props.selectedAccount.filteredOpportunities[OPPORTUNITY_TYPE_RENEWAL].length > 0
        } else if (quoteFlowFlag == QUOTE_FLOW_FLAG_IS_UPSELL
            || props.flowType[QUOTE_FLOW_FLAG_IS_UPSELL]) {
            return props.selectedAccount.filteredOpportunities[OPPORTUNITY_TYPE_UPSELL].length > 0
        } else {
            return props.selectedAccount.filteredOpportunities[OPPORTUNITY_TYPE_ORIGINAL].length > 0
        }

    }

    useEffect(()=>{

        console.log('ContractPanel::useEffect()(0) props.billingDetails.effective_date changed to '
            + props.billingDetails.effective_date
        )


        if(
            // Upsell with Effective Date that's AFTER the current contract's end date -- should not be allowed
            props.flowType[QUOTE_FLOW_FLAG_IS_UPSELL]
            && !props.flowType[QUOTE_FLOW_FLAG_IS_RENEWAL]
            && !props.contract.SBQQ__Evergreen__c // evergreen contracts do not have an end date
            &&  (new Date(props.billingDetails['effective_date']).getTime() >= new Date(props.contract.EndDate).getTime())
        ){
            effectiveDateErrorRenewalFlowSwitchPopup()
        }


    }, [props.billingDetails.effective_date])


    useEffect(() => {
        props.setContract({}) // reset

        // Update the document title using the browser API
        console.log('ContractPanel.js::ContractPanel useEffect() props.isWaitingForData:', props.isWaitingForData);
        console.log('ContractPanel.js::ContractPanel useEffect() props:', props);
        console.log('ContractPanel.js:: props.selectedAccount.is_client :', (props.selectedAccount.is_client))
        console.log('ContractPanel.js:: props.selectedAccount.hasValidContract :', props.selectedAccount.hasValidContract);
        console.log('ContractPanel.js:: !props.selectedAccount.is_client && props.selectedAccount.hasValidContract :',
            (!props.selectedAccount.is_client && props.selectedAccount.hasValidContract)
        );


        if(!props.isWaitingForData
            && Object.entries(props.apiData).length > 0
            && Object.entries(props.selectedAccount).length > 0
        ) {

            props.setOpportunitiesFilterTypes([OPPORTUNITY_TYPE_ORIGINAL])

            if (!props.selectedAccount.is_client && props.selectedAccount.hasValidContract) {
                console.log('ContractPanel.js:: prompt for "Contract but the Account Type is not Client');

                let msg = 'It appears that this account has a Contract but the Account Type is not set to '
                + '"Client" in SalesForce.';
                error_modal('Invalid Account data',
                    msg + getContactFieldOpsText(props.selectedAccount, props.apiData.selectedOpportunity, props.quoteType, msg),
                    getContactFieldOpsPopupButton(props.selectedAccount, props.apiData.selectedOpportunity, props.quoteType, msg)
                )
                props.setFlowType({...props.flowType,
                    [QUOTE_FLOW_FLAG_IS_UPSELL] : false,
                    [QUOTE_FLOW_FLAG_IS_ORIGINAL] : true
                })

            } else if (props.selectedAccount.is_client && !props.selectedAccount.hasValidContract) {

                console.log('ContractPanel.js:: prompt for "Please add a valid contract to the account"');
                props.setFlowType({...props.flowType,
                    [QUOTE_FLOW_FLAG_IS_UPSELL] : false,
                    [QUOTE_FLOW_FLAG_IS_ORIGINAL] : true
                })

                let msg = 'Please add a valid contract to the account. ';
                let buttons = getContactFieldOpsPopupButton(
                    props.selectedAccount,
                    props.apiData.selectedOpportunity,
                    props.quoteType,
                    msg
                )
                if(hasWhatsAppUsageData()){
                    buttons = {...buttons, getWhatsAppUsageButton}
                }


                error_modal(
                        'Sacrebleu !!!',

                        'If you are trying to do an upsell against this account,'
                        +' there is a slight hiccup in that this account does not have a valid contract. '
                        + msg + getContactFieldOpsText(
                            props.selectedAccount,
                            props.apiData.selectedOpportunity,
                            props.quoteType,
                            msg
                        ),
                    buttons,
                    null,
                    function(){window.qb.popup_missing_error.close();},
                    true,
                    hasWhatsAppUsageData() ? true : false // allow closing in case they want to review WhatsApp Usage

                );

            } else if (
                props.flowType[QUOTE_FLOW_FLAG_IS_ORIGINAL]
                && !hasFilteredOpportunities(QUOTE_FLOW_FLAG_IS_ORIGINAL)
                && !props.selectedAccount.is_client
                && !props.selectedAccount.hasValidContract
            ) {

                props.setFlowType({
                    ...props.flowType,
                    [QUOTE_FLOW_FLAG_IS_UPSELL]: false,
                    [QUOTE_FLOW_FLAG_IS_ORIGINAL]: true
                })

                // non-clients (SFDC Account.Type != Client) are expected to have existing open opportunities
                // unless they have a contract (upsell / renewals)
                error_modal('No Open Opportunities',
                    'This account has no open opportunities. Please create an opportunity in SalesForce or'
                    + ' choose a different account.'
                )


            } else if (props.selectedAccount.hasValidContract) {
                console.log('ContractPanel.js:: hasValidContract === TRUE, launching UPSELL flow')

                props.setFlowType({...props.flowType,
                    [QUOTE_FLOW_FLAG_IS_UPSELL] : true,
                    [QUOTE_FLOW_FLAG_IS_ORIGINAL] : false
                })
                props.setOpportunitiesFilterTypes([OPPORTUNITY_TYPE_UPSELL])

                // there should only be one active contract at any given time as per Tristan Linco (SFDC manager)
                // so, we pick the first active contract (active filtering happens on the back-end)

                let activeContract = props.selectedAccount.contracts[0]


                if (activeContract.SBQQ__Evergreen__c != true) {

                    // check that ContractTerm is > 0
                    if (!activeContract.ContractTerm || activeContract.ContractTerm == 0 || activeContract.ContractTerm == "") {
                        let msg = 'Invalid Contract Term: ' + activeContract.ContractTerm + '. '
                        error_modal('Contract Information Error',
                            msg + getContactFieldOpsText(props.selectedAccount, props.apiData.selectedOpportunity, props.quoteType, msg),
                            getContactFieldOpsPopupButton(props.selectedAccount, props.apiData.selectedOpportunity, props.quoteType, msg)
                        );
                        console.log('contract item', activeContract)
                        return false

                    } else if (
                        // BSS-4967 prevents issues with "The subscription term must be a positive number" error
                        // 05/02/2022 Sahil: The way we should be identifying an active contract is that today's date is in
                        // between the start and end date. Any contract created against an account will be marked as Active.
                        // we do a similar validation on the back-end, adding here as well as a check
                        new Date(activeContract.EndDate).getTime() < new Date().getTime()   // expired contract
                        || new Date(activeContract.StartDate).getTime() > new Date().getTime() // future contract
                    ) {
                        let msg = 'Contract Start/End dates cannot be in the future/past respectively.'
                        error_modal('Contract Information Error',
                            msg + getContactFieldOpsText(props.selectedAccount, props.apiData.selectedOpportunity, props.quoteType, msg),
                            getContactFieldOpsPopupButton(props.selectedAccount, props.apiData.selectedOpportunity, props.quoteType, msg)
                        )
                        return false
                    }
/*
                    } else if (!props.flowType[QUOTE_FLOW_FLAG_IS_RENEWAL]) {

                        // BSS-4967 prevents issues with "The subscription term must be a positive number" error
                        // 04/28/2022 Sahil: salesforce will generate the renewal quote for the renewal opp 90 days
                        // before the end of the contract. If we really do need to amend within those 90 days, I suggest
                        // that it is done at least 30 days before the end date to avoid the system populating the
                        // subscription term with 0.
                        const thirtyDaysInMs = 30 * 24 * 60 * 60 * 1000;
                        const timestampThirtyDaysInFuture = new Date().getTime() + thirtyDaysInMs;

                        if (new Date(activeContract.EndDate).getTime() < timestampThirtyDaysInFuture) {
                            // contract end date is less than 30d in the future

                            let msg = 'Contracts cannot be amended within 30 days of renewal. Please use a renewal opportunity or contact RevOps for assistance.'
                            error_modal('Contract Information Error',
                                msg + getContactFieldOpsText(props.selectedAccount, props.apiData.selectedOpportunity, props.quoteType, msg),
                                getContactFieldOpsPopupButton(props.selectedAccount, props.apiData.selectedOpportunity, props.quoteType, msg)
                            )
                            return false
                        }
                    }
*/



                    activeContract.subscriptions.some(function (item) {
                        // check for subscription term and valid start/end date e.g. (start can't be before end date)
                        if (new Date(item.SBQQ__EndDate__c).getTime() > new Date(item.SBQQ__StartDate__c).getTime()) {
                            let msg = 'Contract Subscription Line issue with Start Date before End Date. '
                            error_modal('Contract Information Error',
                                msg + getContactFieldOpsText(props.selectedAccount, props.apiData.selectedOpportunity, props.quoteType, msg),
                                getContactFieldOpsPopupButton(props.selectedAccount, props.apiData.selectedOpportunity, props.quoteType, msg)
                            )
                            return false
                        }
                    });
                }


                props.setContract(activeContract)

                // explicitly set the amended contract info since we default to it & since there is no need for OnChange/OnClick
                props.setContractId(activeContract.Id);

                launchExistingContractFlow(props.selectedAccount, activeContract);


            } else {
                console.log('ContractPanel.js:: NOT isUpsell')
                props.setFlowType({...props.flowType,
                    [QUOTE_FLOW_FLAG_IS_UPSELL] : false,
                    [QUOTE_FLOW_FLAG_IS_ORIGINAL] : true
                })
            }


            console.log('ContractPanel.js:: DONE')

            /*
            if (type == 'direct') {
                $('#company_name').val(data.Name);
            } else {

                if (data.partner_account) {
                    $('#company_name').val(data.partner_account.Name);
                } else {
                    $('#company_name').val(data.Name);
                }
                $('#customer_name').val(data.Name);
            }*/
        }

    }, [
        props.apiData,
        props.selectedAccount, // listen to selectedAccount changes since it's hydrated after apiData is set
        props.isWaitingForData
    ]);

    const forceNewOppUpsellFlow = (forceResetTerm) => {

        props.setUseNewOpportunity(true);
        setFlowFlags(forceResetTerm ? QUOTE_FLOW_TYPE_UPSELL_RESET_EXTEND : QUOTE_FLOW_TYPE_UPSELL_CO_TERM)
        setForceResetTerm(forceResetTerm)

        props.setSelectedOpportunity({
            label: '(QB will create your Upsell Oppty)',
            value: '',
            isDisabled: true
        })
        setShowPanel(true)

        console.log('ContractPanel:: in forceNewOppUpsellFlow() ')
    }


    const setFlowFlags = (quoteType) => {
        props.setQuoteType(quoteType)

        switch (quoteType){ // each quoteType corresponds to a UI button/choice

            case QUOTE_FLOW_TYPE_LPA_MIGRATION:
                props.setFlowType({...props.flowType,
                    [QUOTE_FLOW_FLAG_IS_ORIGINAL] : false,
                    [QUOTE_FLOW_FLAG_IS_UPSELL] : true,
                    [QUOTE_FLOW_FLAG_IS_RENEWAL] : false,
                    [QUOTE_FLOW_FLAG_IS_NEW_RENEWAL] : false,
                    [QUOTE_FLOW_FLAG_IS_FLAT_RENEWAL] : false,
                    [QUOTE_FLOW_FLAG_IS_UPSELL_CO_TERM] : false,
                    [QUOTE_FLOW_FLAG_IS_UPSELL_RESET_TERM] : true
                })
                break;

            case QUOTE_FLOW_TYPE_UPSELL_CO_TERM:
                props.setFlowType({...props.flowType,
                    [QUOTE_FLOW_FLAG_IS_ORIGINAL] : false,
                    [QUOTE_FLOW_FLAG_IS_UPSELL] : true,
                    [QUOTE_FLOW_FLAG_IS_RENEWAL] : false,
                    [QUOTE_FLOW_FLAG_IS_NEW_RENEWAL] : false,
                    [QUOTE_FLOW_FLAG_IS_FLAT_RENEWAL] : false,
                    [QUOTE_FLOW_FLAG_IS_UPSELL_CO_TERM] : true,
                    [QUOTE_FLOW_FLAG_IS_UPSELL_RESET_TERM] : false
                })
                break;

            case QUOTE_FLOW_TYPE_UPSELL_RESET_EXTEND:
                props.setFlowType({...props.flowType,
                    [QUOTE_FLOW_FLAG_IS_ORIGINAL] : false,
                    [QUOTE_FLOW_FLAG_IS_UPSELL] : true,
                    [QUOTE_FLOW_FLAG_IS_RENEWAL] : false,
                    [QUOTE_FLOW_FLAG_IS_NEW_RENEWAL] : false,
                    [QUOTE_FLOW_FLAG_IS_FLAT_RENEWAL] : false,
                    [QUOTE_FLOW_FLAG_IS_UPSELL_CO_TERM] : false,
                    [QUOTE_FLOW_FLAG_IS_UPSELL_RESET_TERM] : true
                })
                break;

            case QUOTE_FLOW_TYPE_RENEWAL:
                props.setFlowType({...props.flowType,
                    [QUOTE_FLOW_FLAG_IS_ORIGINAL] : false,
                    [QUOTE_FLOW_FLAG_IS_UPSELL] : true,
                    [QUOTE_FLOW_FLAG_IS_RENEWAL] : true,

                    // this is a misnomer since we don't know if we'll create a new Renewal Opp
                    // (SFDC does this decision making for us behind the scenes)
                    [QUOTE_FLOW_FLAG_IS_NEW_RENEWAL] : false,

                    [QUOTE_FLOW_FLAG_IS_FLAT_RENEWAL] : false,
                    [QUOTE_FLOW_FLAG_IS_UPSELL_CO_TERM] : false,
                    [QUOTE_FLOW_FLAG_IS_UPSELL_RESET_TERM] : true   // ensures that we capture this as a Reset of Term
                })
                break;

            default:
                break;
        }


    }

    const launchRenewalFlow = () => {
        console.log('ContractPanel:: in launchRenewalFlow()');
        props.setUseNewOpportunity(true);
        setFlowFlags(QUOTE_FLOW_TYPE_RENEWAL)
        setForceResetTerm(forceResetTerm)

        props.setSelectedOpportunity({
            label: '(QB will use the Renewal Oppty)',
            value: '',
            isDisabled: true
        })
        setShowPanel(true)
    }

    const effectiveDateErrorRenewalFlowSwitchPopup = () => {

        let popupContractDateError = $.confirm({
            closeIcon: function(){
                return false; // prevent modal from closing
            },
            lazyOpen: true,
            title: QB_AVATAR_IMG + '<br /><br />Whoa!',
            theme: 'supervan',
            content: '<div style="text-align:left">'
                + 'You selected an <strong>Effective Date</strong> that is beyond your customer\'s current term. That is not possible in the upsell process.'
                + '<br><br>Click <strong>"Change Effective Date"</strong> to correct the effective date and continue with the Upsell process.'
                + '<br><br>Click <strong>"Renewal"</strong> and we will transform this quote into a Renewal, you can continue building it from there.'
                //+ Date(window.qb.data_contract_selected.StartDate) + ' - ' + window.qb.data_contract_selected.EndDate
                //+'<br><br>'
                +'</div>',
            type: 'green',
            columnClass: 'col-md-6',
            buttons: {
                change: {
                    text: "Change Effective Date",
                    keys: ['esc'],
                    action: function(){
                        popupContractDateError.close();
                    }
                },
                renewal: {
                    text: "Renewal",
                    class: 'btn-success',
                    action: function(){
                        launchRenewalFlow()
                        popupContractDateError.close();
                    }
                },
            }
        });

        popupContractDateError.open();
    }


    const launchUpsellFlow = (forceResetTerm) => {
        console.log('ContractPanel:: in launchUpsellFlow()');

        // legacy - shows the CC payment link for upsells
        window.qb.is_upsell = true

            setForceResetTerm(forceResetTerm)
        console.log('opportunity: ', props.selectedOpportunity);

        // set the opp list to the updated list (select first available opp)
        let selectedOpportunity = {};
        if (props.selectedAccount.filteredOpportunities[OPPORTUNITY_TYPE_UPSELL].length > 0) {
            selectedOpportunity = props.selectedAccount.filteredOpportunities[OPPORTUNITY_TYPE_UPSELL][0]
        }
        props.setSelectedOpportunity(selectedOpportunity)

        if(!hasFilteredOpportunities(QUOTE_FLOW_FLAG_IS_UPSELL)){
            console.log("No Existing Open Opportunities found under the selected account, forcing the 'NEW' flow");
            // this will skip the "NEW" vs "CHANGE" prompt and will go directly to "NEW"
            forceNewOppUpsellFlow(forceResetTerm);
            return true;
        }

        let upsellPopup = $.confirm({
            closeIcon: function(){
                location.reload(); // reload the page
                return false; // prevent modal from closing
            },
            lazyOpen: true,
            title: window.qb.avatar + 'Blimey !!!',
            theme: 'supervan',
            content: '<div style="text-align:left">'
                // +'You have selected an account marked as an existing customer.'
                // +'<br><br>'
                + 'To create a <strong>New Upsell Opportunity</strong>, click <strong>"NEW"</strong> - this will create a new Upsell Opportunity and Quote'
                +'<br><br>'
                + 'To create a new Quote on an <strong>Existing Upsell Opportunity</strong>, please click <strong>"CHANGE"</strong>'
                +'<ol style="text-align: left">'
                +'<br>'
                + 'Upsell Tips:'
                + '<li>The quote should represent the final total of what you want to sell (ie if the customer has 3 users and you wish to add 2 more, you should select "5")</li>'
                // + '<li>You can either co-term the upsell or reset the term</li>'
                + '<li>Your quote will show the existing products as "Cancelled" and the new add on products as "New"</li>'
                + '</ol></div>',
            type: 'green',
            columnClass: 'col-md-6',
            buttons: {
                confirm: {
                    text: "NEW",
                    // keys: ['enter'],
                    class: 'btn-success',
                    action: function(){
                        forceNewOppUpsellFlow(forceResetTerm);
                    }
                },
                change: {
                    text: "CHANGE",
                    action: function(){
                        props.setUseNewOpportunity(false);
                        setFlowFlags(forceResetTerm ? QUOTE_FLOW_TYPE_UPSELL_RESET_EXTEND : QUOTE_FLOW_TYPE_UPSELL_CO_TERM)
                        displayContracts(forceResetTerm);

                        // TODO
                        // $('label[for*="sf_opps"] select').prop('disabled', null).select2();
                        // $('label[for*="sf_opps"] span.select2').removeClass('disabled');
                    }
                }
            }
        });

        // close all other popups -- can be VERY problematic if we are error-detecting/preventing flows with another popup
        /*
        if(window.qb.popup_missing_error != null) {
            window.qb.popup_missing_error.close();
        }*/
        upsellPopup.open();

    }

    const launchExistingContractFlow = (account, contract) => {

        console.log('in launchExistingContractFlow()');

        if (contract.SBQQ__Evergreen__c != true && account.RecordTypeId === '0121J000001QGl7QAG') {
            // CAO / LPA / LP Auto
            // non-evergreen contract for
            let msg = 'Selected Account Record Type / Contract is not supported at this time. ';
            error_modal('Account / Contract Information Error',
                msg + getContactFieldOpsText(account, null, null, msg),
                getContactFieldOpsPopupButton(account, null, null, msg)
            )
            return
        }


        if(contract.SBQQ__Evergreen__c){
            setFlowFlags(QUOTE_FLOW_TYPE_LPA_MIGRATION);
            launchUpsellFlow(true);
            return;
        }



        /*
        // close all other popups -- can be VERY problematic if we are error-detecting/preventing flows with another popup
        if(window.qb.popup_missing_error != null) {
            window.qb.popup_missing_error.close();
        }
        */
        existingContractPopup.open()

    }

    const hasWhatsAppUsageData = () => {
        return Object.entries(props.apiData).length > 0
            && Object.entries(props.apiData.recent_whatsapp_usage.months_years).length > 0
    }

    const getWhatsAppUsageButton = {
        text: "Review WhatsApp Usage",
        // keys: ['esc'],
        action: function () {
            props.handlePricingModelChange(PRICING_MODEL_WHATSAPP_USAGE, {selectedMonth: Object.keys(props.apiData.recent_whatsapp_usage.months_years)[0]})
            try{
                existingContractPopup.close()
            } catch (e){
                console.log(e)
            }
        }
    }





    const displayContracts = (forceResetTerm=false) => {

        setForceResetTerm(forceResetTerm)

        if (props.selectedAccount.contracts.length == 0){
            props.setFlowType(DEFAULT_FLOW_STATE)
            setShowPanel(false)
            return false
        } else {
            setShowPanel(true)
        }
    }

    const handleContractSelection = (contractId) => {
        props.setContractId(contractId)
    }

    const handleResetTermChange = (event) => {
        props.setResetTerm(event.target.checked)
    }



    let upsell_coterm_button = {
        text: "Upsell Co-terminous",
        // keys: ['enter'],
        class: 'btn-success',
        action: function () {
            setFlowFlags(QUOTE_FLOW_TYPE_UPSELL_CO_TERM);
            launchUpsellFlow(false);
            existingContractPopup.close()
        }
    };

    let upsell_reset_button = {
        text: "Upsell and Reset/Extend Term",
        // keys: ['enter'],
        class: 'btn-success',
        action: function () {
            setFlowFlags(QUOTE_FLOW_TYPE_UPSELL_RESET_EXTEND);
            launchUpsellFlow(true);
            existingContractPopup.close()
        }
    }
    let upsell_renewal_button = {
        text: "Renewal",
        // keys: ['esc'],
        action: function () {
            launchRenewalFlow();
            upsell_coterm_button
            existingContractPopup.close()
        }
    }

    let existingContractPopup = $.confirm({
        closeIcon: function () {
            location.reload(); // reload the page
            return false; // prevent modal from closing
        },
        lazyOpen: true,
        title: window.qb.avatar + '¡Ay, caramba!',
        theme: 'supervan',
        content: '<div style="text-align:left">'
            + 'We noticed that you selected one of our beloved existing customers. QB can help you in the following wonderful ways:'
            + '</div>',
        type: 'green',
        columnClass: 'col-md-6',
        buttons:
            hasWhatsAppUsageData()
                ? {upsell_coterm_button, upsell_reset_button, upsell_renewal_button, getWhatsAppUsageButton}
                : {upsell_coterm_button, upsell_reset_button, upsell_renewal_button}
    })






    /**
     *         setForceResetTerm(forceResetTerm)
     */
    return (
        showPanel && Object.entries(props.contract).length > 0 &&
        <>
            <div className="existing-customer">
                <hr />
                    <div className="row">
                        <div className="col-md-12">
                            <h3>Current Subscriptions</h3>
                            <table className="table subs">
                                <thead>
                                <tr>
                                    <th>
                                        <label>
                                            <input type="radio"
                                                   name="ammendContract"
                                                   onChange={(e) => handleContractSelection(props.contract.Id)}
                                                   className="info-field" value={props.contract.Id}
                                                   checked={true}
                                            />&nbsp;Contract&nbsp;{props.contract.ContractNumber}
                                            &nbsp;<span style={{fontWeight:"normal"}}>
                                                (
                                                    {formatDate(new Date(props.contract.StartDate), DATE_FORMAT)} -
                                                    {props.contract.EndDate
                                                        ? formatDate(new Date(props.contract.EndDate), DATE_FORMAT)
                                                        : 'Evergreen'
                                                    }
                                                )
                                            </span>
                                        </label>
                                    </th>
                                    <th style={{textAlign:"right"}}>Quantity</th>
                                    <th style={{textAlign:"right"}}>Monthly Fee</th>
                                </tr>
                                </thead>

                                <tbody>
                                {Object.entries(props.contract.subscriptions).map(([key, item]) => (
                                    <tr key={key}>
                                        <td>&nbsp;&nbsp;&nbsp;{item.ProductName}</td>
                                        <td style={{textAlign: "right"}}>{item.SBQQ__Quantity__c}</td>
                                        <td style={{textAlign: "right"}}>{(props.contract.symbol ? props.contract.symbol : '')}{item.RMR_Quote_Line__c}</td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                            <p style={{width:600,textAlign:"center", margin: "0 auto 10px"}}>
                                Continuing will amend the
                                existing contract and generate an amendment opportunity. You will not be able to select
                                another opportunity.
                            </p>
                            <label htmlFor="reset_term_2" name="reset_term_2" className="price-table">
                                <input type="checkbox"
                                 id="reset_term_2"
                                 name="reset_term_2"
                                 className={'price-table info-field' + (props.resetTerm ? ' active' : '')}

                                /**
                                *   "Reset term" means the rep can select a new term, 12, 18 months, etc
                                *   If reset is not ticked, then the contract is ‘coterminous’ therefore, its term is 'as per prior order'
                                */
                                 checked={
                                     props.resetTerm    // already checked manually
                                     || forceResetTerm  // forced checked by the flow
                                     || props.flowType[QUOTE_FLOW_FLAG_IS_UPSELL_RESET_TERM]
                                     || props.flowType[QUOTE_FLOW_FLAG_IS_RENEWAL]
                                 }

                                 disabled={
                                     props.flowType[QUOTE_FLOW_FLAG_IS_UPSELL_RESET_TERM]
                                     || props.flowType[QUOTE_FLOW_FLAG_IS_RENEWAL]
                                     ? 'disabled' : ''
                                 }
                                 onChange={handleResetTermChange}

                            />
                                    Reset contract term
                            </label>
                        </div>
                    </div>
            </div>
        </>

    )

}

