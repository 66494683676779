// !minifyOnSave

console.log('Account JS Loaded.');

/**
 * Reseller_Parent__c: null
 * Id: "0011J00001OAxvkQAD"
 * Name: "Test Quote Builder (Direct)"
 * Type: "Prospect"
 * Website: null
 * OwnerId: "005o0000003aYl7AAE"
 * Industry: null
 * ParentId: null
 * Legal_Entity__c: "LP Inc"
 * LPSiteID1__c: "39415396"
 * VAT__c: null
 * Registration_Tax_ID__c: null
 * Zuora_Legal_Entity_ID__c: null
 * is_prospect: true
 * is_client: false
 * is_unpaid_trial: false
 * current_contract: null
 * web_link: "https://liveperson--Staging.cs1.my.salesforce.com/0011J00001OAxvkQAD"
 * partner_account: null
 * contracts: []
 */

export default class Account {

    constructor(data) {


        console.log('in Account JS constructor');
        this.data = data;
        return this;
    }

    isPartner(){
        if (!this.data) {
            return false;
        }
        return this.data.Type == 'Partner' || this.data.partner_account != null;
    }

    getData(key){

        let keys = ['Reseller_Parent__c',
            'Id',
            'Name',
            'Type',
            'Website',
            'OwnerId',
            'Industry',
            'ParentId',
            'Legal_Entity__c',
            'LPSiteID1__c',
            'VAT__c',
            'Registration_Tax_ID__c',
            'Zuora_Legal_Entity_ID__c',
            'is_prospect',
            'is_client',
            'is_unpaid_trial',
            'current_contract',
            'web_link',
            'partner_account',
            'contracts',
        ]

        if(!keys.includes(key)){
            throw "Invalid key: "+key+" in Account::getData()";
        } else {
            return this.data[key];
        }
    }

}

