import React, {useEffect, useState} from 'react';
import Select from 'react-select';
import './BillingPanel.css';
import { format as formatDate, addWeeks } from 'date-fns'
import {error_modal, info_modal, PRICING_MODEL_CPI, PRICING_MODEL_VELOCITY, QUOTE_FLOW_FLAG_IS_RENEWAL} from "./QBApp";
import {BILLING_PERIOD_MONTHLY_SELECT_OPTION} from "./cpi/CpiInteractionCostPanel";
import {getVelocityTermInMonthsFromProductName} from "./VelocityPackagesPanel";
import {getContactFieldOpsPopupButton, getContactFieldOpsText} from "../modals/ModalUtil";

/**
 *
 * Billing field rules, overrides, etc are documented at
 * https://docs.google.com/spreadsheets/d/1OUlodB26G0NBYePkvPofceo9tO21UVEG4DqG5kWygEE/edit#gid=0
 *
 */
export default function BillingPanel(props) {

    const [billingContacts, setBillingContacts] = useState([]);

    const isCreditCardFlow = props.billingDetails['payment_method'] && props.billingDetails['payment_method'].value == 'card';
    const handleBillingPeriodChange = (event) => {
        console.log('BillingPanel.js:handleBillingPeriodChange() event: ', event);
        let billing_period = {'label' : event.label, 'value' : event.value}
        props.setBillingDetails({...props.billingDetails, billing_period});
    }

    const handleSubscriptionTermChange = (event) => {
        console.log('BillingPanel.js:handleSubscriptionTermChange() event: ', event);
        let subscription_term = {'label' : event.label, 'value' : event.value}
        props.setBillingDetails({...props.billingDetails, subscription_term});
    }
    const handlePaymentTermsChange = (event) => {
        console.log('BillingPanel.js:handlePaymentTermsChange() event: ', event);
        let payment_terms = {'label' : event.label, 'value' : event.value}
        props.setBillingDetails({...props.billingDetails, payment_terms});
    }
    const handlePaymentMethodChange = (event, isLocked=false) => {
        console.log('BillingPanel.js:handlePaymentMethodChange() event: ', event);
        let payment_method = {'label' : event.label, 'value' : event.value}
        if(event.value === 'card'){
            let upon_signature  = true;
            let effective_date = defaultEffectiveDate;
            props.setBillingDetails({...props.billingDetails, payment_method, upon_signature, effective_date});
        } else {
            props.setBillingDetails({...props.billingDetails, payment_method });
        }
    }
    const handleAutoRenewChange = (event) => {
        console.log('BillingPanel.js:handleAutoRenewChange() event: ', event);
        let auto_renew = {'label' : event.label, 'value' : event.value}
        props.setBillingDetails({...props.billingDetails, auto_renew});
    }
    const handleUponSignatureChange = (event) => {
        console.log('BillingPanel.js:handleUponSignatureChange() event.target.checked: ', event.target.checked);
        let upon_signature = event.target.checked
        if(event.target.checked) { // reset the effective date back to default
            let effective_date = defaultEffectiveDate
            props.setBillingDetails({...props.billingDetails, upon_signature, effective_date })
        } else {
            props.setBillingDetails({...props.billingDetails, upon_signature })
        }
    }
    const handleEffectiveDateChange = (event) => {

        // BSS-4967 prevents issues with "The subscription term must be a positive number" error
        // validate e.g. if Effective Date was selected and is after the contract end date, we shouldn't allow this...
        // e.g. Current term ends 4/30/2022 and the Effective Date was selected to be 5/14/2022 which is after the end date.
        // console.log('BillingPanel.js:handleEffectiveDateChange() event.target.value: ', event.target.value);
        let effective_date = event.target.value;

        if(new Date(props.contract.EndDate).getTime() < new Date(effective_date)){

            let msg = 'Effective Date cannot be after the contract end date'
            info_modal('Contract Information Error', msg, {close: {text: 'Close'}});
            return false
        }

        props.setBillingDetails({...props.billingDetails, effective_date});
    }
    const handlePricingValidChange = (event) => {
        console.log('BillingPanel.js:handlePricingValidChange() event.target.value: ', event.target.value);
        let pricing_valid = event.target.value;
        props.setBillingDetails({...props.billingDetails, pricing_valid});
    }
    const handleAccountContactChange = (event) => {
        console.log('BillingPanel.js:handleAccountContactChange() event: ', event);
        if(validateBillingContact(event)){
            // select the contact if it's valid, otherwise do not update the selection
            let sf_contacts = {'label' : event.Name, 'value' : event.Id}
            props.setBillingDetails({...props.billingDetails, sf_contacts})
        }
    }
    // TODO: By Dec 18th -- drop reset functionality in CPQ and do replace instead
    // TODO: All CC successful will set the Account to LP Inc (handled by SFDC)

    // TODO: collapse tables
    // TODO: bottom billing details -- not round
    // TODO: Cost Calculator width for discount -- smaller and right column wider
    // TODO: match font sizes
    // TODO: rm Discounted Unit Price => rename to Unit Price
    // TODO: currency in Export didn't work
    const validateBillingContact = (contact) => {
        // we no longer validate this since we push everything to LP Inc/1000
        return true;
        /*
        if('corp_entity_mismatch' in contact && contact.corp_entity_mismatch === true) {
            window.qb.missing_error(
                'US/Canada Billing Contact is required for this Account',
                'The selected SFDC Account is "LP Inc" but the selected Contact is outside of the US/Canada.'
                +'<br><br>'
                +'<a class="btn btn-lg btn-primary" href="' + props.apiData.account.web_link + '" target="_blank">'
                +'Open in SalesForce.</a></strong>'
                , {close: {text: 'Close'}}
            )
            return false
        }
        return true
        */
    }

/*
    const twoWeeksFromToday = () => {
        console.log('BillingPanel.js::twoWeeksFromToday() START');
        let dateString = formatDate(addWeeks(new Date(), 2), DATE_FORMAT);
        console.log('BillingPanel.js::twoWeeksFromToday() ', dateString);
        return dateString;
    }
*/
    const getFieldDefaultOptions = (fieldName) => {
        return Object.entries(props.pricing[fieldName].options).map(
            function ([index, value]) {
                // console.log(fieldName + ' -- options: ' + 'value: ' + value +  ' label: ' + index);
                return {'value': index, 'label' : value}
            }
        )
    }

    const getDueOnReceiptPaymentTermOption = () => {
        return {label: 'Due on receipt', value: 'Due on receipt'}
    }

    const loadAccountContacts = (contacts, account) => {

        if(contacts && contacts.length > 0) {

            console.log('BillingPanel::getAccountContacts --- contacts ', contacts);

            let isFirstValidOption = false
            let billingContacts = Object.entries(contacts).map(
                function ([index, option]) {
                    // console.log('contacts : --------- ', contacts);
                    console.log('BillingPanel::accountContacts contact processing:', option)

                    if (option.MailingAddress &&
                        option.FirstName &&
                        option.LastName &&
                        option.Email &&
                        option.Phone &&
                        option.MailingAddress.street &&
                        option.MailingAddress.city &&
                        option.MailingAddress.country
                    ) {

                        if (option.MailingAddress.country.toLowerCase() == 'United States'.toLowerCase()
                            || option.MailingAddress.country.toLowerCase() == 'Canada'.toLowerCase()) {
                            if (option.MailingAddress.state && option.MailingAddress.postalCode) {
                                // pass e.g. have state & zip for US & Canada
                                option.value = option.Id
                                option.label = option.Name
                                if(!isFirstValidOption){
                                    option.isFirstValidOption = true;

                                    // populate the default state for the drop down sf_contacts
                                    let sf_contacts = {'label' : option.Name, 'value' : option.Id}
                                    props.setBillingDetails({...props.billingDetails, sf_contacts})
                                }

                            } else {
                                // Disable the option and mark it as missing info.
                                option.isDisabled = true
                                option.value = option.Id + '-invalid'
                                option.label = option.Name + ' - Missing Info';
                            }
                        } else if (
                            option.MailingAddress.country.toLowerCase() != 'United States'.toLowerCase()
                            && option.MailingAddress.country.toLowerCase() != 'Canada'.toLowerCase()
                            && account != null
                            && 'Legal_Entity__c' in account
                            && account.Legal_Entity__c == 'LP Inc'
                        ) { // outside US but under LP Inc

                            console.log('BillingPanel::accountContacts billing contact flagged for corp_entity_mismatch:', option.Id)
                            // LP Inc AND non-us/Canada
                            option.value = option.Id + '-invalid'
                            option.label = option.Name + ' *'
                            option.corp_entity_mismatch = true

                        } else { // outside US, not matching other criteria
                            option.value = option.Id
                            option.label = option.Name
                        }
                    } else {
                        // Disable the option and mark it as missing info.
                        option.isDisabled = true
                        option.value = option.Id + '-invalid'
                        option.label = option.Name + ' - Missing Info'
                    }

                    return option
                }
            );

            // console.log('setBillingContacts(billingContacts): ', billingContacts)
            setBillingContacts(billingContacts);
            props.setBillingContacts(billingContacts); // for higher level modules for sharing
        } else {
            setBillingContacts({});
            props.setBillingContacts({}); // for higher level modules for sharing
        }
    }


    useEffect(() => {


        console.log(`BillingPanel.js:useEffect() apiData : `, props.apiData);
        loadAccountContacts(props.apiData.contacts, props.apiData.account);


        let data = {}
        data['sf_contacts'] = {} // reset billing contacts first

        // IMPLICITLY SET ALL FIELD VALUES, so we cna implicitly access them in ContractPanel
        if(props.apiData.contacts) {
            BILLING_FIELDS_WITH_SET_DEFAULT_VALUES.forEach(function (v) {
                if(props.pricing[v]) {
                    data[v] = getFieldDefaultValue(props.pricing, v);
                }
            })
            data['sf_contacts'] = {...billingContacts}
        }


        // pricing model specific default overrides (fields locked as well in-place below)
        if(props.pricingModel === PRICING_MODEL_VELOCITY){
            data['billing_period'] = 'Upfront'

            console.log('props.velocitySelected: ',props.velocitySelected)
            console.log('props.pricing.velocity_packages.options: ', props.pricing.velocity_packages.options)
            if(typeof props.velocitySelected !== "undefined" && props.velocitySelected !== '') {
                // e.g. 4 or 6 month term
                let package_subscription_term = getVelocityTermInMonthsFromProductName(props.pricing.velocity_packages.options[props.velocitySelected].title)
                data['subscription_term'] = {label: package_subscription_term+' Months', value: parseInt(package_subscription_term)}

            } else {
                data['subscription_term'] = BILLING_SUBSCRIPTION_TERM_6_MONTHS_SELECT_OPTION
            }

            data['payment_method'] = BILLING_PAYMENT_METHOD_ACH_WIRE_SELECT_OPTION
            data['effective_date'] = BILLING_EFFECTIVE_DATE_UPON_SIGNATURE_INPUT
        } else if(props.pricingModel === PRICING_MODEL_CPI){
            // CPI defaults to Monthly billing as per ahecker 07/30/2020
            data['billing_period'] = BILLING_PERIOD_MONTHLY_SELECT_OPTION
        }

        if(Object.entries(data).length >0){
            let updatedBillingDetails = {...props.billingDetails, ...data}
            props.setBillingDetails(updatedBillingDetails)
            //console.log('BillingPanel.js:useEffect() setting default values for props.billingDetails ', props.billingDetails);
            //console.log('BillingPanel.js:useEffect() setting default values for getFieldDefaultValue ', data);
            console.log('BillingPanel.js:useEffect() setting default values for billingContacts ', billingContacts);
        }

    }, [props.apiData, props.flowType, props.pricingModel, props.velocitySelected]);


    function getPaymentTermsFormField(){
        return <label htmlFor="payment_terms">
            Payment Terms
            <Select
                id="payment_terms"
                placeholder={"Loading..."}
                onChange={handlePaymentTermsChange}
                isSearchable={false}
                value={
                    isCreditCardFlow
                        ? getDueOnReceiptPaymentTermOption()
                        : (props.billingDetails['payment_terms'] ?? getFieldDefaultValue(props.pricing,'payment_terms'))
                }
                options={getFieldDefaultOptions('payment_terms')}
                // lock payment terms to "Due on receipt" when card is selected
                isDisabled={(isCreditCardFlow)}
            ></Select>
        </label>
    }
    function getPaymentMethodFormField(){

        let options = getFieldDefaultOptions('payment_method')

        if(props.pricingModel === PRICING_MODEL_CPI) {
            // remove CC option for CPI pricing model
            options.forEach(function (item, index) {
                if (item.value === 'card') {
                    // console.log('removing payment method CC in Upsell flow ~~~')
                    delete options[index]
                }
            })
        }


        // keeping the CC option for upsells to generate the OF BUT won't show the CC Link button
        /*if(props.flowType.isUpsell){
            // remove CC option for upsells
            options.forEach(function(item, index) {
                if(item.value === 'card') {
                    // console.log('removing payment method CC in Upsell flow ~~~')
                    delete options[index]
                }
            })
        }*/


        return <label htmlFor="payment_method">
            Payment Method
            <Select
                id="payment_method"
                placeholder={"Loading..."}
                onChange={handlePaymentMethodChange}
                isSearchable={false}
                value={
                    props.pricingModel === PRICING_MODEL_VELOCITY
                        ? BILLING_PAYMENT_METHOD_ACH_WIRE_SELECT_OPTION
                        : (
                            props.billingDetails['payment_method']
                            ?? getFieldDefaultValue(props.pricing,'payment_method')
                        )
                }
                options={options}
                isDisabled={props.pricingModel === PRICING_MODEL_VELOCITY}
            ></Select>
        </label>
    }
    function getAutoRenewFormField(){
        return <label htmlFor="auto_renew">
            Auto Renew
            <Select
                id="auto_renew"
                placeholder={"Loading..."}
                onChange={handleAutoRenewChange}
                isSearchable={false}
                value={props.billingDetails['auto_renew'] ?? getFieldDefaultValue(props.pricing, 'auto_renew')}
                options={getFieldDefaultOptions('auto_renew')}
            ></Select>
        </label>
    }

    return (
        Object.entries(props.pricing).length > 0 &&
        <>
            <div className="panel panel-default" id="panel_billing">
                <div className="panel-heading panel-darkgrey">Billing Details<span className="collapse-option"></span>
                </div>
                <div className="panel-body">
                    <div className="row row-spacer2">

                        {props.pricingModel !== PRICING_MODEL_CPI &&
                        <>
                        <div className="col-sm-4">
                            <label htmlFor="billing_period">
                                Billing Period
                                <Select
                                    id="billing_period"
                                    // placeholder={"Loading..."}
                                    onChange={handleBillingPeriodChange}
                                    isSearchable={false}
                                    value={
                                        props.pricingModel === PRICING_MODEL_VELOCITY
                                            ? {label: 'Upfront', value: 'Upfront'}
                                            : (
                                                props.billingDetails['billing_period']
                                                ?? getFieldDefaultValue(props.pricing,'billing_period')
                                            )
                                    }
                                    options={getFieldDefaultOptions('billing_period')}
                                    isDisabled={props.pricingModel === PRICING_MODEL_VELOCITY}
                                ></Select>
                            </label>
                        </div>
                        <div className="col-sm-4 hidden_when_implementation_only">
                            <label htmlFor="subscription_term">
                                Subscription Term
                                <Select
                                    id="subscription_term"
                                    placeholder={"Loading..."}
                                    onChange={handleSubscriptionTermChange}
                                    isSearchable={false}
                                    value={
                                        props.pricingModel === PRICING_MODEL_VELOCITY
                                            ? (props.velocitySelected
                                                ? {
                                                    label: getVelocityTermInMonthsFromProductName(props.pricing.velocity_packages.options[props.velocitySelected].title)+' Months',
                                                    value: getVelocityTermInMonthsFromProductName(props.pricing.velocity_packages.options[props.velocitySelected].title)
                                                  }
                                                : BILLING_SUBSCRIPTION_TERM_6_MONTHS_SELECT_OPTION
                                            )
                                            : (
                                                props.billingDetails['subscription_term']
                                                ?? getFieldDefaultValue(props.pricing,'subscription_term')  
                                            )
                                    }
                                    options={getFieldDefaultOptions('subscription_term')}
                                    /**
                                     *   "Reset term" means the rep can select a new term, 12, 18 months, etc
                                     *   If reset is not ticked, then the contract is ‘coterminous’ therefore, its term is 'as per prior order'
                                     */
                                    isDisabled={
                                        props.pricingModel === PRICING_MODEL_VELOCITY ||
                                        (props.resetTerm === false ? true : false)
                                    }
                                ></Select>
                            </label>
                        </div>
                        </>
                        }


                        <div className={"col-sm-4" + (props.pricingModel === PRICING_MODEL_CPI ? ' marginTop14' : '')}>
                            {getPaymentTermsFormField()}
                        </div>
                        <div className={"col-sm-"
                        + ((props.pricingModel !== PRICING_MODEL_VELOCITY && props.pricingModel !== PRICING_MODEL_CPI)
                            ? "6"
                            : "4")
                        + " payment_method marginTop14"}>
                            {getPaymentMethodFormField()}
                        </div>

                        {props.pricingModel !== PRICING_MODEL_VELOCITY
                        &&
                        <div className={"col-sm-"+
                        (props.pricingModel === PRICING_MODEL_CPI ? 4 : 6)
                        +" hidden_when_velocity hidden_when_implementation_only marginTop14"}>
                            {getAutoRenewFormField()}
                        </div>
                        }

                        <div className="col-sm-4 effective_date">
                            <label htmlFor="effective_date" className="mt-3 select-mt">
                                Effective Date <span className="required">*</span>
                                <input
                                    type={props.pricingModel === PRICING_MODEL_VELOCITY ? "text" : "date"}
                                    disabled={
                                        props.flowType[QUOTE_FLOW_FLAG_IS_RENEWAL] // disabled for Renewals
                                    }
                                    className="form-control info-field required"
                                    id="effective_date"
                                    required={true}
                                    value={
                                        props.pricingModel === PRICING_MODEL_VELOCITY
                                            // set to a hardcoded text value
                                            ? BILLING_EFFECTIVE_DATE_UPON_SIGNATURE_INPUT
                                                // set to a date
                                            : (
                                                    (props.flowType[QUOTE_FLOW_FLAG_IS_RENEWAL] && props.contract.SBQQ__ExpirationDate__c)
                                                        // set to next calendar day for Renewals
                                                        ? getContractRenewalDate(props.contract)
                                                        : (props.billingDetails['effective_date'] ?? defaultEffectiveDate)
                                            )

                                    }
                                    onChange={handleEffectiveDateChange}
                                />
                                    <p className="error-text">Effective date is required.</p>
                            </label>
                        </div>

                        {props.pricingModel !== PRICING_MODEL_VELOCITY
                        &&
                        <div className="col-sm-4 hidden_when_velocity">
                            <label htmlFor="pricing_valid" className="mt-3">
                                Pricing Valid Until <span className="required">*</span>

                                <input
                                    type="date"
                                    className="form-control info-field required"
                                    id="pricing_valid"
                                    required
                                    value={props.billingDetails['pricing_valid'] ?? defaultPricingValidUntil}
                                    onChange={handlePricingValidChange}
                                />

                                <p className="error-text">Pricing expiration date is required.</p>


                            </label>
                        </div>
                        }

                        <div className="col-sm-4">

                                <label className="mt-3" htmlFor="sf_contacts search">Billing Contact <span
                                    className="required">*</span>

                                    <Select
                                        id="sf_contacts"
                                        placeholder={"Select billing contact..."}
                                        onChange={handleAccountContactChange}
                                        isSearchable={false}
                                        value={
                                            (props.billingDetails && 'sf_contacts' in props.billingDetails)
                                            ? props.billingDetails['sf_contacts']
                                            : {}
                                        }
                                        //value={
                                            // billingDetails <=== has the contact ID
                                            //getAccountContacts() ? ((option) => option.isFirstValidOption === true) : {}}
                                        options={billingContacts}

                                    ></Select>

                                    {/*<code>{JSON.stringify(billingContacts)}</code>*/}
                                    <p className="error-text">Billing contact is required.</p>
                                </label>


                        </div>
                    </div>

                </div>
            </div>
        </>

    )



}

export const getFieldDefaultValue = (pricing, fieldName) => {
    if(pricing[fieldName]) {
        console.log('DEFAULT FIELD VALUE for : ' + fieldName + ' : ' + pricing[fieldName].options[pricing[fieldName].default])
        return {
            'label': pricing[fieldName].options[pricing[fieldName].default],
            'value': pricing[fieldName].default
        }
    }
}

export const DATE_FORMAT = 'yyyy-MM-dd'

// set to next calendar day for Renewals
export const getContractRenewalDate = (contract) => {
    if(!contract || !("SBQQ__ExpirationDate__c" in contract)){
        return '';
    } else {
        return formatDate(new Date(new Date(contract.SBQQ__ExpirationDate__c).getTime() + 86400000), DATE_FORMAT);
    }
}

export const defaultEffectiveDate = formatDate(new Date(), DATE_FORMAT) // today's date
export const defaultPricingValidUntil = formatDate(addWeeks(new Date(), 2), DATE_FORMAT)
export const BILLING_PAYMENT_METHOD_ACH_WIRE_SELECT_OPTION = {label: 'ACH / Wire Transfer', value: 'ach'}
export const BILLING_SUBSCRIPTION_TERM_6_MONTHS_SELECT_OPTION = {label: '12 Months', value: 12}
export const BILLING_EFFECTIVE_DATE_UPON_SIGNATURE_INPUT = 'Upon Signature'
// export const BILLING_PERIOD_ANNUAL_SELECT_OPTION = {label: 'Annual', value: 'Annual'}
export const BILLING_FIELDS_WITH_SET_DEFAULT_VALUES = [

    // we have defaults for these in the API
    "billing_period",
    "subscription_term",
    "payment_terms",
    "payment_method",
    "auto_renew",
    "pooling_frequency", // used by CPI
    /*
    // these get filled from UI
    "upon_signature",
    "effective_date",
    "pricing_valid",
    "sf_contacts",
    */
]
